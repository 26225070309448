import React from 'react';

import { SvgIcon } from '@mui/material';

import styles from '../styles';

import type { SvgIconProps } from '@mui/material';

import { mergeSx } from '@/utils';

function FilterSettings({ viewBox = '0 0 24 24', sx = {}, ...rest }: SvgIconProps) {
  return (
    <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
      <path
        d="M10.6826 1.74756L10.6826 3.52021M10.6826 14.2522L10.6826 9.84443"
        stroke="#11223C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.35059 1.74756L5.35059 6.29904M5.35059 14.2523L5.35059 12.5753"
        stroke="#11223C"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <ellipse
        cx="10.6492"
        cy="6.73145"
        rx="1.52467"
        ry="1.52467"
        transform="rotate(90 10.6492 6.73145)"
        stroke="#11223C"
        strokeWidth="1.5"
      />
      <ellipse
        cx="5.35082"
        cy="9.36451"
        rx="1.52467"
        ry="1.52467"
        transform="rotate(90 5.35082 9.36451)"
        stroke="#11223C"
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
}

export default FilterSettings;
