export const styles = {
  dateField: {
    display: 'flex',
    gap: {
      xs: 0.5,
      sm: 1.5,
    },
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
  },
} as const;
