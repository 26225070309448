export const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textField: {
    width: '100%',
    height: 52,
    mt: 1,
  },
  textFieldInout: {
    min: 0,
    sx: {
      fontSize: { md: 24, xs: 22 },
      textAlign: 'center',
      height: 52,
      padding: 0,
      borderRadius: 0.5,
    },
  },
  time: {
    width: '48%',
  },
} as const;
