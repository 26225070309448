import React, { memo } from 'react';

import { Close } from '@mui/icons-material';
import { IconButton, FormControl, TextField, Typography, FormHelperText } from '@mui/material';

import { styles } from './styles';
import { usePhoneInput } from './usePhoneInput';

import type { IPhoneInputProps } from './types';
import type { TextFieldProps } from '@mui/material';

const PhoneInput = memo(
  ({
    error,
    value,
    onChange,
    clearable,
    formControlSx,
    ...props
  }: Omit<TextFieldProps, 'onChange' | 'error'> & IPhoneInputProps) => {
    const { t, phone, phoneNumberCode, onClearInput, onPhoneTextChange, numberCodeColor } =
      usePhoneInput({
        value,
        onChange,
      });

    return (
      <FormControl sx={formControlSx} fullWidth>
        {/*<InputMask mask="999999999" value={phone} onChange={onPhoneTextChange}>*/}
        <TextField
          value={phone}
          onChange={onPhoneTextChange}
          sx={styles.input}
          placeholder={t('enterNumber')}
          error={!!error}
          InputProps={{
            startAdornment: (
              <Typography color={numberCodeColor} sx={styles.code}>
                {phoneNumberCode}
              </Typography>
            ),
            endAdornment: clearable && (
              <IconButton size="small" onClick={onClearInput}>
                <Close fontSize="small" />
              </IconButton>
            ),
          }}
          {...props}
        />
        {/*</InputMask>*/}
        {error && <FormHelperText error={!!error}>{t(error as string)}</FormHelperText>}
      </FormControl>
    );
  },
);

PhoneInput.displayName = 'PhoneInput';

export default PhoneInput;
