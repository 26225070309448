import { typographyClasses } from '@mui/material';

import palette from '@/constants/theme/palette';

const styles = {
  hintWrapper: {
    pt: 2,
    pb: {
      xs: 0,
      md: 3,
    },
  },
  container: {
    px: {
      xs: 2,
      sm: 4,
    },
    py: {
      xs: 2,
      sm: 3,
    },
    backgroundColor: palette.primary.yellow['60'],
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    [`& .${typographyClasses.root}`]: {
      lineHeight: 1.5,
      color: palette.text.primary,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'end',
  },
};

export { styles };
