import { useCallback, useMemo, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import type { IPlaybackRuleFormProps } from './types';
import type { SelectChangeEvent } from '@mui/material';

import { pcTypeComponents, tmTypeComponents } from '@/constants';
import { PlayConditionTypeEnum, TimeRuleTypeEnum } from '@/graphql-schema';
import { usePlaybackTypeValues } from '@/hooks';

export const usePlaybackRuleForm = ({
  values,
  touched,
  errors,
  onFieldValueChange,
}: IPlaybackRuleFormProps) => {
  const { t } = useTranslation();
  const firstRender = useRef(true);

  const { pcTypeValues, tmTypeValues } = usePlaybackTypeValues();

  const { pcType, tmType, phoneGroupId, pcParams } = values;

  const isGroup = pcType === PlayConditionTypeEnum.Group;

  const pcValue = isGroup ? `${phoneGroupId}` : pcParams;

  const pcParamsError =
    pcType === PlayConditionTypeEnum.Group
      ? touched?.phoneGroupId && errors?.phoneGroupId
      : touched?.pcParams && errors?.pcParams;

  const PcTypeComponent = useMemo(
    () => pcTypeComponents[pcType ?? PlayConditionTypeEnum.Default],
    [pcType],
  );

  const TmTypeComponent = useMemo(
    () => tmTypeComponents[tmType ?? TimeRuleTypeEnum.Always],
    [tmType],
  );

  const setFormikParamsValue = useCallback(
    (name: string) => (value: string) => {
      const isGroupChanged = pcType === PlayConditionTypeEnum.Group && name === 'pcParams';
      const fieldToChange = isGroupChanged ? 'phoneGroupId' : name;
      const parsedValue = isGroupChanged ? parseInt(value) : value;
      onFieldValueChange(fieldToChange, parsedValue);
      firstRender.current = false;
    },
    [pcType],
  );

  const setFormikSelectInputValue = useCallback(
    (name: string) => (e: SelectChangeEvent<unknown>) => {
      onFieldValueChange(name === 'pcType' ? 'pcParams' : 'tmParams', '');
      onFieldValueChange(name, e.target.value as string);
    },
    [],
  );

  return {
    t,
    isGroup,
    pcValue,
    pcTypeValues,
    tmTypeValues,
    pcParamsError,
    PcTypeComponent,
    TmTypeComponent,
    setFormikParamsValue,
    setFormikSelectInputValue,
  };
};
