import React from 'react';

import { SvgIcon } from '@mui/material';

import styles from '../styles';

import type { SvgIconProps } from '@mui/material';

import { theme } from '@/constants';
import { mergeSx } from '@/utils';

const ArrowLeftIcon = ({
  viewBox = '0 0 25 24',
  sx = {},
  width = '25',
  height = '24',
  fill = theme.palette.secondary.main,
  ...rest
}: SvgIconProps) => (
  <SvgIcon
    viewBox={viewBox}
    sx={mergeSx(styles.root, sx)}
    width={width}
    height={height}
    fill="none"
    {...rest}
  >
    <path
      d="M16.037 7.41L14.627 6L8.62695 12L14.627 18L16.037 16.59L11.457 12L16.037 7.41Z"
      fill={fill}
    />
  </SvgIcon>
);

export default ArrowLeftIcon;
