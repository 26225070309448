import { dialogClasses, paperClasses, typographyClasses } from '@mui/material';

import { baseTheme, theme } from '@/constants';

const styles = {
  dialog: {
    [` .${dialogClasses.container}`]: {
      [` .${paperClasses.root}`]: {
        width: '100%',
        maxWidth: 568,
        mx: 1.5,
        px: 3,
        py: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
  },
  form: {
    width: '100%',
  },
  main: {
    boxSizing: 'border-box',
    borderRadius: 2,
    border: `1px solid ${theme.palette.grey['500']}`,
    background: 'white',
    p: 3,
    pb: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 4,
  },
  logoBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    mb: 2,
  },
  title: {
    p: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [`& .${typographyClasses.root}`]: {
      width: {
        sm: 350,
        xs: '100%',
      },
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mt: 2,
    p: 0,
  },
  button: {
    height: 40,
    fontWeight: 500,
    py: 1.5,
    backgroundColor: 'primary.blue',
    color: 'white',
    width: {
      sm: 350,
      xs: '100%',
    },
    '&:hover': {
      backgroundColor: 'primary.blue',
      [baseTheme.breakpoints.down('md')]: {
        boxShadow: 'none',
      },
    },
  },
  action: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    p: 0,
    mt: 2,
  },
  footer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    mt: 2,
  },
  terms: {
    borderRadius: 1,
    cursor: 'pointer',
    px: 1,
    py: 0.5,
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: theme.palette.grey['400'],
      },
    },
  },
};

export { styles };
