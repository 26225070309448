import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import styles from '../styles';

import { theme } from '@/constants';
import { mergeSx } from '@/utils';

function FavoriteIcon({
  viewBox = '0 0 25 24',
  fill = theme.palette.secondary.main,
  sx = {},
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.519 9.12L22.709 9.74L17.259 14.47L18.889 21.5L12.709 17.77L6.52898 21.5L8.16898 14.47L2.70898 9.74L9.89898 9.13L12.709 2.5L15.519 9.12ZM8.94898 18.17L12.709 15.9L16.479 18.18L15.479 13.9L18.799 11.02L14.419 10.64L12.709 6.6L11.009 10.63L6.62898 11.01L9.94898 13.89L8.94898 18.17Z"
        fill={fill}
      />
    </SvgIcon>
  );
}

export default FavoriteIcon;
