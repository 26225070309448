import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { getPcTypeValues, getTmTypeValues } from '@/constants';

const usePlaybackTypeValues = () => {
  const { t } = useTranslation();
  const tmTypeValues = useMemo(() => getTmTypeValues(), [t]);
  const pcTypeValues = useMemo(() => getPcTypeValues(), [t]);
  return { tmTypeValues, pcTypeValues };
};

export default usePlaybackTypeValues;
