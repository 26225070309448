import React from 'react';

import { usePurchaseModalWrapper } from './usePurchaseModalWrapper';

const PurchaseModal = React.lazy(() => import('@/components/modals/PurchaseModal'));

interface IPurchaseModalWrapperProps {
  children: React.ReactNode;
}

export const PurchaseModalWrapper: React.FC<IPurchaseModalWrapperProps> = ({ children }) => {
  usePurchaseModalWrapper();
  return (
    <>
      {children}
      <PurchaseModal />
    </>
  );
};

export default PurchaseModalWrapper;
