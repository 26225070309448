import {
  tableBodyClasses,
  tableCellClasses,
  tableHeadClasses,
  tableRowClasses,
} from '@mui/material';

import { theme } from '@/constants';

const styles = {
  main: {
    [`& .${tableHeadClasses.root}`]: {
      borderBottom: '8px solid transparent',
      [`& .${tableCellClasses.root}`]: {
        p: 1,
        pb: 0.5,
      },
    },
    [`& .${tableBodyClasses.root}`]: {
      [`& .${tableCellClasses.root}`]: {
        p: 1,
      },
      [`& .${tableRowClasses.root}`]: {
        '&:nth-of-type(odd)': {
          background: theme.palette.grey[100],
          [`&>.${tableCellClasses.root}`]: {
            '&:first-of-type': {
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            },
            '&:last-child': {
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            },
          },
        },
      },
    },
    [`& .${tableCellClasses.root}`]: {
      '&:not(:last-child)': {
        borderRight: '12px solid transparent',
      },
    },
    [`& .${tableRowClasses.root}`]: {
      '&:not(:last-child)': {
        borderBottom: '8px solid transparent',
      },
      '&:last-child': {
        [`& .${tableCellClasses.root}`]: {
          borderBottom: 'none',
        },
      },
    },
  },
};

export { styles };
