import { createSlice } from '@reduxjs/toolkit';

import type { IErrorModalState } from './types';

const initialState: IErrorModalState = {
  isModalOpen: false,
  errorMessage: '',
};

export const errorModalSlice = createSlice({
  name: 'errorModal',
  initialState,
  reducers: {
    openErrorModal: (state) => {
      state.isModalOpen = true;
    },
    closeErrorModal: (state) => {
      state.isModalOpen = false;
    },
    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
    clearErrorMessage: (state) => {
      state.errorMessage = '';
    },
  },
});

export const { openErrorModal, closeErrorModal, setErrorMessage, clearErrorMessage } =
  errorModalSlice.actions;

export default errorModalSlice.reducer;
