export const styles = {
  container: {
    mt: {
      md: 10,
      xs: 5,
    },
  },
  content: {
    mt: {
      md: 5,
      xs: 2,
    },
  },
} as const;
