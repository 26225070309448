import { ClientRoutes, ExternalUrls } from '@/constants';
import { useClientConfig } from '@/context';
import { useLocalization } from '@/hooks/index';

const useSharingSocialNetwork = (contentNo: string) => {
  const { isEnSelected, localization } = useLocalization();
  const {
    config: { textForSocialMedia, textForSocialMediaEn },
  } = useClientConfig();

  const textForSocialMediaSelected = isEnSelected ? textForSocialMediaEn : textForSocialMedia;

  const urlToContent = `${window.location.origin}/${localization}${ClientRoutes.Content}/${contentNo}`;

  const shareToSocialNetwork = (url: string) => {
    if (url === ExternalUrls.Facebook)
      return url + urlToContent + '&quote=' + textForSocialMediaSelected;
    if (url === ExternalUrls.Instagram) return;
    if (url === ExternalUrls.Viber) return url + textForSocialMediaSelected + ' ' + urlToContent;
    if (url === ExternalUrls.Telegram)
      return url + urlToContent + '&text=' + textForSocialMediaSelected;
  };

  return {
    shareToSocialNetwork,
  };
};

export default useSharingSocialNetwork;
