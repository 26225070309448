import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ComplexTypeEnum, GiftBoxContentItem } from '@/graphql-schema';
import { openAcceptGiftModal } from '@/redux/accept-gift-modal/slice';
import { openDeclineGiftModal } from '@/redux/decline-gift-modal/slice';
import { formatFullDateFromTimestamp, formatFullTimeFromTimestamp } from '@/utils';

export const useGiftCard = (contentItem: GiftBoxContentItem) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { createdDt, endDt, complexType } = contentItem;

  const formatCreatedDt = useMemo(() => formatFullDateFromTimestamp(createdDt), [createdDt]);
  const formatCreatedTime = useMemo(() => formatFullTimeFromTimestamp(createdDt), [createdDt]);
  const formatEndDt = useMemo(() => formatFullDateFromTimestamp(endDt), [endDt]);
  const formatEndTime = useMemo(() => formatFullTimeFromTimestamp(endDt), [endDt]);
  const isPackage = complexType === ComplexTypeEnum.Package;

  const onAccept = () => dispatch(openAcceptGiftModal(contentItem));

  const onDecline = () => dispatch(openDeclineGiftModal(contentItem));

  return {
    t,
    isPackage,
    formatEndDt,
    formatEndTime,
    formatCreatedDt,
    formatCreatedTime,
    onAccept,
    onDecline,
  };
};
