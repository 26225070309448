import React from 'react';

import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material';

import { styles } from './styles';
import { useSidebarNavigation } from './useSidebarNavigation';

import type { SxProps } from '@mui/material';

import { HelpIcon, PlaybackRulesIcon, RecordMeloringIcon } from '@/components';
import { ClientRoutes, Theme, theme } from '@/constants';
import { mergeSx } from '@/utils';

interface ISidebarNavigationProps {
  sx?: SxProps<Theme>;
  isOpenedByDefault?: boolean;
  isMobileDrawer?: boolean;
}

const SidebarNavigation = ({ sx, isOpenedByDefault }: ISidebarNavigationProps) => {
  const {
    t,
    firstLinkType,
    secondLinkType,
    isPublicPage,
    isHelpPage,
    isMyMeloringDisplayed,
    isRbtAuthorized,
    navigationList,
    currentPath,
    isMobileScreenView,
    isRecordMyMeloringDisplayForNotRbt,
    goToPage,
    openMyMeloringPage,
  } = useSidebarNavigation();

  if (isRecordMyMeloringDisplayForNotRbt) {
    return (
      <>
        <Box
          sx={{
            ...styles.content,
            ...styles.link,
            ...(currentPath === firstLinkType ? styles.selected : {}),
          }}
          onClick={openMyMeloringPage}
        >
          <RecordMeloringIcon />
          <Typography variant="h3" ml={1.5}>
            {t('sideBarNavigation.recordMyMeloring')}
          </Typography>
        </Box>
        {!isMobileScreenView && <Divider sx={{ my: 4 }} />}
      </>
    );
  }

  if (!isRbtAuthorized && !isHelpPage) return null;

  return (
    <Box sx={mergeSx(styles.container, styles.content, sx)}>
      <Typography variant="h1" fontWeight="bold" color="text.primary">
        {t(isHelpPage ? 'help' : 'myProfile')}
      </Typography>
      <Box sx={[styles.navigation, isPublicPage || isHelpPage ? styles.publicNavigation : null]}>
        {!isPublicPage && !isHelpPage ? (
          navigationList.map(({ title, icon: Icon, children }, key) => {
            return (
              <Box key={title} mt={key !== 0 ? 1.25 : 0}>
                <Accordion
                  disableGutters
                  square
                  sx={styles.accordion}
                  defaultExpanded={isOpenedByDefault}
                >
                  <AccordionSummary sx={styles.summary} expandIcon={<KeyboardArrowDown />}>
                    <Box sx={styles.summaryContent}>
                      <Icon />
                      <Typography variant="h3" ml={1.5}>
                        {title}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={styles.details}>
                    {children.map(({ title, icon: Icon, link }, idx) => {
                      const isSelected = currentPath === link;
                      const isLast = children.length - 1 === idx;
                      if (!isMyMeloringDisplayed && link === ClientRoutes.RecordMyMeloring) {
                        return null;
                      }
                      return (
                        <Box
                          key={title}
                          sx={{
                            ...(isSelected
                              ? isLast
                                ? styles.selectedLast
                                : styles.selected
                              : styles.withHover),
                            ...styles.item,
                          }}
                          onClick={goToPage(link)}
                        >
                          <Icon fill={!isSelected ? theme.palette.primary.main : 'white'} />
                          <Typography variant="h3" sx={styles.title}>
                            {title}
                          </Typography>
                        </Box>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              </Box>
            );
          })
        ) : (
          <>
            {(isMyMeloringDisplayed || isHelpPage) && (
              <Box
                sx={{
                  ...styles.link,
                  ...(currentPath === firstLinkType ? styles.selected : {}),
                }}
                onClick={goToPage(firstLinkType)}
              >
                {isHelpPage ? <HelpIcon /> : <RecordMeloringIcon />}
                <Typography variant="h3" ml={1.5}>
                  {isHelpPage ? t('faq') : t('sideBarNavigation.recordMyMeloring')}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                ...styles.link,
                ...(currentPath === secondLinkType ? styles.selected : styles.withHover),
              }}
              onClick={goToPage(secondLinkType)}
            >
              {isHelpPage ? <HelpIcon /> : <PlaybackRulesIcon />}
              <Typography variant="h3" ml={1.5}>
                {isHelpPage ? t('termsAndConditions') : t('sideBarNavigation.setUpPlaybackRules')}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SidebarNavigation;
