import { useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import type { FaultPayload, Maybe } from '@/graphql-schema';

import { ClientRoutes } from '@/constants';
import { useActiveSubscriber, useClientConfig, useKeycloak } from '@/context';
import {
  CHECK_SERVICE_ACTIVATIONS,
  POST_SERVICE_ACTIVATION_FOR_UPLOAD,
} from '@/graphqL-queries/activation';
import { GET_SUBSCRIBER, GET_SUBSCRIBER_CONTENT } from '@/graphqL-queries/contents';
import { PERSONAL_CONTENT_UPLOAD } from '@/graphqL-queries/subscriber';
import { ContentTypeEnum, PathTypeEnum } from '@/graphql-schema';
import { isRbtUserErrorRequest } from '@/helpers';
import { useGraphQlErrorHandler, useLocalization } from '@/hooks/index';
import { recordMyMeloringSelector } from '@/redux/record-my-meloring';
import { changeAudioSelectedStatus } from '@/redux/record-my-meloring/slice';
import { responseErrorHandling } from '@/utils';

const useUploadPersonalContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isDiyAudioSelected } = useSelector(recordMyMeloringSelector);
  const { msisdn } = useKeycloak();

  const [getActiveSubscriber] = useLazyQuery(GET_SUBSCRIBER, {
    fetchPolicy: 'no-cache',
  });
  const [uploadAudioRequest, { loading: isPersonalUploadLoading, error: uploadError }] =
    useMutation(PERSONAL_CONTENT_UPLOAD);
  const { isRbtAuthorized, changeRbtAuthorizedStatus } = useActiveSubscriber();
  const { navigate } = useLocalization();
  const {
    config: { defaultCatalogCategory },
  } = useClientConfig();
  const [
    uploadAudioWithActivation,
    { loading: isPostActivationLoading, error: postActivationError },
  ] = useMutation(POST_SERVICE_ACTIVATION_FOR_UPLOAD);

  const loading = isPersonalUploadLoading || isPostActivationLoading;

  useGraphQlErrorHandler(uploadError);
  useGraphQlErrorHandler(postActivationError);

  const onUploadCompleted = (data: { personalContentUpload: Maybe<FaultPayload> }) => {
    if (isDiyAudioSelected) dispatch(changeAudioSelectedStatus(false));
    if (data?.personalContentUpload?.error) {
      responseErrorHandling(data.personalContentUpload);
    } else {
      toast.success(t('contentSuccessfullyUploaded'));
    }
  };

  const uploadPersonalContent = async (upload: File, title: string, pathType: PathTypeEnum) => {
    const response = await getActiveSubscriber({
      variables: { msisdn },
    });
    const activesSubscriber = response?.data?.subscriber || null;
    const error = response?.error;

    const areAnySubscriptions = !!activesSubscriber?.subscriptions?.length;
    const isRbtUser = isRbtUserErrorRequest(error) && areAnySubscriptions;
    if (isRbtAuthorized && !isRbtUser) {
      changeRbtAuthorizedStatus(false);
      navigate(`${ClientRoutes.Catalog}/${defaultCatalogCategory}`);
      return;
    }
    if (!isRbtUser) {
      return uploadAudioWithActivation({
        variables: {
          upload,
          input: {
            contentTitle: title.trim(),
            subsIdent: msisdn,
            pathType,
          },
        },
        refetchQueries: [
          {
            query: CHECK_SERVICE_ACTIVATIONS,
            variables: {
              subsIdent: msisdn,
            },
          },
        ],
        onCompleted: (data) => {
          if (data?.serviceActivationCreate?.error) {
            dispatch(changeAudioSelectedStatus(false));
            responseErrorHandling(data.serviceActivationCreate);
          } else {
            toast.success(t('contentSuccessfullyUploaded'));
          }
        },
        awaitRefetchQueries: true,
      });
    }
    return uploadAudioRequest({
      variables: {
        upload,
        input: {
          contentTitle: title.trim(),
          subsIdent: msisdn,
          contentType: ContentTypeEnum.Personal,
        },
      },
      refetchQueries: [
        {
          query: GET_SUBSCRIBER_CONTENT,
          variables: {
            msisdn,
          },
        },
      ],
      onCompleted: onUploadCompleted,
    });
  };

  return { loading, isDiyAudioSelected, uploadPersonalContent };
};

export default useUploadPersonalContent;
