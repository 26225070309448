import { chipClasses, typographyClasses } from '@mui/material';

import { baseTheme } from '@/constants';
import palette from '@/constants/theme/palette';

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  head: {
    display: 'flex',
    mb: 3,
    gap: 2,
  },
  imgPreview: {
    width: 120,
    height: 120,
    borderRadius: 8,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 12,
  },
  button: {
    py: 0.75,
    px: 2,
    minWidth: 105,
    boxShadow: 'none',
    borderRadius: 2,
    fontSize: 12,
    '.MuiButton-endIcon': {
      marginLeft: 0,
      marginRight: 0,
    },
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.400',
      },
    },
    // '&:focus': {
    //   backgroundColor: 'primary.soft.blue.150',
    // },
  },
  buttonIcon: {
    borderRadius: 2,
    boxShadow: '0 4px 24px 0 ' + palette.shadows.main,
    minWidth: 57,
    px: 0,
    py: 1,
    '.MuiButton-endIcon': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  network: {
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.500',
      },
    },
    backgroundColor: 'grey.400',
  },
  back: {
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.400',
      },
    },
    backgroundColor: 'transparent',
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: 'calc(100% - 136px)',
    [`.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      wordWrap: 'break-word',
    },
  },
  imgContainer: {
    position: 'relative',
    '&:hover': {
      '.MuiBox-root': {
        opacity: '1 !important',
        pointerEvents: 'all !important',
      },
    },
  },
  descriptionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    p: 1.5,
    backgroundColor: 'grey.100',
    borderRadius: 1,
  },
  purchaseDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    my: 3,
  },
  contentState: {
    px: 0.75,
    py: 0.5,
    borderRadius: 1,
    backgroundColor: 'primary.soft.blue.150',
    color: 'primary.blue',
    [` .${chipClasses.label}`]: {
      p: 0,
      fontSize: {
        md: 14,
        xs: 13,
      },
    },
  },
  itemArtist: {
    cursor: 'pointer',
    overflow: 'hidden',
    fontWeight: 500,
    color: 'grey.dark',
    [`&.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      wordBreak: 'break-word',
    },
  },
  complexType: {
    px: 0.75,
    py: 0.5,
    borderRadius: 1,
    backgroundColor: 'primary.soft.blue.150',
    color: 'primary.blue',
    width: 'min-content',
    height: 'auto',
    mb: 1.75,
    [` .${chipClasses.label}`]: {
      p: 0,
      fontSize: {
        md: 14,
        xs: 13,
      },
    },
  },
  itemTitle: {
    overflow: 'hidden',
    fontWeight: 700,
    color: 'blue.100',
    mb: 1,
    [`&.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      wordBreak: 'break-word',
    },
  },
  isOneLineRow: {
    [`&.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical',
      wordBreak: 'break-word',
    },
  },
} as const;
