export const styles = {
  container: {
    backgroundColor: 'grey.400',
    display: 'flex',
    justifyContent: 'space-between',
    px: 1,
    py: 0.5,
    borderRadius: 2,
    alignItems: 'center',
  },
  togglePlayIcon: {
    fontSize: {
      md: 23,
      xs: 23,
    },
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  fileName: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
} as const;
