import React from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

import { Image } from '@/components';
import { SelectedPurchaseForModal } from '@/redux/purchases';

const SmallCard = ({ title, artist, imgUrl }: SelectedPurchaseForModal) => {
  return (
    <Box sx={styles.main}>
      <Image sx={styles.img} src={imgUrl} />
      <Box sx={styles.title}>
        <Typography variant="h5" fontWeight="bold" color="text.primary">
          {title}
        </Typography>
        <Typography variant="h5" color="text.secondary">
          {artist}
        </Typography>
      </Box>
    </Box>
  );
};

export default SmallCard;
