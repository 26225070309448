import React, { FC } from 'react';

import { Box } from '@mui/material';

import { styles } from './styles';

import type { ILayoutProps } from '@/types';

import { Header } from '@/components';
const ConfirmLogoutModal = React.lazy(() => import('@/components/modals/ConfirmLogoutModal'));

const EmptyLayout: FC<ILayoutProps> = ({ children }) => (
  <Box sx={styles.mainContainer}>
    <Header isEmpty />
    <Box sx={styles.container}>{children}</Box>
    <ConfirmLogoutModal />
  </Box>
);

export default EmptyLayout;
