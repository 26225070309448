import { listClasses, outlinedInputClasses } from '@mui/material';

import { baseTheme, theme } from '@/constants';

export const styles = {
  preview: {
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'background.default',
    borderRadius: 2,
    p: 2,
  },
  input: {
    backgroundColor: 'background.default',
    [`.${listClasses.root}`]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    width: '100%',
    '&.Mui-focusVisible': {
      backgroundColor: 'grey.400',
    },
  },
  formControl: {
    width: '100%',
    borderRadius: 0.5,
    [` .${outlinedInputClasses.root}`]: {
      border: 'none',
      borderRadius: 0.5,
    },
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'grey.400',
    '&.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
      backgroundColor: 'grey.400',
    },
  },
  selected: {
    '&& .Mui-selected': {
      backgroundColor: 'grey.400',
      [baseTheme.breakpoints.up('md')]: {
        '&:hover': {
          backgroundColor: 'grey.500',
        },
      },
    },
  },
  paperProps: {
    borderTop: `2px solid ${theme.palette.grey['600']}`,
    maxHeight: 175,
    overflowY: 'auto',
    borderRadius: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: {
      md: 'row',
      xs: 'column',
    },
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  emptyTitle: {
    mb: {
      sm: 0,
      xs: 1,
    },
    color: 'grey.dark',
  },
  groupName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
} as const;
