import { inputBaseClasses } from '@mui/material';

const styles = {
  input: {
    [`& .${inputBaseClasses.root}`]: {
      pl: 0,
    },
  },
  code: {
    ml: 1.25,
    mr: 1.5,
    fontSize: 16,
  },
};

export { styles };
