import { createSlice } from '@reduxjs/toolkit';

import { IDeclineGiftModalState } from './types';

const initialState: IDeclineGiftModalState = {
  isModalOpen: false,
  contentItem: null,
};

export const declineGiftModalSlice = createSlice({
  name: 'declineGiftModal',
  initialState,
  reducers: {
    openDeclineGiftModal: (state, { payload }) => {
      state.contentItem = payload;
      state.isModalOpen = true;
    },
    closeDeclineGiftModal: (state) => {
      state.isModalOpen = false;
      state.contentItem = null;
    },
  },
});

export const { openDeclineGiftModal, closeDeclineGiftModal } = declineGiftModalSlice.actions;

export default declineGiftModalSlice.reducer;
