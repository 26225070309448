import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import styles from '../styles';

import { theme } from '@/constants';
import { mergeSx } from '@/utils';

function PlayRecordIcon({
  viewBox = '0 0 23 24',
  sx = {},
  fill = theme.palette.text.primary,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
      <g clipPath="url(#clip0_17555_55198)">
        <path
          d="M18.1307 10.6084L6.27129 3.59726C5.30772 3.02788 3.83203 3.58041 3.83203 4.98871V19.0077C3.83203 20.2711 5.20327 21.0326 6.27129 20.3992L18.1307 13.3914C19.1886 12.7681 19.1919 11.2317 18.1307 10.6084Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_17555_55198">
          <rect width="15.0938" height="17.25" fill="white" transform="translate(3.83203 3.375)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default PlayRecordIcon;
