import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import styles from '../styles';

import { theme } from '@/constants';
import { mergeSx } from '@/utils';

function PauseIcon({
  viewBox = '0 0 24 24',
  sx = {},
  fill = theme.palette.text.primary,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
      <g clipPath="url(#clip0_18068_22475)">
        <path
          d="M9.06641 19.8398H5.69141C4.75977 19.8398 4.00391 19.084 4.00391 18.1523V5.77734C4.00391 4.8457 4.75977 4.08984 5.69141 4.08984H9.06641C9.99805 4.08984 10.7539 4.8457 10.7539 5.77734V18.1523C10.7539 19.084 9.99805 19.8398 9.06641 19.8398ZM19.7539 18.1523V5.77734C19.7539 4.8457 18.998 4.08984 18.0664 4.08984H14.6914C13.7598 4.08984 13.0039 4.8457 13.0039 5.77734V18.1523C13.0039 19.084 13.7598 19.8398 14.6914 19.8398H18.0664C18.998 19.8398 19.7539 19.084 19.7539 18.1523Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_18068_22475">
          <rect width="15.75" height="18" fill="white" transform="translate(4 2.99707)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default PauseIcon;
