import { createSlice } from '@reduxjs/toolkit';

import type { IPlaybackRulesState } from './types';

const initialState: IPlaybackRulesState = {
  isCreateModalOpen: false,
  isDeleteModalOpen: false,
  isInfoModalOpen: false,
  selectedPlayback: null,
  selectedPlaybackForEditing: null,
  selectedPlaybackForDeleting: null,
};

export const playbackRulesSlice = createSlice({
  name: 'playbackRules',
  initialState,
  reducers: {
    openCreatePlaybackModal: (state) => {
      state.isCreateModalOpen = true;
      state.selectedPlaybackForEditing = null;
    },
    closeCreatePlaybackModal: (state) => {
      state.isCreateModalOpen = false;
    },
    openDeletePlaybackModal: (state, { payload }) => {
      state.isDeleteModalOpen = true;
      state.selectedPlaybackForDeleting = payload;
    },
    selectPlayback: (state, { payload }) => {
      state.selectedPlaybackForEditing = payload;
    },
    closeDeletePlaybackModal: (state) => {
      state.isDeleteModalOpen = false;
      state.selectedPlaybackForDeleting = null;
    },
    openPlaybackInfoModal: (state, { payload }) => {
      state.isInfoModalOpen = true;
      state.selectedPlayback = payload;
    },
    closePlaybackInfoModal: (state) => {
      state.isInfoModalOpen = false;
    },
  },
});

export const {
  openCreatePlaybackModal,
  closeCreatePlaybackModal,
  openDeletePlaybackModal,
  closeDeletePlaybackModal,
  selectPlayback,
  openPlaybackInfoModal,
  closePlaybackInfoModal,
} = playbackRulesSlice.actions;

export default playbackRulesSlice.reducer;
