import React from 'react';

import type { IHighlightedTextProps } from './types';

import { getHighlightedText } from '@/utils';

const HighlightedText = ({ text, searchValue }: IHighlightedTextProps) => {
  const parts = getHighlightedText(text, searchValue);
  return (
    <span>
      {parts?.map((p, i) =>
        p !== undefined ? (
          <span key={'text' + i}>{p}</span>
        ) : (
          <mark key={'text' + i}>{searchValue}</mark>
        ),
      )}
    </span>
  );
};

export default HighlightedText;
