import { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import type { ITypeRulesComponentProps } from '@/components';

import { TimeRuleTypeEnum } from '@/graphql-schema';
import { getDaysOfTheWeek } from '@/helpers';
import { extractDaysFromWeekValue, combineTimeAndDaysInWeekValue, getTimeFromDate } from '@/utils';

export const useWeeklyPlaybackRule = ({ value, onChange, rule }: ITypeRulesComponentProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [selectedTime, setSelectedTime] = useState<null | string>(null);
  const [selectedDays, setSelectedDays] = useState<number[]>([]);

  const isSavedRule = rule === TimeRuleTypeEnum.Weekly;

  const daysOfTheWeek = useMemo(() => getDaysOfTheWeek(), [language]);

  useEffect(() => {
    if (value && isSavedRule) {
      setSelectedDays(extractDaysFromWeekValue(value));
      setSelectedTime(getTimeFromDate(value));
    }
  }, [value, isSavedRule]);

  useEffect(() => {
    if (onChange && selectedTime) {
      const parsed = combineTimeAndDaysInWeekValue(selectedTime, selectedDays);
      onChange(parsed);
    }
  }, [selectedTime, selectedDays]);

  const onChangeDayStatus = (idx: number, isSelected: boolean) => () => {
    const newDays = !isSelected
      ? [...selectedDays, idx + 1].sort()
      : selectedDays.filter((day) => day !== idx + 1).sort();

    setSelectedDays(newDays);
  };

  const onTimeChange = useCallback((time: string) => {
    setSelectedTime(time);
  }, []);

  return { t, daysOfTheWeek, selectedTime, selectedDays, onChangeDayStatus, onTimeChange };
};
