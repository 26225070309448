import { baseTheme, theme } from '@/constants';
import { addOpacityToHexColor } from '@/utils';

export const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: {
      xs: '100%',
      md: 'auto',
    },
    alignSelf: 'center',
    gap: 1,
  },
  drawerWithSpacing: {
    mb: {
      md: 0,
      xs: 3,
    },
  },
  title: {
    fontSize: {
      md: 18,
      xs: 12,
    },
  },
  item: {
    cursor: 'pointer',
    px: 1,
    py: 1,
    borderRadius: 1,
    height: 'fit-content',
  },
  selected: {
    backgroundColor: addOpacityToHexColor(theme.palette.secondary.main, 0.15),
    color: 'secondary.main',
  },
  noSelected: {
    color: 'text.primary',
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'light.blue',
        color: 'secondary.main',
      },
    },
  },
} as const;
