import { useEffect, useMemo, useRef } from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import type { ITypeRulesComponentProps } from '@/components';
import type { SelectChangeEvent } from '@mui/material';

import { ErrorCodes } from '@/constants';
import { useKeycloak } from '@/context';
import { GET_PHONE_GROUPS } from '@/graphqL-queries/groups';
import { useGraphQlErrorHandler, useScreenSize } from '@/hooks';
import { openCreateGroupModal } from '@/redux/groups/slice';
import { NotSavedListType } from '@/redux/not-saved-changes';
import { setFormPlaybackDirty } from '@/redux/not-saved-changes/slice';
import { closeCreatePlaybackModal } from '@/redux/plaback-rules/slice';

export const useGroupPlaybackRule = ({ value, onChange }: ITypeRulesComponentProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { msisdn, isLoaded } = useKeycloak();
  const { isMobile } = useScreenSize();
  const isGroupChanged = useRef(false);
  const { data, error } = useQuery(GET_PHONE_GROUPS, {
    variables: {
      msisdn,
    },
    skip: !msisdn || !isLoaded,
  });

  const groups = data?.subscriber?.phoneGroups;

  const selectedGroup = useMemo(
    () => groups?.find(({ phoneGroupId }) => value === `${phoneGroupId}`),
    [value, groups],
  );

  useEffect(() => {
    if (!error) return;
    const errorCode = error?.graphQLErrors?.[0]?.extensions?.code;
    const isNotRbtError = errorCode === ErrorCodes.NO_RBT_AUTHORIZED;
    if (isNotRbtError) {
      dispatch(closeCreatePlaybackModal());
      dispatch(
        setFormPlaybackDirty({
          listType: NotSavedListType.Playback,
          isFormPlaybackDirty: false,
        }),
      );
    }
  }, [error]);

  useGraphQlErrorHandler(error);

  useEffect(() => {
    if (!selectedGroup && onChange && isGroupChanged.current) {
      onChange('');
      isGroupChanged.current = false;
    }
  }, [selectedGroup, onChange]);

  const onGroupChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value;
    if (value && onChange) {
      onChange(value as string);
      isGroupChanged.current = true;
    }
  };

  const openGroupModal = () => {
    dispatch(openCreateGroupModal());
  };

  return {
    t,
    groups,
    isMobile,
    selectedGroup,
    openGroupModal,
    onGroupChange,
  };
};
