import { toast } from 'react-toastify';

import type { FaultPayload } from '@/graphql-schema';

import { getErrorMessages } from '@/constants';

type ErrorMessages = {
  [key: string]: string;
};

export const responseErrorHandling = (response: FaultPayload) => {
  if (!response?.error?.code) return;
  const errorMessages: ErrorMessages = getErrorMessages();

  const { code } = response.error;

  const errorMessage = errorMessages?.[code];
  if (errorMessage) {
    toast.error(errorMessage);
  }
};
