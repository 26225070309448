import React, { FC, memo } from 'react';

import { Box, Button, Typography } from '@mui/material';

import { styles } from './styles';
import { useGroupItemEdit } from './useGroupItemEdit';

import type { IGroupItemEditProps } from './types';

import { GroupForm } from '@/components';

const GroupItemEdit: FC<IGroupItemEditProps> = memo((props) => {
  const { toggleIsEditing } = props;
  const { t, formik, errorMessage } = useGroupItemEdit(props);
  const { values, dirty, errors, touched, handleChange, setFieldValue, handleSubmit } = formik;
  return (
    <Box mt={4}>
      <form onSubmit={handleSubmit}>
        <Box sx={styles.container}>
          <GroupForm {...{ values, touched, errors, errorMessage, handleChange, setFieldValue }} />
          <Box sx={styles.buttons}>
            <Button variant="outlined" sx={styles.cancelBtn} onClick={toggleIsEditing}>
              <Typography variant="h4">{t('cancel')}</Typography>
            </Button>
            <Button type="submit" sx={styles.btn} variant="contained" disabled={!dirty}>
              <Typography variant="h4">{t('save')}</Typography>
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
});

GroupItemEdit.displayName = 'GroupItemEdit';

export default GroupItemEdit;
