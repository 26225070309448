import { t } from 'i18next';

import { ComplexTypeEnum } from '@/graphql-schema';

interface ComplexTypeMap {
  [ComplexTypeEnum.Channel]: string;
  [ComplexTypeEnum.Disk]: string;
  [ComplexTypeEnum.Package]: string;
}

export const getComplexLabel = (type: ComplexTypeEnum) => {
  const map: ComplexTypeMap = {
    [ComplexTypeEnum.Channel]: t('channel'),
    [ComplexTypeEnum.Disk]: t('disk'),
    [ComplexTypeEnum.Package]: t('package'),
  };
  return map[type as keyof ComplexTypeMap];
};
