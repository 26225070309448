import React from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

const InfoCard = ({ title = '', description = '' }: IInfoCardProps) => {
  return (
    <Box sx={styles.container}>
      <Typography variant="h3" color="text.primary">
        {title}
      </Typography>
      <Typography variant="h3" color="text.primary">
        {description}
      </Typography>
    </Box>
  );
};

export default InfoCard;
