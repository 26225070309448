import React, { memo } from 'react';

import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import { Box, Typography, IconButton, FormControl, FormHelperText, Button } from '@mui/material';

import { styles } from './styles';
import { usePlaybackCalendar } from './usePlaybackCalendar';

import type { PlaybackCalendarProps } from './types';

import { DailyPlaybackRule } from '@/components';

const PlaybackCalendar: React.FC<PlaybackCalendarProps> = memo((props) => {
  const { error, isPreview } = props;
  const {
    t,
    year,
    time,
    isYearly,
    isOneTime,
    monthName,
    daysInMonth,
    daysOfTheWeek,
    isDaySelected,
    isTimeOutdated,
    daysFromPrevMonth,
    onTimeChange,
    isExtendedDuration,
    isNextMonthDisabled,
    isPrevMonthDisabled,
    checkIsDayDisabled,
    onDayClick,
    getSxButton,
    goToMonth,
  } = usePlaybackCalendar(props);

  return (
    <>
      <FormControl fullWidth sx={{ mb: 3 }}>
        <Box sx={styles.container}>
          {isExtendedDuration && (
            <>
              <Box sx={styles.yearAndMonth}>
                <Box>
                  <IconButton
                    sx={{ p: 0 }}
                    disabled={isPrevMonthDisabled}
                    onClick={goToMonth(true)}
                  >
                    <KeyboardArrowLeft />
                  </IconButton>
                  <IconButton sx={{ p: 0 }} disabled={isNextMonthDisabled} onClick={goToMonth()}>
                    <KeyboardArrowRight />
                  </IconButton>
                </Box>
                <Typography variant="h3">{`${monthName} ${isYearly ? '' : year}`}</Typography>
              </Box>
              <Box sx={styles.daysOfTheWeek}>
                {!isYearly &&
                  daysOfTheWeek.map((day) => (
                    <Typography key={day} variant="h6">
                      {day}
                    </Typography>
                  ))}
              </Box>
            </>
          )}
          <Box sx={styles.days}>
            {isOneTime && (
              <>
                {daysFromPrevMonth?.map((item) => (
                  <Box key={item} sx={styles.dayInNextMonth} />
                ))}
              </>
            )}
            {daysInMonth.map(([name, value], idx) => (
              <Box key={value} sx={styles.day}>
                <Button
                  disableRipple
                  disabled={isPreview || checkIsDayDisabled(idx + 1)}
                  sx={[styles.day, styles.dayBtn, getSxButton(value)]}
                  onClick={onDayClick(value)}
                >
                  <Typography variant="h4">{name}</Typography>
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
        {((!!error && !isDaySelected) || isTimeOutdated) && (
          <FormHelperText error={!!error}>{t(error as string)}</FormHelperText>
        )}
      </FormControl>
      <DailyPlaybackRule
        error={error}
        allowInitialize
        value={time}
        isPreview={isPreview}
        onChange={onTimeChange}
      />
    </>
  );
});

PlaybackCalendar.displayName = 'PlaybackCalendar';

export default PlaybackCalendar;
