import type { INavigationList, INavigationListChild } from '@/types';

import {
  MenuIcon,
  StarSelectedIcon,
  HistoryIcon,
  PlaybackRulesIcon,
  PresentIcon,
  RecordMeloringIcon,
  UnPaidContentIcon,
  HelpIcon,
  SearchContentIcon,
  MyMusicIcon,
} from '@/components';
import { ClientRoutes } from '@/constants/enums';
import i18n from '@/i18n';

// TODO: need to add more correct urls
const getContentNavigationList = (): INavigationListChild[] => [
  {
    title: i18n.t('sideBarNavigation.activeContent'),
    icon: RecordMeloringIcon,
    link: ClientRoutes.MyMusic,
  },
  {
    title: i18n.t('sideBarNavigation.unPaidContent'),
    icon: UnPaidContentIcon,
    link: ClientRoutes.UnpaidContent,
  },
  {
    title: i18n.t('sideBarNavigation.selected'),
    icon: StarSelectedIcon,
    link: ClientRoutes.Favourites,
  },
  {
    title: i18n.t('sideBarNavigation.recordMyMeloring'),
    icon: RecordMeloringIcon,
    link: ClientRoutes.RecordMyMeloring,
  },
  {
    title: i18n.t('sideBarNavigation.history'),
    icon: HistoryIcon,
    link: ClientRoutes.History,
  },
  {
    title: i18n.t('sideBarNavigation.setUpPlaybackRules'),
    icon: PlaybackRulesIcon,
    link: ClientRoutes.PlayRules,
  },
  {
    title: i18n.t('sideBarNavigation.searchUser'),
    icon: SearchContentIcon,
    link: ClientRoutes.SearchUser,
  },
];

// TODO: need to add more correct urls
const perentsNavigationList = (): INavigationListChild[] => [
  // {
  //   title: i18n.t('sideBarNavigation.presentForYou'),
  //   icon: PresentIcon,
  //   link: ClientRoutes.Main,
  // },
  {
    title: i18n.t('sideBarNavigation.giftsForYou'),
    icon: PresentIcon,
    link: ClientRoutes.GiftForYou,
  },
  {
    title: i18n.t('sideBarNavigation.sentGifts'),
    icon: PresentIcon,
    link: ClientRoutes.SentGift,
  },
  {
    title: i18n.t('sideBarNavigation.giftsHistory'),
    icon: HistoryIcon,
    link: ClientRoutes.GiftHistory,
  },
];

// TODO: need to add more correct urls
export const getSideBarNavigationList = (): INavigationList => [
  {
    title: i18n.t('sideBarNavigation.myMusic'),
    icon: MyMusicIcon,
    children: getContentNavigationList(),
  },
  {
    title: i18n.t('sideBarNavigation.meloringInPresent'),
    icon: PresentIcon,
    children: perentsNavigationList(),
  },
];

// TODO: need to add more correct urls
export const getHeaderNavigationList = () => [
  {
    title: i18n.t('headerNavigation.myProfile'),
    link: ClientRoutes.MyMusic,
    isRbtProtected: true,
    subPages: [
      ClientRoutes.UnpaidContent,
      ClientRoutes.UnpaidContent,
      ClientRoutes.Favourites,
      ClientRoutes.RecordMyMeloring,
      ClientRoutes.History,
      ClientRoutes.PlayRules,
      ClientRoutes.SearchUser,
      ClientRoutes.GiftForYou,
      ClientRoutes.SentGift,
      ClientRoutes.GiftHistory,
    ],
  },
  {
    title: i18n.t('headerNavigation.catalog'),
    link: ClientRoutes.Catalog,
    isRbtProtected: false,
  },
  {
    title: i18n.t('headerNavigation.promotions'),
    link: ClientRoutes.Promotions,
    isRbtProtected: false,
  },
  {
    title: i18n.t('headerNavigation.help'),
    link: ClientRoutes.FAQ,
    isRbtProtected: false,
    subPages: [ClientRoutes.FAQ, ClientRoutes.TermsAndConditions],
  },
];

const getMenuNavigationList = () => [
  {
    title: i18n.t('faq'),
    icon: HelpIcon,
    link: ClientRoutes.FAQ,
  },
  {
    title: i18n.t('termsOfUse'),
    icon: HelpIcon,
    link: ClientRoutes.TermsAndConditions,
  },
];

export const getTextNavigationList = (): INavigationList => [
  {
    title: i18n.t('help'),
    icon: MenuIcon,
    children: getMenuNavigationList(),
  },
];
