import { createContext, useContext } from 'react';

import { LocalAudioTypes } from '@/constants';

interface AudioPlayerContextContextType {
  playingType: null | LocalAudioTypes;
  isPlaying: boolean;
  selectedAudio: null | string;
  stopPlaying: () => void;
  toggleAudioStatus: (contentNo: string) => () => void;
  changePlayingType: (type: LocalAudioTypes | null) => void;
}

export const AudioPlayerContext = createContext<AudioPlayerContextContextType>({
  playingType: null,
  isPlaying: false,
  selectedAudio: null,
  stopPlaying: () => {},
  toggleAudioStatus: (contentNo) => () => {
    console.log(contentNo);
  },
  changePlayingType: () => {},
});

export const useAudioPlayer = () => {
  return useContext(AudioPlayerContext);
};
