import { combineReducers, compose, configureStore } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { acceptGiftModalReducer } from './accept-gift-modal';
import { catalogReducer } from './catalog';
import { declineGiftModalReducer } from './decline-gift-modal';
import { disableServiceReducer } from './disable-service';
import { errorModalReducer } from './error-modal';
import { groupsReducer } from './groups';
import { happyHourReducer } from './happy-hour';
import { logoutReducer } from './logout';
import { mobileDrawerReducer } from './mobile-drawer';
import { myMusicReducer } from './my-music';
import { notRbtNotificationModalReducer } from './not-rbt-notification-modal';
import { notSavedChangesReducer } from './not-saved-changes';
import { permissionServiceReducer } from './permission-service';
import { personalContentReducer } from './personal-content';
import { playbackRulesReducer } from './plaback-rules';
import { purchaseReducer } from './purchases';
import { recordMyMeloringReducer } from './record-my-meloring';
import { successModalReducer } from './success-modal';
import { unpaidContentReducer } from './unpaid-content';

import type { TypedUseSelectorHook } from 'react-redux';

import { audioApi, clientConfigApi } from '@/apis';
import { offsetReducer } from '@/redux/offset';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const rootReducer = combineReducers({
  [audioApi.reducerPath]: audioApi.reducer,
  [clientConfigApi.reducerPath]: clientConfigApi.reducer,
  playbackRules: playbackRulesReducer,
  notSavedChanges: notSavedChangesReducer,
  purchase: purchaseReducer,
  catalog: catalogReducer,
  groups: groupsReducer,
  logout: logoutReducer,
  offset: offsetReducer,
  myMusic: myMusicReducer,
  unpaidContent: unpaidContentReducer,
  mobileDrawer: mobileDrawerReducer,
  errorModal: errorModalReducer,
  successModal: successModalReducer,
  acceptGiftModal: acceptGiftModalReducer,
  declineGiftModal: declineGiftModalReducer,
  permissionService: permissionServiceReducer,
  disableService: disableServiceReducer,
  personalContent: personalContentReducer,
  happyHour: happyHourReducer,
  recordMyMeloring: recordMyMeloringReducer,
  notRbtNotificationModal: notRbtNotificationModalReducer,
});
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([audioApi.middleware, clientConfigApi.middleware]),
});

export type RootState = ReturnType<typeof rootReducer>;

export const storeSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
