import React from 'react';

import { SvgIcon } from '@mui/material';

import styles from '../styles';

import type { SvgIconProps } from '@mui/material';

import { mergeSx } from '@/utils';

const CheckBoxCheckedIcon = ({ viewBox = '0 0 24 24', sx = {}, ...rest }: SvgIconProps) => (
  <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM16.58 7.58L17.99 9L9.99 17L5.99 13.01L7.41 11.6L9.99 14.17L16.58 7.58Z"
        fill="#11223C"
      />
    </g>
  </SvgIcon>
);

export default CheckBoxCheckedIcon;
