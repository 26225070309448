import { array, mixed, object, string, number } from 'yup';

import {
  dailyTimeRegexp,
  phoneRegex,
  yearlyTimeRegexp,
  weeklyTimeRegexp,
  monthlyTimeRegexp,
  oneTimeRegexp,
} from '@/constants';
import { TimeRuleTypeEnum, PlayConditionTypeEnum } from '@/graphql-schema';
import { isEndTimeOutdated } from '@/utils';

export const playbackRuleSchema = object({
  contentNo: string().required('errors.selectTone'),
  tmType: array(
    mixed<TimeRuleTypeEnum>().oneOf(Object.values(TimeRuleTypeEnum)).required(),
  ).ensure(),
  pcType: array(
    mixed<PlayConditionTypeEnum>().oneOf(Object.values(PlayConditionTypeEnum)).required(),
  ).ensure(),
  phoneGroupId: number()
    .nullable('required')
    .transform((value) => (Number.isNaN(value) ? null : value))
    .test({
      name: 'phoneGroupId',
      test: function (value?: number | null) {
        const pcType = this.options.context?.pcType;
        return pcType === PlayConditionTypeEnum.Group ? typeof value === 'number' : true;
      },
      message: 'errors.required',
    }),
  pcParams: string()
    .nullable('required')
    .test({
      name: 'pcParams',
      test: function (value?: string | null) {
        const pcType = this.options.context?.pcType;

        if (pcType === PlayConditionTypeEnum.Personal) {
          return phoneRegex.test(value || '');
        }

        return true;
      },
      message: 'formNotifications.wrongPhoneNumber',
    }),
  tmParams: string()
    .nullable('required')
    .test({
      name: 'tmParams',
      test: function (value?: string | null) {
        const tmType = this.options.context?.tmType;

        if (tmType === TimeRuleTypeEnum.Daily) {
          return dailyTimeRegexp.test(value || '');
        }

        if (tmType === TimeRuleTypeEnum.Weekly && !weeklyTimeRegexp.test(value || '')) {
          return this.createError({ message: 'errors.selectWeekday' });
        }

        if (tmType === TimeRuleTypeEnum.Yearly && !yearlyTimeRegexp.test(value ?? '')) {
          return this.createError({ message: 'errors.selectDateOrPeriod' });
        }

        if (tmType === TimeRuleTypeEnum.Monthly && !monthlyTimeRegexp.test(value ?? '')) {
          return this.createError({ message: 'errors.selectDateOrPeriod' });
        }

        if (tmType === TimeRuleTypeEnum.OneTime && !oneTimeRegexp.test(value ?? '')) {
          return this.createError({ message: 'errors.selectDateOrPeriod' });
        }
        if (tmType === TimeRuleTypeEnum.OneTime) {
          if (isEndTimeOutdated(value ?? '')) {
            return this.createError({ message: 'errors.wrongEndTime' });
          }
        }
        if (tmType === TimeRuleTypeEnum.Always) {
          return true;
        }
        return true;
      },
      message: 'errors.required',
    }),
}).required();
