export const styles = {
  card: {
    p: 2,
    boxShadow: 'none',
    borderRadius: 4,
    backgroundColor: 'white',
    width: '100%',
    boxSizing: 'border-box',
  },
} as const;
