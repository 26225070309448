import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import type { PlayCondition } from '@/graphql-schema';

import { pcTypeComponents, tmTypeComponents } from '@/constants';
import { PlayConditionTypeEnum, TimeRuleTypeEnum } from '@/graphql-schema';
import { usePlaybackTypeValues } from '@/hooks';
import { isEndTimeOutdated } from '@/utils';

export const usePlaybackItemPreview = ({
  pcType,
  tmType,
  tmParams,
  phoneGroupId,
  pcParams,
}: PlayCondition) => {
  const { t } = useTranslation();
  const { tmTypeValues, pcTypeValues } = usePlaybackTypeValues();

  const pcTypePreview = pcTypeValues[pcType ?? PlayConditionTypeEnum.Default];
  const tmTypePreview = tmTypeValues[tmType ?? TimeRuleTypeEnum.Always];

  const TmTypeComponent = tmTypeComponents[tmType ?? TimeRuleTypeEnum.Always];
  const PcTypeComponent = pcTypeComponents[pcType ?? PlayConditionTypeEnum.Default];

  const pcValue = pcType === PlayConditionTypeEnum.Group ? `${phoneGroupId}` : pcParams;

  const isRuleOutdated = useMemo(
    () => tmType === TimeRuleTypeEnum.OneTime && isEndTimeOutdated(tmParams ?? ''),
    [tmType, tmParams],
  );

  const isDefaultRule =
    pcType === PlayConditionTypeEnum.Default && tmType === TimeRuleTypeEnum.Always;

  return {
    t,
    isRuleOutdated,
    isDefaultRule,
    pcValue,
    pcTypePreview,
    tmTypePreview,
    TmTypeComponent,
    PcTypeComponent,
  };
};
