const styles = {
  main: {
    width: '100%',
    boxSizing: 'border-box',
    minHeight: 141,
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    px: {
      bg: 8.125,
      xs: 2,
    },
    pt: 5,
    pb: 8,
    backgroundColor: 'white',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: {
      bg: 'row',
      xs: 'column',
    },
    gap: 3,
  },
  buttonWrapper: {
    display: 'flex',
    gap: 2,
    alignItems: 'start',
  },
  textWrapper: {
    width: {
      bg: 650,
      xs: '100%',
    },
  },
  button: {
    width: {
      bg: 260,
      xs: 150,
    },
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 1,
    backgroundColor: 'grey.400',
    '&:hover': {
      backgroundColor: {
        md: 'grey.500',
        xs: 'grey.400',
      },
    },
  },
  google: {
    fontSize: {
      bg: 119,
      xs: 90,
    },
  },
  apple: {
    fontSize: {
      bg: 119,
      xs: 90,
    },
  },
  link: {
    overflow: 'hidden',
  },
};
export { styles };
