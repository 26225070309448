import i18n from '@/i18n';

export const giftHistorySentHeader = () => [
  i18n.t('pageGiftsHistory.headers.datetime'),
  i18n.t('pageGiftsHistory.headers.tone'),
  i18n.t('pageGiftsHistory.headers.operation'),
  i18n.t('pageGiftsHistory.headers.recipient'),
  i18n.t('pageGiftsHistory.headers.channel'),
];

export const giftHistoryReceivedHeader = () => [
  i18n.t('pageGiftsHistory.headers.datetime'),
  i18n.t('pageGiftsHistory.headers.tone'),
  i18n.t('pageGiftsHistory.headers.operation'),
  i18n.t('pageGiftsHistory.headers.donor'),
  i18n.t('pageGiftsHistory.headers.channel'),
];

export const historyPayOperationHeader = () => [
  i18n.t('pageHistory.headers.datetime'),
  i18n.t('pageHistory.headers.tone'),
  i18n.t('pageHistory.headers.operation'),
  i18n.t('pageHistory.headers.amount'),
  i18n.t('pageHistory.headers.channel'),
];

export const historyRemoveContentHeader = () => [
  i18n.t('pageHistory.headers.datetime'),
  i18n.t('pageHistory.headers.tone'),
  i18n.t('pageHistory.headers.operation'),
  i18n.t('pageHistory.headers.channel'),
];
