import { useMemo } from 'react';

import type { INetworkButtonsProps } from './types';

import { ExternalUrls } from '@/constants';
import { useAudioPlayer } from '@/context';
import { useSharingSocialNetwork } from '@/hooks';
import { getVirtualNoFromContentNo } from '@/utils';

export const useNetworkButtons = ({ contentItem, isComplexSubItem }: INetworkButtonsProps) => {
  const { stopPlaying } = useAudioPlayer();

  const contentNo = useMemo(
    () =>
      isComplexSubItem
        ? getVirtualNoFromContentNo(contentItem?.contentNo)
        : contentItem?.virtContentNo,
    [isComplexSubItem, contentItem],
  );
  const { shareToSocialNetwork } = useSharingSocialNetwork(contentNo);

  const goToSocialNetwork = (url: ExternalUrls) => () => {
    stopPlaying();
    window.open(shareToSocialNetwork(url), '_blank');
  };

  return {
    goToSocialNetwork,
  };
};
