import {
  dialogActionsClasses,
  dialogClasses,
  paperClasses,
  typographyClasses,
} from '@mui/material';

const styles = {
  dialog: {
    [` .${dialogClasses.container}`]: {
      [` .${paperClasses.root}`]: {
        width: '100%',
        maxWidth: 400,
        mx: 1.5,
        [`& > .${typographyClasses.root}`]: {
          pb: 1,
        },
        [`& > .${dialogActionsClasses.root}`]: {
          gap: 0.5,
        },
      },
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    mt: 2,
    p: 1,
    borderRadius: 2,
  },
  closeIcon: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeIconBtn: {
    p: 0,
  },
  contentNo: {
    height: 92,
    backgroundColor: 'background.default',
    borderRadius: 2,
  },
  contentNoFormControl: {
    width: '100%',
  },
  submitBtn: {
    height: 40,
    flexGrow: 1,
  },
  cancel: {
    minWidth: 115,
    height: 40,
  },
  content: { pb: 5, wordBreak: 'break-word' },
  submitText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  descriptionSx: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
};

export { styles };
