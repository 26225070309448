import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { DateParamsType, initialValues } from './constants';
import { styles } from './styles';

import type { IPlaybackCalendarValues, PlaybackCalendarProps } from './types';

import { TimeRuleTypeEnum } from '@/graphql-schema';
import { getDaysOfTheWeek, getMonthName } from '@/helpers';
import {
  isClickedDaySelected,
  genTimeRangeStr,
  generateDaysMap,
  getDaysFromPrevMonth,
  parseDateValueToFormikValues,
  extractDaysRange,
  getCurrentDate,
  isEndTimeOutdated,
} from '@/utils';

export const usePlaybackCalendar = ({
  value,
  onChange,
  rule,
  calendarType,
}: PlaybackCalendarProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [selectedDay, setSelectedDay] = useState<number | null>(null);
  const [dateRanges, setDateRanges] = useState<number[][]>([]);
  const [isSecondClickedDayLater, setIsSecondClickedDayLater] = useState(false);
  const [isChanged, setIsChanged] = useState(true);

  const isDaySelected = dateRanges?.[0]?.length;

  const { values, setFieldValue, setValues } = useFormik<IPlaybackCalendarValues>({
    initialValues,
    onSubmit: () => {},
  });

  const { time, month, year } = values;

  const isSavedRule = rule === calendarType;

  const isYearly = calendarType === TimeRuleTypeEnum.Yearly;
  const isOneTime = calendarType === TimeRuleTypeEnum.OneTime;

  const isExtendedDuration = isYearly || isOneTime;

  const isNextMonthDisabled = isYearly && month === 12;
  const isPrevMonthDisabled = isYearly && month === 1;

  const { currentMonth, currentYear, currentDay } = useMemo(() => getCurrentDate(), []);

  const isTimeOutdated = useMemo(() => isEndTimeOutdated(value ?? ''), [value]);

  const daysInMonth = useMemo(
    () => generateDaysMap(year, month, isExtendedDuration),
    [year, month, isExtendedDuration],
  );

  const monthName = useMemo(
    () => (isExtendedDuration ? getMonthName(month) : null),
    [month, isExtendedDuration, language],
  );

  const checkIsDayDisabled = (day: number) => {
    const isPrevYear = year < currentYear;
    const isPrevMonth = month < currentMonth;
    const isPrevDay = day < currentDay;

    const isCurrentYear = year === currentYear;
    const isCurrentMonth = month === currentMonth;

    const isPrev =
      isPrevYear ||
      (isCurrentYear && isPrevMonth) ||
      (isCurrentYear && isCurrentMonth && isPrevDay);

    return isPrev && isOneTime;
  };

  const daysOfTheWeek = useMemo(
    () => (isExtendedDuration ? getDaysOfTheWeek() : []),
    [language, isExtendedDuration],
  );

  const daysFromPrevMonth = useMemo(
    () => getDaysFromPrevMonth(daysInMonth?.[0]?.[1], month, year),
    [daysInMonth, month, year],
  );

  const onDateFormikChange = (name: string, value: string | number | null) => {
    setFieldValue(name, value);
  };

  useEffect(() => {
    if (value && isSavedRule) {
      setDateRanges([extractDaysRange(value, calendarType)]);
      const parsedValues = parseDateValueToFormikValues(
        value,
        isOneTime,
        isChanged,
        isSecondClickedDayLater,
      );
      setValues(parsedValues);
    }
  }, [value, isSavedRule, isOneTime, isChanged, isSecondClickedDayLater]);

  useEffect(() => {
    if (onChange && time) {
      onChange(genTimeRangeStr(dateRanges, time, isExtendedDuration, isOneTime));
    }
  }, [time, dateRanges]);

  useEffect(() => {
    if (!dateRanges.length) return;
    const [[start, end]] = dateRanges;
    if (start === end) {
      setSelectedDay(start);
    }
  }, [dateRanges.length]);

  const onDayClick = (clickedDay: number) => () => {
    if (isClickedDaySelected(dateRanges, clickedDay)) {
      setSelectedDay(null);
      setDateRanges([]);
      return;
    }

    if (dateRanges.length >= 1 && !selectedDay) {
      setDateRanges([[clickedDay, clickedDay]]);
      setSelectedDay(clickedDay);
      return;
    }

    if (selectedDay) {
      const [[start, end]] = dateRanges;
      setIsSecondClickedDayLater(start > clickedDay);
      const newRange = [Math.min(start, clickedDay), Math.max(end, clickedDay)];
      if (isChanged) {
        setIsChanged(false);
      }
      setDateRanges([newRange]);
    } else {
      setDateRanges([[clickedDay, clickedDay]]);
    }

    setSelectedDay(selectedDay ? null : clickedDay);
    onDateFormikChange(DateParamsType.Day, selectedDay ? null : clickedDay);
  };

  const getSxButton = (currentDay: number) => {
    if (selectedDay === currentDay) return styles.selectedDay;

    for (const [start, end] of dateRanges) {
      if (start === currentDay || end === currentDay) return styles.selectedDay;
      if (currentDay > start && currentDay < end) return styles.range;
    }

    // const firstRange = dateRanges?.[0];

    // if (firstRange?.length >= 2 && firstRange?.[0] !== firstRange?.[1]) {
    //   return styles.noSelectedDayWithoutHover;
    // }
    return styles.noSelectedDay;
  };

  const onTimeChange = useCallback((time: string) => {
    onDateFormikChange(DateParamsType.Time, time);
  }, []);

  const goToMonth =
    (isPrev = false) =>
    () => {
      const newMonth = isPrev ? (month === 1 ? 12 : month - 1) : month === 12 ? 1 : month + 1;
      const newYear = isPrev ? (month === 1 ? year - 1 : year) : month === 12 ? year + 1 : year;

      if (!isYearly) {
        onDateFormikChange(DateParamsType.Year, newYear);
      }
      onDateFormikChange(DateParamsType.Month, newMonth);
    };

  return {
    t,
    year,
    time,
    isYearly,
    isOneTime,
    monthName,
    dateRanges,
    daysInMonth,
    daysOfTheWeek,
    isDaySelected,
    isTimeOutdated,
    daysFromPrevMonth,
    isExtendedDuration,
    isNextMonthDisabled,
    isPrevMonthDisabled,
    onTimeChange,
    checkIsDayDisabled,
    onDayClick,
    getSxButton,
    goToMonth,
  };
};
