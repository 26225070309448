import React from 'react';

import { SvgIcon } from '@mui/material';

import styles from '../styles';

import type { SvgIconProps } from '@mui/material';

import { mergeSx } from '@/utils';

const ShowMoreIcon = ({ viewBox = '0 0 19 18', sx = {}, ...rest }: SvgIconProps) => {
  return (
    <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
      <path
        d="M12.9425 6.22168L9.5 9.65668L6.0575 6.22168L5 7.27918L9.5 11.7792L14 7.27918L12.9425 6.22168Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ShowMoreIcon;
