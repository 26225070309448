import React from 'react';

import { Box } from '@mui/material';

interface IHTMLRendererProps {
  children: string;
}

const HTMLRenderer: React.FC<IHTMLRendererProps> = ({ children }) => (
  <Box dangerouslySetInnerHTML={{ __html: children }} />
);

export default HTMLRenderer;
