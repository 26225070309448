import React from 'react';

import { SvgIcon } from '@mui/material';

import type { SvgIconProps } from '@mui/material';

import { theme } from '@/constants';

const LogoutIcon = ({
  viewBox = '0 0 18 19',
  fill = theme.palette.text.secondary,
  sx = {},
  ...rest
}: SvgIconProps) => (
  <SvgIcon viewBox={viewBox} sx={sx} {...rest}>
    <g clipPath="url(#clip0_7602_24883)">
      <path
        d="M12.75 5.75L11.6925 6.8075L13.6275 8.75H6V10.25H13.6275L11.6925 12.185L12.75 13.25L16.5 9.5L12.75 5.75ZM3 4.25H9V2.75H3C2.175 2.75 1.5 3.425 1.5 4.25V14.75C1.5 15.575 2.175 16.25 3 16.25H9V14.75H3V4.25Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_7602_24883">
        <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default LogoutIcon;
