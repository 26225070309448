import React from 'react';

import { Box, IconButton } from '@mui/material';

import { SettingsIcon, TrashIcon } from '@/components';

interface ICardEditButton {
  toggleIsEditing: () => void;
  openDeleteModal: () => void;
}

const CardEditButton: React.FC<ICardEditButton> = ({ toggleIsEditing, openDeleteModal }) => (
  <Box display="flex">
    <IconButton sx={{ height: 'fit-content' }} onClick={toggleIsEditing}>
      <SettingsIcon />
    </IconButton>
    <IconButton sx={{ height: 'fit-content' }} onClick={openDeleteModal}>
      <TrashIcon />
    </IconButton>
  </Box>
);

export default CardEditButton;
