export const styles = {
  contentEmptyText: {
    color: 'text.secondary',
    textAlign: 'center',
    mx: 'auto',
    mt: {
      xs: 2,
      md: 5,
    },
  },
  progress: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    mt: 2,
  },
} as const;
