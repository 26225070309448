import { useDispatch } from 'react-redux';

import { ClientRoutes } from '@/constants';
import { useClientConfig } from '@/context';
import { useEditFormDirty, useLocalization, useScreenSize } from '@/hooks';
import { openMobileDrawer } from '@/redux/mobile-drawer/slice';

export const useHeader = () => {
  const { navigate } = useLocalization();
  const dispatch = useDispatch();
  const {
    config: { defaultCatalogCategory },
  } = useClientConfig();
  const { isMobileScreenView } = useScreenSize();

  const { openNotSavedChangesModal } = useEditFormDirty();

  const onMobileDrawerClick = () => {
    dispatch(openMobileDrawer());
  };

  const goToMainPage = () => {
    if (!openNotSavedChangesModal()) {
      navigate(`${ClientRoutes.Catalog}/${defaultCatalogCategory}`);
    }
  };

  return {
    isMobileScreenView,
    goToMainPage,
    onMobileDrawerClick,
  };
};
