import type { Setup, Charge } from '@/graphql-schema';

export enum ICustomTableCellType {
  Time = 'time',
  Card = 'card',
  Text = 'text',
}

export interface ICustomTableCell {
  type: ICustomTableCellType;
  text?: string;
  card?: Charge | Setup | null;
  imgUrl?: string;
  time?: {
    date: string;
    time: string;
  };
}

export interface ITable {
  headers: string[];
  loading: boolean;
  emptyTitle: string;
  data: ICustomTableCell[][];
}
