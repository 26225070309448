import React, { FC } from 'react';

import { Box, TextField, Typography } from '@mui/material';

import { styles } from './styles';
import { usePlaybackYearlyPreview } from './usePlaybackYearlyPreview';

import type { IPlaybackRangePreviewProps } from './types';

const PlaybackRangePreview: FC<IPlaybackRangePreviewProps> = (props) => {
  const { t, startDate, endDate, startTime, endTime } = usePlaybackYearlyPreview(props);

  return (
    <Box sx={styles.container}>
      <Box sx={{ ...styles.row, mb: 3 }}>
        <Box width="49%">
          <Typography variant="h4">{t('dateFrom')}</Typography>
          <TextField
            value={startDate}
            disabled
            sx={styles.textField}
            inputProps={styles.textFieldInout}
          />
        </Box>
        <Box width="48%">
          <Typography variant="h4">{t('dateTill')}</Typography>
          <TextField
            value={endDate}
            disabled
            sx={styles.textField}
            inputProps={styles.textFieldInout}
          />
        </Box>
      </Box>
      <Box sx={styles.row}>
        <Box width="48%">
          <Typography variant="h4">{t('timeFrom')}</Typography>
          <TextField
            value={startTime}
            disabled
            sx={styles.textField}
            inputProps={styles.textFieldInout}
          />
        </Box>
        <Box width="49%">
          <Typography variant="h4">{t('timeTill')}</Typography>
          <TextField
            value={endTime}
            disabled
            sx={styles.textField}
            inputProps={styles.textFieldInout}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PlaybackRangePreview;
