import { gql } from '@apollo/client';

export const CHECK_SERVICE_ACTIVATIONS = gql`
  query ServiceActivations($subsIdent: String!) {
    serviceActivations(subsIdent: $subsIdent) {
      action
      state
      contentItems {
        title
      }
    }
  }
`;

export const POST_SERVICE_ACTIVATION = gql`
  mutation PostServiceActivation($input: ServiceActivationCreateUploadInput) {
    serviceActivationCreate(input: $input) {
      error {
        code
        message
      }
    }
  }
`;

export const POST_SERVICE_ACTIVATION_FOR_UPLOAD = gql`
  mutation PostServiceActivation($file: FileUpload, $input: ServiceActivationCreateUploadInput) {
    serviceActivationCreate(input: $input, upload: $file) {
      error {
        code
        message
      }
    }
  }
`;

export const PERSONAL_CONTENT_UPLOAD = gql`
  mutation ($file: FileUpload!, $title: String, $subsIdent: String) {
    personalContentUpload(
      input: { subsIdent: $subsIdent, contentTitle: $title, contentType: PERSONAL }
      upload: $file
    ) {
      error {
        code
        message
      }
    }
  }
`;
