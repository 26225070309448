import React from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

import { PlayContentImage } from '@/components';
import { Setup, Charge } from '@/graphql-schema';

const HistoryCard = (contentItem: Setup | Charge) => {
  const { title, artist } = contentItem;
  return (
    <Box sx={styles.main}>
      <PlayContentImage size={40} contentItem={contentItem} isPreview />
      <Box sx={styles.container}>
        <Typography variant="h5" sx={styles.title}>
          {title}
        </Typography>
        <Typography variant="h5" color="text.secondary">
          {artist}
        </Typography>
      </Box>
    </Box>
  );
};

export default HistoryCard;
