import { typographyClasses } from "@mui/material";

const styles = {
  main: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
    maxWidth: "100px",
    [`.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      wordWrap: 'break-word',
    },
  },
  title: {
    fontWeight: 'bold',
    color: 'text.primary',
    lineHeight: '15px',
  },
};

export { styles };
