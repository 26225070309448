import type { ChangeEvent } from 'react';

import { useTranslation } from 'react-i18next';

import type { IGroupFormProps } from './types';

import { useClientConfig } from '@/context';
import { useScreenSize } from '@/hooks';

export const useGroupForm = ({ values, setFieldValue }: IGroupFormProps) => {
  const {
    config: { maxPhoneNumbers },
  } = useClientConfig();

  const { t } = useTranslation();
  const { isMobile, isTabletSmall } = useScreenSize();

  const isAddMemberDisabled = (values?.groupMembers?.length ?? 0) >= Number(maxPhoneNumbers);

  const addContactToGroup = () => {
    setFieldValue('groupMembers', [
      ...(values.groupMembers ?? []),
      { phoneName: null, phoneNumber: null },
    ]);
  };

  const changeGroupMembersValue = (idx: number, value: string, field: string) => {
    const updatedMembers = (values.groupMembers || []).map((member, i) =>
      i === idx ? { ...member, [field]: value } : member,
    );
    setFieldValue('groupMembers', updatedMembers);
  };

  const onPhoneNameChange = (idx: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length > 32) return;
    changeGroupMembersValue(idx, value, 'phoneName');
  };

  const onPhoneNumberChange = (idx: number) => (value: string) => {
    changeGroupMembersValue(idx, value, 'phoneNumber');
  };

  const onDeletePhoneMember = (idx: number) => () => {
    const members = (values.groupMembers || []).filter((_, i) => i !== idx);
    setFieldValue('groupMembers', members);
  };

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length > 32) return;
    setFieldValue('groupName', value);
  };

  return {
    t,
    isMobile,
    isTabletSmall,
    isAddMemberDisabled,
    addContactToGroup,
    onPhoneNameChange,
    onPhoneNumberChange,
    onDeletePhoneMember,
    onChangeName,
  };
};
