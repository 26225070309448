import { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { LocalAudioTypes } from '@/constants';
import { useAudioPlayer } from '@/context';

const useUploadedPlayAudio = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const { isPlaying: isGlobalPlaying, playingType, changePlayingType } = useAudioPlayer();
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedFile, setSelectedFile] = useState<null | File>(null);
  const audioPlayer = useRef<null | HTMLAudioElement>(null);

  useEffect(() => {
    return () => {
      stopPlaying();
    };
  }, []);

  useEffect(() => {
    if (isPlaying && playingType !== LocalAudioTypes.Uploaded) {
      stopPlaying();
    }
  }, [playingType, isGlobalPlaying, isPlaying]);

  useEffect(() => {
    if (isPlaying) {
      togglePlayAudio();
    }
  }, [language]);

  useEffect(() => {
    if (selectedFile && !audioPlayer.current) {
      const audioUrl = URL.createObjectURL(selectedFile);
      audioPlayer.current = new Audio(audioUrl);
      audioPlayer.current.src = audioUrl;
      audioPlayer.current.load();
    }
  }, [selectedFile, audioPlayer]);

  const selectAudioFile = (file: File) => {
    if (selectedFile) deleteAudio();
    setSelectedFile(file);
  };

  const deleteAudio = () => {
    if (isPlaying) {
      setIsPlaying(false);
      audioPlayer.current?.pause();
    }
    audioPlayer.current = null;
    setSelectedFile(null);
  };

  const removeListeners = () => {
    if (audioPlayer?.current) {
      audioPlayer.current.pause();
      audioPlayer.current.removeEventListener('ended', stopPlaying);
    }
  };

  const stopPlaying = () => {
    removeListeners();
    setIsPlaying(false);
  };

  const togglePlayAudio = () => {
    if (!audioPlayer.current) return;
    if (!isPlaying) {
      audioPlayer.current.addEventListener('ended', stopPlaying);
      audioPlayer.current.play();
      changePlayingType(LocalAudioTypes.Uploaded);
    } else {
      audioPlayer.current.pause();
    }
    setIsPlaying(!isPlaying);
  };

  return { isPlaying, stopPlaying, selectedFile, selectAudioFile, deleteAudio, togglePlayAudio };
};

export default useUploadedPlayAudio;
