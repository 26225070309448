import React from 'react';

import { FolderOpen } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  Typography,
} from '@mui/material';

import { styles } from './styles';
import { useUploadAudioCard } from './useUploadAudioCard';

import { AudioRecordedPreview } from '@/components';

const UploadAudioCard = () => {
  const {
    t,
    title,
    loading,
    fileName,
    fileInput,
    isPlaying,
    selectedFile,
    fileSizeLimit,
    isBtnDisabled,
    isSizeLarger,
    isFormatError,
    isEmptyTextError,
    isPlayBtnDisabled,
    deleteAudio,
    handleUpload,
    togglePlayAudio,
    handleFileChange,
    onTitleChange,
  } = useUploadAudioCard();

  return (
    <Card sx={styles.card}>
      <CardContent sx={styles.cardContent}>
        <Typography variant="h1" fontWeight={700}>
          {t('upload')}
        </Typography>
        <FormControl fullWidth sx={styles.formControl}>
          <FormLabel sx={{ mb: 1 }}>{t('title')}</FormLabel>
          <TextField
            size="small"
            value={title}
            onChange={onTitleChange}
            placeholder={t('enterTitle')}
            inputProps={{ maxLength: 200 }}
            error={isEmptyTextError}
          />
          <FormHelperText error={isEmptyTextError}>
            {isEmptyTextError
              ? t('pleaseEnterTitleOfDIYTone')
              : t('allowedFormats', { audioFormat: '*.mp3, *.wav, *.m4a' })}
          </FormHelperText>
        </FormControl>
        {!!selectedFile && (
          <>
            <Box mt={2}>
              <AudioRecordedPreview
                isUpload
                {...{ isPlaying, isPlayBtnDisabled, fileName, deleteAudio, togglePlayAudio }}
              />
              {(isSizeLarger || isFormatError) && (
                <FormHelperText error>
                  {isFormatError
                    ? t('invalidContentFormat')
                    : t('fileSizeLarger', { fileSizeLimit })}
                </FormHelperText>
              )}
            </Box>
          </>
        )}
      </CardContent>
      <CardActions sx={styles.cardActions}>
        <Button
          fullWidth
          size="large"
          variant="contained"
          sx={styles.btn}
          onClick={handleUpload}
          disabled={isBtnDisabled}
        >
          <Typography variant="h3" mr={1.2}>
            {t(loading ? 'uploading' : selectedFile ? 'sendToReview' : 'upload')}
          </Typography>
          {loading && <CircularProgress size={20} sx={{ ml: 1.5 }} color="inherit" />}
          {!selectedFile && <FolderOpen sx={styles.folderOpen} />}
        </Button>
        {!selectedFile && (
          <input
            type="file"
            onChange={handleFileChange}
            ref={fileInput}
            accept="audio/mp3, audio/wav, audio/x-m4a"
            style={{ display: 'none' }}
          />
        )}
      </CardActions>
    </Card>
  );
};

export default UploadAudioCard;
