import { ErrorCodes } from '@/constants/enums';
import i18n from '@/i18n';

export const getErrorMessages = () => ({
  [ErrorCodes.CONTENT_ALREADY_EXISTS_IN_BOX]: i18n.t('errors.contentAlreadyExist'),
  [ErrorCodes.PUBLIC_INBOX_IS_FULL]: i18n.t('errors.publicInboxIsFull'),
  [ErrorCodes.GIFT_SUBSCRIBER_NOT_FOUND]: i18n.t('errors.giftSubscriberNotFound'),
  [ErrorCodes.GROUP_DUPLICATE_NAME]: i18n.t('errors.configuringGroup'),
  [ErrorCodes.GROUPS_LIMIT]: i18n.t('errors.groupSetUpError'),
  [ErrorCodes.GROUP_LIMIT_PHONE_NUMBERS]: i18n.t('errors.groupSetUpError'),
  [ErrorCodes.SET_UP_PLAYBACK_RULE]: i18n.t('errors.setUpPlayBackRule'),
  [ErrorCodes.Phone_Group_Has_Play_Conditions]: i18n.t('errors.groupSetUpError'),
  [ErrorCodes.NUMBER_OF_FAVOURITE_TONES_EXCEEDS_THE_LIMIT]: i18n.t(
    'errors.numberOfFavouriteExceedsTheLimit',
  ),
  [ErrorCodes.GIFT_TO_ONESELF_REJECTED]: i18n.t('errors.giftToOneselfRejected'),
  [ErrorCodes.CONTENT_ALREADY_IN_GIFTBOX]: i18n.t('errors.contentAlreadyInGiftBox'),
  [ErrorCodes.TIME_PLAY_CONDITION_EXISTS]: i18n.t('errors.canNotCreateDuplicatedPlayRules'),
  [ErrorCodes.PERSONAL_PLAY_CONDITION_EXISTS]: i18n.t('errors.canNotCreateDuplicatedPlayRules'),
  [ErrorCodes.GROUP_PLAY_CONDITION_EXISTS]: i18n.t('errors.canNotCreateDuplicatedPlayRules'),
  [ErrorCodes.PLAYBACK_RULE_EXISTS]: i18n.t('errors.canNotCreateDuplicatedPlayRules'),
  [ErrorCodes.GIFT_FAILED_ALREADY_IN_GIFTBOX]: i18n.t('errors.giftedToneIsAlreadyInstalled'),
  [ErrorCodes.GIFT_FAILED_LOW_BALANCE]: i18n.t('errors.giftFailedLowBalance'),
  [ErrorCodes.UNEXPECTED_REQUEST]: i18n.t('errors.unexpectedError'),
  [ErrorCodes.CONTENT_NOT_FOUND]: i18n.t('errors.unexpectedError'),
  [ErrorCodes.UNSUPPORTED_MEDIA_TYPE]: i18n.t('errors.invalidFormatOfContentFile'),
  [ErrorCodes.INVALID_CONTENT_FORMAT]: i18n.t('errors.invalidFormatOfContentFile'),
});
