import { inputBaseClasses } from '@mui/material';

export const styles = {
  preview: {
    height: 45,
    width: 170,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'background.default',
    borderRadius: 10,
    mt: 1,
  },
  input: {
    [`.${inputBaseClasses.root}`]: {
      backgroundColor: 'background.default',
    },
    width: '100%',
  },
} as const;
