import React, { memo } from 'react';

import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { styles } from './styles';
import { IDownloadMoreButtonProps } from './types';

import { ReloadIcon } from '@/components';
import { theme } from '@/constants';

const DownloadMoreButton: React.FC<IDownloadMoreButtonProps> = memo(({ changePage, ...props }) => {
  const { t } = useTranslation();
  return (
    <Box sx={styles.downloadButtonContainer}>
      <Button
        variant="contained"
        color="primary"
        disableRipple
        disableFocusRipple
        sx={styles.downloadButton}
        endIcon={
          <ReloadIcon
            fill={props.disabled ? theme.palette.grey[700] : theme.palette.text.primary}
          />
        }
        onClick={changePage}
        {...props}
      >
        {t('downloadMore')}
      </Button>
    </Box>
  );
});

DownloadMoreButton.displayName = 'DownloadMoreButton';

export default DownloadMoreButton;
