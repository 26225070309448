import { type ContentItem, HappyHours, InboxContentItem } from '@/graphql-schema';

export const getHappyHourForContent = ({
  happyHourData,
  contentItem,
}: {
  happyHourData: HappyHours[] | null;
  contentItem?: ContentItem | InboxContentItem | null;
}) => {
  const happyHourProvider = happyHourData?.find(
    ({ providerId }) => providerId === contentItem?.providerId,
  );

  if (happyHourProvider) return happyHourProvider;

  const happyHourDefault = happyHourData?.find(({ providerId }) => providerId === null);

  if (happyHourDefault) return happyHourDefault;

  return contentItem;
};
