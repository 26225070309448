import {
  DailyPlaybackRule,
  WeeklyPlaybackRule,
  MonthPlaybackRule,
  YearlyPlaybackRule,
  OneTimePlaybackRule,
  PhonePlaybackRule,
  GroupPlaybackRule,
} from '@/components';
import { PlayConditionTypeEnum, TimeRuleTypeEnum } from '@/graphql-schema';
import i18n from '@/i18n';

const getTmValueLocalization = (value: TimeRuleTypeEnum) => i18n.t(`tmTypeValues.${value}`);
const getPcValueLocalization = (value: PlayConditionTypeEnum) => i18n.t(`pcTypeValues.${value}`);

export const getPcTypeValues = () => ({
  [PlayConditionTypeEnum.Default]: getPcValueLocalization(PlayConditionTypeEnum.Default),
  [PlayConditionTypeEnum.Group]: getPcValueLocalization(PlayConditionTypeEnum.Group),
  [PlayConditionTypeEnum.Incomplete]: null,
  [PlayConditionTypeEnum.Personal]: getPcValueLocalization(PlayConditionTypeEnum.Personal),
  [PlayConditionTypeEnum.System]: null,
});

export const getTmTypeValues = () => ({
  [TimeRuleTypeEnum.Always]: getTmValueLocalization(TimeRuleTypeEnum.Always),
  [TimeRuleTypeEnum.Daily]: getTmValueLocalization(TimeRuleTypeEnum.Daily),
  [TimeRuleTypeEnum.OneTime]: getTmValueLocalization(TimeRuleTypeEnum.OneTime),
  [TimeRuleTypeEnum.Weekly]: getTmValueLocalization(TimeRuleTypeEnum.Weekly),
  [TimeRuleTypeEnum.Monthly]: getTmValueLocalization(TimeRuleTypeEnum.Monthly),
  [TimeRuleTypeEnum.Yearly]: getTmValueLocalization(TimeRuleTypeEnum.Yearly),
  [TimeRuleTypeEnum.Incomplete]: null,
});

export const pcTypeComponents = {
  [PlayConditionTypeEnum.Personal]: PhonePlaybackRule,
  [PlayConditionTypeEnum.Incomplete]: PhonePlaybackRule,
  [PlayConditionTypeEnum.Group]: GroupPlaybackRule,
  [PlayConditionTypeEnum.System]: PhonePlaybackRule,
  [PlayConditionTypeEnum.Default]: PhonePlaybackRule,
};

export const tmTypeComponents = {
  [TimeRuleTypeEnum.Always]: DailyPlaybackRule,
  [TimeRuleTypeEnum.Incomplete]: DailyPlaybackRule,
  [TimeRuleTypeEnum.Daily]: DailyPlaybackRule,
  [TimeRuleTypeEnum.Monthly]: MonthPlaybackRule,
  [TimeRuleTypeEnum.Weekly]: WeeklyPlaybackRule,
  [TimeRuleTypeEnum.Yearly]: YearlyPlaybackRule,
  [TimeRuleTypeEnum.OneTime]: OneTimePlaybackRule,
};
