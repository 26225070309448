import React from 'react';

import { Pause } from '@mui/icons-material';
import { Button } from '@mui/material';

import { styles } from './styles';
import { usePlayButton } from './usePlayButton';

import type { ButtonProps } from '@mui/material';

import { PlayIcon } from '@/components';
import { mergeSx } from '@/utils';

const PlayButton = (props: ButtonProps & { contentNo: string }) => {
  const { contentNo, sx, ...otherProps } = props;
  const { isCurrentPlaying, toggleAudioStatus } = usePlayButton(contentNo);

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={toggleAudioStatus(contentNo)}
      disableFocusRipple
      disableRipple
      sx={mergeSx(styles.playButton, sx)}
      endIcon={
        isCurrentPlaying ? <Pause sx={styles.pauseButtonIcon} /> : <PlayIcon sx={styles.playButtonIcon} />
      }
      {...otherProps}
    />
  );
};

export default PlayButton;
