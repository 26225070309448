const styles = {
  container: {
    flexGrow: 1,
    overflow: 'hidden',
    minHeight: {
      xs: 'calc(100vh - 116px - 260px - 32px - 24px)',
      md: 'calc(100vh - 69px - 242px - 32px - 24px)',
    },
  },
};

export { styles };
