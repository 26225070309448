const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    p: 1.5,
    borderRadius: 1,
    backgroundColor: 'grey.100',
  },
};

export { styles };
