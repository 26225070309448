import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import styles from '../styles';

import { theme } from '@/constants';
import { mergeSx } from '@/utils';

const RecordInProcessIcon = ({
  viewBox = '0 0 33 32',
  fill = theme.palette.error.main,
  sx = {},
  ...rest
}: SvgIconProps) => (
  <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5013 15.3335C20.5013 17.5468 18.7146 19.3335 16.5013 19.3335C14.288 19.3335 12.5013 17.5468 12.5013 15.3335V7.3335C12.5013 5.12016 14.288 3.3335 16.5013 3.3335C18.7146 3.3335 20.5013 5.12016 20.5013 7.3335V15.3335ZM16.5013 22.0002C20.1813 22.0002 23.168 19.0135 23.168 15.3335H25.8346C25.8346 20.0402 22.3546 23.9068 17.8346 24.5602V28.6668H15.168V24.5602C10.648 23.9068 7.16797 20.0402 7.16797 15.3335H9.83464C9.83464 19.0135 12.8213 22.0002 16.5013 22.0002Z"
      fill={fill}
    />
  </SvgIcon>
);

export default RecordInProcessIcon;
