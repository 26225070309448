import type { ContentItem, InboxContentItem } from '@/graphql-schema';

export type SelectedPurchaseForModal = (ContentItem | InboxContentItem) & { imgUrl?: string };
export interface PurchaseState {
  isPurchaseModalOpen: boolean;
  selectedPurchaseForModal: ContentItem | InboxContentItem | null;
  purchaseType?: PurchaseEnum | null;
  virtContentNo?: string | null;
}

export enum PurchaseEnum {
  BUY = 'buy',
  GIFT = 'gift',
}
