import { createSlice } from '@reduxjs/toolkit';

import { PurchaseEnum, type PurchaseState } from '@/redux/purchases/types';

const initialState: PurchaseState = {
  isPurchaseModalOpen: false,
  selectedPurchaseForModal: {},
  purchaseType: null,
  virtContentNo: null,
};
export const purchaseSlice = createSlice({
  name: 'purchases',
  initialState,
  reducers: {
    openPurchaseModal: (state, { payload }) => {
      state.isPurchaseModalOpen = true;
      state.selectedPurchaseForModal = payload.selectedPurchaseForModal;
      state.purchaseType = payload.purchaseType;
    },
    closePurchaseModal: (state) => {
      state.isPurchaseModalOpen = false;
    },
    clearSelectedPurchaseForModal: (state) => {
      state.selectedPurchaseForModal = null;
      state.purchaseType = null;
      state.virtContentNo = null;
    },
    setPurchaseTypeAndVirtContentNo: (state, { payload }) => {
      state.purchaseType = payload.purchaseType;
      state.virtContentNo = payload.virtContentNo;
    },
    clearPurchaseTypeAndVirtContentNo: (state) => {
      state.purchaseType = null;
      state.virtContentNo = null;
    },
    setVirtContentNoBuy: (state, { payload }) => {
      state.purchaseType = PurchaseEnum.BUY;
      state.virtContentNo = payload;
    },
    setVirtContentNoGift: (state, { payload }) => {
      state.purchaseType = PurchaseEnum.GIFT;
      state.virtContentNo = payload;
    },
  },
});

export const {
  setVirtContentNoBuy,
  setVirtContentNoGift,
  openPurchaseModal,
  closePurchaseModal,
  setPurchaseTypeAndVirtContentNo,
  clearPurchaseTypeAndVirtContentNo,
  clearSelectedPurchaseForModal,
} = purchaseSlice.actions;

export default purchaseSlice.reducer;
