import React from 'react';

import { SvgIcon } from '@mui/material';

import type { SvgIconProps } from '@mui/material';

import styles from '@/components/shared/icons/styles';
import { theme } from '@/constants';
import { mergeSx } from '@/utils';

export const StarSelectedIcon = ({
  viewBox = '0 0 24 24',
  fill = theme.palette.primary.main,
  sx = {},
  ...rest
}: SvgIconProps) => (
  <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
    <path
      d="M12 17.77L18.18 21.5L16.54 14.47L22 9.74L14.81 9.13L12 2.5L9.19 9.13L2 9.74L7.46 14.47L5.82 21.5L12 17.77Z"
      fill={fill}
    />
  </SvgIcon>
);
