import type { IClientConfigType } from '@/types';

import { imageFormat, StorageNames } from '@/constants';
import { getSessionStorageObject } from '@/helpers';

export const transformIdToPath = (id: number | string, fileType = imageFormat) => {
  const config = getSessionStorageObject<IClientConfigType>(StorageNames.ClientConfig);
  const picturePathWeb = config?.picturePathWeb;
  // TODO: remove this (it need for local development, for showing images) remove when will be ready
  const { origin } = window.location;
  const host = origin.includes('localhost') ? 'http://10.4.4.148:3003' : origin;
  const path =
    id
      .toString()
      .slice(0, -3)
      .match(/.{1,3}/g)
      ?.join('/') + `/${id}.${fileType}`;

  return `${host}/${picturePathWeb}/${path}`;
};
