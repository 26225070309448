import { createSlice } from '@reduxjs/toolkit';

import type { IOffsetState } from './types';

const initialState: IOffsetState = {
  offset: 0,
  isOffsetLoading: false,
};

export const offsetSlice = createSlice({
  name: 'offset',
  initialState,
  reducers: {
    changeOffset(state, { payload }) {
      state.offset = state.offset + payload;
    },
    clearOffset(state) {
      state.offset = 0;
    },
    setOffsetLoading(state, { payload }) {
      state.isOffsetLoading = payload;
    },
  },
});

export const { setOffsetLoading } = offsetSlice.actions;

export default offsetSlice.reducer;
