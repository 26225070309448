import { useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';

import { ClientRoutes } from '@/constants';
import { useScreenSize } from '@/hooks';

const useScrollOnPageChange = () => {
  const { isMobileScreenView } = useScreenSize();
  const { pathname } = useLocation();
  const prevLocation = useRef<null | string>(null);

  useEffect(() => {
    if (pathname) {
      const isPrevCatalog = prevLocation.current?.indexOf(ClientRoutes.Catalog) !== -1;
      const isCurrentCatalog = pathname?.indexOf(ClientRoutes.Catalog) !== -1;
      prevLocation.current = pathname;
      if (isPrevCatalog && isCurrentCatalog && isMobileScreenView) return;
    }
    window.scrollTo(0, 0);
  }, [pathname, isMobileScreenView]);
};

export default useScrollOnPageChange;
