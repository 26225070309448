export enum ErrorCodes {
  NO_RBT_AUTHORIZED = 'E007',
  PUBLIC_INBOX_IS_FULL = 'E017',
  GIFT_SUBSCRIBER_NOT_FOUND = 'E070',
  CONTENT_ALREADY_EXISTS_IN_BOX = 'E031',
  UNEXPECTED_REQUEST = 'C001',
  GROUP_DUPLICATE_NAME = 'E063',
  SET_UP_PLAYBACK_RULE = 'E006',
  PLAYBACK_RULE_EXISTS = 'E030',
  GROUP_LIMIT_PHONE_NUMBERS = 'E081',
  GROUPS_LIMIT = 'E082',
  Phone_Group_Has_Play_Conditions = 'E084',
  Group_Play_Condition_Already_Set = 'E090',
  Play_Condition_Already_Set = 'E089',
  NUMBER_OF_FAVOURITE_TONES_EXCEEDS_THE_LIMIT = 'E083',
  GIFT_TO_ONESELF_REJECTED = 'E040',
  CONTENT_ALREADY_IN_GIFTBOX = 'E013',
  CONTENT_NOT_FOUND = 'E001',
  TIME_PLAY_CONDITION_EXISTS = 'E088',
  PERSONAL_PLAY_CONDITION_EXISTS = 'E089',
  GROUP_PLAY_CONDITION_EXISTS = 'E090',
  GIFT_FAILED_ALREADY_IN_GIFTBOX = 'E073',
  GIFT_FAILED_LOW_BALANCE = 'E074',
  UNSUPPORTED_MEDIA_TYPE = 'N058',
  INVALID_CONTENT_FORMAT = 'N020',
}
