import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import styles from '../styles';

import { theme } from '@/constants';
import { mergeSx } from '@/utils';

function FavoriteFilledIcon({
  viewBox = '0 0 25 24',
  fill = theme.palette.secondary.main,
  sx = {},
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
      <path
        d="M20.875 7.74L13.685 7.12L10.875 0.5L8.065 7.13L0.875 7.74L6.335 12.47L4.695 19.5L10.875 15.77L17.055 19.5L15.425 12.47L20.875 7.74Z"
        fill={fill}
      />
    </SvgIcon>
  );
}

export default FavoriteFilledIcon;
