import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useActiveSubscriber } from '@/context';
import {
  catalogSelectors,
  clearContentForCatalog,
  loadMoreContentForCatalog,
  setContentForCatalog,
} from '@/redux/catalog';
import { IContentResponse, ISubscriberResponse } from '@/types';

const useCatalogPagination = (offset: number, data?: ISubscriberResponse | IContentResponse) => {
  const dispatch = useDispatch();
  const { isRbtAuthorized } = useActiveSubscriber();
  const { contentItems } = useSelector(catalogSelectors.catalogSelector);

  const elements = isRbtAuthorized
    ? (data as ISubscriberResponse)?.subscriber?.contentItems?.elements
    : (data as IContentResponse)?.contentItems?.elements;

  const numberOfElements =
    (isRbtAuthorized
      ? (data as ISubscriberResponse)?.subscriber?.contentItems?.numberOfElements
      : (data as IContentResponse)?.contentItems?.numberOfElements) ?? 0;

  const isDownloadMoreVisible = numberOfElements > (contentItems?.length ?? 0);

  useEffect(() => {
    if (!elements?.length && offset === 0) dispatch(clearContentForCatalog());
    if (!elements?.length) return;
    if (offset === 0) {
      dispatch(setContentForCatalog(elements));
    } else {
      dispatch(loadMoreContentForCatalog(elements));
    }
  }, [elements, offset]);

  useEffect(() => {
    return () => {
      dispatch(clearContentForCatalog());
    };
  }, []);

  return {
    isDownloadMoreVisible,
  };
};
export default useCatalogPagination;
