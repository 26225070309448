import { baseTheme } from '@/constants';

export const styles = {
  container: {
    display: 'flex',
    justifyContent: {
      md: 'space-between',
      xs: 'flex-start',
    },
    flexWrap: 'wrap',
  },
  day: {
    width: 52.14,
    minWidth: 52.14,
    height: {
      md: 50,
      xs: 45,
    },
    borderRadius: {
      md: 4,
      xs: 3,
    },
    mt: 1,
    mr: 1,
  },
  notSelectedDay: {
    backgroundColor: 'grey.400',
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.500',
      },
    },
    '&.Mui-disabled': {
      color: 'grey.600',
      background: 'transparent',
      border: 'none',
    },
  },
  selectedDay: {
    '&.Mui-disabled': {
      backgroundColor: 'primary.main',
      color: 'black',
    },
    '&:hover': {
      backgroundColor: 'primary.main',
      boxShadow: 'none',
    },
  },
};
