import React, { memo } from 'react';

import type { ITypeRulesComponentProps } from '@/components';

import { PlaybackCalendar } from '@/components';
import { TimeRuleTypeEnum } from '@/graphql-schema';

const MonthPlaybackRule: React.FC<ITypeRulesComponentProps> = memo((props) => (
  <PlaybackCalendar calendarType={TimeRuleTypeEnum.Monthly} {...props} />
));

MonthPlaybackRule.displayName = 'MonthPlaybackRule';

export default MonthPlaybackRule;
