import { paperClasses } from '@mui/material';

export const styles = {
  card: {
    width: 319,
    px: 2,
    py: 2.5,
    gap: 8,
    borderRadius: 2,
    [`.${paperClasses.root}`]: {
      height: 'fit-container',
    },
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    mt: 1.5,
    p: 0,
  },
} as const;
