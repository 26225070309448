import { buttonClasses } from '@mui/material';


const styles = {
  playButton: {
    height: 32,
    minWidth: {
      xs: 47,
      lg: 67,
    },
    width: '100%',
    borderRadius: 8,
    p: 1,
    '&:hover': {
      backgroundColor: 'primary.main',
    },
    [`.${buttonClasses.endIcon}`]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  pauseButtonIcon: {
    color: 'white',
  },
  playButtonIcon: {
    fill: 'white',
    width: 10,
    height: 12,
  },
};

export { styles };
