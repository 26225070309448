import React from 'react';

import { Box, Button, IconButton, Typography } from '@mui/material';

import { styles } from './styles';
import { useSlider } from './useSlider';
import SliderSwitcher from '../SliderSwitcher';

import type { ContentItem as ContentItemType } from '@/graphql-schema';

import { PlayCircleIcon, Image, PauseCircleIcon, PlayContentImage } from '@/components';

const Slider = () => {
  const {
    t,
    items,
    imgUrl,
    hasItems,
    contentItem,
    onetimePeriod,
    amountOnetime,
    isComplexContent,
    isSelectedAudioPlayed,
    isMobileScreenView,
    onChange,
    openModalForBuy,
    toggleAudioStatus,
  } = useSlider();
  if (!hasItems) return null;
  return (
    <Box sx={styles.main}>
      <Box sx={styles.card}>
        <Box sx={styles.cardContent}>
          <Box display="flex" gap={3}>
            {isMobileScreenView ? (
              <Box sx={styles.imgContainer}>
                <PlayContentImage
                  size={132}
                  isSlider
                  isPreview={isComplexContent}
                  contentItem={contentItem as ContentItemType}
                  isOverlayVisible
                />
              </Box>
            ) : (
              <>
                {!isComplexContent && (
                  <IconButton
                    sx={styles.playIcon}
                    onClick={toggleAudioStatus(contentItem?.contentNo)}
                  >
                    {isSelectedAudioPlayed ? (
                      <PauseCircleIcon sx={styles.icon} />
                    ) : (
                      <PlayCircleIcon sx={styles.icon} />
                    )}
                  </IconButton>
                )}
              </>
            )}
            <Box sx={styles.content}>
              <Typography variant="h1" color="white" fontWeight={700} sx={styles.contentText}>
                {contentItem?.title}
              </Typography>
              <Typography variant="h2" color="white" sx={styles.contentText}>
                {contentItem?.artist}
              </Typography>
            </Box>
          </Box>
          {contentItem && (
            <Box>
              <Button
                variant="contained"
                disableFocusRipple
                disableRipple
                sx={styles.purchaseButton}
                onClick={openModalForBuy}
              >
                <Typography variant="h4" sx={styles.purchaseText}>
                  {t('order')}
                </Typography>{' '}
                {contentItem && (
                  <Typography variant="h4" color="white">
                    {t('amountOnetime', {
                      amountOnetime,
                    })}
                    {' / '}
                    {t('onetimePeriod', {
                      onetimePeriod,
                    })}
                  </Typography>
                )}
              </Button>
            </Box>
          )}
        </Box>
        {!isMobileScreenView && (
          <Box sx={styles.imageContainer}>
            <Image sx={styles.image} src={imgUrl} />
          </Box>
        )}
      </Box>
      <SliderSwitcher items={items} onChange={onChange} />
    </Box>
  );
};

export default Slider;
