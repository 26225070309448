import React, { memo } from 'react';

import { TextField, Box, Typography, FormHelperText } from '@mui/material';
import InputMask from 'react-input-mask';

import { styles } from './styles';
import { useDailyPlaybackRule } from './useDailyPlaybackRule';

import type { ITypeRulesComponentProps } from '@/components';

const DailyPlaybackRule: React.FC<ITypeRulesComponentProps> = memo((props) => {
  const { error, isPreview } = props;
  const { t, values, startDateError, endDateError, handleInputChange, handleInputBlur } =
    useDailyPlaybackRule(props);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.time}>
        <Typography variant="h4">{t('timeFrom')}</Typography>
        <InputMask
          name="startDate"
          mask="99:99"
          value={values.startDate}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          disabled={isPreview}
        >
          <TextField
            error={!!error && !!startDateError}
            sx={styles.textField}
            name="startDate"
            inputProps={styles.textFieldInout}
          />
        </InputMask>
        {error && startDateError && (
          <FormHelperText error={!!startDateError}>{t(startDateError)}</FormHelperText>
        )}
      </Box>
      <Box sx={styles.time}>
        <Typography variant="h4">{t('timeTill')}</Typography>
        <InputMask
          name="endDate"
          mask="99:99"
          value={values.endDate}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          disabled={isPreview}
        >
          <TextField
            error={!!error && !!endDateError}
            sx={styles.textField}
            name="startDate"
            inputProps={styles.textFieldInout}
          />
        </InputMask>
        {error && endDateError && (
          <FormHelperText error={!!endDateError}>{t(endDateError)}</FormHelperText>
        )}
      </Box>
    </Box>
  );
});

DailyPlaybackRule.displayName = 'StartEndPlayback';

export default DailyPlaybackRule;
