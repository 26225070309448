import { chipClasses } from '@mui/material';

export const styles = {
  complexType: {
    px: 0.75,
    py: 0.5,
    borderRadius: 1,
    backgroundColor: 'primary.soft.blue.150',
    color: 'primary.blue',
    width: 'min-content',
    height: 'auto',
    mb: 1.75,
    [` .${chipClasses.label}`]: {
      p: 0,
      fontSize: {
        md: 14,
        xs: 13,
      },
    },
  },
};
