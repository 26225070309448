import React from 'react';

import { useNotSavedMyMeloringModal } from './useNotSavedMyMeloringModal';

import { CustomConfirmModal } from '@/components';

const NotSavedMyMeloringModal = () => {
  const modalProps = useNotSavedMyMeloringModal();

  return <CustomConfirmModal {...modalProps} />;
};

export default NotSavedMyMeloringModal;
