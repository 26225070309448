import React from 'react';

import { Checkbox, IconButton } from '@mui/material';

import type { CheckboxProps } from '@mui/material';

import { CheckBoxCheckedIcon, CheckBoxNotCheckedIcon } from '@/components';

interface CustomCheckboxProps extends CheckboxProps {
  onChange?: () => void;
}

const CustomCheckbox = ({ onChange, ...props }: CustomCheckboxProps) => {
  return (
    <IconButton onClick={onChange} disableFocusRipple disableTouchRipple>
      <Checkbox
        disableRipple
        disableFocusRipple
        icon={<CheckBoxNotCheckedIcon />}
        checkedIcon={<CheckBoxCheckedIcon />}
        {...props}
      />
    </IconButton>
  );
};

export default CustomCheckbox;
