import i18n from '@/i18n';

export const giftsHistoryTabs = () => [
  {
    id: 'tab-sent',
    label: i18n.t('pageGiftsHistory.tabs.sent'),
  },
  {
    id: 'tab-received',
    label: i18n.t('pageGiftsHistory.tabs.received'),
  },
];

export const historyTabs = () => [
  {
    id: 'tab-pay',
    label: i18n.t('pageHistory.tabs.pay'),
  },
  {
    id: 'tab-history',
    label: i18n.t('pageHistory.tabs.history'),
  },
];
