import React, { FC } from 'react';

import {
  TextField,
  Box,
  FormControl,
  FormLabel,
  IconButton,
  Divider,
  Typography,
  FormHelperText,
} from '@mui/material';

import { styles } from './styles';
import { useGroupForm } from './useGroupForm';

import type { IGroupFormProps } from './types';
import type { GroupMemberInput } from '@/graphql-schema';

import { CreateSmallButton, PhoneInput, TrashIcon } from '@/components';
import { theme } from '@/constants';

const GroupForm: FC<IGroupFormProps> = (props) => {
  const { values, errors, errorMessage, touched } = props;
  console.log(values);
  const {
    t,
    isMobile,
    isTabletSmall,
    isAddMemberDisabled,
    addContactToGroup,
    onPhoneNameChange,
    onPhoneNumberChange,
    onDeletePhoneMember,
    onChangeName,
  } = useGroupForm(props);

  return (
    <Box sx={styles.container}>
      <FormControl fullWidth>
        <FormLabel sx={{ mb: 1 }}>{t('groupName')}</FormLabel>
        <TextField
          placeholder={t('enterGroupName')}
          error={touched?.groupName && !!errors?.groupName}
          size="small"
          name="groupName"
          value={values.groupName}
          onChange={onChangeName}
        />
        {touched?.groupName && !!errors?.groupName && (
          <FormHelperText error={!!errors.groupName}>{t(errors.groupName)}</FormHelperText>
        )}
      </FormControl>
      <Divider sx={styles.divider} />
      <Typography variant="h2" alignSelf="flex-start">
        {t('groupMembers')}
      </Typography>
      {values?.groupMembers?.map((member, idx) => {
        const phoneNameError =
          touched?.groupMembers && (errors.groupMembers?.[idx] as GroupMemberInput)?.phoneName;

        const phoneNumberError =
          touched.groupMembers && (errors.groupMembers?.[idx] as GroupMemberInput)?.phoneNumber;
        const isPhoneNumberError = !!phoneNumberError;
        return (
          <Box
            key={idx}
            sx={{
              ...styles.member,
              alignItems: isPhoneNumberError
                ? 'flex-start'
                : {
                    bg: 'flex-start',
                    xs: 'flex-end',
                  },
            }}
          >
            <Box sx={styles.formWrapper}>
              <FormControl sx={styles.formControl}>
                <FormLabel sx={{ mb: 1 }}>{t('subscriberName')}</FormLabel>
                <TextField
                  placeholder={t('enterName')}
                  size="small"
                  value={member?.phoneName ?? ''}
                  error={!!phoneNameError}
                  onChange={onPhoneNameChange(idx)}
                />
              </FormControl>
              <FormControl sx={styles.formControl}>
                <FormLabel sx={{ mb: 1 }}>{t('number')}</FormLabel>
                <PhoneInput
                  placeholder={t('enterNumber')}
                  size="small"
                  value={member?.phoneNumber ?? ''}
                  error={phoneNumberError}
                  onChange={onPhoneNumberChange(idx)}
                />
              </FormControl>
            </Box>
            {isMobile || isTabletSmall ? (
              <Box sx={styles.iconWrapper} onClick={onDeletePhoneMember(idx)}>
                <TrashIcon fill={theme.palette.text.secondary} />
              </Box>
            ) : (
              <IconButton sx={styles.trashButton} onClick={onDeletePhoneMember(idx)}>
                <TrashIcon fill={theme.palette.text.secondary} />
              </IconButton>
            )}
          </Box>
        );
      })}
      <Box mt={3}>
        <CreateSmallButton disabled={isAddMemberDisabled} onClick={addContactToGroup}>
          <Typography variant="h3" mr={1.5}>
            {t(isAddMemberDisabled ? 'numbersLimitExceeded' : 'addNumber')}
          </Typography>
        </CreateSmallButton>
      </Box>
      {!!errorMessage && !!touched?.groupMembers && (
        <FormHelperText sx={styles.helperText} error>
          {errorMessage}
        </FormHelperText>
      )}
    </Box>
  );
};

export default GroupForm;
