import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ClientRoutes } from '@/constants';
import {
  ComplexTypeEnum,
  ContentChargeStateEnum,
  ContentTypeEnum,
  InboxContentItem,
} from '@/graphql-schema';
import { useLocalization } from '@/hooks';
import { openDeleteUnpaidContent } from '@/redux/unpaid-content/slice';
import { formatFullDateFromTimestamp } from '@/utils';

export const useUnpaidContentItem = (contentItem: InboxContentItem) => {
  const dispatch = useDispatch();
  const { navigate } = useLocalization();
  const { t } = useTranslation();

  const complexType = contentItem?.complexType;

  const isPersonalContent = contentItem?.contentType === ContentTypeEnum.Personal;
  const isComplexContent = !!complexType && complexType !== ComplexTypeEnum.Single;

  const startDt = useMemo(
    () => formatFullDateFromTimestamp(contentItem.startDt),
    [contentItem.startDt],
  );

  const endDt = useMemo(() => formatFullDateFromTimestamp(contentItem.endDt), [contentItem.endDt]);

  const graceDt = useMemo(
    () => (contentItem.graceDt ? formatFullDateFromTimestamp(contentItem.graceDt) : null),
    [contentItem.graceDt],
  );

  const isWaitingProlongation =
    contentItem.chargeState === ContentChargeStateEnum.DeferredProlongation ||
    contentItem.chargeState === ContentChargeStateEnum.WaitingProlongation;

  const chargeState =
    contentItem.chargeState && t(isWaitingProlongation ? 'waitingProlongation' : 'waitingPurchase');

  const onContentItemClick = () => {
    if (isPersonalContent) return;
    navigate(`${ClientRoutes.Content}/${contentItem?.virtContentNo}`);
  };

  const onContentDelete = () => {
    dispatch(openDeleteUnpaidContent(contentItem));
  };

  const onArtistClick = () => {
    navigate(`${ClientRoutes.Artist}/${contentItem?.artist}`);
  };

  return {
    t,
    isPersonalContent,
    complexType,
    isComplexContent,
    chargeState,
    isWaitingProlongation,
    startDt,
    endDt,
    graceDt,
    onContentItemClick,
    onContentDelete,
    onArtistClick,
  };
};
