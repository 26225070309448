import { createSelector } from '@reduxjs/toolkit';

import type { IPlaybackRulesState } from './types';
import type { RootState } from '@/redux';

export const playbackRulesSelector = (state: RootState) => state.playbackRules;

export const isCreateModalOpenSelector = createSelector(
  playbackRulesSelector,
  (state: IPlaybackRulesState) => state.isCreateModalOpen,
);

export const selectedPlaybackForEditingSelector = createSelector(
  playbackRulesSelector,
  (state: IPlaybackRulesState) => state.selectedPlaybackForEditing,
);
