import { createSlice } from '@reduxjs/toolkit';

import type { HappyHours } from '@/graphql-schema';

const initialState: {
  isHappyHour: boolean;
  happyHourData: null | HappyHours[];
} = {
  isHappyHour: false,
  happyHourData: null,
};

export const happyHourSlice = createSlice({
  name: 'happyHour',
  initialState,
  reducers: {
    setHappyHourData(state, { payload }) {
      state.isHappyHour = true;
      state.happyHourData = payload;
    },
    clearHappyHourData(state) {
      state.isHappyHour = false;
      state.happyHourData = null;
    },
  },
});

export const { setHappyHourData, clearHappyHourData } = happyHourSlice.actions;

export default happyHourSlice.reducer;
