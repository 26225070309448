import React from 'react';

import { Button, CardContent, Typography } from '@mui/material';

import { styles } from './styles';
import { usePlaybackItemEdit } from './usePlaybackItemEdit';

import type { IPlaybackItemEditProps } from './types';

import { PlaybackRuleForm } from '@/components';

const PlaybackItemEdit: React.FC<IPlaybackItemEditProps> = (props) => {
  const { toggleIsEditing } = props;

  const { t, rule, formik, onFieldValueChange } = usePlaybackItemEdit(props);

  const { touched, errors, dirty, values, handleSubmit } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <PlaybackRuleForm {...{ rule, values, errors, touched, onFieldValueChange }} />
      <CardContent sx={styles.сontent}>
        <Button variant="outlined" sx={styles.cancelBtn} onClick={toggleIsEditing}>
          <Typography variant="h4">{t('cancel')}</Typography>
        </Button>
        <Button type="submit" variant="contained" disabled={!dirty} sx={styles.saveBtn}>
          <Typography variant="h4" sx={styles.btnText}>
            {t('save')}
          </Typography>
        </Button>
      </CardContent>
    </form>
  );
};

export default PlaybackItemEdit;
