import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import { useClientConfig, useKeycloak } from '@/context';
import { GET_SUBSCRIBER } from '@/graphqL-queries/contents';
import { isRbtUserErrorRequest } from '@/helpers';

export const useActiveUserProvider = () => {
  const {
    config: { isTestMode, testMsisdn },
  } = useClientConfig();
  const [isUserInitLoaded, setIsUserInitLoaded] = useState(false);
  const [isRbtAuthorized, setIsRbtAuthorized] = useState(false);
  const { keycloak, isLoaded, ...keycloackProps } = useKeycloak();

  const { pathname } = useLocation();

  const msisdn = isTestMode ? testMsisdn : keycloackProps?.msisdn;
  const {
    error,
    data,
    refetch: refetchSubscriber,
  } = useQuery(GET_SUBSCRIBER, {
    skip: !msisdn || !isLoaded,
    variables: {
      msisdn,
    },
  });

  const activesSubscriber = data?.subscriber || null;

  const serviceNo = activesSubscriber?.subscriptions?.[0]?.serviceNo;

  const changeRbtAuthorizedStatus = (isError: boolean) => {
    setIsRbtAuthorized(isError);
  };

  useEffect(() => {
    if (isLoaded && !keycloak?.authenticated) {
      setIsUserInitLoaded(true);
    }
    if ((isTestMode || isLoaded) && (activesSubscriber || error)) {
      const areAnySubscriptions = !!activesSubscriber?.subscriptions?.length;
      const isRbtUser =
        (!!keycloak?.authenticated || isTestMode) &&
        isRbtUserErrorRequest(error) &&
        areAnySubscriptions;
      changeRbtAuthorizedStatus(isRbtUser);
      setIsUserInitLoaded(true);
    }
  }, [isLoaded, keycloak?.authenticated, activesSubscriber, error]);

  useEffect(() => {
    if (msisdn) {
      refetchSubscriber();
    }
  }, [pathname, isRbtAuthorized, msisdn]);

  return {
    serviceNo,
    isUserInitLoaded,
    isRbtAuthorized,
    activesSubscriber,
    refetchSubscriber,
    changeRbtAuthorizedStatus,
  };
};
