import { useState } from 'react';

import { ICustomContentCard } from './types';

import { ClientRoutes } from '@/constants';
import { ComplexTypeEnum } from '@/graphql-schema';
import { useLocalization } from '@/hooks';

export const useCustomContentCard = (contentItem: ICustomContentCard) => {
  const { navigate } = useLocalization();
  const [toggleButton, setToggleButton] = useState(false);

  const complexType = contentItem?.complexType;

  const isComplexContent = !!complexType && complexType !== ComplexTypeEnum.Single;

  const onToggle = () => setToggleButton(!toggleButton);

  const onArtistClick = () => {
    navigate(`${ClientRoutes.Artist}/${contentItem?.artist}`);
  };

  return {
    complexType,
    isComplexContent,
    toggleButton,
    onToggle,
    onArtistClick,
  };
};
