import { createSlice } from '@reduxjs/toolkit';

import type { IRecordMyMeloringState } from './types';

const initialState: IRecordMyMeloringState = {
  isDiyAudioSelected: false,
};

export const recordMyMeloringSlice = createSlice({
  name: 'recordMyMeloring',
  initialState,
  reducers: {
    changeAudioSelectedStatus: (state, { payload }) => {
      state.isDiyAudioSelected = payload;
    },
  },
});

export const { changeAudioSelectedStatus } = recordMyMeloringSlice.actions;

export default recordMyMeloringSlice.reducer;
