import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import { storageConfig } from './constants';

import type { IClientConfigType } from '@/types';

import { useGetClientConfigQuery } from '@/apis/client-config';
import { defaultClientConfig, StorageNames } from '@/constants';
import { GET_WEB_PARAMETERS } from '@/graphqL-queries/parameters';
import { setSessionStorageItem } from '@/helpers';
import { useGraphQlErrorHandler } from '@/hooks';

export const useClientConfigProvider = () => {
  const [config, setConfig] = useState<IClientConfigType>(defaultClientConfig);
  const [isInitialized, setInitialized] = useState(false);
  const { data: fetchedConfig } = useGetClientConfigQuery({}, { skip: !!storageConfig });

  const { data, error } = useQuery(GET_WEB_PARAMETERS);

  const webParameters = data?.webParameters;

  useGraphQlErrorHandler(error);

  useEffect(() => {
    if (storageConfig && !isInitialized) {
      setConfig(storageConfig);
      setInitialized(true);
    }
  }, [storageConfig]);

  useEffect(() => {
    if (fetchedConfig && !storageConfig) {
      setConfig(fetchedConfig);
      setSessionStorageItem(StorageNames.ClientConfig, fetchedConfig);
    }
  }, [fetchedConfig]);

  useEffect(() => {
    if (webParameters && isInitialized) {
      setConfig({ ...storageConfig, ...webParameters });
      setSessionStorageItem(StorageNames.ClientConfig, {
        ...storageConfig,
        ...webParameters,
      });
    }
  }, [webParameters, isInitialized]);

  const onClientConfigChange = (params: Object) => {
    const newConfig = { ...config, ...params };
    setSessionStorageItem(StorageNames.ClientConfig, newConfig);
    setConfig(newConfig);
  };

  return { config, onClientConfigChange };
};
