import { useDispatch, useSelector } from 'react-redux';

import { mobileDrawerSelector } from '@/redux/mobile-drawer';
import { closeMobileDrawer } from '@/redux/mobile-drawer/slice';

const useMobileDrawerHook = () => {
  const dispatch = useDispatch();
  const { isMobileDrawerOpen } = useSelector(mobileDrawerSelector);

  const onMobileDrawerClose = () => {
    dispatch(closeMobileDrawer());
  };

  return {
    isMobileDrawerOpen,
    onMobileDrawerClose,
  };
};

export default useMobileDrawerHook;