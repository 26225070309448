import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { ISuccessPurchaseModal } from './types';

import { PurchaseEnum } from '@/redux/purchases';
import { purchaseSelector } from '@/redux/purchases/selectors';
import { successModalSelector } from '@/redux/success-modal/selectors';

export const useSuccessPurchaseModal = ({ onClose }: ISuccessPurchaseModal) => {
  const { t } = useTranslation();
  const { isModalOpen: isOpen } = useSelector(successModalSelector);
  const { purchaseType } = useSelector(purchaseSelector);

  const description = useMemo(
    () =>
      purchaseType === PurchaseEnum.BUY
        ? t('componentModalBuy.successPurchaseOrder')
        : t('componentModalBuy.successGiftOrder'),
    [purchaseType],
  );

  return {
    isOpen,
    description,
    submitText: t('componentModalBuy.ok'),
    onSubmit: onClose,
    onClose,
    onlyConfirm: true,
  };
};
