import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import { styles } from './styles';
import { usePersonalContentItem } from './usePersonalContentItem';

import type { ContentItem, InboxContentItem } from '@/graphql-schema';

import { PlayButton, PlayContentImage, ResponsiveCardComponent } from '@/components';
import { formatFullDateFromTimestamp } from '@/utils';

interface PersonalContentItem {
  isReview?: boolean;
  contentItem?: InboxContentItem | null;
}

const PersonalContentItem: React.FC<PersonalContentItem> = ({ isReview = false, contentItem }) => {
  const { t, isProcessing, authName, onContentDelete } = usePersonalContentItem(
    contentItem,
    isReview,
  );

  return (
    <ResponsiveCardComponent sx={styles.container}>
      <Box>
        <Box sx={styles.head}>
          <Box sx={styles.imgContainer}>
            <PlayContentImage
              size={120}
              isPreview={isProcessing}
              contentItem={contentItem as ContentItem}
            />
          </Box>
          <Box sx={styles.description}>
            <Typography variant="h2" fontWeight={700} color="blue.100" mb={1}>
              {contentItem?.title}
            </Typography>
            <Typography variant="h3" fontWeight={500} color="grey.dark" sx={styles.itemArtist}>
              {contentItem?.artist}
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.playBtn}>
          {isProcessing ? (
            <Typography variant="h3" ml={1.5}>
              {t('processing')}
            </Typography>
          ) : (
            <PlayButton contentNo={contentItem?.contentNo} />
          )}
        </Box>
        <Box sx={styles.purchaseDescription}>
          <Box sx={styles.descriptionItem}>
            <Typography variant="h3" sx={styles.descriptionTitle}>
              {t('uploaded')}
            </Typography>
            <Typography variant="h3" sx={styles.descriptionText}>
              {formatFullDateFromTimestamp(contentItem?.startDt)}
            </Typography>
          </Box>
          {!isReview && (
            <Box sx={styles.descriptionItem}>
              <Typography variant="h3" sx={styles.descriptionTitle}>
                {t('reason')}
              </Typography>
              <Typography variant="h3" sx={styles.descriptionText}>
                {authName}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {!isProcessing && (
        <Button
          size="large"
          variant="outlined"
          color="error"
          fullWidth
          sx={styles.delete}
          onClick={onContentDelete}
        >
          <Typography variant="h3">{t('delete')}</Typography>
        </Button>
      )}
    </ResponsiveCardComponent>
  );
};

export default PersonalContentItem;
