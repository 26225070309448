import React from 'react';

import { SvgIcon } from '@mui/material';

import styles from '../styles';

import type { SvgIconProps } from '@mui/material';

import { mergeSx } from '@/utils';

const DefaultContentIcon = ({ viewBox = '0 0 60 60', sx = {}, ...rest }: SvgIconProps) => (
  <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
    <rect width="60" height="60" rx="8" fill="#EAEAEA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.01 31.55L30 21H36V25H32V35C32 37.21 30.23 39 28.01 39C25.79 39 24 37.21 24 35C24 32.79 25.79 31 28.01 31C28.74 31 29.42 31.21 30.01 31.55ZM26.01 35C26.01 36.1 26.91 37 28.01 37C29.11 37 30.01 36.1 30.01 35C30.01 33.9 29.11 33 28.01 33C26.91 33 26.01 33.9 26.01 35Z"
      fill="#11223C"
    />
  </SvgIcon>
);

export default DefaultContentIcon;
