import { object, string, array } from 'yup';

import { phoneSchema } from './phoneSchema';

import { phoneRegex } from '@/constants';

export const getGroupSchema = (isCreate?: boolean) =>
  object({
    groupName: string().max(32).required('errors.required'),
    subsIdent: string().matches(phoneRegex).required(),
    groupMembers: array()
      .of(
        object({
          phoneName: string().max(32).nullable(),
          phoneNumber: phoneSchema,
        }),
      )
      .test(
        'is-not-empty',
        isCreate ? 'сreateGroupsLimitExceeded' : 'editGroupsLimitExceeded',
        (value) => value && value.length > 0,
      ),
  }).required();
