import React from 'react';

import { Close } from '@mui/icons-material';
import { ToastContainer } from 'react-toastify';

import { useClientConfig } from '@/context';

import 'react-toastify/dist/ReactToastify.css';

import './styles.css';

const ToastNotifications = () => {
  const {
    config: { notificationVisibilityTime },
  } = useClientConfig();
  const time = notificationVisibilityTime ? parseInt(notificationVisibilityTime) : 4000;
  return (
    <ToastContainer hideProgressBar autoClose={time} position="top-right" closeButton={<Close />} />
  );
};

export default ToastNotifications;
