import React from 'react';

import { Box, Button, Link, Typography } from '@mui/material';

import { styles } from './styles';
import { useUserLoginButton } from './useUserLoginButton';

import { LogoutIcon, MeloringIcon, PersonIcon } from '@/components';
import { theme } from '@/constants';

const UserLoginButton = () => {
  const {
    t,
    keycloak,
    isMobile,
    msisdn,
    isTestMode,
    testMsisdn,
    isRbtAuthorized,
    isUserInitLoaded,
    onLoginClick,
    onTestModeLogout,
    onLogoutClick,
    goToTestPage,
    goToMyMusicPage,
  } = useUserLoginButton();

  return (
    <>
      {isTestMode ? (
        <>
          <Link fontSize={18} onClick={goToTestPage}>
            Test mode ({testMsisdn ?? 'Number not given'})
          </Link>
          <Button onClick={onTestModeLogout}>Logout</Button>
        </>
      ) : (
        <>
          {keycloak?.authenticated ? (
            <Box sx={styles.phoneAuthorized} onClick={onLogoutClick}>
              {isUserInitLoaded && isRbtAuthorized && (
                <MeloringIcon sx={styles.icon} fill={theme.palette.text.primary} />
              )}
              {!isMobile && (
                <Typography variant="h3" sx={styles.headerText}>
                  {msisdn}
                </Typography>
              )}
              <LogoutIcon sx={styles.logout} />
            </Box>
          ) : (
            <Box sx={styles.loginContainer} onClick={onLoginClick}>
              <PersonIcon fill={theme.palette.text.primary} />
              <Typography sx={styles.login} variant="h3" onClick={goToMyMusicPage}>
                {t('login')}
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default UserLoginButton;
