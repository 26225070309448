import React from 'react';

import { useActiveUserProvider } from './useActiveUserProvider';

import { ActiveSubscriberContext } from '@/context';

interface IActiveUserProviderProps {
  children: React.ReactNode;
}

export const ActiveUserProvider: React.FC<IActiveUserProviderProps> = ({ children }) => {
  const value = useActiveUserProvider();
  return (
    <ActiveSubscriberContext.Provider value={value}>{children}</ActiveSubscriberContext.Provider>
  );
};

export default ActiveUserProvider;
