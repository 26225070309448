import React from 'react';

import { useAudioPlayerProvider } from './useAudioPlayerProvider';

import { AudioPlayerContext } from '@/context';

interface IAudioPlayerProviderProps {
  children: React.ReactNode;
}

const AudioPlayerProvider: React.FC<IAudioPlayerProviderProps> = ({ children }) => {
  const props = useAudioPlayerProvider();

  return <AudioPlayerContext.Provider value={props}>{children}</AudioPlayerContext.Provider>;
};

export default AudioPlayerProvider;
