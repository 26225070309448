import { useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { useDispatch } from 'react-redux';

import { ErrorCodes } from '@/constants';
import { useKeycloak } from '@/context';
import { openNotRbtNotificationModal } from '@/redux/not-rbt-notification-modal/slice';

interface NetworkError extends Error {
  statusCode: number;
}

const useGraphQlErrorHandler = (error?: ApolloError) => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (error) {
      const networkError = error?.networkError;

      const networkErrorCode = (networkError as NetworkError)?.statusCode;

      const isUnauthorizedError = networkErrorCode === 401;

      const errorCode = error?.graphQLErrors?.[0]?.extensions?.code;

      const isNotRbtError = errorCode === ErrorCodes.NO_RBT_AUTHORIZED;

      if (isNotRbtError || isUnauthorizedError) {
        if (isUnauthorizedError) {
          const isTokenExpired = keycloak?.isTokenExpired();
          if (isUnauthorizedError && keycloak?.authenticated && isTokenExpired) {
            return;
          }
          keycloak?.logout();
        }

        dispatch(openNotRbtNotificationModal());
        // changeRbtAuthorizedStatus(false);
        // navigate(`${ClientRoutes.Catalog}/${defaultCatalogCategory}`);
      }
    }
  }, [error]);
};

export default useGraphQlErrorHandler;
