export const styles = {
  card: {
    mt: 2,
    borderRadius: 2,
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'grey.100',
    p: 2,
  },
  icon: {
    height: 'fit-content',
  },
  cardContent: {
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  groupName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
} as const;
