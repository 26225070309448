import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from '@/locales/en.json';
import ukTranslation from '@/locales/uk.json';

use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    uk: {
      translation: ukTranslation,
    },
  },
  lng: 'uk',
  fallbackLng: 'uk',
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ['path'],
    lookupFromPathIndex: 0,
  },
});

export default i18n;
