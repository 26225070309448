import { theme } from '@/constants';

export const styles = {
  header: {
    px: {
      xs: 2,
      md: 4,
    },
    height: {
      xs: 116,
      md: 69,
    },
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none',
  },
  toolbar: {
    display: 'flex',
    justifyContent: {
      xs: 'center',
      md: 'space-between',
    },
    width: '100%',
    height: '100%',
    gap: {
      xs: 1,
      md: 0,
    },
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
  },
  headerControls: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  logoBig: {
    width: 122,
  },
  logoSmall: {
    width: 90,
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  searchLocalization: {
    display: 'flex',
    alignItems: 'center',
    width: {
      xs: '100%',
      md: 'fit-content',
    },
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  localization: {
    [theme.breakpoints.down('md')]: {
      backgroundColor: 'grey.200',
      p: 1.25,
      borderRadius: 1,
      mr: 1,
    },
    mx: {
      md: 4,
    },
  },
  searchContent: {
    width: {
      md: 238,
    },
    minWidth: {
      xs: '100%',
      lg: 300,
      xl: 413,
    },
  },
  searchContainer: {
    width: {
      xs: '100%',
      md: 'fit-content',
    },
  },
  logo: {
    cursor: { md: 'pointer', xs: 'default' },
  },
} as const;
