import { useCallback, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useAudioPlayer, useClientConfig, useKeycloak } from '@/context';
import { GET_CONTENT_ITEM } from '@/graphqL-queries/contents';
import { ComplexTypeEnum, ContentItem } from '@/graphql-schema';
import { usePurchaseParams, useScreenSize } from '@/hooks';
import { useGetContentImage } from '@/hooks/useGetContentImage';
import { happyHourSelector } from '@/redux/happy-hour/selectors';
import { setVirtContentNoBuy } from '@/redux/purchases/slice';
import { getHappyHourForContent } from '@/utils';

export const useSlider = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isLoaded } = useKeycloak();
  const {
    config: { virtNoBanner1, virtNoBanner2, virtNoBanner3, virtNoBanner4 },
  } = useClientConfig();
  const { isHappyHour, happyHourData } = useSelector(happyHourSelector);

  const { clearPurchaseParams } = usePurchaseParams();

  const { isPlaying, selectedAudio, stopPlaying, toggleAudioStatus } = useAudioPlayer();

  const { isMobileScreenView } = useScreenSize();

  const [activeIndex, setActiveIndex] = useState(0);

  const { data: firstBanner } = useQuery(GET_CONTENT_ITEM, {
    skip: !virtNoBanner1 || !isLoaded,
    variables: {
      virtContentNo: virtNoBanner1,
    },
  });

  const { data: secondBanner } = useQuery(GET_CONTENT_ITEM, {
    skip: !virtNoBanner2 || !isLoaded,
    variables: {
      virtContentNo: virtNoBanner2,
    },
  });

  const { data: thirdBanner } = useQuery(GET_CONTENT_ITEM, {
    skip: !virtNoBanner3 || !isLoaded,
    variables: {
      virtContentNo: virtNoBanner3,
    },
  });

  const { data: forthBanner } = useQuery(GET_CONTENT_ITEM, {
    skip: !virtNoBanner4 || !isLoaded,
    variables: {
      virtContentNo: virtNoBanner4,
    },
  });

  const data = [
    firstBanner?.contentItem,
    secondBanner?.contentItem,
    thirdBanner?.contentItem,
    forthBanner?.contentItem,
  ];

  useEffect(() => {
    if (activeIndex === 0) {
      const index = hasItems && data.findIndex((el) => el?.contentNo);
      setActiveIndex(index);
    }
  }, [data, activeIndex]);

  const hasItems = data.filter((el) => el?.contentNo).length;

  const elements = data ? (Object.values(data) as ContentItem[]) : [];

  const contentItem = elements?.[activeIndex] ?? null;

  const complexType = contentItem?.complexType;

  const isComplexContent = !!complexType && complexType !== ComplexTypeEnum.Single;

  const isSelectedAudioPlayed = isPlaying && selectedAudio === contentItem?.contentNo;

  const isCurrentPlaying = selectedAudio === contentItem?.contentNo && isPlaying;

  const happyHourContent = getHappyHourForContent({ happyHourData, contentItem });

  const amountOnetime = happyHourContent?.amountOnetime;
  const onetimePeriod = happyHourContent?.onetimePeriod;

  const items =
    elements?.map((item, index) => ({
      value: index,
      label: item?.artist || '',
      active: index === activeIndex,
    })) || [];

  const imgUrl = useGetContentImage(contentItem);

  const onChange = useCallback(
    (value: number) => {
      stopPlaying();
      setActiveIndex(value);
    },
    [stopPlaying],
  );

  const openModalForBuy = () => {
    clearPurchaseParams();
    stopPlaying();
    dispatch(setVirtContentNoBuy(contentItem?.virtContentNo));
  };

  return {
    t,
    items,
    hasItems,
    imgUrl,
    isPlaying,
    isHappyHour,
    onetimePeriod,
    amountOnetime,
    happyHourData,
    contentItem,
    isCurrentPlaying,
    isComplexContent,
    isSelectedAudioPlayed,
    isMobileScreenView,
    onChange,
    openModalForBuy,
    toggleAudioStatus,
  };
};
