import { lazy } from 'react';

import type { IRouterParams } from './types';

import { ClientRoutes } from '@/constants';
import { MainLayout } from '@/layouts';
import CatalogPage from '@/pages/CatalogPage';
import CategoryCatalogPage from '@/pages/CategoryCatalogPage';

const SearchUserPage = lazy(() => import('@/pages/SearchUserPage'));
const PromotionsPage = lazy(() => import('@/pages/PromotionsPage'));
const PersonalCabinetPage = lazy(() => import('@/pages/PersonalCabinetPage'));
const PlaybackRulesPage = lazy(() => import('@/pages/PlaybackRulesPage'));
const MyMusicPage = lazy(() => import('@/pages/MyMusicPage'));
const UnpaidContentPage = lazy(() => import('@/pages/UnpaidContentPage'));
const ContentItemPage = lazy(() => import('@/pages/ContentItemPage'));
const GiftForYouPage = lazy(() => import('@/pages/GiftForYouPage'));
const SentGiftPage = lazy(() => import('@/pages/SentGiftPage'));
const GiftHistoryPage = lazy(() => import('@/pages/GiftHistoryPage'));
const HistoryPage = lazy(() => import('@/pages/HistoryPage'));
const FAQPage = lazy(() => import('@/pages/FAQPage/FAQPage'));
const TermsOfUsePage = lazy(() => import('@/pages/TermsOfUsePage'));
const FavouritesPage = lazy(() => import('@/pages/FavouritesPage'));
const ArtistPage = lazy(() => import('@/pages/ArtistPage'));
const RecordMyMeloring = lazy(() => import('@/pages/RecordMyMeloring'));
const SearchResultPage = lazy(() => import('@/pages/SearchResultPage'));

const routes: IRouterParams[] = [
  {
    layout: MainLayout,
    component: CatalogPage,
    path: ClientRoutes.Catalog,
    protectedRoute: false,
  },
  {
    layout: MainLayout,
    component: CategoryCatalogPage,
    path: ClientRoutes.CatalogByCategoryId,
    protectedRoute: false,
  },
  {
    layout: MainLayout,
    component: PersonalCabinetPage,
    path: ClientRoutes.PersonalCabinet,
    protectedRoute: true,
  },
  {
    layout: MainLayout,
    component: PlaybackRulesPage,
    path: ClientRoutes.PlayRules,
    protectedRoute: true,
  },
  {
    layout: MainLayout,
    component: MyMusicPage,
    path: ClientRoutes.MyMusic,
    protectedRoute: true,
  },
  {
    layout: MainLayout,
    component: SearchUserPage,
    path: ClientRoutes.SearchUser,
    protectedRoute: true,
  },
  {
    layout: MainLayout,
    component: UnpaidContentPage,
    path: ClientRoutes.UnpaidContent,
    protectedRoute: true,
  },
  {
    layout: MainLayout,
    component: GiftForYouPage,
    path: ClientRoutes.GiftForYou,
    protectedRoute: true,
  },
  {
    layout: MainLayout,
    component: ContentItemPage,
    path: ClientRoutes.ContentByContentNo,
    protectedRoute: false,
  },
  {
    layout: MainLayout,
    component: PromotionsPage,
    path: ClientRoutes.Promotions,
    protectedRoute: false,
  },
  {
    layout: MainLayout,
    component: SentGiftPage,
    path: ClientRoutes.SentGift,
    protectedRoute: true,
  },
  {
    layout: MainLayout,
    component: GiftHistoryPage,
    path: ClientRoutes.GiftHistory,
    protectedRoute: true,
  },
  {
    layout: MainLayout,
    component: HistoryPage,
    path: ClientRoutes.History,
    protectedRoute: true,
  },
  {
    layout: MainLayout,
    component: FAQPage,
    path: ClientRoutes.FAQ,
    protectedRoute: false,
  },
  {
    layout: MainLayout,
    component: TermsOfUsePage,
    path: ClientRoutes.TermsAndConditions,
    protectedRoute: false,
  },
  {
    layout: MainLayout,
    component: FavouritesPage,
    path: ClientRoutes.Favourites,
    protectedRoute: true,
  },
  {
    layout: MainLayout,
    component: ArtistPage,
    path: ClientRoutes.ContentByArtist,
    protectedRoute: false,
  },
  {
    layout: MainLayout,
    component: RecordMyMeloring,
    path: ClientRoutes.RecordMyMeloring,
    protectedRoute: false,
  },
  {
    layout: MainLayout,
    component: SearchResultPage,
    path: ClientRoutes.ContentBySearch,
    protectedRoute: false,
  },
];

export default routes;
