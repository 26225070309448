import { useMemo } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorCodes } from '@/constants';
import { useAudioPlayer, useKeycloak } from '@/context';
import { GET_GIFT_BOX_CONTENTS } from '@/graphqL-queries/contents';
import { POST_GIFT_CONTENT_REJECT } from '@/graphqL-queries/purchase';
import { getComplexContentLabel } from '@/helpers';
import { useGraphQlErrorHandler } from '@/hooks';
import { declineGiftModalSelector } from '@/redux/decline-gift-modal/selectors';
import { closeDeclineGiftModal } from '@/redux/decline-gift-modal/slice';

export const useDeclineGiftModal = () => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { msisdn } = useKeycloak();
  const { isModalOpen: isOpen, contentItem } = useSelector(declineGiftModalSelector);
  const { stopPlaying } = useAudioPlayer();
  const [decline, { loading, error }] = useMutation(POST_GIFT_CONTENT_REJECT);

  const complexType = contentItem?.complexType;

  const contentType = useMemo(
    () => (complexType ? getComplexContentLabel(complexType, true).toLowerCase() : null),
    [language, complexType],
  );

  const submitText = t('componentPresentCard.declineModal.accept');
  const cancelText = t('componentPresentCard.declineModal.cancel');
  const description = t('componentPresentCard.declineModal.description', {
    ...{ ...contentItem, title: contentItem?.title?.trim() ?? '' },
    contentType,
  });

  useGraphQlErrorHandler(error);

  const onClose = () => dispatch(closeDeclineGiftModal());

  const onSubmit = () => {
    decline({
      variables: {
        input: {
          contentNo: contentItem?.contentNo,
          subsIdent: msisdn,
        },
      },
      onCompleted: () => {
        stopPlaying();
        onClose();
      },
      refetchQueries: [
        {
          query: GET_GIFT_BOX_CONTENTS,
          variables: {
            msisdn,
          },
        },
      ],
    }).catch((err) => {
      const errorCode = err?.graphQLErrors?.[0]?.extensions?.code;
      const isNotRbtError = errorCode === ErrorCodes.NO_RBT_AUTHORIZED;
      if (isNotRbtError) {
        onClose();
      }
    });
  };

  return {
    isOpen,
    loading,
    submitText,
    cancelText,
    description,
    onClose,
    onSubmit,
    onCancel: onClose,
  };
};
