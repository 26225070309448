import { createSlice } from '@reduxjs/toolkit';

import type { IUnpaidContentState } from './types';

const initialState: IUnpaidContentState = {
  isDeleteModalOpen: false,
  selectedUnPaidContentForModal: null,
};

export const umPaidContentSlice = createSlice({
  name: 'unPaidContent',
  initialState,
  reducers: {
    openDeleteUnpaidContent: (state, { payload }) => {
      state.isDeleteModalOpen = true;
      state.selectedUnPaidContentForModal = payload;
    },
    closeDeleteUnpaidContent: (state) => {
      state.isDeleteModalOpen = false;
      state.selectedUnPaidContentForModal = null;
    },
  },
});

export const { openDeleteUnpaidContent, closeDeleteUnpaidContent } = umPaidContentSlice.actions;

export default umPaidContentSlice.reducer;
