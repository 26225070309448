import { gql } from '@apollo/client';

export const GET_PARAMETERS = gql(/* GraphQL */ `
  query GetParameters {
    parameters {
      name
      value
    }
  }
`);

export const GET_WEB_PARAMETERS = gql(/* GraphQL */ `
  query GetWebParameters {
    webParameters {
      maxFavourites
      maxGiftBoxSize
      maxInBoxSize
      maxPhoneGroups
      maxPhoneNumbers
      maxPlayConditions
      mobileAppAndroidUrl
      mobileAppIosUrl
      textForSocialMedia
      textForSocialMediaEn
      textInFooter
      textInFooterEn
      virtNoBanner1
      virtNoBanner2
      virtNoBanner3
      virtNoBanner4
    }
  }
`);
