import React, { memo } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { styles } from './styles';

import { ButtonCross } from '@/components';

interface ICreateCustomModalProps {
  dirty: boolean;
  isModalOpen: boolean;
  onCloseModal: () => void;
  children: React.ReactNode;
  handleSubmit: () => void;
  submitBtnText: string;
  titleBtnText: string;
}

const CustomFormModal: React.FC<ICreateCustomModalProps> = memo(
  ({ dirty, isModalOpen, onCloseModal, children, handleSubmit, submitBtnText, titleBtnText }) => {
    const { t } = useTranslation();
    return (
      <Dialog open={isModalOpen} onClose={onCloseModal} sx={styles.dialog}>
        <form onSubmit={handleSubmit}>
          <DialogTitle sx={{ p: 2 }}>
            <Box sx={styles.closeIcon}>
              <ButtonCross onClick={onCloseModal} />
            </Box>
            <Typography variant="h1" fontWeight="bold" color="grey.main">
              {titleBtnText}
            </Typography>
          </DialogTitle>
          <DialogContent sx={styles.dialogContent}>{children}</DialogContent>
          <DialogActions sx={styles.actions}>
            <Button variant="outlined" sx={styles.cancel} onClick={onCloseModal}>
              <Typography variant="h4"> {t('cancel')}</Typography>
            </Button>
            <Button sx={styles.submitBtn} type="submit" variant="contained" disabled={!dirty}>
              <Typography variant="h4">{submitBtnText}</Typography>
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  },
);

CustomFormModal.displayName = 'CreateCustomModal';

export default CustomFormModal;
