import { baseTheme } from '@/constants';

export const styles = {
  listItem: {
    width: 'auto',
    cursor: 'pointer',
    borderRadius: 16,
    px: 1.5,
    py: 1,
    mr: 1,
    mb: 1,
    color: 'text.primary',
  },
  selected: {
    backgroundColor: 'primary.main',
  },
  noSelected: {
    cursor: 'pointer',
    backgroundColor: 'grey.200',
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.400',
      },
    },
  },
} as const;
