import type { InboxContentItem, Maybe } from '@/graphql-schema';

import { ContentChargeStateEnum, ContentStateEnum, ContentTypeEnum } from '@/graphql-schema';

export const filterActiveContentItems = (contentItem: Maybe<InboxContentItem>) => {
  const isActivated =
    contentItem?.contentType === ContentTypeEnum.Public ||
    contentItem?.contentState === ContentStateEnum.Activated;

  return contentItem?.chargeState === ContentChargeStateEnum.Activated && isActivated;
};

export const filterUnActiveContentItems = (contentItem: Maybe<InboxContentItem>) => {
  const isActivated =
    contentItem?.contentType === ContentTypeEnum.Public ||
    contentItem?.contentState === ContentStateEnum.Activated;

  return contentItem?.chargeState !== ContentChargeStateEnum.Activated && isActivated;
};
