import { useCallback, useEffect, useRef, useState } from 'react';

import MicRecorder from 'mic-recorder-to-mp3';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { LocalAudioTypes } from '@/constants';
import { useAudioPlayer, useClientConfig } from '@/context';
import { useFileUploadLimit } from '@/hooks/index';
import { changeAudioSelectedStatus } from '@/redux/record-my-meloring/slice';

const useAudioRecorder = () => {
  const dispatch = useDispatch();

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    config: { audioRecorder },
  } = useClientConfig();
  const { playingType, changePlayingType } = useAudioPlayer();

  const [audioBlob, setAudioBlob] = useState<null | Blob>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [recordedFile, setRecordedFile] = useState<null | File>(null);
  const { isSizeLarger, fileSizeLimit } = useFileUploadLimit(audioBlob);
  const stopRecordingTimeOut = useRef<null | ReturnType<typeof setTimeout>>(null);
  const audioPlayer = useRef<null | HTMLAudioElement>(null);

  const recorder = useRef(
    new MicRecorder({
      bitRate: 128,
    }),
  );

  const isAudioRecorded = !!audioBlob;

  const removeListeners = () => {
    if (audioPlayer?.current) {
      audioPlayer.current.pause();
      audioPlayer.current.removeEventListener('ended', stopPlaying);
    }
  };

  const playingFinished = useCallback(() => {
    setIsPlaying(false);
    removeListeners();
  }, []);

  const stopPlaying = () => {
    setIsPlaying(false);
    removeListeners();
  };

  const togglePlayAudio = useCallback(() => {
    if (!isPlaying) {
      audioPlayer.current?.play();
      changePlayingType(LocalAudioTypes.Recorded);
      audioPlayer.current?.addEventListener('ended', playingFinished);
    } else {
      stopPlaying();
    }
    setIsPlaying(!isPlaying);
  }, [isPlaying, playingFinished]);

  useEffect(() => {
    if (recordedFile) {
      audioPlayer.current = new Audio(URL.createObjectURL(recordedFile));
    } else {
      audioPlayer.current = null;
    }
  }, [recordedFile]);

  useEffect(() => {
    return () => {
      stopPlaying();
    };
  }, []);

  useEffect(() => {
    if (isPlaying && playingType !== LocalAudioTypes.Recorded) {
      stopPlaying();
    }
  }, [playingType]);

  useEffect(() => {
    if (isPlaying) {
      togglePlayAudio();
    }
  }, [language]);

  const stopRecording = () => {
    recorder.current
      .stop()
      .getMp3()
      .then(([buffer, blob]: [BlobPart[], Blob]) => {
        const file = new File(buffer, 'me-at-thevoice.mp3', {
          type: blob.type,
          lastModified: Date.now(),
        });
        setAudioBlob(blob);
        setRecordedFile(file);
      });
    setIsRecording(false);
    if (stopRecordingTimeOut.current) {
      clearTimeout(stopRecordingTimeOut.current);
    }
  };

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        setIsRecording(true);
        dispatch(changeAudioSelectedStatus(true));
        recorder.current.start();
        stopRecordingTimeOut.current = setTimeout(() => {
          stopRecording();
        }, Number(audioRecorder?.audioMaxDuration) * 1000 + 100);
      })
      .catch(() => {
        toast.error(t('unableToRecordAudio'));
      });
  };

  const deleteAudio = useCallback(() => {
    setAudioBlob(null);
    setRecordedFile(null);
    stopPlaying();
  }, [togglePlayAudio]);

  return {
    isSizeLarger,
    fileSizeLimit,
    recordedFile,
    isPlaying,
    isRecording,
    isAudioRecorded,
    deleteAudio,
    togglePlayAudio,
    stopRecording,
    startRecording,
    stopPlaying,
  };
};

export default useAudioRecorder;
