import { baseTheme } from '@/constants';

export const styles = {
  downloadButton: {
    display: 'flex',
    borderRadius: 1000,
    textTransform: 'none',
    py: 1.5,
    px: 6.25,
    width: 300,
    lineHeight: '100%',
    gap: 0.25,
    '&:hover': {
      backgroundColor: 'primary.main',
      [baseTheme.breakpoints.down('md')]: {
        boxShadow: 'none',
      },
    },
  },
  downloadButtonContainer: {
    mt: 5,
    ml: 'calc(50% - 150px)',
  },
};
