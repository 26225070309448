import React, { memo } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';
import { usePlaybackItemPreview } from './usePlaybackItemPreview';

import type { PlayCondition } from '@/graphql-schema';

import { PlayConditionTypeEnum, TimeRuleTypeEnum } from '@/graphql-schema';

const PlaybackItemPreview: React.FC<PlayCondition> = memo((props) => {
  const { pcType, tmParams, tmType } = props;
  const {
    t,
    isRuleOutdated,
    isDefaultRule,
    pcValue,
    pcTypePreview,
    tmTypePreview,
    TmTypeComponent,
    PcTypeComponent,
  } = usePlaybackItemPreview(props);

  if (isDefaultRule) {
    return (
      <Box sx={styles.row}>
        <Box sx={styles.rowTitle}>
          <Typography variant="h3">{t('rule')}</Typography>
          <Box sx={styles.value}>
            <Typography sx={{ fontSize: 16 }}>{t('byDefault')}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box sx={styles.row}>
        <Box sx={styles.rowTitle}>
          <Typography variant="h3">{t('rule')}</Typography>
          <Box sx={styles.value}>
            <Typography fontSize={16}>{pcTypePreview}</Typography>
          </Box>
        </Box>
        {pcType !== PlayConditionTypeEnum.Default && (
          <Box sx={styles.rowItem}>
            <PcTypeComponent value={pcValue} rule={pcType} isPreview />
          </Box>
        )}
      </Box>

      <Box sx={styles.row}>
        <Box sx={styles.rowTitle}>
          <Typography variant="h3">{t('timeCondition')}</Typography>
          <Box
            sx={{ ...styles.value, backgroundColor: isRuleOutdated ? 'grey.600' : 'primary.main' }}
          >
            <Typography fontSize={isRuleOutdated ? 18 : 16}>
              {isRuleOutdated ? t('outdated') : tmTypePreview}
            </Typography>
          </Box>
        </Box>
        {tmType !== TimeRuleTypeEnum.Always && (
          <Box sx={styles.rowItem}>
            <TmTypeComponent value={tmParams} rule={tmType} isPreview />
          </Box>
        )}
      </Box>
    </>
  );
});

PlaybackItemPreview.displayName = 'PlaybackItemPreview';

export default PlaybackItemPreview;
