import { useCallback, useLayoutEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setOffsetLoading } from '@/redux/offset';
import { offsetSelector } from '@/redux/offset/selectors';

const usePagination = (pageSize = 12) => {
  const dispatch = useDispatch();
  const [activeOffset, setActiveOffset] = useState(0);
  const { isOffsetLoading } = useSelector(offsetSelector);
  const { pathname } = useLocation();

  const clearOffset = useCallback(() => {
    setActiveOffset(0);
  }, [isOffsetLoading]);

  useLayoutEffect(() => {
    dispatch(setOffsetLoading(false));
    return () => {
      dispatch(setOffsetLoading(false));
      clearOffset();
    };
  }, [pathname, clearOffset]);

  const changePage = useCallback(() => {
    setActiveOffset((prevState) => pageSize + prevState);
  }, [pageSize]);

  return {
    isOffsetLoading,
    activeOffset,
    changePage,
    clearOffset,
  };
};
export default usePagination;
