import { useTranslation } from 'react-i18next';

import { ClientRoutes } from '@/constants';
import { ComplexTypeEnum, ContentTypeEnum, InboxContentItem } from '@/graphql-schema';
import { useLocalization } from '@/hooks';

export const usePurchasedContentItem = (contentItem: InboxContentItem) => {
  const { navigate } = useLocalization();
  const { t } = useTranslation();

  const isPersonalContent = contentItem?.contentType === ContentTypeEnum.Personal;

  const complexType = contentItem?.complexType;

  const isComplexContent = !!complexType && complexType !== ComplexTypeEnum.Single;

  const onArtistClick = () => {
    navigate(`${ClientRoutes.Artist}/${contentItem?.artist}`);
  };

  const onContentItemClick = () => {
    if (isPersonalContent) return;
    navigate(`${ClientRoutes.Content}/${contentItem?.virtContentNo}`);
  };

  return {
    t,
    complexType,
    isPersonalContent,
    isComplexContent,
    onArtistClick,
    onContentItemClick,
  };
};
