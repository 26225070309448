import { cardActionsClasses } from '@mui/material';

export const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: {
      sm: '49%',
      xs: '100%',
    },
    borderRadius: 2,
    height: 'auto',
    mt: {
      sm: 0,
      xs: 2,
    },
  },
  cardContent: {
    pb: 3,
  },
  formControl: {
    mt: 3,
  },
  cardActions: {
    [`&.${cardActionsClasses.root}`]: {
      pt: 0,
      px: 2,
      pb: 2,
    },
  },
  btn: {
    py: 1.5,
  },
  folderOpen: {
    fontSize: 16,
  },
} as const;
