import { useMemo } from 'react';

import { ClientRoutes, contentItemPageRegExp } from '@/constants';
import { useLocalization } from '@/hooks/index';

const useGetPageAccessStatus = () => {
  const { currentPath } = useLocalization();
  const isTermsOfUse = currentPath === ClientRoutes.TermsAndConditions;
  const isFAQ = currentPath === ClientRoutes.FAQ;

  const isContentItemPage = useMemo(() => contentItemPageRegExp.test(currentPath), [currentPath]);

  const isHelpPage = isTermsOfUse || isFAQ;

  const isPublicPage =
    currentPath.indexOf(ClientRoutes.Catalog) !== -1 ||
    currentPath.indexOf(ClientRoutes.Content) !== -1 ||
    currentPath.indexOf(ClientRoutes.Artist) !== -1 ||
    currentPath.indexOf(ClientRoutes.SearchResult) !== -1 ||
    currentPath === ClientRoutes.Promotions;

  return { isContentItemPage, isHelpPage, isPublicPage };
};

export default useGetPageAccessStatus;
