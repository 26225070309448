import React from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { styles } from './styles';

import type { IConfirmModal } from './types';

import { ButtonCross } from '@/components';
import { mergeSx } from '@/utils';

const CustomConfirmModal = ({
  title,
  isOpen,
  loading,
  description,
  submitText,
  cancelText,
  onSubmit,
  onCancel,
  onClose,
  onlyConfirm,
  sx = {},
  descriptionSx = {},
}: IConfirmModal) => (
  <Dialog open={isOpen} onClose={onClose} sx={{ ...styles.dialog, ...sx }}>
    <DialogTitle sx={{ pb: 1 }}>
      <Box sx={styles.closeIcon}>
        <ButtonCross onClick={onClose} />
      </Box>
      {!!title && (
        <Typography variant="h1" fontWeight="bold" component="div" textAlign="center" mt={1}>
          {title}
        </Typography>
      )}
    </DialogTitle>
    <DialogContent sx={styles.content}>
      <Typography variant="h3" sx={mergeSx(styles.descriptionSx, descriptionSx)}>
        {description}
      </Typography>
    </DialogContent>
    <DialogActions>
      {!onlyConfirm && (
        <Button variant="outlined" sx={styles.cancel} onClick={onCancel} disabled={loading}>
          <Typography variant="h4">{cancelText}</Typography>
        </Button>
      )}
      <Button
        type="submit"
        variant="contained"
        sx={styles.submitBtn}
        onClick={onSubmit}
        disabled={loading}
      >
        <Typography variant="h4" sx={styles.submitText}>
          {submitText}
        </Typography>
      </Button>
    </DialogActions>
  </Dialog>
);

export default CustomConfirmModal;
