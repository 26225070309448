import type { HappyHours } from '@/graphql-schema';

import i18n from '@/i18n';
import { formatFullDateFromTimestamp, formatFullTimeFromTimestamp } from '@/utils';

export const getData = (item: HappyHours | undefined, isOneDay: boolean) => {
  if (!item) return null;

  const startTime = formatFullTimeFromTimestamp(item.startDt);

  const endTime = formatFullTimeFromTimestamp(item.endDt);

  const startDate = formatFullDateFromTimestamp(item.startDt);

  const endDate = formatFullDateFromTimestamp(item.endDt);

  return isOneDay
    ? `${startTime} ${i18n.t('to')} ${endTime} ${startDate}`
    : `${startTime} ${startDate} ${i18n.t('to')} ${endTime} ${endDate}`;
};
