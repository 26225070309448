import { useState } from 'react';

import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';

import type { IButtonsGroupProps } from './types';

import { ClientRoutes } from '@/constants';
import { useActiveSubscriber, useAudioPlayer, useKeycloak } from '@/context';
import { CREATE_FAVOURITE_CONTENT, DELETE_FAVOURITE_CONTENT } from '@/graphqL-queries/contents';
import { GET_CONTENTS_FAVOURITES_FOR_SUBSCRIBER } from '@/graphqL-queries/subscriber';
import { ContentTypeEnum } from '@/graphql-schema';
import { useGraphQlErrorHandler, useLocalization } from '@/hooks';
import { changeFavouriteForCatalog } from '@/redux/catalog';
import { openDeleteMyMusicModal } from '@/redux/my-music/slice';
import { setVirtContentNoGift } from '@/redux/purchases/slice';
import { responseErrorHandling } from '@/utils';

export const useContentButtonsGroup = ({
  contentItem,
  idx,
  isFavouriteContent,
  isPromotion,
}: IButtonsGroupProps) => {
  const dispatch = useDispatch();
  const { currentPath } = useLocalization();

  const [toggleButton, setToggleButton] = useState(true);

  const [createFavourite, { error: createFavouriteError }] = useMutation(CREATE_FAVOURITE_CONTENT);
  const [deleteFavourite, { error: deleteFavouriteError }] = useMutation(DELETE_FAVOURITE_CONTENT);

  const { isRbtAuthorized } = useActiveSubscriber();

  const isFavouritesPage = currentPath === ClientRoutes.Favourites;

  const isMyMusicPage = currentPath === ClientRoutes.MyMusic;

  const isPersonalContent = contentItem.contentType === ContentTypeEnum.Personal;

  const isFavouriteButtonVisible = isRbtAuthorized && !isPersonalContent && !isMyMusicPage;

  const isGiftButtonVisible = isRbtAuthorized && !isPromotion && !isPersonalContent;

  const { msisdn } = useKeycloak();

  const { stopPlaying } = useAudioPlayer();

  useGraphQlErrorHandler(createFavouriteError);
  useGraphQlErrorHandler(deleteFavouriteError);

  const openModalForGift = () => {
    stopPlaying();
    dispatch(setVirtContentNoGift(contentItem?.virtContentNo));
  };

  const onToggle = () => {
    setToggleButton(!toggleButton);
  };

  const refetchQueries = isFavouritesPage
    ? [
        {
          query: GET_CONTENTS_FAVOURITES_FOR_SUBSCRIBER,
          variables: {
            msisdn,
          },
        },
      ]
    : [];

  const onFavouriteClick = () => {
    const params = {
      variables: {
        input: {
          subsIdent: msisdn,
          virtContentNo: contentItem?.virtContentNo,
        },
      },

      refetchQueries,
    };
    if (!isFavouriteContent) {
      createFavourite({
        ...params,
        ...{
          onCompleted: (data) => {
            const isError = data?.favouriteContentCreate?.error;
            if (!isError) {
              onFavouriteSuccessCallback();
            }
            responseErrorHandling(data?.favouriteContentCreate);
          },
        },
      });
      return;
    }
    deleteFavourite(params).then(onFavouriteSuccessCallback);
  };

  const onFavouriteSuccessCallback = () => {
    if (isFavouritesPage) {
      stopPlaying();
      return;
    }
    dispatch(
      changeFavouriteForCatalog({
        favourite: !isFavouriteContent,
        idx,
      }),
    );
  };

  const onContentRemove = () => {
    dispatch(openDeleteMyMusicModal(contentItem));
  };

  return {
    toggleButton,
    isRbtAuthorized,
    isFavouriteContent,
    isPersonalContent,
    isFavouriteButtonVisible,
    isGiftButtonVisible,
    isMyMusicPage,
    onToggle,
    onFavouriteClick,
    openModalForGift,
    onContentRemove,
  };
};
