import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { GiftBoxContentItem, GiftChargeStateEnum } from '@/graphql-schema';
import { formatFullDateFromTimestamp, formatFullTimeFromTimestamp } from '@/utils';

export const useSentGiftCard = ({ createdDt, endDt, chargeState }: GiftBoxContentItem) => {
  const { t } = useTranslation();

  const formatCreatedDt = useMemo(() => formatFullDateFromTimestamp(createdDt), [createdDt]);
  const formatCreatedTime = useMemo(() => formatFullTimeFromTimestamp(createdDt), [createdDt]);
  const formatEndDt = useMemo(() => formatFullDateFromTimestamp(endDt), [endDt]);
  const formatEndTime = useMemo(() => formatFullTimeFromTimestamp(endDt), [endDt]);

  const until =
    chargeState === GiftChargeStateEnum.Activated
      ? t('componentSentPresentCard.validTill')
      : t('componentSentPresentCard.waitingTill');

  return {
    t,
    until,
    formatEndDt,
    formatEndTime,
    formatCreatedDt,
    formatCreatedTime,
  };
};
