import { createSlice } from '@reduxjs/toolkit';

import type { ISuccessModalState } from './types';

const initialState: ISuccessModalState = {
  isModalOpen: false,
};

export const successModalSlice = createSlice({
  name: 'successModal',
  initialState,
  reducers: {
    openSuccessModal: (state) => {
      state.isModalOpen = true;
    },
    closeSuccessModal: (state) => {
      state.isModalOpen = false;
    },
  },
});

export const { openSuccessModal, closeSuccessModal } = successModalSlice.actions;

export default successModalSlice.reducer;
