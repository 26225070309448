import React from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';
import { usePurchasedContentItem } from './usePurchasedContentItem';

import {
  ComplexCardLabel,
  ContentButtonsGroup,
  PlayContentImage,
  ResponsiveCardComponent,
} from '@/components';
import { ContentItem, InboxContentItem } from '@/graphql-schema';
import { formatFullDateFromTimestamp } from '@/utils';

const PurchasedContentItem: React.FC<InboxContentItem> = (contentItem) => {
  const { t, complexType, isPersonalContent, isComplexContent, onArtistClick, onContentItemClick } =
    usePurchasedContentItem(contentItem);

  return (
    <ResponsiveCardComponent>
      <Box sx={styles.head}>
        <Box sx={styles.imgContainer}>
          <PlayContentImage
            contentItem={contentItem as ContentItem}
            size={120}
            isPreview={isComplexContent}
          />
        </Box>
        <Box sx={styles.description}>
          {isComplexContent && <ComplexCardLabel complexType={complexType} />}
          <Typography
            variant="h2"
            sx={{
              cursor: isPersonalContent ? 'auto' : 'pointer',
              ...styles.itemTitle,
              ...(isComplexContent ? styles.isOneLineRow : {}),
            }}
            onClick={onContentItemClick}
          >
            {contentItem.title}
          </Typography>
          <Typography
            variant="h3"
            onClick={onArtistClick}
            sx={{ ...styles.itemArtist, ...(isComplexContent ? styles.isOneLineRow : {}) }}
          >
            {contentItem.artist}
          </Typography>
        </Box>
      </Box>
      <ContentButtonsGroup
        {...{
          isComplexContent,
          contentItem,
        }}
      />
      <Box sx={styles.purchaseDescription}>
        <Box sx={styles.descriptionItem}>
          <Typography variant="h3">{t('purchaseDate')}</Typography>
          <Typography variant="h3">{formatFullDateFromTimestamp(contentItem.startDt)}</Typography>
        </Box>
        <Box sx={styles.descriptionItem}>
          <Typography variant="h3">{t('validTo')}</Typography>
          <Typography variant="h3">{formatFullDateFromTimestamp(contentItem.endDt)}</Typography>
        </Box>
        <Box sx={styles.descriptionItem}>
          <Typography variant="h3">{t('prolongationCost')}</Typography>
          <Typography variant="h3">{t('pricePeriodic', { item: contentItem })}</Typography>
        </Box>
      </Box>
    </ResponsiveCardComponent>
  );
};

export default PurchasedContentItem;
