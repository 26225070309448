import React from 'react';

import { Typography, Box } from '@mui/material';

import { styles } from './styles';
import { useCategoryItem } from './useCategoryItem';

import type { ContentCategory } from '@/graphql-schema';

const CategoryItem: React.FC<ContentCategory> = ({ catName, contentCatId }: ContentCategory) => {
  const { transform, categoryId, onCategoryClick } = useCategoryItem({ contentCatId });
  return (
    <Box
      key={contentCatId}
      onClick={onCategoryClick}
      sx={{
        ...styles.listItem,
        ...(`${contentCatId}` === categoryId ? styles.selected : styles.noSelected),
      }}
    >
      <Typography variant="h5">{transform(catName)}</Typography>
    </Box>
  );
};

export default CategoryItem;
