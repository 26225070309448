import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import styles from '../styles';

import { mergeSx } from '@/utils';

function PauseCircleIcon({
  viewBox = '0 0 66 66',
  width = 66,
  height = 66,
  fill = 'white',
  sx = {},
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox={viewBox}
      sx={mergeSx(styles.root, sx)}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 33C5.5 17.82 17.82 5.5 33 5.5C48.18 5.5 60.5 17.82 60.5 33C60.5 48.18 48.18 60.5 33 60.5C17.82 60.5 5.5 48.18 5.5 33ZM30.25 44H24.75V22H30.25V44ZM33 55C20.8725 55 11 45.1275 11 33C11 20.8725 20.8725 11 33 11C45.1275 11 55 20.8725 55 33C55 45.1275 45.1275 55 33 55ZM41.25 44H35.75V22H41.25V44Z"
        fill={fill}
      />
    </SvgIcon>
  );
}

export default PauseCircleIcon;
