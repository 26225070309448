import React from 'react';

import { useAcceptGiftModal } from './useAcceptGiftModal';

import { CustomConfirmModal } from '@/components';
import { ErrorPurchaseModal } from '@/components/modals/PurchaseModal/modals';

const AcceptGiftModal = () => {
  const { onCloseErrorModal, errorCode, ...modalProps } = useAcceptGiftModal();
  return (
    <>
      <CustomConfirmModal {...modalProps} />
      <ErrorPurchaseModal onClose={onCloseErrorModal} errorCode={errorCode ?? ''} />
    </>
  );
};

export default AcceptGiftModal;
