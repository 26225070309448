import { typographyClasses } from '@mui/material';

import { theme } from '@/constants';

const styles = {
  main: {
    width: 'calc(100% - 32px)',
    padding: 2,
    borderRadius: 4,
    background: theme.palette.grey['100'],
    display: 'flex',
    gap: 2,
  },
  img: {
    width: 64,
    height: 64,
    borderRadius: 8,
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    maxWidth: 'calc(100% - 80px)',
    [`.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      wordWrap: 'break-word',
    },
  },
};

export { styles };
