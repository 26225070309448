import React, { memo } from 'react';

import type { ITypeRulesComponentProps } from '@/components';

import { PlaybackCalendar, PlaybackRangePreview } from '@/components';
import { TimeRuleTypeEnum } from '@/graphql-schema';

const OneTimePlaybackRule: React.FC<ITypeRulesComponentProps> = memo((props) => {
  const { value, isPreview } = props;
  return isPreview ? (
    <PlaybackRangePreview calendarType={TimeRuleTypeEnum.OneTime} date={value} />
  ) : (
    <PlaybackCalendar calendarType={TimeRuleTypeEnum.OneTime} {...props} />
  );
});

OneTimePlaybackRule.displayName = 'OneTimePlaybackRule';

export default OneTimePlaybackRule;
