import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { notSavedChangesSelectors } from '@/redux/not-saved-changes';
import {
  clearNotOpenStatus,
  clearRecordContentDirty,
  clearUploadContentDirty,
  closeNotSavedModal,
} from '@/redux/not-saved-changes/slice';

export const useNotSavedMyMeloringModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isModalOpen: isOpen,
    isUploadContentDirty,
    isRecordContentDirty,
  } = useSelector(notSavedChangesSelectors.notSavedChangesSelector);

  const description = t('notSavedMyMeloring');
  const submitText = t('yesDontSave');
  const cancelText = t('cancel');

  const onClose = () => {
    dispatch(closeNotSavedModal());
  };

  const onSubmit = () => {
    if (isUploadContentDirty) dispatch(clearUploadContentDirty());
    if (isRecordContentDirty) dispatch(clearRecordContentDirty());
    dispatch(clearNotOpenStatus());
  };

  return {
    isOpen,
    description,
    submitText,
    cancelText,
    onSubmit,
    onCancel: onClose,
    onClose,
  };
};
