import { useEffect, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import type { IPersonalContentListProps } from './types';

import { useKeycloak } from '@/context';
import { GET_SUBSCRIBER_CONTENT } from '@/graphqL-queries/contents';
import { ContentStateEnum, ContentTypeEnum } from '@/graphql-schema';
import { useGraphQlErrorHandler } from '@/hooks';

export const usePersonalContentList = ({ isReview }: IPersonalContentListProps) => {
  const { t } = useTranslation();
  const { msisdn, isLoaded } = useKeycloak();
  const { pathname } = useLocation();

  const { data, loading, refetch, error } = useQuery(GET_SUBSCRIBER_CONTENT, {
    skip: !msisdn || !isLoaded,
    variables: {
      msisdn,
    },
  });

  useGraphQlErrorHandler(error);

  const personalContent = useMemo(
    () =>
      data?.subscriber?.inboxContentItems?.filter((content) => {
        const condition = isReview
          ? content?.contentState === ContentStateEnum.Submitting ||
            content?.contentState === ContentStateEnum.InProgress ||
            content?.contentState === ContentStateEnum.Submitted
          : content?.contentState === ContentStateEnum.Rejected;

        return content?.contentType === ContentTypeEnum.Personal && condition;
      }),
    [data, isReview],
  );

  useEffect(() => {
    refetch();
  }, [pathname]);

  return { t, loading, personalContent };
};
