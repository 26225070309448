import { outlinedInputClasses } from '@mui/material';

export const styles = {
  img: {
    width: 80,
    height: 80,
    borderRadius: 8,
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'primary.main',
    p: 2,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    mt: 2,
    p: 1,
    borderRadius: 2,
  },
  value: {
    width: 270,
    height: 35,
    background: '#FFC40C',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  settings: {
    height: 'fit-content',
  },
  select: {
    backgroundColor: 'grey.100',
    width: 210,
    borderRadius: 2,
    height: 35,
  },
  formControl: {
    [` .${outlinedInputClasses.root}`]: {
      border: 'none',
    },
  },
  saveBtn: {
    height: 40,
    flex: 1,
  },
  deleteBtn: {
    height: 40,
  },
  сontent: {
    display: 'flex',
    p: 0,
    mt: 3,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cancelBtn: {
    px: 4,
    py: 0,
    height: 40,
    mr: 1.5,
  },
  btnText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};
