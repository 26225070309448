import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { openDisableServiceModal } from '@/redux/disable-service/slice';
import { PermissionServiceSelector } from '@/redux/permission-service/selectors';
import { openPermissionServiceModal } from '@/redux/permission-service/slice';

export const useToggleDisablePermission = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isChecked } = useSelector(PermissionServiceSelector);

  const onOpenDisabledModal = () => dispatch(openDisableServiceModal());
  const onOpenPermissionModalClick = () => dispatch(openPermissionServiceModal());

  return {
    t,
    isChecked,
    onOpenDisabledModal,
    onOpenPermissionModalClick,
  };
};
