import React from 'react';

import MicIcon from '@mui/icons-material/Mic';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  Typography,
} from '@mui/material';

import { AudioRecordedPreview, AudioRecordInProcess } from './components';
import { styles } from './styles';
import { useCreateRecordCard } from './useCreateRecordCard';

const CreateRecordCard = () => {
  const {
    t,
    title,
    loading,
    isPlaying,
    buttonTitle,
    isRecording,
    isBtnDisabled,
    isSizeLarger,
    fileSizeLimit,
    isAudioRecorded,
    isEmptyTextError,
    deleteAudio,
    stopRecording,
    onTitleChange,
    togglePlayAudio,
    toggleRecordingStatus,
  } = useCreateRecordCard();

  return (
    <Card sx={styles.card}>
      <CardContent sx={styles.cardContent}>
        <Typography variant="h1" fontWeight={700}>
          {t('record')}
        </Typography>
        <FormControl fullWidth sx={styles.formControl}>
          <FormLabel sx={{ mb: 1 }}>{t('title')}</FormLabel>
          <TextField
            size="small"
            value={title}
            error={isEmptyTextError}
            placeholder={t('enterTitle')}
            inputProps={{ maxLength: 200 }}
            onChange={onTitleChange}
          />
          {isEmptyTextError && (
            <FormHelperText error={isEmptyTextError}>
              {t('pleaseEnterTitleOfDIYTone')}
            </FormHelperText>
          )}
          {isAudioRecorded && (
            <>
              <AudioRecordedPreview {...{ isPlaying, deleteAudio, togglePlayAudio }} />
              {isSizeLarger && (
                <FormHelperText error={isSizeLarger}>
                  {t('fileSizeLarger', { fileSizeLimit })}
                </FormHelperText>
              )}
            </>
          )}
          {isRecording && <AudioRecordInProcess {...{ stopRecording }} />}
        </FormControl>
      </CardContent>
      <CardActions sx={styles.cardActions}>
        <Button
          fullWidth
          size="large"
          variant="contained"
          sx={styles.btn}
          disabled={isBtnDisabled}
          onClick={toggleRecordingStatus}
        >
          <Typography variant="h3" mr={1.2}>
            {buttonTitle}
          </Typography>
          {loading && <CircularProgress size={20} sx={{ ml: 1.5 }} color="inherit" />}
          {!isRecording && !isAudioRecorded && <MicIcon sx={styles.recordIcon} />}
        </Button>
      </CardActions>
    </Card>
  );
};

export default CreateRecordCard;
