import React from 'react';

import { AddRounded } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

import { styles } from './styles';
import { useGroupsList } from './useGroupsList';

import { EmptyContent, GroupItem, ContainerRulesAndGroups } from '@/components';

const DeleteGroupModal = React.lazy(() => import('@/components/modals/DeleteGroupModal'));
const CreateGroupModal = React.lazy(() => import('@/components/modals/CreateGroupModal'));

const GroupsList = () => {
  const { t, loading, phoneGroups, isCreateDisabled, onClickCreateGroupModal } = useGroupsList();
  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant="h1" fontWeight="bold">
          {t('groups')}
        </Typography>
        <Button
          sx={styles.createGroupBtn}
          variant="contained"
          color="secondary"
          onClick={onClickCreateGroupModal}
          disabled={isCreateDisabled}
        >
          <Typography fontWeight={700} variant="h2" mr={3}>
            {t(isCreateDisabled ? 'groupsLimitExceeded' : 'addNewGroup')}
          </Typography>
          <AddRounded />
        </Button>
      </Box>
      <EmptyContent isLoading={loading} title={t('youHaveNoGroups')} isEmpty={!phoneGroups?.length}>
        <ContainerRulesAndGroups>
          {phoneGroups?.map((group) => (
            <GroupItem key={group.phoneGroupId} {...group} />
          ))}
        </ContainerRulesAndGroups>
      </EmptyContent>

      <CreateGroupModal />
      <DeleteGroupModal />
    </Box>
  );
};

export default GroupsList;
