import { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { pageSize } from './constants';

import type { IContentResponse, ISubscriberResponse } from '@/types';

import { useActiveSubscriber, useKeycloak } from '@/context';
import { GET_CONTENTS } from '@/graphqL-queries/contents';
import { GET_CONTENTS_WITH_FAVOURITE } from '@/graphqL-queries/subscriber';
import {
  useCatalogPagination,
  useGraphQlErrorHandler,
  useHappyHourData,
  useLocalization,
  usePagination,
  useScreenSize,
} from '@/hooks';
import { catalogSelectors, setContentForCatalog } from '@/redux/catalog';

export const useCatalogPage = () => {
  const dispatch = useDispatch();
  const { msisdn, isLoaded } = useKeycloak();
  const { isOffsetLoading, activeOffset, changePage, clearOffset } = usePagination(pageSize);
  const { categoryId: contentCatId } = useParams();
  const { isRbtAuthorized, isUserInitLoaded } = useActiveSubscriber();
  const { isMobileScreenView } = useScreenSize();
  const { transform } = useLocalization();
  const { currentCategory, contentItems } = useSelector(catalogSelectors.catalogSelector);

  useHappyHourData();

  const paginationVariables = {
    filter: {
      contentCatId: contentCatId ?? null,
    },
    offset: activeOffset,
    pageSize,
  };

  const variables = isRbtAuthorized
    ? {
        msisdn,
        ...paginationVariables,
      }
    : paginationVariables;

  const skip = isOffsetLoading || (isRbtAuthorized ? !msisdn : false) || !isLoaded;

  const { data, loading, error } = useQuery<ISubscriberResponse | IContentResponse>(
    isRbtAuthorized ? GET_CONTENTS_WITH_FAVOURITE : GET_CONTENTS,
    {
      variables,
      fetchPolicy: 'network-only',
      skip,
    },
  );

  const { isDownloadMoreVisible } = useCatalogPagination(activeOffset, data);

  const catName = currentCategory?.catName ? transform(currentCategory?.catName) : '';

  useGraphQlErrorHandler(error);

  useEffect(() => {
    return () => {
      clearOffset();
      dispatch(setContentForCatalog([]));
    };
  }, [contentCatId]);

  return {
    loading,
    catName,
    contentItems,
    isRbtAuthorized,
    isUserInitLoaded,
    isDownloadMoreVisible,
    isMobileScreenView,
    changePage,
  };
};
