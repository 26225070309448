import React from 'react';

import { AppBar, Box, IconButton, Toolbar } from '@mui/material';

import { styles } from './styles';
import { useHeader } from './useHeader';

import {
  HeaderNavigation,
  LocalizationSwitcher,
  MenuIcon,
  SearchContent,
  UserLoginButton,
} from '@/components';
import { pathToAssets } from '@/constants';

const Header = ({ isEmpty = false }) => {
  const { isMobileScreenView, goToMainPage, onMobileDrawerClick } = useHeader();

  return (
    <AppBar position="fixed" sx={styles.header}>
      <Toolbar sx={styles.toolbar}>
        <Box sx={styles.headerControls}>
          <Box sx={styles.logoWrapper}>
            {isMobileScreenView && !isEmpty && (
              <IconButton disableRipple onClick={onMobileDrawerClick} sx={{ mr: 2 }}>
                <MenuIcon />
              </IconButton>
            )}
            <Box sx={styles.logo} onClick={goToMainPage}>
              <img
                src={`${pathToAssets}/meloring.svg`}
                style={isMobileScreenView ? styles.logoSmall : styles.logoBig}
              />
            </Box>
          </Box>
          {!isMobileScreenView && <HeaderNavigation />}
          <Box sx={styles.rightContainer}>
            <Box sx={styles.searchLocalization}>
              {!isMobileScreenView && (
                <Box sx={styles.searchContainer}>
                  <SearchContent />
                </Box>
              )}
              <Box mx={4} sx={styles.localization}>
                <LocalizationSwitcher />
              </Box>
            </Box>

            <UserLoginButton />
          </Box>
        </Box>
        {isMobileScreenView && (
          <Box sx={styles.searchContainer}>
            <SearchContent />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
