import { baseTheme, theme } from '@/constants';

export const styles = {
  contentNo: {
    height: 92,
    backgroundColor: 'background.default',
    borderRadius: 2,
  },
  contentNoFormControl: {
    width: '100%',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  contentIcon: {
    width: 60,
    height: 60,
  },
  smallImgPreview: {
    width: 60,
    height: 60,
    borderRadius: 8,
  },
  paperProps: {
    maxHeight: 275,
    overflowY: 'auto',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'grey.400',
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.500',
      },
    },
    py: 2,
    '&.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
      backgroundColor: 'grey.400',
    },
  },
  selected: {
    '&& .Mui-selected': {
      backgroundColor: 'grey.400',
      [baseTheme.breakpoints.up('md')]: {
        '&:hover': {
          backgroundColor: 'grey.500',
        },
      },
    },
  },
  label: {
    color: theme.palette.text.primary,
    mb: 1,
  },
  nothingFound: {
    display: 'flex',
    justifyContent: 'center',
    py: 4.46,
  },
  selectSongPreview: {
    color: 'grey.dark',
    fontSize: {
      sm: 18,
      xs: 11,
    },
    ml: 2,
  },
} as const;
