import { useEffect, useMemo, useState } from 'react';

import type { IAudioRecordInProcessProps } from './types';

import { useClientConfig } from '@/context';
import { transformSecondsToMinutes } from '@/utils';

export const useAudioRecordInProcess = ({ stopRecording }: IAudioRecordInProcessProps) => {
  const {
    config: { audioRecorder },
  } = useClientConfig();

  const [seconds, setSeconds] = useState(0);

  const audioMaxDuration = useMemo(() => Number(audioRecorder?.audioMaxDuration), [audioRecorder]);

  const time = useMemo(() => transformSecondsToMinutes(seconds), [seconds]);

  const maxDuration = useMemo(
    () => transformSecondsToMinutes(audioMaxDuration),
    [audioMaxDuration],
  );

  useEffect(() => {
    if (!audioMaxDuration) return;
    const intervalId = setInterval(() => {
      setSeconds((prevTime) => {
        const newTime = prevTime + 1;
        if (audioMaxDuration <= newTime) {
          stopRecording();
          clearInterval(intervalId);
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [audioMaxDuration]);

  return { time, maxDuration };
};
