import React from 'react';

import { SvgIcon } from '@mui/material';

import type { SvgIconProps } from '@mui/material';

import styles from '@/components/shared/icons/styles';
import { theme } from '@/constants';
import { mergeSx } from '@/utils';

export const SearchContentIcon = ({
  viewBox = '0 0 24 24',
  fill = theme.palette.primary.main,
  sx = {},
  ...rest
}: SvgIconProps) => (
  <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.965 14.6748H15.755L20.745 19.6748L19.255 21.1648L14.255 16.1748V15.3848L13.985 15.1048C12.845 16.0848 11.365 16.6748 9.755 16.6748C6.165 16.6748 3.255 13.7648 3.255 10.1748C3.255 6.5848 6.165 3.6748 9.755 3.6748C13.345 3.6748 16.255 6.5848 16.255 10.1748C16.255 11.7848 15.665 13.2648 14.685 14.4048L14.965 14.6748ZM5.255 10.1748C5.255 12.6648 7.26501 14.6748 9.755 14.6748C12.245 14.6748 14.255 12.6648 14.255 10.1748C14.255 7.6848 12.245 5.6748 9.755 5.6748C7.26501 5.6748 5.255 7.6848 5.255 10.1748Z"
      fill={fill}
    />
  </SvgIcon>
);
