import { useEffect, useState } from 'react';
import type { ChangeEvent } from 'react';

import { useTranslation } from 'react-i18next';

import type { IPhoneInputProps } from './types';

import { useClientConfig } from '@/context';
import { useDebounce } from '@/hooks';
import { checkIfValueIsNumber, replacePhoneCodeFromNumber } from '@/utils';

export const usePhoneInput = ({ value, onChange }: IPhoneInputProps) => {
  const { t } = useTranslation();
  const [phone, setPhone] = useState('');
  const {
    config: { phoneNumberCode },
  } = useClientConfig();

  const debouncedValue = useDebounce(phone, 400);

  useEffect(() => {
    if (phoneNumberCode) {
      setPhone(replacePhoneCodeFromNumber(phoneNumberCode, value));
    }
  }, [value, phoneNumberCode]);

  useEffect(() => {
    if (debouncedValue && onChange) {
      onChange(`${phoneNumberCode}${debouncedValue}`);
    }
  }, [debouncedValue]);

  const onPhoneTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const formattedPhone = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    const maskedPhone = formattedPhone.slice(0, 9);
    setPhone(maskedPhone);
  };

  const onClearInput = () => {
    setPhone('');
  };

  const numberCodeColor = value && checkIfValueIsNumber(value) ? 'text.primary' : 'text.secondary';

  return { t, phone, phoneNumberCode, onClearInput, onPhoneTextChange, numberCodeColor };
};
