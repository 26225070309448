import { typographyClasses } from '@mui/material';

export const styles = {
  head: {
    display: 'flex',
    mb: 2,
    gap: 2,
  },
  imgPreview: {
    width: 120,
    height: 120,
    borderRadius: 8,
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: 'calc(100% - 136px)',
    [`.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      wordWrap: 'break-word',
    },
  },
  imgContainer: {
    position: 'relative',
    '&:hover': {
      '.MuiBox-root': {
        opacity: '1 !important',
        pointerEvents: 'all !important',
      },
    },
  },
  descriptionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    p: 1.5,
    backgroundColor: 'grey.100',
    borderRadius: 1,
  },
  purchaseDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    mt: 3,
  },
  itemArtist: {
    cursor: 'pointer',
    fontWeight: 500,
    color: 'grey.dark',
    overflow: 'hidden',
    [`&.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      wordBreak: 'break-word',
    },
  },
  itemTitle: {
    fontWeight: 700,
    color: 'blue.100',
    mb: 1,
    overflow: 'hidden',
    [`&.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      wordBreak: 'break-word',
    },
  },
  isOneLineRow: {
    [`&.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical',
      wordBreak: 'break-word',
    },
  },
} as const;
