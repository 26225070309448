export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  btn: {
    py: 1.5,
    width: '100%',
    height: 40,
  },
  buttons: {
    mt: 5,
    width: '100%',
    display: 'flex',
  },
  cancelBtn: {
    px: 4,
    py: 0,
    height: 40,
    mr: 1.5,
  },
} as const;
