import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import type { IPlaybackRangePreviewProps } from './types';

import { getTimeFromDate, formatDateRangeToPointFormat } from '@/utils';

export const usePlaybackYearlyPreview = ({ date, calendarType }: IPlaybackRangePreviewProps) => {
  const { t } = useTranslation();

  const [startDate, endDate] = useMemo(
    () => formatDateRangeToPointFormat(date ?? '', calendarType),
    [date, calendarType],
  );

  const [startTime, endTime] = useMemo(() => getTimeFromDate(date ?? '').split('~'), [date]);

  return { t, startDate, endDate, startTime, endTime };
};
