import React from 'react';

import { SvgIcon } from '@mui/material';

import type { SvgIconProps } from '@mui/material';

import styles from '@/components/shared/icons/styles';
import { theme } from '@/constants';
import { mergeSx } from '@/utils';

export const UnPaidContentIcon = ({
  viewBox = '0 0 24 24',
  fill = theme.palette.primary.main,
  sx = {},
  ...rest
}: SvgIconProps) => (
  <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9254 9C14.8654 7.75 14.2054 6.9 12.4254 6.9C12.0254 6.9 11.6554 6.95 11.3354 7.03L9.82535 5.52C10.1754 5.37 10.5354 5.24 10.9254 5.16V3H13.9254V5.19C16.0154 5.7 17.0654 7.28 17.1354 9H14.9254ZM3.98535 5.33L5.39535 3.92L20.0154 18.54L18.6054 19.95L16.3854 17.73C15.7554 18.3 14.8854 18.67 13.9254 18.85V21H10.9254V18.83C9.00535 18.42 7.36535 17.19 7.24535 15H9.44535C9.55535 16.18 10.3654 17.1 12.4254 17.1C13.7954 17.1 14.5054 16.68 14.8454 16.19L11.3354 12.68C8.98535 11.99 7.42535 10.85 7.42535 8.77L3.98535 5.33Z"
      fill={fill}
    />
  </SvgIcon>
);
