import { ComplexTypeEnum } from '@/graphql-schema';
import i18n from '@/i18n';

export const getComplexContentLabel = (type: ComplexTypeEnum, isGift = false) =>
  ({
    [ComplexTypeEnum.Package]: i18n.t('package'),
    [ComplexTypeEnum.Channel]: i18n.t('channel'),
    [ComplexTypeEnum.Disk]: i18n.t('disk'),
    [ComplexTypeEnum.Single]: i18n.t(isGift ? 'toneGift' : 'toneUnpaid'),
  }[type]);
