import { createSlice } from '@reduxjs/toolkit';

import type { ILogoutState } from './types';

const initialState: ILogoutState = {
  isConfirmLogoutModalOpen: false,
};
export const logoutSlice = createSlice({
  name: 'logout',
  initialState,
  reducers: {
    openConfirmLogoutModal: (state) => {
      state.isConfirmLogoutModalOpen = true;
    },
    closeConfirmLogoutModal: (state) => {
      state.isConfirmLogoutModalOpen = false;
    },
  },
});

export const { openConfirmLogoutModal, closeConfirmLogoutModal } = logoutSlice.actions;

export default logoutSlice.reducer;
