import React from 'react';

import { SvgIcon } from '@mui/material';

import styles from '../styles';

import type { SvgIconProps } from '@mui/material';

import { mergeSx } from '@/utils';

const FacebookIcon = ({
  viewBox = '0 0 19 18',
  sx = {},
  width = '19',
  height = '18',
  fill = '#0D4BA0',
  ...rest
}: SvgIconProps) => (
  <SvgIcon
    viewBox={viewBox}
    sx={mergeSx(styles.root, sx)}
    width={width}
    height={height}
    fill="none"
    {...rest}
  >
    <g clipPath="url(#clip0_2898_1539)">
      <path
        d="M18.333 9C18.333 4.02891 14.3041 0 9.33301 0C4.36191 0 0.333008 4.02891 0.333008 9C0.333008 13.493 3.62363 17.216 7.92676 17.891V11.6016H5.6416V9H7.92676V7.01719C7.92676 4.76191 9.26973 3.51562 11.3264 3.51562C12.3107 3.51562 13.3408 3.69141 13.3408 3.69141V5.90625H12.2053C11.0873 5.90625 10.7393 6.60058 10.7393 7.3125V9H13.2354L12.8363 11.6016H10.7393V17.891C15.0424 17.216 18.333 13.493 18.333 9Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_2898_1539">
        <rect width="18" height="18" fill="white" transform="translate(0.333008)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default FacebookIcon;
