import React, { memo, Suspense } from 'react';

import { Card, CardContent, Typography, Box, CircularProgress, Divider } from '@mui/material';

import { styles } from './styles';
import { usePlaybackItem } from './usePlaybackItem';

import type { ContentItem, PlayCondition } from '@/graphql-schema';

import { CardEditButton, PlaybackItemPreview } from '@/components';
import PlayContentImage from '@/components/widgets/PlayContentImage';

const PlaybackItemEdit = React.lazy(
  () => import('@/components/features/playback/PlaybackItemEdit'),
);

const PlaybackItem: React.FC<PlayCondition> = memo((condition) => {
  const { contentItem } = condition;
  const { t, isSelectedItem, toggleIsEditing, openDeleteModal } = usePlaybackItem(condition);

  return (
    <Card sx={styles.card}>
      <CardContent>
        {isSelectedItem && (
          <Box sx={styles.editHeader}>
            <Typography variant="h1" fontWeight={700}>
              {t('editPlayRule')}
            </Typography>
            <CardEditButton {...{ toggleIsEditing, openDeleteModal }} />
          </Box>
        )}
        <Box sx={styles.titleContainer}>
          <Box sx={styles.imgContainer}>
            <PlayContentImage
              isPreview
              contentItem={contentItem as ContentItem}
              size={isSelectedItem ? 60 : 90}
            />
            <Box sx={styles.description}>
              <Typography variant="h2" fontWeight="bold">
                {contentItem?.title}
              </Typography>
              <Typography variant="h3" color="grey.dark" mt={1}>
                {contentItem?.artist}
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.btnWrapper}>
            {!isSelectedItem && (
              <>
                <CardEditButton {...{ toggleIsEditing, openDeleteModal }} />
              </>
            )}
          </Box>
        </Box>
        {isSelectedItem && <Divider sx={{ mt: 4, mb: 3 }} />}
        {isSelectedItem ? (
          <Suspense fallback={<CircularProgress />}>
            <PlaybackItemEdit condition={condition} toggleIsEditing={toggleIsEditing} />
          </Suspense>
        ) : (
          <PlaybackItemPreview {...condition} />
        )}
      </CardContent>
    </Card>
  );
});

PlaybackItem.displayName = 'PlaybackItem';

export default PlaybackItem;
