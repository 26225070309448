import React from 'react';

import { AddRounded } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';

import { styles } from './styles';
import { usePlaybackList } from './usePlaybackList';

import {
  ContainerRulesAndGroups,
  EmptyContent,
  HintBlock,
  PlaybackInfoIcon,
  PlaybackItem,
} from '@/components';
const CreatePlaybackRuleModal = React.lazy(
  () => import('@/components/modals/CreatePlaybackRuleModal'),
);

const DeletePlaybackRuleModal = React.lazy(
  () => import('@/components/modals/DeletePlaybackRuleModal'),
);

const PlaybackList = () => {
  const {
    t,
    msisdn,
    loading,
    isHintOpen,
    contentItems,
    playConditions,
    isCreateDisabled,
    playConditionsSorted,
    isActiveContentReceived,
    onCloseHint,
    onToggleHint,
    onClickCreatePlaybackModal,
  } = usePlaybackList();

  return (
    <Box>
      <Box sx={styles.header}>
        <Box sx={styles.title}>
          <Typography variant="h1" fontWeight="bold">
            {t('setUpPlaybackRules')}
          </Typography>
          <IconButton sx={{ height: 'fit-content' }} onClick={onToggleHint}>
            <PlaybackInfoIcon />
          </IconButton>
        </Box>
        <Button
          sx={styles.createGroupBtn}
          variant="contained"
          color="secondary"
          onClick={onClickCreatePlaybackModal}
          disabled={isCreateDisabled || !contentItems?.length}
        >
          <Typography fontWeight={700} variant="h2" sx={styles.createNewRule}>
            {t(isCreateDisabled ? 'rulesLimitExceeded' : 'createNewRule')}
          </Typography>
          <AddRounded />
        </Button>
      </Box>
      <HintBlock isHintOpen={isHintOpen} onClose={onCloseHint} />
      {!contentItems?.length && isActiveContentReceived && (
        <Box sx={styles.createDisableMessage}>
          <Typography variant="h2" sx={styles.createDisableMessageTxt}>
            {t('youShouldHaveAtLeastOneActiveContent')}
          </Typography>
        </Box>
      )}
      <EmptyContent
        isLoading={loading}
        title={t('youHaveNoPlayRules')}
        isEmpty={!playConditions?.length}
        isProtected={!msisdn}
      >
        <ContainerRulesAndGroups>
          {playConditionsSorted?.map((condition) => (
            <PlaybackItem key={condition?.playCondId} {...condition} />
          ))}
        </ContainerRulesAndGroups>
      </EmptyContent>

      <CreatePlaybackRuleModal contentItems={contentItems} />
      <DeletePlaybackRuleModal />
    </Box>
  );
};

export default PlaybackList;
