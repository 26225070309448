export const styles = {
  container: {
    backgroundColor: 'grey.200',
    px: 2,
    py: 1.5,
    borderRadius: 2,
    mt: 2,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  recordInProcessIcon: {
    fontSize: { md: 32, xs: 32 },
    mb: 2.75,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
} as const;
