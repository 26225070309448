import React from 'react';

import { Box, Button, Typography } from '@mui/material';
import { t } from 'i18next';

import { styles } from './styles';
import { useContentItem } from './useContentItem';

import type { IContentItemProps } from './types';
import type { ContentItem as ContentItemType } from '@/graphql-schema';

import {
  ComplexCardLabel,
  ContentButtonsGroup,
  HighlightedText,
  PlayContentImage,
  ResponsiveCardComponent,
} from '@/components';

const ContentItem: React.FC<IContentItemProps> = (props) => {
  const { isComplexSubItem, isPromotion = false, contentItem, isFullInfo, idx } = props;
  const {
    textSx,
    complexType,
    contentNo,
    amountOnetime,
    onetimePeriod,
    isPackageContent,
    isComplexContent,
    phoneNumberForSms,
    openModalForBuy,
    onContentItemClick,
    descriptionSx,
    isFavouriteContent,
    searchValue,
    isSearchResultPage,
    onArtistClick,
  } = useContentItem(props);

  return (
    <ResponsiveCardComponent>
      <Box sx={styles.container}>
        <Box sx={styles.head}>
          <Box sx={styles.imgContainer}>
            <PlayContentImage
              isPreview={isComplexContent}
              contentItem={contentItem as ContentItemType}
              size={120}
            />
          </Box>
          <Box sx={{ ...styles.description, ...descriptionSx }}>
            {isComplexContent && <ComplexCardLabel complexType={complexType} />}
            <Typography
              variant="h2"
              onClick={onContentItemClick}
              sx={{
                ...styles.itemTitle,
                ...textSx,
              }}
            >
              {isSearchResultPage ? (
                <HighlightedText text={contentItem.title} searchValue={searchValue} />
              ) : (
                contentItem.title
              )}
            </Typography>
            <Typography
              variant="h3"
              onClick={onArtistClick}
              sx={{
                ...styles.itemArtist,
                textSx,
              }}
            >
              {isSearchResultPage ? (
                <HighlightedText text={contentItem.artist} searchValue={searchValue} />
              ) : (
                contentItem.artist
              )}
            </Typography>
            <Box sx={styles.smsNumber}>
              <Typography align="center" variant="h4" fontWeight={500} color="blue.100">
                SMS <span>{contentNo}</span> {t('on')} <span>{phoneNumberForSms}</span>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <ContentButtonsGroup
            {...{
              isComplexContent,
              isComplexSubItem,
              isPromotion,
              contentItem,
              idx,
              isFavouriteContent,
            }}
          />
          {isFullInfo && (
            <Box sx={styles.purchaseDescription}>
              <Box sx={styles.descriptionItem}>
                <Typography variant="h3">{t('amountOnetimeLabel')}</Typography>
                <Typography variant="h3">
                  {t('amountOnetime', { amountOnetime: contentItem?.amountOnetime })}
                </Typography>
              </Box>
              <Box sx={styles.descriptionItem}>
                <Typography variant="h3">{t('onetimePeriodLabel')}</Typography>
                <Typography variant="h3">
                  {t('onetimePeriod', { onetimePeriod: contentItem?.onetimePeriod })}
                </Typography>
              </Box>
              {!isPackageContent && (
                <>
                  <Box sx={styles.descriptionItem}>
                    <Typography variant="h3">{t('amountPeriodicLabel')}</Typography>
                    <Typography variant="h3">
                      {t('amountPeriodic', { item: contentItem })}
                    </Typography>
                  </Box>
                  <Box sx={styles.descriptionItem}>
                    <Typography variant="h3">{t('chargePeriodLabel')}</Typography>
                    <Typography variant="h3">{t('chargePeriod', { item: contentItem })}</Typography>
                  </Box>
                </>
              )}
            </Box>
          )}
          {!isComplexSubItem && (
            <Box sx={styles.orderButtonContainer}>
              <Button
                variant="contained"
                disableFocusRipple
                disableRipple
                sx={styles.purchaseButton}
                onClick={openModalForBuy}
              >
                <Typography variant="h4" fontWeight={700} sx={styles.purchaseText}>
                  {t('order')}
                </Typography>{' '}
                <Typography variant="h4" color="white">
                  {t('amountOnetime', {
                    amountOnetime,
                  })}
                  {' / '}
                  {t('onetimePeriod', {
                    onetimePeriod,
                  })}
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </ResponsiveCardComponent>
  );
};

export default ContentItem;
