import { buttonClasses } from '@mui/material';

import { baseTheme } from '@/constants';
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 50,
    height: 32,
    p: 0.5,
    borderRadius: 2,
    backgroundColor: 'grey.400',
    [`& .${buttonClasses.endIcon}`]: {
      margin: 0,
      '&>:nth-of-type(1)': {
        fontSize: 18,
      },
    },
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.500',
      },
    },
  },
  buttonBack: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 50,
    height: 32,
    p: 0.5,
    borderRadius: 2,
    [`& .${buttonClasses.endIcon}`]: {
      margin: 0,
      '&>:nth-of-type(1)': {
        fontSize: 24,
      },
    },
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.400',
      },
    },
  },
};

export { styles };
