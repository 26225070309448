import React, { memo } from 'react';

import { KeyboardArrowDown } from '@mui/icons-material';
import { FormControl, FormHelperText, Select, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { styles } from './styles';

import type { SelectProps } from '@mui/material';

interface ISelectInputProps extends Omit<SelectProps, 'error'> {
  error?: string | null | boolean;
  value?: string | null;
  name?: string;
  children: React.ReactNode;
  formControlSx?: {};
  paperPropsSx?: {};
  menuPropsSx?: {};
  placeholder?: string;
}

const SelectInput = memo(
  ({
    error,
    value,
    name,
    children,
    formControlSx = {},
    paperPropsSx = {},
    menuPropsSx = {},
    onChange,
    ...props
  }: ISelectInputProps) => {
    const { t } = useTranslation();
    return (
      <FormControl error={!!error} sx={formControlSx}>
        {!!props?.placeholder && (
          <Typography variant="h6" mb={1}>
            {props?.placeholder}
          </Typography>
        )}
        <Select
          error={!!error}
          displayEmpty
          size="small"
          name={name}
          value={value ?? ''}
          onChange={onChange}
          IconComponent={KeyboardArrowDown}
          inputProps={{
            sx: styles.input,
          }}
          MenuProps={{
            sx: { ...styles.selected, ...menuPropsSx },
            PaperProps: {
              style: { ...styles.paperProps, ...paperPropsSx },
            },
            // disableScrollLock: true,
          }}
          {...props}
        >
          {children}
        </Select>
        {error && <FormHelperText error={!!error}>{t(error as string)}</FormHelperText>}
      </FormControl>
    );
  },
);

SelectInput.displayName = 'SelectInput';

export default SelectInput;
