import { createSlice } from '@reduxjs/toolkit';

import type { IGroupState } from './types';

const initialState: IGroupState = {
  isCreateGroupOpen: false,
  isDeleteModalOpen: false,
  selectedGroupForEditing: null,
  selectedGroupForDeleting: null,
};
export const groupSlice = createSlice({
  name: 'playbackRules',
  initialState,
  reducers: {
    openCreateGroupModal: (state) => {
      state.isCreateGroupOpen = true;
    },
    closeCreateGroupModal: (state) => {
      state.isCreateGroupOpen = false;
    },
    openDeleteGroupModal: (state, { payload }) => {
      state.isDeleteModalOpen = true;
      state.selectedGroupForDeleting = payload;
    },
    closeDeleteGroupModal: (state) => {
      state.isDeleteModalOpen = false;
      state.selectedGroupForDeleting = null;
    },
    selectGroupForEditing: (state, { payload }) => {
      state.selectedGroupForEditing = payload;
    },
  },
});

export const {
  openCreateGroupModal,
  closeCreateGroupModal,
  openDeleteGroupModal,
  closeDeleteGroupModal,
  selectGroupForEditing,
} = groupSlice.actions;

export default groupSlice.reducer;
