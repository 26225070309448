export enum NotSavedListType {
  Group = 'group',
  Playback = 'playback',
  Both = 'both',
}

export enum NotSavedRequestType {
  Create = 'create',
  Edit = 'edit',
}

export interface INotSavedChangesState {
  isModalOpen: boolean;
  isAcceptedClose: boolean;
  listType: null | NotSavedListType;
  isFormGroupDirty: boolean;
  isFormPlaybackDirty: boolean;
  isRecordContentDirty: boolean;
  isUploadContentDirty: boolean;
}
