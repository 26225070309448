import { createContext, useContext } from 'react';

import type { Subscriber } from '@/graphql-schema';

interface ActiveSubscriberContextType {
  isRbtAuthorized: boolean;
  isUserInitLoaded: boolean;
  serviceNo: null | number;
  activesSubscriber: Subscriber | null;
  refetchSubscriber: () => void;
  changeRbtAuthorizedStatus: (isError: boolean) => void;
}

export const ActiveSubscriberContext = createContext<ActiveSubscriberContextType>({
  isRbtAuthorized: false,
  activesSubscriber: null,
  serviceNo: null,
  isUserInitLoaded: false,
  refetchSubscriber: () => {},
  changeRbtAuthorizedStatus: () => {},
});

export const useActiveSubscriber = () => {
  return useContext(ActiveSubscriberContext);
};
