import React from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

import type { ICustomTableCell } from '@/constants';

import { HistoryCard } from '@/components';
import { ICustomTableCellType } from '@/constants';

const CustomTableItem = ({ type, time, text, card }: ICustomTableCell) => {
  return (
    <>
      {type === ICustomTableCellType.Time && (
        <Box sx={styles.dateField}>
          <Typography variant="h4" color="text.primary">
            {time?.date}
          </Typography>
          <Typography variant="h4" color="text.secondary">
            {time?.time}
          </Typography>
        </Box>
      )}
      {type === ICustomTableCellType.Text && (
        <Typography variant="h4" color="text.primary">
          {text}
        </Typography>
      )}
      {type === ICustomTableCellType.Card && card?.contentNo && <HistoryCard {...card} />}
    </>
  );
};

export default CustomTableItem;
