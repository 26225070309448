import React from 'react';

import { Box } from '@mui/material';

import { styles } from './styles';

import { ContainerContent, SidebarNavigation } from '@/components';
import { ClientRoutes } from '@/constants';
import { useLocalization, useScreenSize } from '@/hooks';

interface IPageWrapperProps {
  isProtected?: boolean;
  children: React.ReactNode;
}

const PageWrapper = ({ isProtected, children }: IPageWrapperProps) => {
  const { currentPath } = useLocalization();
  const { isMobileScreenView } = useScreenSize();
  const isMyMeloring = currentPath === ClientRoutes.RecordMyMeloring;

  return (
    <ContainerContent>
      <Box sx={styles.container}>
        {isProtected && isMobileScreenView && !isMyMeloring && (
          <SidebarNavigation sx={styles.mobileNavigation} />
        )}
        {children}
      </Box>
    </ContainerContent>
  );
};

export default PageWrapper;
