import React from 'react';

import { useKeycloakProvider } from './useKeycloakProvider';

import { KeycloakContext } from '@/context';

interface IKeycloakProviderProps {
  children: React.ReactNode;
}

export const KeycloakProvider: React.FC<IKeycloakProviderProps> = ({ children }) => {
  const value = useKeycloakProvider();
  return <KeycloakContext.Provider value={value}>{children}</KeycloakContext.Provider>;
};

export default KeycloakProvider;
