import React from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';

import { styles } from './styles';

interface IEmptyContentProps {
  title: string;
  isEmpty: boolean;
  isLoading: boolean;
  isProtected?: boolean;
  children: React.ReactNode;
}

const EmptyContent: React.FC<IEmptyContentProps> = ({
  isLoading,
  title,
  isEmpty,
  isProtected = false,
  children,
}) => {
  if ((isLoading && isEmpty) || isProtected) {
    return (
      <Box sx={styles.progress}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      {isEmpty && !isLoading ? (
        <Typography variant="h2" sx={styles.contentEmptyText}>
          {title}
        </Typography>
      ) : (
        children
      )}
    </>
  );
};

export default EmptyContent;
