import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { ClientRoutes, getSideBarNavigationList, getTextNavigationList } from '@/constants';
import { useActiveSubscriber, useClientConfig, useKeycloak } from '@/context';
import {
  useGetPageAccessStatus,
  useLocalization,
  useScreenSize,
  useMobileDrawerHook,
  useEditFormDirty,
} from '@/hooks';

export const useSidebarNavigation = () => {
  const { isMobileScreenView } = useScreenSize();
  const { navigate, currentPath } = useLocalization();
  const {
    config: { isMyMeloringDisplayed },
  } = useClientConfig();

  const { msisdn } = useKeycloak();
  const { t } = useTranslation();
  const {
    i18n: { language },
  } = useTranslation();
  const { onMobileDrawerClose } = useMobileDrawerHook();
  const { isRbtAuthorized } = useActiveSubscriber();

  const { isPublicPage, isHelpPage } = useGetPageAccessStatus();
  const { openNotSavedChangesModal, checkPlayRulesPageAndCloseEditMode } = useEditFormDirty();

  const isMyMusicPage = currentPath === ClientRoutes.MyMusic;

  const firstLinkType = isHelpPage ? ClientRoutes.FAQ : ClientRoutes.RecordMyMeloring;
  const secondLinkType = isHelpPage ? ClientRoutes.TermsAndConditions : ClientRoutes.PlayRules;

  const isRecordMyMeloringDisplayForNotRbt =
    !isRbtAuthorized && isMyMeloringDisplayed && !isHelpPage && !!msisdn;

  const goToPage = (link: string) => () => {
    if (!openNotSavedChangesModal()) {
      checkPlayRulesPageAndCloseEditMode();
      navigate(link);
    }
    if (isMobileScreenView) {
      onMobileDrawerClose();
    }
  };

  const navigationList = useMemo(
    () => (isHelpPage ? getTextNavigationList() : getSideBarNavigationList()),
    [isHelpPage, language],
  );

  const openMyMeloringPage = () => {
    navigate(ClientRoutes.RecordMyMeloring);
    if (isMobileScreenView) {
      onMobileDrawerClose();
    }
  };

  return {
    t,
    firstLinkType,
    secondLinkType,
    isMyMusicPage,
    isPublicPage,
    isHelpPage,
    isRbtAuthorized,
    isMobileScreenView,
    isMyMeloringDisplayed,
    msisdn,
    navigationList,
    currentPath,
    isRecordMyMeloringDisplayForNotRbt,
    goToPage,
    openMyMeloringPage,
  };
};
