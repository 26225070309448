import React, { FC, useState } from 'react';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { styles } from './styles';

import type { PhoneGroup } from '@/graphql-schema';

const GroupItemPreview: FC<PhoneGroup> = ({ groupMembers }) => {
  const { t } = useTranslation();
  const [isVisibleMembers, setIsVisibleMembers] = useState(false);

  const toggleDisplayMembers = () => {
    setIsVisibleMembers(!isVisibleMembers);
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: isVisibleMembers ? 1 : 0,
          height: isVisibleMembers ? '100%' : 0,
        }}
      >
        <Box
          sx={{
            ...styles.members,
          }}
        >
          {groupMembers?.map((member) => (
            <Box key={member?.phoneNumber} sx={styles.member}>
              {member?.phoneName && (
                <Typography variant="h5" mr={2}>
                  {member?.phoneName}
                </Typography>
              )}
              <Typography variant="h5" sx={styles.phoneNumber}>
                {member?.phoneNumber}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Button size="medium" sx={styles.hideBtn} onClick={toggleDisplayMembers}>
        <Typography mr={3}>{t(isVisibleMembers ? 'hide' : 'show')}</Typography>
        {isVisibleMembers ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </Button>
    </Box>
  );
};

export default GroupItemPreview;
