import React from 'react';

import { Close, Search } from '@mui/icons-material/';
import { Box, MenuItem, Typography, Input, IconButton } from '@mui/material';
import { t } from 'i18next';

import { styles } from './styles';
import { useSearchContent } from './useSearchContent';

import type { ISearchContentProps } from './types';
import type { ContentItem } from '@/graphql-schema';

import { ArrowLeftIcon, HighlightedText, PlayContentImage } from '@/components';
import { theme } from '@/constants';

const SearchContent: React.FC<ISearchContentProps> = ({ inputControlSx = {} }) => {
  const {
    contents,
    searchValue,
    trimmedValue,
    ref,
    isDropdownVisible,
    menuButtonsRef,
    onSearchValueChange,
    onClear,
    getBorderRadiusSx,
    onContentItem,
    onBlur,
    moveToSearchResultPage,
    onEnter,
  } = useSearchContent();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.searchFieldContainer}>
        <Input
          key="search"
          ref={ref}
          disableUnderline
          onBlur={onBlur}
          placeholder={t('search')}
          value={searchValue}
          onKeyUp={onEnter}
          onChange={onSearchValueChange}
          sx={{
            ...styles.inputControl,
            borderRadius: getBorderRadiusSx(),
            ...inputControlSx,
          }}
          inputProps={{
            sx: {
              ...styles.input,
            },
          }}
          startAdornment={<Search sx={styles.searchIcon} />}
          endAdornment={
            <Box sx={styles.buttons}>
              {!!searchValue.length && (
                <IconButton
                  disableFocusRipple
                  disableRipple
                  sx={{
                    ...styles.inputButton,
                  }}
                  onClick={onClear}
                >
                  <Close sx={{ fontSize: 18 }} />
                </IconButton>
              )}
            </Box>
          }
        />
        <Box
          sx={{
            ...styles.dropdown,
            display: isDropdownVisible,
            width: ref.current?.scrollWidth,
          }}
        >
          {contents?.map((content, i) => (
            <MenuItem
              onClick={() => onContentItem(content)}
              key={content?.contentNo}
              sx={styles.menuItem}
              ref={(el) => el && (menuButtonsRef.current[i] = el)}
            >
              <PlayContentImage isPreview size={40} contentItem={content as ContentItem} />
              <Box sx={styles.content}>
                <Typography sx={{ ...styles.text, ...styles.songTitle }} variant="h5">
                  <HighlightedText text={content?.title} searchValue={trimmedValue} />
                </Typography>
                <Typography sx={styles.text} variant="h5">
                  <HighlightedText text={content?.artist} searchValue={trimmedValue} />
                </Typography>
              </Box>
            </MenuItem>
          ))}
          {!contents?.length && (
            <Typography variant="h3" sx={styles.nothingFoundText}>
              {t('nothingFound')}
            </Typography>
          )}
        </Box>
      </Box>
      <IconButton
        sx={styles.searchButton}
        onClick={moveToSearchResultPage}
        disabled={!contents?.length}
      >
        <ArrowLeftIcon
          fill={contents?.length ? theme.palette.text.primary : theme.palette.grey[700]}
          sx={styles.returnButton}
        />
      </IconButton>
    </Box>
  );
};

export default SearchContent;
