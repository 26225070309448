import React from 'react';

import { Box, Button, Link, Typography } from '@mui/material';

import { styles } from './styles';
import { useFooter } from './useFooter';

import { GooglePlayIcon, AppStoreIcon } from '@/components';

const Footer = () => {
  const {
    t,
    buildVersion,
    textInFooterLocal,
    mobileAppIosUrl,
    mobileAppAndroidUrl,
    displayBuildVersion,
  } = useFooter();
  return (
    <Box sx={styles.main}>
      <Typography variant="h1" color="text.primary" fontWeight={700}>
        {t('footerTitle')}
      </Typography>
      <Box sx={styles.container}>
        <Box sx={styles.buttonWrapper}>
          <Link sx={styles.link} href={`${mobileAppAndroidUrl}`} target="_blank">
            <Button variant="outlined" sx={styles.button}>
              <GooglePlayIcon sx={styles.google} />
            </Button>
          </Link>
          <Link sx={styles.link} href={`${mobileAppIosUrl}`} target="_blank">
            <Button variant="outlined" disableElevation sx={styles.button}>
              <AppStoreIcon sx={styles.apple} />
            </Button>
          </Link>
        </Box>
        <Box sx={styles.textWrapper}>
          <Typography
            variant="h5"
            color="text.secondary"
            dangerouslySetInnerHTML={{
              __html: `${textInFooterLocal}`,
            }}
          />
        </Box>
        {displayBuildVersion && <Typography variant="h4">V {buildVersion}</Typography>}
      </Box>
    </Box>
  );
};

export default Footer;
