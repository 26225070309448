import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ClientRoutes } from '@/constants';
import { useActiveSubscriber, useClientConfig, useKeycloak } from '@/context';
import { useEditFormDirty, useLocalization, useScreenSize } from '@/hooks';
import { openConfirmLogoutModal } from '@/redux/logout/slice';

export const useUserLoginButton = () => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  const dispatch = useDispatch();
  const { navigate } = useLocalization();
  const { keycloak, msisdn } = useKeycloak();
  const { isUserInitLoaded, isRbtAuthorized } = useActiveSubscriber();
  const { openNotSavedChangesModal } = useEditFormDirty();

  const {
    config: { isTestMode, testMsisdn },
    onClientConfigChange,
  } = useClientConfig();

  const onTestModeLogout = () => {
    onClientConfigChange({ testMsisdn: null });
  };

  const onLogoutClick = () => {
    if (!openNotSavedChangesModal()) {
      dispatch(openConfirmLogoutModal());
    }
  };

  const goToTestPage = () => {
    navigate(ClientRoutes.Test);
  };

  const goToMyMusicPage = () => {
    if (isRbtAuthorized) {
      navigate(ClientRoutes.MyMusic);
    }
  };

  const onLoginClick = () => {
    keycloak?.login();
  };

  return {
    t,
    keycloak,
    isMobile,
    msisdn,
    isTestMode,
    testMsisdn,
    isRbtAuthorized,
    isUserInitLoaded,
    onLoginClick,
    onTestModeLogout,
    onLogoutClick,
    goToTestPage,
    goToMyMusicPage,
  };
};
