export enum ClientRoutes {
  Main = '/',
  RecordMyMeloring = '/record-my-meloring',
  FAQ = '/help/faq',
  TermsAndConditions = '/help/terms-and-conditions',
  Catalog = '/catalog',
  Promotions = '/promotions',
  CatalogByCategoryId = '/catalog/:categoryId',
  PersonalCabinet = '/personal-cabinet',
  PlayRules = '/play-rules',
  Test = '/test',
  Localization = '/:localization',
  MyMusic = '/my-music',
  UnpaidContent = '/unpaid-content',
  SearchUser = '/search-user',
  GiftForYou = '/gift-for-you',
  Content = '/content',
  ContentByContentNo = '/content/:contentNo',
  SentGift = '/sent-gift',
  GiftHistory = '/gift-history',
  History = '/history',
  Favourites = '/favourites',
  Artist = '/artist',
  ContentByArtist = '/artist/:artist',
  SearchResult = '/search-result',
  ContentBySearch = '/search-result/:searchValue',
}
