import { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import { ClientRoutes } from '@/constants';
import { useActiveSubscriber, useClientConfig, useKeycloak } from '@/context';
import { GET_CONTENT_ITEM } from '@/graphqL-queries/contents';
import { ContentItem } from '@/graphql-schema';
import { useGraphQlErrorHandler, useLocalization, usePurchaseParams } from '@/hooks';
import { useGetContentImage } from '@/hooks/useGetContentImage';
import { PurchaseEnum } from '@/redux/purchases';
import { purchaseSelector } from '@/redux/purchases/selectors';
import {
  clearPurchaseTypeAndVirtContentNo,
  openPurchaseModal,
  setPurchaseTypeAndVirtContentNo,
} from '@/redux/purchases/slice';

export const usePurchaseModalWrapper = () => {
  const dispatch = useDispatch();
  const { navigate } = useLocalization();
  const { paramPurchaseType, paramVirtContentNo, addPurchaseParams, clearPurchaseParams } =
    usePurchaseParams();
  const { msisdn, keycloak, isLoaded } = useKeycloak();
  const { isRbtAuthorized } = useActiveSubscriber();
  const {
    config: { isTestMode },
  } = useClientConfig();
  const { purchaseType, virtContentNo } = useSelector(purchaseSelector);

  const { data, error } = useQuery(GET_CONTENT_ITEM, {
    skip: !virtContentNo || !isLoaded,
    variables: {
      virtContentNo,
    },
  });

  useGraphQlErrorHandler(error);

  const contentItem = data?.contentItem;

  const imgUrl = useGetContentImage(contentItem as ContentItem);

  const checkAuth = () => {
    if (!purchaseType) return;
    if (purchaseType !== PurchaseEnum.BUY && !isRbtAuthorized) {
      clearPurchaseParams();
      return;
    }
    if (msisdn) {
      dispatch(
        openPurchaseModal({ selectedPurchaseForModal: { ...contentItem, imgUrl }, purchaseType }),
      );
      clearPurchaseParams();
      return;
    }
    if (keycloak?.authenticated) {
      navigate(ClientRoutes.PersonalCabinet);
      return;
    }
    if (isTestMode) {
      navigate(ClientRoutes.Test);
    } else {
      if (paramPurchaseType && isLoaded && !keycloak?.authenticated) {
        clearPurchaseParams();
        dispatch(clearPurchaseTypeAndVirtContentNo());
        return;
      }
      addPurchaseParams(contentItem?.virtContentNo, purchaseType);
      keycloak?.login();
    }
  };

  useEffect(() => {
    if (paramVirtContentNo && paramPurchaseType && !contentItem) {
      dispatch(
        setPurchaseTypeAndVirtContentNo({
          virtContentNo: paramVirtContentNo,
          purchaseType: paramPurchaseType,
        }),
      );
    }
    if (contentItem) checkAuth();
  }, [paramVirtContentNo, paramPurchaseType, contentItem, isLoaded, msisdn]);

  return {};
};
