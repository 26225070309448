import React from 'react';

import { Box, Button, Chip, Typography } from '@mui/material';

import { styles } from './styles';
import { useUnpaidContentItem } from './useUnpaidContentItem';

import { ComplexCardLabel, PlayContentImage, ResponsiveCardComponent } from '@/components';
import { ContentItem, InboxContentItem } from '@/graphql-schema';

const UnpaidContentItem: React.FC<InboxContentItem> = (contentItem) => {
  const {
    t,
    chargeState,
    isPersonalContent,
    isWaitingProlongation,
    complexType,
    isComplexContent,
    onContentDelete,
    startDt,
    endDt,
    graceDt,
    onArtistClick,
    onContentItemClick,
  } = useUnpaidContentItem(contentItem);
  return (
    <ResponsiveCardComponent sx={styles.container}>
      <Box>
        <Box sx={styles.head}>
          <Box sx={styles.imgContainer}>
            <PlayContentImage
              contentItem={contentItem as ContentItem}
              size={120}
              isPreview={isComplexContent}
            />
          </Box>
          <Box sx={styles.description}>
            {isComplexContent && <ComplexCardLabel complexType={complexType} />}
            <Typography
              variant="h2"
              sx={{
                cursor: isPersonalContent ? 'auto' : 'pointer',
                ...styles.itemTitle,
                ...(isComplexContent ? styles.isOneLineRow : {}),
              }}
              onClick={onContentItemClick}
            >
              {contentItem.title}
            </Typography>
            <Typography
              variant="h3"
              onClick={onArtistClick}
              sx={{ ...styles.itemArtist, ...(isComplexContent ? styles.isOneLineRow : {}) }}
            >
              {contentItem.artist}
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.purchaseDescription}>
          {!isWaitingProlongation && (
            <Box sx={styles.descriptionItem}>
              <Typography variant="h3">{t('purchaseDate')}</Typography>
              <Typography variant="h3">{startDt}</Typography>
            </Box>
          )}
          {isWaitingProlongation && (
            <Box sx={styles.descriptionItem}>
              <Typography variant="h3">{t('validTo')}</Typography>
              <Typography variant="h3">{endDt}</Typography>
            </Box>
          )}
          {!!graceDt && (
            <Box sx={styles.descriptionItem}>
              <Typography variant="h3">{t('removedDate')}</Typography>
              <Typography variant="h3">{graceDt}</Typography>
            </Box>
          )}
          {isWaitingProlongation ? (
            <Box sx={styles.descriptionItem}>
              <Typography variant="h3">{t('prolongationCost')}</Typography>
              <Typography variant="h3">{t('pricePeriodic', { item: contentItem })}</Typography>
            </Box>
          ) : (
            <Box sx={styles.descriptionItem}>
              <Typography variant="h3">{t('purchaseCost')}</Typography>
              <Typography variant="h3">{t('priceOnetime', { item: contentItem })}</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box style={styles.buttons}>
        <Chip label={chargeState} sx={styles.contentState} />
        <Button
          sx={{ ...styles.button }}
          disableFocusRipple
          disableRipple
          onClick={onContentDelete}
        >
          <Typography variant="h4">{t('deactive')}</Typography>
        </Button>
      </Box>
    </ResponsiveCardComponent>
  );
};

export default UnpaidContentItem;
