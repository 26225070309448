import React from 'react';

interface IImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  sx?: {};
}

const Image = ({ sx = {}, ...props }: IImageProps) => {
  return (
    <img
      style={{
        ...sx,
        ...props.style,
      }}
      {...props}
    />
  );
};

export default Image;
