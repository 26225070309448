import { inputBaseClasses } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import palette from './palette';

import { addOpacityToHexColor } from '@/utils';

export type Theme = typeof theme;

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    bg: true;
    md: true;
    lg: true;
    xl: true;
  }
}

export const baseTheme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'bg', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 699,
      bg: 999,
      md: 1260,
      lg: 1439,
      xl: 1900,
    },
  },
});

const components = {
  MuiContainer: {
    styleOverrides: {
      root: {
        maxWidth: 'auto',
        '@media (min-width: 992px)': {
          maxWidth: '100%',
        },
        '@media (min-width: 600px)': {
          paddingRight: 0,
        },
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        background: 'white',
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        color: 'black',
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: 16,
        '&:last-child': {
          paddingBottom: 16,
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: '0px 16px 16px 16px',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: palette.grey['400'],
        '&:hover': {
          backgroundColor: palette.grey['500'],
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        cursor: { md: 'pointer', xs: 'default' },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: 400,
        color: palette.text.primary,
        boxShadow: 'none',
        borderRadius: '1000px',
        fontSize: 14,
        textTransform: 'none',
        border: 'none',
        '&:hover': {
          [baseTheme.breakpoints.down('md')]: {
            boxShadow: 'none',
          },
        },
      },
      outlined: {
        backgroundColor: palette.grey['100'],
        '&:hover': {
          backgroundColor: palette.grey['400'],
          border: 'none',
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: palette.primary.main,
          border: 'none',
        },
        '&: disabled': {
          backgroundColor: palette.grey['100'],
          color: addOpacityToHexColor(palette.grey['700'], 0.2),
        },
      },
      containedSecondary: {
        color: 'white',
        '&:hover': {
          backgroundColor: palette.primary.blue,
        },
      },
      outlinedError: {
        color: palette.red.main,
        backgroundColor: palette.red['10'],
        '&:hover': {
          backgroundColor: palette.error.main,
          color: 'white',
          border: 'none',
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '& .Mui-error': {
          backgroundColor: palette.error.light,
          border: `1px solid ${palette.red.main}`,
        },
      },
      notchedOutline: {
        border: 'none',
      },
      input: {
        height: 'auto',
        lineHeight: 1.5,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: 16,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: 1,
        borderRadius: 3,
        width: 24,
        height: 24,
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      list: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      outlined: {
        border: 'none',
      },
      root: {
        backgroundColor: palette.grey['400'],
        borderRadius: 4,
        border: 'none',
        '&:hover': {
          backgroundColor: palette.grey['500'],
        },
        [`.${inputBaseClasses.disabled}`]: {
          backgroundColor: palette.grey['400'],
          '&:hover': {
            backgroundColor: palette.grey['400'],
          },
        },
        '& fieldset': { border: 'none' },
        '& .Mui-error': {
          backgroundColor: palette.error.light,
          border: `1px solid ${palette.red.main}`,
        },
      },
      field: {
        border: 'none',
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        backgroundColor: palette.grey['400'],
      },
      filled: {
        '&:focus': {
          backgroundColor: 'white',
          borderRadius: 16,
        },
      },
      paper: {
        boxShadow: 'none',
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: 12,
        color: palette.text.primary,
        // marginBottom: 8,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginTop: 8,
        marginLeft: 0,
        [baseTheme.breakpoints.up('md')]: {
          fontSize: 14,
        },
        [baseTheme.breakpoints.down('md')]: {
          fontSize: 13,
        },
      },
    },
  },
};

export const theme = createTheme(baseTheme, {
  palette,
  components,
  typography: {
    fontFamily: 'Robot, sans-serif',
    h1: {
      fontFamily: 'Robot, sans-serif',
      fontSize: 24,
      fontWeight: 400,
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
    h2: {
      fontFamily: 'Robot, sans-serif',
      fontSize: 18,
      fontWeight: 400,
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    h3: {
      fontFamily: 'Robot, sans-serif',
      fontSize: 16,
      fontWeight: 400,
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
    h4: {
      fontFamily: 'Robot, sans-serif',
      fontSize: 14,
      fontWeight: 400,
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
    },
    h5: {
      fontFamily: 'Robot, sans-serif',
      fontSize: 13,
      fontWeight: 400,
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    h6: {
      fontFamily: 'Robot, sans-serif',
      fontSize: 12,
      fontWeight: 400,
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },
  },
});
