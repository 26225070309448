import { buttonClasses } from '@mui/material';

import { baseTheme, theme } from '@/constants';
import { addOpacityToHexColor } from '@/utils';

export const styles = {
  container: {
    backgroundColor: 'grey.400',
    borderRadius: 3,
    p: {
      md: 2,
      xs: 1,
    },
  },
  days: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    height: {
      md: 256,
      xs: 180,
    },
  },
  dayWrapper: {
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: { md: 'pointer', xs: 'default' },
    borderRadius: 1,
    flexGrow: 1,
  },
  day: {
    width: 'calc(100% / 7)',
    boxSizing: 'border-box',
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
  },
  dayBtn: {
    width: '100%',
    minWidth: 'auto',
  },
  dayInNextMonth: {
    width: 'calc(100% / 7)',
    boxSizing: 'border-box',
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: { md: 'pointer', xs: 'default' },
  },
  selectedDay: {
    backgroundColor: 'primary.main',
    borderRadius: 1,
    height: 32,
    '&:hover': {
      backgroundColor: addOpacityToHexColor(theme.palette.primary.main, 0.6),
      [baseTheme.breakpoints.down('md')]: {
        backgroundColor: 'primary.main',
      },
    },
    [`&.${buttonClasses.disabled}`]: {
      color: 'text.primary',
    },
  },
  noSelectedDay: {
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.500',
      },
    },
  },
  noSelectedDayWithoutHover: {
    cursor: 'auto',
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.400',
      },
    },
  },
  range: {
    cursor: { md: 'pointer', xs: 'default' },
    backgroundColor: 'primary.yellow.60',
    '&:hover': {
      backgroundColor: 'primary.yellow.60',
    },
    [`&.${buttonClasses.disabled}`]: {
      color: 'text.primary',
    },
  },
  daysOfTheWeek: {
    display: 'flex',
    justifyContent: 'space-between',
    px: 2.5,
    mt: 3,
  },
  yearAndMonth: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 1.5,
  },
};
