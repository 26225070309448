import React, { memo } from 'react';

import { Navigate } from 'react-router-dom';

import { ClientRoutes } from '@/constants';
import { useActiveSubscriber, useClientConfig, useKeycloak } from '@/context';

interface IAuthGateProps {
  children: React.ReactNode;
  protectedRoute: boolean;
  path: ClientRoutes;
}

const AuthGate: React.FC<IAuthGateProps> = memo(({ children, path, protectedRoute }) => {
  const { isLoaded, msisdn } = useKeycloak();
  const {
    config: { isMyMeloringDisplayed },
  } = useClientConfig();

  const { isUserInitLoaded, isRbtAuthorized } = useActiveSubscriber();
  const isMyMeloringPage = path === ClientRoutes.RecordMyMeloring;

  const isMeloringPageDisabled =
    (!isMyMeloringDisplayed && isMyMeloringPage) || (isLoaded && !msisdn && isMyMeloringPage);

  if (
    (!isRbtAuthorized && isUserInitLoaded && isLoaded && protectedRoute) ||
    isMeloringPageDisabled
  ) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
});

AuthGate.displayName = 'AuthGate';

export default AuthGate;
