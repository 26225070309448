export const styles = {
  mainContainer: {
    backgroundColor: 'background.default',
    minHeight: 'calc(100vh - 69px)',
    pt: 8.6,
  },
  container: {
    minHeight: 'inherit',
    display: 'flex',
  },
};
