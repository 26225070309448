import React, { FC, Suspense } from 'react';

import { Box, Card, CardContent, CircularProgress, Typography } from '@mui/material';

import { styles } from './styles';
import { useGroupItem } from './useGroupItem';

import type { PhoneGroup } from '@/graphql-schema';

import { CardEditButton, GroupItemPreview } from '@/components';
const GroupItemEdit = React.lazy(() => import('@/components/features/groups/GroupItemEdit'));

const GroupItem: FC<PhoneGroup> = (group) => {
  const { t, groupName, isSelectedItem, toggleIsEditing, openDeleteModal } = useGroupItem(group);
  return (
    <Card sx={styles.card}>
      <CardContent sx={styles.cardContent}>
        <Box sx={styles.titleContainer}>
          {isSelectedItem && (
            <Typography variant="h1" fontWeight={700}>
              {t('editGroup')}
            </Typography>
          )}
          {!isSelectedItem && (
            <Typography variant="h1" sx={styles.groupName}>
              {groupName}
            </Typography>
          )}
          <CardEditButton {...{ toggleIsEditing, openDeleteModal }} />
        </Box>
        {isSelectedItem ? (
          <Suspense fallback={<CircularProgress />}>
            <GroupItemEdit group={group} toggleIsEditing={toggleIsEditing} />
          </Suspense>
        ) : (
          <GroupItemPreview {...group} />
        )}
      </CardContent>
    </Card>
  );
};

export default GroupItem;
