import { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import type { IReduxErrorsWithStatus } from '@/types';

import { useGetAudioMutation } from '@/apis/audio';
import { LocalAudioTypes, NetworkStatusCodes } from '@/constants';

export const useAudioPlayerProvider = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isErrorNotificationDisplayed, setIsErrorNotificationDisplayed] = useState(false);
  const audioElement = useRef<null | HTMLAudioElement>(null);
  const {
    i18n: { language },
  } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState<null | string>(null);
  const [playingType, setPlayingType] = useState<LocalAudioTypes | null>(null);

  const [getAudio, { data: audioFile, error }] = useGetAudioMutation();

  const fileNotFound = (error as IReduxErrorsWithStatus)?.status === NetworkStatusCodes.NOT_FOUND;

  useEffect(() => {
    if (isPlaying) {
      audioElement.current?.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (isPlaying && playingType !== LocalAudioTypes.Content) {
      stopPlaying();
    }
  }, [playingType]);

  const removeListeners = () => {
    if (audioElement?.current) {
      audioElement.current.pause();
      audioElement.current.removeEventListener('ended', stopPlaying);
      audioElement.current = null;
    }
  };

  const stopPlaying = () => {
    if (!isPlaying) return;
    removeListeners();
    setSelectedAudio(null);
    setIsPlaying(false); // Subscription: Audio playback has ended
  };

  useEffect(() => {
    if (audioFile && selectedAudio && isPlaying) {
      const audioUrl = URL.createObjectURL(audioFile);
      audioElement.current = new Audio(audioUrl);
      audioElement.current.addEventListener('ended', stopPlaying);
      audioElement.current?.play().catch((err) => {
        if (err.name === 'NotAllowedError' && !isErrorNotificationDisplayed) {
          setIsErrorNotificationDisplayed(true);
          toast.error(t('errors.audioPlayerBrowserError'));
        }
      });
    }
    return () => {
      removeListeners();
    };
  }, [audioFile, selectedAudio, isPlaying]);

  useEffect(() => {
    if (fileNotFound) {
      toast.error(t('errors.audioFileNotFound'));
    }
  }, [fileNotFound]);

  useEffect(() => {
    setSelectedAudio(null);
    removeListeners();
  }, [location.pathname]);

  useEffect(() => {
    if (isPlaying) {
      setIsPlaying(false);
    }
  }, [language]);

  useEffect(() => {
    if (isPlaying) {
      audioElement.current?.play();
    } else {
      audioElement.current?.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (!selectedAudio) return;
    removeListeners();
    getAudio({ contentNo: selectedAudio });
  }, [selectedAudio]);

  const toggleAudioStatus = (contentNo: string) => () => {
    if (contentNo === selectedAudio) {
      if (!isPlaying) {
        setPlayingType(LocalAudioTypes.Content);
      }
      setIsPlaying(!isPlaying);
    } else {
      setSelectedAudio(contentNo);
      setPlayingType(LocalAudioTypes.Content);
      setIsPlaying(true);
    }
  };

  const changePlayingType = (type: LocalAudioTypes | null) => {
    setPlayingType(type);
  };

  return {
    playingType,
    isPlaying,
    selectedAudio,
    changePlayingType,
    stopPlaying,
    toggleAudioStatus,
  };
};
