import React, { memo } from 'react';

import { Box, IconButton, Typography } from '@mui/material';

import { styles } from './styles';
import { IHintBlock } from './type';
import { useHintBlock } from './useHintBlock';

import { CrossIcon } from '@/components';

const HintBlock: React.FC<IHintBlock> = memo(({ isHintOpen, onClose }) => {
  const { t } = useHintBlock();

  return (
    isHintOpen && (
      <Box sx={styles.hintWrapper}>
        <Box sx={styles.container}>
          <Box sx={styles.header}>
            <IconButton sx={{ height: 'fit-content' }} onClick={onClose}>
              <CrossIcon onClick={onClose} />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="h3">{t('hint.one')}</Typography>
            <Typography component="br" />
            <Typography variant="h3">{t('hint.two')}</Typography>
            <Box component="ol" m={0}>
              <Typography component="li" variant="h3">
                {t('hint.three')}
              </Typography>
              <Typography component="li" variant="h3">
                {t('hint.four')}
              </Typography>
            </Box>
            <Typography component="br" />
            <Typography variant="h3">{t('hint.five')}</Typography>
            <Box component="ol" m={0}>
              <Typography component="li" variant="h3">
                {t('hint.six')}
              </Typography>
              <Typography component="li" variant="h3">
                {t('hint.seven')}
              </Typography>
              <Typography component="li" variant="h3">
                {t('hint.eight')}
              </Typography>
              <Typography component="li" variant="h3">
                {t('hint.nine')}
              </Typography>
            </Box>
            <Typography variant="h3">{t('hint.ten')}</Typography>
            <Typography component="br" />
            <Typography variant="h3">{t('hint.eleven')}</Typography>
          </Box>
        </Box>
      </Box>
    )
  );
});

HintBlock.displayName = 'HintBlock';

export default HintBlock;
