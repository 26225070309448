import React from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';
import { useHappyHourBanner } from './useHappyHourBanner';

const HappyHourBanner = () => {
  const { t, date, amountOnetime, onetimePeriod } = useHappyHourBanner();
  if (!date) return;
  return (
    <Box sx={styles.container}>
      <Typography variant="h1" fontWeight="bold" color="secondary.main">
        {t('happyHour.title')}
      </Typography>
      <Typography variant="h3" sx={styles.bannerDescription}>
        {t('happyHour.description', { date, amountOnetime })}
        {onetimePeriod && t('happyHour.period', { onetimePeriod })}
      </Typography>
    </Box>
  );
};

export default HappyHourBanner;
