import { useTranslation } from 'react-i18next';

import { ClientRoutes } from '@/constants';
import { useLocalization } from '@/hooks';

export const useCustomModalWithLogo = () => {
  const { t } = useTranslation();
  const { openNewWindow } = useLocalization();

  const goToTermsPage = () => {
    openNewWindow(ClientRoutes.TermsAndConditions);
  };
  return {
    t,
    goToTermsPage,
  };
};
