import { useEffect, useMemo, useReducer, useRef } from 'react';
import type { ChangeEvent } from 'react';

import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import { SearchActionsEnum, initialArg, reducer, searchLimit } from './constants';

import type { ContentItem } from '@/graphql-schema';

import { ClientRoutes, removeSpacesRegExp } from '@/constants';
import { useClientConfig, useKeycloak } from '@/context';
import { GET_CONTENTS } from '@/graphqL-queries/contents';
import {
  useLocalization,
  useScreenSize,
  useDebounce,
  useMobileDrawerHook,
  useEditFormDirty,
  useGraphQlErrorHandler,
} from '@/hooks';
import { checkIfValueIsNumber } from '@/utils';

export const useSearchContent = () => {
  const { isMobileScreenView } = useScreenSize();
  const { navigate } = useLocalization();
  const { pathname } = useLocation();
  const { isLoaded } = useKeycloak();
  const {
    config: { maxSearchResults },
  } = useClientConfig();
  const { onMobileDrawerClose } = useMobileDrawerHook();

  const [{ anchorEl, searchValue }, updateSearch] = useReducer(reducer, initialArg);
  const ref = useRef<HTMLInputElement>(null);
  const menuButtonsRef = useRef<HTMLElement[]>([]);
  const debouncedValue = useDebounce(searchValue, 300);
  const { openNotSavedChangesModal } = useEditFormDirty();

  const trimmedValue = useMemo(
    () => debouncedValue.replace(removeSpacesRegExp, ' ').trim(),
    [debouncedValue],
  );

  const valueLength = trimmedValue.length;

  const isVirtNumber = valueLength === 5 || valueLength === 6;

  const isNumber = checkIfValueIsNumber(trimmedValue);

  const variables = useMemo(
    () =>
      isVirtNumber && isNumber
        ? { virtContentNo: trimmedValue }
        : { artist: trimmedValue, title: trimmedValue },
    [trimmedValue],
  );

  const { data, error } = useQuery(GET_CONTENTS, {
    skip: trimmedValue?.length < 3 || !isLoaded,
    variables: { filter: variables, pageSize: Number(maxSearchResults) },
  });

  const contents = data?.contentItems.elements;
  const isSearchButtonVisible = contents?.length ? 1 : 0;

  useGraphQlErrorHandler(error);

  useEffect(() => {
    onClear();
  }, [pathname]);

  const onSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length <= searchLimit) {
      updateSearch({ type: SearchActionsEnum.UPDATE_SEARCH, value });
      if (!anchorEl && value) {
        const updatedAnchor = e.currentTarget;
        updateSearch({ type: SearchActionsEnum.UPDATE_ANCHOR, value: updatedAnchor });
      }
    }
  };

  const onClear = () => {
    updateSearch({ type: SearchActionsEnum.CLEAR_SEARCH });
    handleClose();
  };

  const handleClose = () => {
    updateSearch({ type: SearchActionsEnum.CLEAR_INPUT });
  };

  const onContentItem = (contentItem: ContentItem | null) => {
    if (isMobileScreenView) {
      onMobileDrawerClose();
    }
    if (!openNotSavedChangesModal()) {
      onClear();
      navigate(`${ClientRoutes.Content}/${contentItem?.virtContentNo}`);
    }
  };

  const getBorderRadiusSx = () => {
    return !!anchorEl && searchValue ? '22px 22px 0 0' : 50;
  };

  const onBlur = (e: React.FocusEvent<HTMLElement>) => {
    const target = e.relatedTarget as HTMLElement;
    if (!menuButtonsRef.current.includes(target)) {
      updateSearch({ type: SearchActionsEnum.CLEAR_ANCHOR });
    }
  };

  const isDropdownVisible = !!anchorEl && !!searchValue ? 'block' : 'none';

  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && contents?.length) {
      moveToSearchResultPage();
    }
  };

  const moveToSearchResultPage = () => {
    if (isMobileScreenView) {
      onMobileDrawerClose();
    }
    if (!openNotSavedChangesModal()) {
      onClear();
      navigate(`${ClientRoutes.SearchResult}/${trimmedValue}`);
    }
  };

  return {
    contents,
    searchValue,
    trimmedValue,
    ref,
    isDropdownVisible,
    isSearchButtonVisible,
    menuButtonsRef,
    onSearchValueChange,
    onClear,
    getBorderRadiusSx,
    onContentItem,
    onBlur,
    onEnter,
    moveToSearchResultPage,
  };
};
