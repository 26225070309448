import { typographyClasses } from '@mui/material';

export const styles = {
  card: {
    mt: 2,
    borderRadius: 2,
    width: '100%',
  },
  img: {
    width: 90,
    height: 90,
    borderRadius: 8,
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'grey.400',
    p: 2,
    borderRadius: 2,
  },
  editHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'grey.100',
    p: 2,
    mb: 4,
  },
  description: {
    ml: 2,
    maxWidth: {
      xs: 100,
      md: 200,
    },
    [`.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      wordWrap: 'break-word',
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'grey.100',
    mt: 2,
    p: 1,
    borderRadius: 2,
  },
  value: {
    width: 270,
    height: 35,
    background: '#FFC40C',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    height: 'fit-content',
  },
  btnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
};
