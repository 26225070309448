import React from 'react';

import { SvgIcon } from '@mui/material';

import styles from '../styles';

import type { SvgIconProps } from '@mui/material';

import { mergeSx } from '@/utils';

const TelegramIcon = ({
  viewBox = '0 0 19 18',
  sx = {},
  width = '19',
  height = '18',
  fill = '#0D4BA0',
  ...rest
}: SvgIconProps) => (
  <SvgIcon
    viewBox={viewBox}
    sx={mergeSx(styles.root, sx)}
    width={width}
    height={height}
    fill="none"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.333 9C18.333 13.9706 14.3036 18 9.33301 18C4.36245 18 0.333008 13.9706 0.333008 9C0.333008 4.02944 4.36245 0 9.33301 0C14.3036 0 18.333 4.02944 18.333 9ZM6.35983 8.17557C5.45826 8.5692 4.53191 8.97366 3.68485 9.44023L3.68479 9.44031C3.24249 9.76414 3.8304 9.99319 4.3819 10.2081C4.46958 10.2422 4.55634 10.276 4.63789 10.3098C4.70575 10.3306 4.77477 10.3526 4.8447 10.3749C5.45808 10.5703 6.14204 10.7882 6.73752 10.4604C7.71573 9.89851 8.63888 9.24884 9.56133 8.59967C9.86355 8.38699 10.1657 8.17436 10.4697 7.96488C10.4839 7.95577 10.5 7.94535 10.5175 7.93397C10.7765 7.7661 11.3589 7.38859 11.1435 7.90877C10.6341 8.46587 10.0884 8.95903 9.5398 9.45487C9.17007 9.78903 8.79899 10.1244 8.43673 10.4814C8.12122 10.7378 7.79361 11.2533 8.14689 11.6123C8.9606 12.1819 9.78703 12.7378 10.613 13.2933C10.8817 13.474 11.1505 13.6548 11.4187 13.8359C11.8733 14.1989 12.5837 13.9053 12.6836 13.3382C12.7281 13.0773 12.7727 12.8164 12.8173 12.5554C13.0639 11.1137 13.3106 9.67148 13.5285 8.22505C13.5581 7.99817 13.5917 7.77129 13.6253 7.54429C13.7067 6.99412 13.7882 6.44328 13.8137 5.89015C13.7481 5.33818 13.0788 5.45954 12.7063 5.58366C10.7919 6.31213 8.89653 7.09461 7.0087 7.89088C6.79485 7.98563 6.57806 8.08028 6.35983 8.17557Z"
      fill={fill}
    />
  </SvgIcon>
);

export default TelegramIcon;
