const palette = {
  primary: {
    main: '#FFC40C',
    blue: '#0D4BA0',
    yellow: {
      '60': '#FFC40C99',
    },
    soft: {
      blue: {
        '150': '#0D4BA026',
      },
    },
  },
  secondary: {
    main: '#0D4BA0',
  },
  blue: {
    '50': '#0D4BA0',
    '100': '#11223C',
    '200': '#0D4BA0',
    '300': '#F3F6FA',
    '400': '#0D4BA026',
  },
  grey: {
    dark: '#808080',
    main: '#000000CC',
    overlay: '#0000004D',
    '50': '#333333',
    '100': '#FAFAFA',
    '200': '#F7F7F7',
    '300': '#F4F4F4',
    '400': '#F2F2F2',
    '500': '#EAEAEA',
    '600': '#DEDEDE',
    '700': '#33333333',
    '800': '#FFFFFF33',
    '900': '#00000026',
  },
  background: {
    default: '#F4F4F4',
  },
  text: {
    primary: '#11223C',
    secondary: '#808080',
  },
  white: '#FFFFFF',
  light: {
    blue: '#F3F6FA',
  },
  red: {
    main: '#FF3A44',
    '10': '#FF3A441A',
  },
  error: {
    main: '#FF3A44',
    light: '#FF3A441A',
  },
  shadows: {
    main: '#0000000D',
    secondary: '#11223c24',
  },
};

export default palette;
