import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useClientConfig } from '@/context';
import { isFileSizeLarger } from '@/utils';

const useFileUploadLimit = (file: Blob | null) => {
  const { t } = useTranslation();
  const {
    config: {
      audioRecorder: { audioUploadSize },
    },
  } = useClientConfig();

  const fileSizeLimit = useMemo(() => {
    const size = Number(audioUploadSize);
    if (size >= 1024) {
      const mb = Math.floor(size / 1024);
      const kbRemainder = size % 1024;
      return mb + t('mb') + ' ' + (kbRemainder ? kbRemainder + t('kb') : '');
    } else {
      return size + t('kb');
    }
  }, [t, audioUploadSize]);

  const isSizeLarger = useMemo(() => {
    if (file) {
      return isFileSizeLarger(file.size, audioUploadSize);
    }
  }, [file, audioUploadSize]);

  return { isSizeLarger, fileSizeLimit };
};

export default useFileUploadLimit;
