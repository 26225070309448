export const initialValues = {
  time: null,
  day: null,
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
};

export enum DateParamsType {
  Time = 'time',
  Day = 'day',
  Year = 'year',
  Month = 'month',
}

export const getDaysFromNextMonth = (days: [string, number][]): number[] => {
  const array: number[] = [];

  for (let i = 1; i <= 35 - days.length; i++) {
    array.push(i);
  }

  return array;
};
