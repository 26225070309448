import type { IClientConfigType } from '@/types';

export const defaultClientConfig: IClientConfigType = {
  keycloak: {
    api: null,
    realm: null,
    clientId: null,
  },
  audioRecorder: {
    audioMaxDuration: null,
    audioUploadSize: null,
  },
  actionListCd: null,
  parentCategoryId: null,
  parentPromoId: null,
  isTestMode: false,
  testMsisdn: null,
  phoneNumberCode: null,
  phoneNumberForSms: null,
  picturePathWeb: null,
  maxSearchResults: null,
  periodHistory: null,
  mobileAppAndroidUrl: null,
  mobileAppIosUrl: null,
  textInFooter: null,
  textInFooterEn: null,
  virtNoBanner1: null,
  virtNoBanner2: null,
  virtNoBanner3: null,
  virtNoBanner4: null,
  urlsForBanner: null,
  textForSocialMedia: null,
  textForSocialMediaEn: null,
  historyIntervalInDays: null,
  historyPageSize: null,
  maxPlayConditions: null,
  maxPhoneGroups: null,
  maxPhoneNumbers: null,
  buildVersion: null,
  displayBuildVersion: false,
  defaultCatalogCategory: null,
  isMyMeloringDisplayed: true,
  contentPicDefault: null,
  contentDiyDefault: null,
  docPathWeb: null,
  docFormat: null,
  notificationVisibilityTime: null,
};
