import React, { memo } from 'react';

import { Pause } from '@mui/icons-material';
import { Box } from '@mui/material';

import { styles } from './styles';

import { PlayIcon } from '@/components';
import { ClientRoutes } from '@/constants';
import { useAudioPlayer } from '@/context';
import {
  ContentItem,
  InboxContentItem,
  GiftBoxContentItem,
  Setup,
  Charge,
  ComplexTypeEnum,
} from '@/graphql-schema';
import { useLocalization } from '@/hooks';
import { useGetContentImage } from '@/hooks/useGetContentImage';

interface IPlayContentImageProps {
  isSlider?: boolean;
  isPreview?: boolean;
  size?: number;
  contentItem?: ContentItem | InboxContentItem | GiftBoxContentItem | Setup | Charge;
  isOverlayVisible?: boolean;
}

const PlayContentImage: React.FC<IPlayContentImageProps> = memo(
  ({ isSlider = false, isPreview = false, isOverlayVisible = false, size = 60, contentItem }) => {
    const { navigate } = useLocalization();
    const { selectedAudio, isPlaying, toggleAudioStatus } = useAudioPlayer();
    const isCurrentPlaying = selectedAudio === contentItem?.contentNo && isPlaying;

    const imgUrl = useGetContentImage(contentItem as ContentItem);

    const complexType = (contentItem as ContentItem)?.complexType;

    const isComplexContent = !!complexType && complexType !== ComplexTypeEnum.Single;

    const getOverlaySx = () => (isCurrentPlaying || isOverlayVisible ? 1 : 0);

    const goToContentPage = () => {
      if (isComplexContent && !isSlider) {
        navigate(`${ClientRoutes.Content}/${contentItem?.virtContentNo}`);
      }
    };

    return (
      <Box
        sx={[!isPreview ? styles.imgContainerPlay : { width: size, height: size }]}
        onClick={!isPreview ? toggleAudioStatus(contentItem?.contentNo) : goToContentPage}
      >
        <img
          alt={`${contentItem?.title}`}
          src={imgUrl}
          style={{
            ...styles.imgPreview,
            cursor: isComplexContent ? 'pointer' : 'auto',
            width: size,
            height: size,
          }}
        />
        {!isPreview && (
          <Box sx={{ ...styles.overlay, width: size, height: size, opacity: getOverlaySx() }}>
            {isCurrentPlaying ? <Pause sx={styles.pauseIcon} /> : <PlayIcon sx={styles.playIcon} />}
          </Box>
        )}
      </Box>
    );
  },
);

PlayContentImage.displayName = 'PlayContentImage';

export default PlayContentImage;
