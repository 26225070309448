import { typographyClasses } from '@mui/material';

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  head: {
    display: 'flex',
    mb: 3,
    gap: 2,
  },
  imgPreview: {
    width: 120,
    height: 120,
    borderRadius: 8,
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: 'calc(100% - 136px)',
    [`.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      wordWrap: 'break-word',
    },
  },
  imgContainer: {
    position: 'relative',
    '&:hover': {
      '.MuiBox-root': {
        opacity: '1 !important',
        pointerEvents: 'all !important',
      },
    },
  },
  descriptionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    p: 1.5,
    backgroundColor: 'grey.100',
    borderRadius: 1,
  },
  descriptionText: {
    textAlign: 'right',
  },
  descriptionTitle: {
    mr: 2,
  },
  purchaseDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    mt: 3,
  },
  itemArtist: {
    cursor: 'pointer',
  },
  delete: {
    mt: 2,
    height: 43,
    boxSizing: 'border-box',
  },
  playBtn: {
    width: 120,
  },
} as const;
