export const allowedAudioFormats = ['audio/mpeg', 'audio/mp3', 'audio/wav', 'audio/x-m4a'];

const extensions = ['.mp3', '.wav', '.m4a'];
export const getFileNameWithoutExtension = (fileName: string) => {
  for (const item of extensions) {
    const splitted = fileName.split(item);
    if (splitted.length > 1) return splitted[0];
  }
  return fileName;
};
