import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import styles from '../styles';

import { theme } from '@/constants';
import { mergeSx } from '@/utils';

function PersonIcon({
  viewBox = '0 0 24 25',
  fill = theme.palette.text.primary,
  sx = {},
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.5C9.79 4.5 8 6.29 8 8.5C8 10.71 9.79 12.5 12 12.5C14.21 12.5 16 10.71 16 8.5C16 6.29 14.21 4.5 12 4.5ZM14 8.5C14 7.4 13.1 6.5 12 6.5C10.9 6.5 10 7.4 10 8.5C10 9.6 10.9 10.5 12 10.5C13.1 10.5 14 9.6 14 8.5ZM18 18.5C17.8 17.79 14.7 16.5 12 16.5C9.31 16.5 6.23 17.78 6 18.5H18ZM4 18.5C4 15.84 9.33 14.5 12 14.5C14.67 14.5 20 15.84 20 18.5V20.5H4V18.5Z"
        fill={fill}
      />
    </SvgIcon>
  );
}

export default PersonIcon;
