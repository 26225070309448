const styles = {
  item: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  labelContainer: {
    display: "flex",
    height: 22,
    mt: 1,
  },
  label: {
    py: 0.5,
    px: 0.75,
    backgroundColor: 'primary.soft.blue.150',
    borderRadius: 1,
  },
};

export { styles };
