import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Localizations } from '@/constants';
import { useClientConfig } from '@/context';

export const useFooter = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    config: {
      textInFooter,
      textInFooterEn,
      mobileAppIosUrl,
      mobileAppAndroidUrl,
      buildVersion,
      displayBuildVersion,
    },
  } = useClientConfig();

  const textInFooterLocal = useMemo(
    () => (language === Localizations.UK ? textInFooter : textInFooterEn),
    [language, textInFooter, textInFooterEn],
  );

  return {
    t,
    buildVersion,
    textInFooterLocal,
    mobileAppIosUrl,
    mobileAppAndroidUrl,
    displayBuildVersion,
  };
};
