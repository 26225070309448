import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { scroller } from 'react-scroll';

import type { ContentCategory } from '@/graphql-schema';

import { ClientRoutes } from '@/constants';
import { useEditFormDirty, useLocalization, useMobileDrawerHook, useScreenSize } from '@/hooks';
import { setOffsetLoading } from '@/redux/offset';

export const useCategoryItem = ({
  contentCatId,
}: Pick<ContentCategory, 'contentCatId' | 'catName'>) => {
  const dispatch = useDispatch();
  const { isMobileScreenView } = useScreenSize();
  const { transform } = useLocalization();
  const { navigate } = useLocalization();
  const { categoryId } = useParams();
  const { onMobileDrawerClose } = useMobileDrawerHook();
  const { openNotSavedChangesModal } = useEditFormDirty();

  const scrollToCatalogList = () => {
    if (!isMobileScreenView) return;
    scroller.scrollTo('catalog-container', {
      duration: 700,
      delay: 200,
      smooth: true,
      offset: -180,
    });
  };

  const onCategoryClick = () => {
    if (contentCatId === categoryId) {
      navigate(ClientRoutes.Catalog);
      scrollToCatalogList();
      return;
    }

    if (!openNotSavedChangesModal()) {
      dispatch(setOffsetLoading(true));
      navigate(`${ClientRoutes.Catalog}/${contentCatId}`);
    }
    if (isMobileScreenView) {
      onMobileDrawerClose();
    }
    scrollToCatalogList();
  };

  return { transform, categoryId, onCategoryClick };
};
