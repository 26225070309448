import { accordionSummaryClasses } from '@mui/material';

import { baseTheme, theme } from '@/constants';

export const styles = {
  container: {},
  content: {
    mt: {
      md: 0,
      xs: 3,
    },
  },
  accordion: {
    backgroundColor: 'grey.400',
    width: '100%',
    borderRadius: 2,
    position: 'inherit',
    cursor: { md: 'pointer', xs: 'default' },
  },
  navigation: {
    display: 'flex',
    flexDirection: 'column',
    mt: {
      xs: 2,
      md: 4,
    },
  },
  publicNavigation: {
    gap: 1.25,
  },
  item: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    py: 1.5,
    cursor: { md: 'pointer', xs: 'default' },
    px: 2,
  },
  withHover: {
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: theme.palette.grey['500'],
        color: 'black',
      },
    },
  },
  selected: {
    backgroundColor: 'secondary.main',
    color: 'white',
    '&:hover': {
      backgroundColor: 'secondary.main',
      color: 'white',
    },
  },
  selectedLast: {
    backgroundColor: 'secondary.main',
    color: 'white',
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  summary: {
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: theme.palette.grey['500'],
        color: 'black',
      },
    },
    [`&.${accordionSummaryClasses.root}`]: {
      height: 48,
      minHeight: 48,
      maxHeight: 48,
    },
    [`. ${accordionSummaryClasses.content}`]: {
      margin: 0,
    },
  },
  summaryContent: {
    display: 'flex',
    alignItems: 'center',
  },
  details: {
    p: 0,
  },
  title: {
    width: 200,
    ml: 1.5,
  },
  link: {
    cursor: 'pointer',
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: theme.palette.grey['500'],
        color: 'black',
      },
    },
    color: 'text.primary',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    py: 1.5,
    px: 2,
    backgroundColor: theme.palette.grey['400'],
  },
};
