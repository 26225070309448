import React, { useMemo } from 'react';

import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { styles } from './styles';

import { ComplexTypeEnum, Maybe } from '@/graphql-schema';
import { getComplexLabel } from '@/utils';

interface IComplexCardLabelProps {
  isPurchaseModal?: boolean;
  complexType: Maybe<ComplexTypeEnum> | undefined;
}

const ComplexCardLabel: React.FC<IComplexCardLabelProps> = ({
  complexType,
  isPurchaseModal = false,
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  const complexLabel = useMemo(() => {
    if (!complexType) return null;
    return getComplexLabel(complexType);
  }, [language, complexType]);

  return (
    <Chip label={complexLabel} sx={{ ...styles.complexType, mb: isPurchaseModal ? 0 : 1.75 }} />
  );
};

export default ComplexCardLabel;
