import { typographyClasses } from '@mui/material';

import palette from '@/constants/theme/palette';

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  head: {
    display: 'flex',
    mb: 3,
    gap: 2,
  },
  imgPreview: {
    width: 120,
    height: 120,
    borderRadius: 8,
  },
  buttonIcon: {
    borderRadius: 2,
    boxShadow: '0 4px 24px 0 ' + palette.shadows.main,
    minWidth: 57,
    px: 0,
    py: 1,
  },
  smsNumber: {
    mt: 'auto',
    px: 1,
    py: 0.25,
    backgroundColor: 'grey.200',
    borderRadius: 4,
    span: {
      color: 'secondary.main',
    },
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    maxWidth: 'calc(100% - 136px)',
  },
  severalCards: {
    [`.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      wordWrap: 'break-word',
    },
  },
  oneCard: {
    [`.${typographyClasses.root}`]: { wordWrap: 'break-word' },
  },
  imgContainer: {
    position: 'relative',
    '&:hover': {
      '.MuiBox-root': {
        opacity: '1 !important',
        pointerEvents: 'all !important',
      },
    },
  },
  overlay: {
    borderRadius: 8,
    backgroundColor: palette.grey.overlay,
    position: 'absolute',
    width: 120,
    height: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transition: 'opacity 250ms linear',
    top: 0,
    cursor: { md: 'pointer', xs: 'default' },
    pointerEvents: 'none',
  },
  purchaseButton: {
    width: '100%',
    justifyContent: 'space-between',
    px: 2,
    py: 1.5,
    backgroundColor: 'primary.blue',
    '&:hover': {
      backgroundColor: 'primary.blue',
    },
  },
  purchaseText: {
    textTransform: 'uppercase',
    color: 'white',
  },
  descriptionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    p: 1.5,
    backgroundColor: 'grey.100',
    borderRadius: 1,
  },
  purchaseDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    mt: 3,
  },
  orderButtonContainer: {
    mt: 2,
  },
  isOneLineRow: {
    [`&.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical',
      wordBreak: 'break-word',
    },
  },
  areTwoRows: {
    overflow: 'hidden',
    [`&.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      wordBreak: 'break-word',
    },
  },
  itemTitle: {
    cursor: 'pointer',
    fontWeight: 700,
    color: 'blue.100',
    mb: 1,
  },
  itemArtist: {
    cursor: 'pointer',
    fontWeight: 400,
    color: 'grey.dark',
  },
} as const;
