import { createContext, useContext } from 'react';

import Keycloak from 'keycloak-js';

interface KeycloakContextType {
  keycloak: Keycloak | null;
  msisdn: string | null;
  isLoaded: boolean;
}

export const KeycloakContext = createContext<KeycloakContextType>({
  keycloak: null,
  msisdn: null,
  isLoaded: false,
});

export const useKeycloak = () => {
  return useContext(KeycloakContext);
};
