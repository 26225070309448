import { createSlice } from '@reduxjs/toolkit';

import { IPermissionServiceState } from './types';

const initialState: IPermissionServiceState = {
  isModelOpen: false,
  isChecked: false,
};

export const permissionServiceSlice = createSlice({
  name: 'permissionService',
  initialState,
  reducers: {
    openPermissionServiceModal: (state) => {
      state.isModelOpen = true;
    },
    closePermissionServiceModal: (state) => {
      state.isModelOpen = false;
    },
    setCheck: (state, { payload }) => {
      state.isChecked = payload;
    },
  },
});

export const { openPermissionServiceModal, closePermissionServiceModal, setCheck } =
  permissionServiceSlice.actions;

export default permissionServiceSlice.reducer;
