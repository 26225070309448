export enum SetupType {
  B = 'B',
  G = 'G',
  N = 'N',
  S = 'S',
}

export enum SetupSubtype {
  GIFT_ACCEPT = 'GIFT_ACCEPT',
  INSERT = 'INSERT',
  DELETE = 'DELETE',
}
