import React from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

const InfoCardWithTime = ({ title = '', date = '', time = '' }: IInfoCardWithTimeProps) => {
  return (
    <Box sx={styles.container}>
      <Typography variant="h3" color="text.primary">
        {title}
      </Typography>
      <Box sx={styles.dateAndTime}>
        <Typography variant="h3" color="text.primary">
          {date}
        </Typography>
        <Typography variant="h3" color="text.secondary">
          {time}
        </Typography>
      </Box>
    </Box>
  );
};

export default InfoCardWithTime;
