import { buttonGroupClasses, typographyClasses } from '@mui/material';

import { baseTheme } from '@/constants';
import { addOpacityToHexColor } from '@/utils';

const styles = {
  buttonGroups: {
    flexWrap: 'wrap',
    [`.${buttonGroupClasses.grouped}`]: {
      width: {
        sm: '25%',
        xs: '50%',
      },
      py: 1.5,
      [baseTheme.breakpoints.up('md')]: {
        '&:hover': {
          backgroundColor: addOpacityToHexColor('#FFFFFF', 0.2),
        },
      },
      [baseTheme.breakpoints.down('md')]: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  active: {
    backgroundColor: 'white',
    [`& .${typographyClasses.root}`]: {
      color: 'primary.blue',
    },
    '&:hover': {
      backgroundColor: 'white !important',
    },
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    color: 'white',
    fontWeight: 500,
  },
  noActive: {
    background: 'transparent',
    boxSizing: 'border-box',
  },
  disabled: {
    '&:hover': {
      cursor: 'default',
      backgroundColor: 'transparent !important',
    },
  },
  first: {
    border: '2px solid white',
    borderRight: '0px !important',
    boxSizing: 'border-box',
    borderTopLeftRadius: '10px !important',
    borderBottomLeftRadius: {
      sm: '10px',
      xs: '0px',
    },
    '&:hover': {
      border: '2px solid white',
    },
  },
  second: {
    border: '2px solid white',
    borderRight: {
      sm: '0px solid white !important',
      xs: '2px solid white !important',
    },
    boxSizing: 'border-box',
    borderRadius: 0,
    borderTopRightRadius: {
      sm: '0px !important',
      xs: '10px !important',
    },
    '&:hover': {
      border: '2px solid white',
    },
  },
  third: {
    border: '2px solid white',
    borderRight: '0px !important',
    borderTop: {
      xs: 'none',
      sm: '2px solid white',
    },
    boxSizing: 'border-box',
    borderBottomLeftRadius: {
      xs: '10px !important',
      sm: '0px !important',
    },
    marginLeft: {
      xs: '0px !important',
      sm: -1,
    },
    '&:hover': {
      border: '2px solid white',
      borderTop: {
        sm: '2px solid white',
        xs: 'none',
      },
    },
  },
  forth: {
    border: '2px solid white',
    borderTop: {
      xs: 'none',
      sm: '2px solid white',
    },
    boxSizing: 'border-box',
    borderRadius: 0,
    borderBottomRightRadius: '10px',
    borderTopRightRadius: {
      sm: '10px',
      xs: '0px',
    },
    '&:hover': {
      border: '2px solid white',
      borderTop: {
        sm: '2px solid white',
        xs: 'none',
      },
    },
  },
};

export { styles };
