import { createSelector } from '@reduxjs/toolkit';

import type { IGroupState } from './types';
import type { RootState } from '@/redux';

export const groupsSelector = (state: RootState) => state.groups;

export const isCreateGroupOpenSelector = createSelector(
  groupsSelector,
  (state: IGroupState) => state.isCreateGroupOpen,
);
