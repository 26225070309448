import React from 'react';

import { Box, type BoxProps } from '@mui/material';

import { styles } from './styles';

import { mergeSx } from '@/utils';

const ContainerRulesAndGroups = ({ children, sx, ...props }: BoxProps) => (
  <Box sx={mergeSx(styles.main, sx)} {...props}>
    {children}
  </Box>
);

export default ContainerRulesAndGroups;
