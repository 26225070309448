import { ClientRoutes } from '@/constants';

export const getPathSlices = (path: string) => {
  const currentPathSlices = path.split('/');
  const search = currentPathSlices?.[2];
  const searchValue = decodeURIComponent(search);
  const isSearchResultPage = '/' + currentPathSlices?.[1] === ClientRoutes.SearchResult;

  return { searchValue, isSearchResultPage };
};
