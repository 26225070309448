import { PlayCondition, TimeRuleTypeEnum } from '@/graphql-schema';
import { formatDateFromTimestamp, getCurrentDate, getTimestampFromDate } from '@/utils';

const splitStringByComma = (str: string) => str.split(',');

export const extractDaysFromWeekValue = (value: string): number[] =>
  splitStringByComma(value).map((item) => parseInt(item.trim()?.[0]));

export const convertNumbersToTimeString = (value: string): string => {
  const cleanedValue = value.replace(/:/g, '');
  return cleanedValue.length > 2
    ? cleanedValue.slice(0, 2) + ':' + cleanedValue.slice(2)
    : cleanedValue;
};

export const combineTimeAndDaysInWeekValue = (time: string, days: number[]): string => {
  const [start, end] = time.split('~');
  return days.map((day) => `${day} ${start}~${day} ${end}`).join(',');
};

export const getSplittedDates = (value: string): string[] =>
  splitStringByComma(value)?.[0].split('~');

export const getCurrentCentury = () => {
  const currentYear = new Date().getFullYear();
  return Math.ceil(currentYear / 100) - 1;
};

export const getMonthNumberFromDate = (value: string, isOneTime: boolean): number => {
  const monthStr = value.split(' ')?.[0].split('-')?.[isOneTime ? 1 : 0];
  return parseInt(monthStr, 10);
};

export const getYearFromDate = (value: string, isOneTime: boolean): number => {
  if (!isOneTime) return new Date().getFullYear();
  const yearStr = value.split(' ')?.[0].split('-')?.[0];
  const century = getCurrentCentury();
  return parseInt(century + yearStr, 10);
};

export const getTimeFromDate = (value: string): string =>
  getSplittedDates(value)
    .map((item) => item.split(' ')[1])
    .join('~');

export const parseDateValueToFormikValues = (
  value: string,
  isOneTime: boolean,
  isFirstRender: boolean,
  isSecondClickedDayLater: boolean,
) => {
  const [firstDate, endDate] = getSplittedDates(value);
  const actualDate = isFirstRender ? firstDate : isSecondClickedDayLater ? firstDate : endDate;

  return {
    day: null,
    year: getYearFromDate(actualDate, isOneTime),
    month: getMonthNumberFromDate(actualDate, isOneTime),
    time: getTimeFromDate(value),
  };
};

export const extractDaysRange = (value: string, type: TimeRuleTypeEnum): number[] => {
  const dateArray = value.split('~').map((item) => item.split(' ')[0]);

  if (type === TimeRuleTypeEnum.Monthly) {
    return dateArray.map((item) => parseInt(item));
  }

  if (type === TimeRuleTypeEnum.Yearly) {
    return dateArray.map((date) => {
      const [month, day] = date.split('-');
      const year = new Date().getFullYear();
      return new Date(year, parseInt(month) - 1, parseInt(day), 0, 0, 0, 0).getTime();
    });
  }

  if (type === TimeRuleTypeEnum.OneTime) {
    return dateArray.map((date) => {
      const [year, month, day] = date.split('-');
      const parsedDate = new Date(
        2000 + parseInt(year),
        parseInt(month) - 1,
        parseInt(day),
        0,
        0,
        0,
        0,
      );
      return parsedDate.getTime();
    });
  }

  return [];
};

export const genTimeRangeStr = (
  ranges: number[][],
  time: string,
  isTimestamp = false,
  isOneTime = false,
) => {
  const [startTime, endTime] = time.split('~');

  return ranges.reduce((acum, [start, end]) => {
    const formattedStart = isTimestamp ? formatDateFromTimestamp(start, isOneTime) : start;
    const formattedEnd = isTimestamp ? formatDateFromTimestamp(end, isOneTime) : end;
    return `${acum}${formattedStart} ${startTime}~${formattedEnd} ${endTime}`;
  }, '');
};

export const formatDateRangeToPointFormat = (date = '', calendarType: TimeRuleTypeEnum) =>
  date
    ?.split('~')
    .map((item) => item.split(' ')?.[0])
    .map((item) => {
      const dateArray = item.split('-');
      if (calendarType === TimeRuleTypeEnum.Yearly) {
        const [month, day] = dateArray;
        return `${day}.${month}`;
      } else {
        const [year, month, day] = dateArray;
        return `${day}.${month}.20${year}`;
      }
    }) ?? ['', ''];

export const generateDaysMap = (year: number, month: number, isTimeStamp: boolean) => {
  const map: { [key: number]: number } = {};
  const daysCount = isTimeStamp ? new Date(year, month, 0).getDate() : 31;

  for (let i = 1; i <= daysCount; i++) {
    map[i] = isTimeStamp ? getTimestampFromDate(year, month, i) : i;
  }

  return Object.entries(map);
};

export const isClickedDaySelected = (ranges: number[][], day: number): boolean =>
  ranges.some((range) => range.includes(day));

export const isClickedDayInRange = (ranges: number[][], day: number): boolean =>
  ranges.some(([start, end]) => day > start && day < end);

export const getDaysFromPrevMonth = (
  firstDayInCurrentMonth: number,
  year: number,
  month: number,
) => {
  const prevDaysDisplayingCount = new Date(firstDayInCurrentMonth).getUTCDay();
  const daysInPrevMonth = new Date(year, month, 0).getDate();
  const result = [];

  for (let i = daysInPrevMonth; i > daysInPrevMonth - prevDaysDisplayingCount; i--) {
    result.push(i);
  }

  return result.reverse();
};

export const isTimeOutdated = (time: string) => {
  const [hour, minutes] = time.split(':');
  const currentTime = new Date();
  const currentHour = currentTime.getHours();
  const currentMinutes = currentTime.getMinutes();
  return (
    parseInt(hour) < currentHour ||
    (parseInt(hour) === currentHour && parseInt(minutes) < currentMinutes)
  );
};

export const isCurrentDay = (date: string) => {
  const endDate = getSplittedDates(date ?? '')[1];
  const [year, month, day] = endDate.split('-');
  const { currentMonth, currentYear, currentDay } = getCurrentDate();
  return (
    parseInt(day, 10) === currentDay &&
    parseInt(month) === currentMonth &&
    parseInt('20' + year) === currentYear
  );
};

const isValidTime = (time: string) => /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time);

export const isEndTimeOutdated = (date: string) => {
  if (!date.length) return false;
  const [startTime, endTime] = getTimeFromDate(date ?? '').split('~');

  if (!isValidTime(endTime) || !isValidTime(startTime)) return false;
  if (isCurrentDay(date) && isTimeOutdated(endTime)) return true;

  const endDate = getSplittedDates(date ?? '')[1];
  const splittedDate = endDate.split('-');
  const year = parseInt('20' + splittedDate[0]);
  const month = parseInt(splittedDate[1]);
  const day = parseInt(splittedDate[2], 10);

  const { currentMonth, currentYear, currentDay } = getCurrentDate();

  const isNextMonth = month > currentMonth;
  const isNextYear = year > currentYear;

  const isCurrentOrNextYear = year >= currentYear;
  const isCurrentOrNextMonth = month >= currentMonth;
  const isCurrentOrNextDay = day >= currentDay;

  return !(
    (isCurrentOrNextDay || isNextMonth || isNextYear) &&
    (isCurrentOrNextMonth || isNextYear) &&
    isCurrentOrNextYear
  );
};

const isRuleOneTimeAndOutdated = (rule: PlayCondition) =>
  rule?.tmType === TimeRuleTypeEnum.OneTime && isEndTimeOutdated(rule.tmParams as string);

export const sortPlayRulesWithOutdatedTimes = (
  prevRule: PlayCondition,
  nextRule: PlayCondition,
) => {
  const isPrevRuleOutdated = isRuleOneTimeAndOutdated(prevRule);
  const isNextRuleOutdated = isRuleOneTimeAndOutdated(nextRule);

  if (isPrevRuleOutdated && !isNextRuleOutdated) {
    return 1;
  } else if (isNextRuleOutdated && !isPrevRuleOutdated) {
    return -1;
  } else {
    return 0;
  }
};
