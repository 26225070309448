import React from 'react';

import { SvgIcon } from '@mui/material';

import type { SvgIconProps } from '@mui/material';

import styles from '@/components/shared/icons/styles';
import { theme } from '@/constants';
import { mergeSx } from '@/utils';

export const HistoryIcon = ({
  viewBox = '0 0 24 24',
  fill = theme.palette.primary.main,
  sx = {},
  ...rest
}: SvgIconProps) => (
  <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 12C4.5 7.03 8.53 3 13.5 3C18.47 3 22.5 7.03 22.5 12C22.5 16.97 18.47 21 13.5 21C11.01 21 8.77 19.99 7.14 18.36L8.56 16.94C9.82 18.21 11.57 19 13.5 19C17.37 19 20.5 15.87 20.5 12C20.5 8.13 17.37 5 13.5 5C9.63 5 6.5 8.13 6.5 12H9.5L5.46 16.03L5.39 15.89L1.5 12H4.5ZM12.5 13V8H14V12.15L17.52 14.24L16.75 15.52L12.5 13Z"
      fill={fill}
    />
  </SvgIcon>
);
