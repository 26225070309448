import React, { memo } from 'react';

import { useErrorPurchaseModal } from './useErrorPurchaseModal';

import type { IErrorPurchaseModal } from './types';

import { CustomConfirmModal } from '@/components';

const ErrorPurchaseModal = memo((props: IErrorPurchaseModal) => {
  const modalProps = useErrorPurchaseModal(props);

  return <CustomConfirmModal {...modalProps} />;
});

ErrorPurchaseModal.displayName = 'ErrorPurchaseModal';

export default ErrorPurchaseModal;
