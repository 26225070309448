import { string, object } from 'yup';

export const getDailySchema = () =>
  object().shape({
    startDate: string()
      .required('formNotifications.wrongTimeFormat')
      .matches(/^(?:[01]\d|2[0-3]):(?:[0-5]\d)$/, 'formNotifications.wrongTimeFormat'),
    endDate: string()
      .required('formNotifications.wrongTimeFormat')
      .matches(/^(?:[01]\d|2[0-3]):(?:[0-5]\d)$/, 'formNotifications.wrongTimeFormat'),
  });
