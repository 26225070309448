import React from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { styles } from './styles';
import { useCustomModalWithLogo } from './useCustomModalWithLogo';

import { LogoIcon, ButtonCross } from '@/components';

interface ICustomModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  handleSubmit?: () => void;
  submitBtnText?: string;
  loading?: boolean;
}

const CustomModalWithLogo = ({
  children,
  isOpen,
  onClose,
  title,
  handleSubmit,
  submitBtnText,
  loading,
}: ICustomModalProps) => {
  const { t, goToTermsPage } = useCustomModalWithLogo();
  return (
    <Dialog open={isOpen} onClose={onClose} sx={styles.dialog}>
      <form onSubmit={handleSubmit} style={styles.form}>
        <DialogTitle sx={styles.title}>
          <Box sx={styles.logoBox}>
            <LogoIcon />
            <ButtonCross onClick={onClose} />
          </Box>
          <Typography variant="h1" color="grey.main">
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent sx={styles.content}>{children}</DialogContent>
        <DialogActions sx={styles.action}>
          <Button sx={styles.button} type="submit" variant="contained" fullWidth disabled={loading}>
            <Typography variant="h4">{submitBtnText}</Typography>
          </Button>
          <Box sx={styles.footer}>
            <Box sx={styles.terms} onClick={goToTermsPage}>
              <Typography variant="h6" color="text.secondary">
                {t('componentModalBuy.termsOfUse')}
              </Typography>
            </Box>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CustomModalWithLogo;
