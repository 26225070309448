import React from 'react';

import { Box, type BoxProps } from '@mui/material';

import { styles } from './styles';

import { mergeSx } from '@/utils';

const ContainerContentItems = ({ children, sx, ...props }: BoxProps) => {
  return (
    <Box sx={mergeSx(styles.main, sx)} {...props}>
      {children}
    </Box>
  );
};

export default ContainerContentItems;
