import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import type { PhoneGroup } from '@/graphql-schema';

import { groupsSelector } from '@/redux/groups/selectors';
import { openDeleteGroupModal, selectGroupForEditing } from '@/redux/groups/slice';
import { notSavedChangesSelectors, NotSavedListType } from '@/redux/not-saved-changes';
import { openNotSavedModal, clearFormGroupDirty } from '@/redux/not-saved-changes/slice';

export const useGroupItem = (group: PhoneGroup) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { groupName } = group;
  const { isFormGroupDirty, listType } = useSelector(
    notSavedChangesSelectors.notSavedChangesSelector,
  );
  const { selectedGroupForEditing } = useSelector(groupsSelector);
  const isSelectedItem = group.phoneGroupId === selectedGroupForEditing?.phoneGroupId;

  const openDeleteModal = () => {
    dispatch(openDeleteGroupModal(group));
  };

  const closeEditOnSubmit = useCallback(() => {
    dispatch(clearFormGroupDirty());
    dispatch(selectGroupForEditing(null));
  }, []);

  const toggleIsEditing = useCallback(() => {
    if (isFormGroupDirty) {
      const isPlaybackOrBoth =
        listType === NotSavedListType.Playback || listType === NotSavedListType.Both;
      dispatch(
        openNotSavedModal(isPlaybackOrBoth ? NotSavedListType.Both : NotSavedListType.Group),
      );
      return;
    }
    dispatch(selectGroupForEditing(isSelectedItem ? null : group));
  }, [group, isSelectedItem, isFormGroupDirty]);

  return {
    t,
    groupName,
    isSelectedItem,
    toggleIsEditing,
    openDeleteModal,
    closeEditOnSubmit,
  };
};
