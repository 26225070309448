import React from 'react';

import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { styles } from './styles';

import type { ITable } from '@/constants';

import { CustomTableItem, EmptyContent } from '@/components';

const CustomTable = ({ headers, data, loading, emptyTitle }: ITable) => (
  <EmptyContent isLoading={loading} title={emptyTitle} isEmpty={!data?.length}>
    <Table sx={styles.main}>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell key={index}>
              <Typography variant="h4" color="text.primary" fontWeight={500}>
                {header}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={index}>
            {row.map((cell, index) => (
              <TableCell key={index}>
                <CustomTableItem {...cell} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </EmptyContent>
);

export default CustomTable;
