import React from 'react';

import { Box, MenuItem, Radio, Typography } from '@mui/material';

import { styles } from './styles';
import { useGroupPlaybackRule } from './useGroupPlaybackRule';

import type { ITypeRulesComponentProps } from '@/components';

import { CreateSmallButton, SelectInput } from '@/components';

const GroupPlaybackRule: React.FC<ITypeRulesComponentProps> = (props) => {
  const { error, value, isPreview } = props;
  const { t, groups, isMobile, selectedGroup, openGroupModal, onGroupChange } =
    useGroupPlaybackRule(props);

  if (isPreview) {
    return (
      <Box sx={styles.preview}>
        <Typography variant="h1" sx={styles.groupName}>
          {selectedGroup?.groupName}
        </Typography>
      </Box>
    );
  }

  return (
    <SelectInput
      error={error}
      value={value}
      sx={styles.input}
      formControlSx={styles.formControl}
      onChange={onGroupChange}
      MenuProps={{
        MenuListProps: { disablePadding: true },
        sx: styles.selected,
        PaperProps: {
          style: styles.paperProps,
        },
      }}
      renderValue={() => (
        <Typography variant="h3">{selectedGroup?.groupName ?? t('selectGroup')}</Typography>
      )}
    >
      {groups?.length ? (
        groups?.map(({ groupName, phoneGroupId }) => {
          const isSelected = phoneGroupId === selectedGroup?.phoneGroupId;
          return (
            <MenuItem key={phoneGroupId} value={`${phoneGroupId}`} sx={styles.menuItem}>
              <Typography variant="h3">{groupName}</Typography>
              <Radio size="small" color="secondary" checked={isSelected} />
            </MenuItem>
          );
        })
      ) : (
        <MenuItem sx={{ py: 1.5 }} disableRipple>
          <Box sx={styles.emptyContainer}>
            <Typography variant="h2" sx={styles.emptyTitle}>
              {t('youHaveNoGroups')}
            </Typography>
            <CreateSmallButton onClick={openGroupModal} fullWidth={isMobile}>
              <Typography variant="h3" mr={1.5}>
                {t('createGroup')}
              </Typography>
            </CreateSmallButton>
          </Box>
        </MenuItem>
      )}
    </SelectInput>
  );
};

export default GroupPlaybackRule;
