import React from 'react';

import { SvgIcon } from '@mui/material';

import type { SvgIconProps } from '@mui/material';

import styles from '@/components/shared/icons/styles';
import { theme } from '@/constants';
import { mergeSx } from '@/utils';

export const RecordMeloringIcon = ({
  viewBox = '0 0 24 24',
  fill = theme.palette.primary.main,
  sx = {},
  ...rest
}: SvgIconProps) => (
  <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.01 13.55L12 3H18V7H14V17C14 19.21 12.23 21 10.01 21C7.79 21 6 19.21 6 17C6 14.79 7.79 13 10.01 13C10.74 13 11.42 13.21 12.01 13.55ZM8.01 17C8.01 18.1 8.91 19 10.01 19C11.11 19 12.01 18.1 12.01 17C12.01 15.9 11.11 15 10.01 15C8.91 15 8.01 15.9 8.01 17Z"
      fill={fill}
    />
  </SvgIcon>
);
