import { createContext, useContext } from 'react';

import { defaultClientConfig } from '@/constants';

export const ClientConfigContext = createContext({
  config: defaultClientConfig,
  onClientConfigChange: (params: Object) => {
    console.log(params);
  },
});

export const useClientConfig = () => {
  return useContext(ClientConfigContext);
};
