import { baseTheme } from '@/constants';

export const styles = {
  container: {
    display: 'flex',
    gap: 0.25,
    alignItems: 'center',
  },
  language: {
    cursor: 'pointer',
    py: 0.5,
    px: 1,
  },
  notSelected: {
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'primary.main',
        borderRadius: 1,
        color: 'text.primary',
      },
    },
  },
} as const;
