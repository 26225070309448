import { useSearchParams } from 'react-router-dom';

import { SearchParamsPurchaseType } from '@/constants';
import { PurchaseEnum } from '@/redux/purchases';

const usePurchaseParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const paramVirtContentNo = searchParams.get(SearchParamsPurchaseType.Content);
  const paramPurchaseType = searchParams.get(SearchParamsPurchaseType.Type) as PurchaseEnum;

  const clearPurchaseParams = () => {
    setSearchParams((prev) => {
      prev.delete(SearchParamsPurchaseType.Content);
      prev.delete(SearchParamsPurchaseType.Type);
      return prev;
    });
  };

  const addPurchaseParams = (virtContentNo: string, type: PurchaseEnum) =>
    setSearchParams({
      [SearchParamsPurchaseType.Content]: virtContentNo,
      [SearchParamsPurchaseType.Type]: type,
    });

  return {
    paramPurchaseType,
    paramVirtContentNo,
    addPurchaseParams,
    clearPurchaseParams,
  };
};

export default usePurchaseParams;
