import { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useActiveSubscriber, useClientConfig, useKeycloak } from '@/context';
import { GET_PHONE_GROUPS } from '@/graphqL-queries/groups';
import { useGraphQlErrorHandler } from '@/hooks';
import {
  closeCreateGroupModal,
  openCreateGroupModal,
  selectGroupForEditing,
} from '@/redux/groups/slice';
import { NotSavedListType } from '@/redux/not-saved-changes';
import { notSavedChangesSelector } from '@/redux/not-saved-changes/selectors';
import {
  clearFormGroupDirty,
  clearNotOpenStatus,
  openNotSavedModal,
} from '@/redux/not-saved-changes/slice';

export const useGroupsList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isRbtAuthorized } = useActiveSubscriber();
  const {
    config: { maxPhoneGroups },
  } = useClientConfig();

  const { msisdn, isLoaded } = useKeycloak();
  const { data, loading, called, error } = useQuery(GET_PHONE_GROUPS, {
    variables: {
      msisdn,
    },
    skip: !msisdn || !isRbtAuthorized || !isLoaded,
  });
  const { isFormGroupDirty, isAcceptedClose, listType } = useSelector(notSavedChangesSelector);
  const isBoth = listType === NotSavedListType.Both;

  const phoneGroups = data?.subscriber?.phoneGroups;

  const isCreateDisabled = called && (phoneGroups?.length ?? 0) >= Number(maxPhoneGroups);

  useGraphQlErrorHandler(error);

  useEffect(() => {
    const isGroup = listType === NotSavedListType.Group;
    if (isAcceptedClose && (isGroup || isBoth)) {
      dispatch(selectGroupForEditing(null));
      dispatch(clearFormGroupDirty());
      dispatch(clearNotOpenStatus());
      dispatch(closeCreateGroupModal());
    }
  }, [isAcceptedClose, listType]);

  const onClickCreateGroupModal = () => {
    if (isFormGroupDirty) {
      const isPlayback = listType === NotSavedListType.Playback;
      dispatch(
        openNotSavedModal(isPlayback || isBoth ? NotSavedListType.Both : NotSavedListType.Group),
      );
      return;
    }
    dispatch(openCreateGroupModal());
  };

  return { t, loading, phoneGroups, isCreateDisabled, onClickCreateGroupModal };
};
