import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import styles from '../styles';

import { mergeSx } from '@/utils';

function PlayIcon({ viewBox = '0 0 40 46', sx = {}, ...rest }: SvgIconProps) {
  return (
    <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
      <g clipPath="url(#clip0_1543_2942)">
        <path d="M37.3008 19.6582L6.36328 1.36815C3.84961 -0.117197 0 1.32421 0 4.99804V41.5693C0 44.8652 3.57715 46.8515 6.36328 45.1992L37.3008 26.918C40.0605 25.292 40.0693 21.2842 37.3008 19.6582Z" />
      </g>
      <defs>
        <clipPath id="clip0_1543_2942">
          <rect width="39.375" height="45" fill="white" transform="translate(0 0.788086)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default PlayIcon;
