import React, { memo } from 'react';

import {
  Box,
  FormControl,
  MenuItem,
  Radio,
  Typography,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { styles } from './styles';

import type { InboxContentItem } from '@/graphql-schema';
import type { SelectChangeEvent } from '@mui/material';

import { DefaultContentIcon, SelectInput, ShowMoreIcon, PlayContentImage } from '@/components';
import { useScreenSize } from '@/hooks';
import { shortenString } from '@/utils';

interface IContentItemPickerProps {
  error?: string | boolean;
  list?: Array<InboxContentItem>;
  selected?: InboxContentItem;
  onChange: (e: SelectChangeEvent<unknown>) => void;
}

const ContentItemPickerInput: React.FC<IContentItemPickerProps> = memo(
  ({ error, selected, list, onChange }) => {
    const { t } = useTranslation();
    const { isMobile } = useScreenSize();
    return (
      <FormControl fullWidth>
        <FormLabel sx={styles.label}>{t('selectSong')}</FormLabel>
        <SelectInput
          name="contentNo"
          fullWidth
          value={selected?.contentNo}
          sx={styles.contentNo}
          onChange={onChange}
          formControlSx={styles.contentNoFormControl}
          paperPropsSx={styles.paperProps}
          IconComponent={(props) => <ShowMoreIcon {...props} />}
          renderValue={() => (
            <Box sx={styles.content}>
              {selected ? (
                <>
                  {selected && <PlayContentImage contentItem={selected} isPreview />}
                  <Box ml={2}>
                    <Typography variant="h2" fontWeight="bold">
                      {shortenString(selected?.title ?? '', isMobile ? 15 : 30)}
                    </Typography>
                    <Typography variant="h3" mt={1} color="grey.dark">
                      {shortenString(selected?.artist ?? '', isMobile ? 15 : 30)}
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <DefaultContentIcon sx={styles.contentIcon} />
                  <Typography variant="h2" sx={styles.selectSongPreview}>
                    {t('selectSongForThisSchedule')}
                  </Typography>
                </>
              )}
            </Box>
          )}
        >
          {list?.length ? (
            list?.map((contentItem) => {
              const { contentNo, title, artist } = contentItem;
              const isSelected = contentNo === selected?.contentNo;
              return (
                <MenuItem value={contentNo} key={contentNo} sx={styles.menuItem}>
                  <Box sx={styles.content}>
                    <PlayContentImage contentItem={contentItem} isPreview />
                    <Box ml={2}>
                      <Typography variant="h2" fontWeight="bold">
                        {shortenString(title ?? '', isMobile ? 15 : 30)}
                      </Typography>
                      <Typography variant="h3" mt={1} color="grey.dark">
                        {shortenString(artist ?? '', isMobile ? 15 : 30)}
                      </Typography>
                    </Box>
                  </Box>
                  <Radio size="small" color="secondary" checked={isSelected} />
                </MenuItem>
              );
            })
          ) : (
            <MenuItem sx={styles.nothingFound}>
              <Typography variant="h3" color="text.secondary">
                {t('youHaveNoActiveContent')}
              </Typography>
            </MenuItem>
          )}
        </SelectInput>
        {!!error && <FormHelperText error={!!error}>{t(error as string)}</FormHelperText>}
      </FormControl>
    );
  },
);

ContentItemPickerInput.displayName = 'ContentItemPickerInput';

export default ContentItemPickerInput;
