import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import { styles } from './styles';
import { useGiftCard } from './useGiftCard';

import { InfoCard, CustomContentCard, InfoCardWithTime } from '@/components';
import { GiftBoxContentItem } from '@/graphql-schema';

const GiftCard = (props: GiftBoxContentItem) => {
  const { refIdent, amountPeriodic, chargePeriod } = props;
  const {
    t,
    isPackage,
    formatEndDt,
    formatEndTime,
    formatCreatedDt,
    formatCreatedTime,
    onAccept,
    onDecline,
  } = useGiftCard(props);
  return (
    <CustomContentCard {...props}>
      <Box sx={styles.item}>
        <InfoCard title={`${t('componentPresentCard.giftFrom')}`} description={`${refIdent}`} />
        <InfoCardWithTime
          title={`${t('componentPresentCard.sentAt')}`}
          date={formatCreatedDt}
          time={formatCreatedTime}
        />
        <InfoCardWithTime
          title={`${t('componentPresentCard.validTill')}`}
          date={formatEndDt}
          time={formatEndTime}
        />
        {!isPackage && (
          <InfoCard
            title={`${t('componentPresentCard.prolongationPrice')}`}
            description={`${t('pricePeriodic', { item: { amountPeriodic, chargePeriod } })}`}
          />
        )}
      </Box>
      <Box sx={styles.wrapperButtons}>
        <Box sx={styles.containerButtons}>
          <Button variant="outlined" color="primary" onClick={onDecline} fullWidth>
            <Typography variant="h4">{t('componentPresentCard.decline')}</Typography>
          </Button>
          <Button
            sx={styles.buttonAccept}
            variant="contained"
            color="primary"
            onClick={onAccept}
            fullWidth
          >
            <Typography variant="h4">{t('componentPresentCard.accept')}</Typography>
          </Button>
        </Box>
      </Box>
    </CustomContentCard>
  );
};

export default GiftCard;
