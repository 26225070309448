import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { meloringTitleRegExp } from '@/constants';
import { useAudioPlayer } from '@/context';
import { PathTypeEnum } from '@/graphql-schema';
import { useAudioRecorder, useUploadPersonalContent } from '@/hooks';
import { notSavedChangesSelector } from '@/redux/not-saved-changes/selectors';
import { clearRecordContentDirty, setIsRecordContentDirty } from '@/redux/not-saved-changes/slice';
import { recordMyMeloringSelector } from '@/redux/record-my-meloring';
import { changeAudioSelectedStatus } from '@/redux/record-my-meloring/slice';

export const useCreateRecordCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const audioRecorderParams = useAudioRecorder();
  const { isDiyAudioSelected } = useSelector(recordMyMeloringSelector);
  const { isRecordContentDirty } = useSelector(notSavedChangesSelector);

  const [title, setTitle] = useState('');

  const {
    isSizeLarger,
    recordedFile,
    isRecording,
    isAudioRecorded,
    deleteAudio: deleteUploadedAudio,
    stopRecording,
    startRecording,
    stopPlaying: stopPlayingRecorded,
  } = audioRecorderParams;
  const { stopPlaying } = useAudioPlayer();

  const isEmptyTextError = useMemo(
    () => !!isAudioRecorded && (!title.trim().length || meloringTitleRegExp.test(title.trim())),
    [isAudioRecorded, title],
  );

  const { loading, uploadPersonalContent } = useUploadPersonalContent();

  const buttonTitle = t(
    loading ? 'uploading' : isAudioRecorded ? 'sendToReview' : isRecording ? 'stop' : 'record',
  );

  const isBtnDisabled =
    (isDiyAudioSelected && !recordedFile && !isRecording) ||
    isSizeLarger ||
    isEmptyTextError ||
    loading;

  useEffect(() => {
    if (isAudioRecorded) {
      dispatch(setIsRecordContentDirty(true));
    }
  }, [isAudioRecorded]);

  const uploadAudio = () => {
    if (recordedFile) {
      if (isRecordContentDirty) dispatch(clearRecordContentDirty());
      uploadPersonalContent(recordedFile, title.trim(), PathTypeEnum.Record).then(() => {
        stopPlaying();
        stopPlayingRecorded();
        setTitle('');
        deleteUploadedAudio();
        dispatch(clearRecordContentDirty());
      });
    }
  };

  const deleteAudio = () => {
    if (isDiyAudioSelected) dispatch(changeAudioSelectedStatus(false));
    dispatch(clearRecordContentDirty());
    deleteUploadedAudio();
  };

  const toggleRecordingStatus = () => {
    if (isAudioRecorded) {
      uploadAudio();
      return;
    }
    isRecording ? stopRecording() : startRecording();
  };

  const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  return {
    t,
    title,
    loading,
    isBtnDisabled,
    isEmptyTextError,
    buttonTitle,
    onTitleChange,
    toggleRecordingStatus,
    ...audioRecorderParams,
    deleteAudio,
  };
};
