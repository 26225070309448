import React from 'react';

import { SvgIcon } from '@mui/material';

import styles from '../styles';

import type { SvgIconProps } from '@mui/material';

import { mergeSx } from '@/utils';

const CheckBoxNotCheckedIcon = ({ viewBox = '0 0 24 24', sx = {}, ...rest }: SvgIconProps) => (
  <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM5 19H19V5H5V19Z"
        fill="#11223C"
      />
    </g>
  </SvgIcon>
);

export default CheckBoxNotCheckedIcon;
