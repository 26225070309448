import { createSlice } from '@reduxjs/toolkit';

import type { ICatalogState } from './types';

const initialState: ICatalogState = {
  currentCategory: null,
  contentItems: [],
};

export const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setCurrentCategory(state, { payload }) {
      state.currentCategory = payload;
    },
    setContentForCatalog(state, { payload }) {
      state.contentItems = payload;
    },
    loadMoreContentForCatalog(state, { payload }) {
      state.contentItems = [...(state.contentItems ?? []), ...payload];
    },
    changeFavouriteForCatalog(state, { payload }) {
      const { idx, favourite } = payload;
      const content = state.contentItems[idx];
      if (content) {
        state.contentItems[idx] = { ...content, favourite };
      }
    },
    clearContentForCatalog(state) {
      state.contentItems = [];
    },
  },
});

export const {
  setCurrentCategory,
  loadMoreContentForCatalog,
  setContentForCatalog,
  changeFavouriteForCatalog,
  clearContentForCatalog,
} = catalogSlice.actions;

export default catalogSlice.reducer;
