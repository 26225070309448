const styles = {
  main: {
    cursor: { md: 'pointer', xs: 'default' },
    fontSize: 24,
    height: 24,
    p: 0,
    color: "text.primary"
  },
};

export { styles };
