import { createSlice } from '@reduxjs/toolkit';

import type { INotRbtServiceModalState } from './types';

const initialState: INotRbtServiceModalState = {
  isModalOpen: false,
};

export const notRbtNotificationModalSlice = createSlice({
  name: 'notRbtNotificationModal',
  initialState,
  reducers: {
    openNotRbtNotificationModal: (state) => {
      state.isModalOpen = true;
    },
    closeNotRbtNotificationModal: (state) => {
      state.isModalOpen = false;
    },
  },
});

export const { openNotRbtNotificationModal, closeNotRbtNotificationModal } =
  notRbtNotificationModalSlice.actions;

export default notRbtNotificationModalSlice.reducer;
