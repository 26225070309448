import { useCallback, useEffect, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import type { IGroupItemEditProps } from './types';

import { ErrorCodes } from '@/constants';
import { getGroupSchema } from '@/constants/schemas/groupSchema';
import { useKeycloak } from '@/context';
import { GET_PHONE_GROUPS, Update_PHONE_GROUP } from '@/graphqL-queries/groups';
import { PhoneGroupUpdateInput } from '@/graphql-schema';
import { useGraphQlErrorHandler } from '@/hooks';
import { selectGroupForEditing } from '@/redux/groups/slice';
import { NotSavedListType } from '@/redux/not-saved-changes';
import { clearFormGroupDirty, setFormGroupDirty } from '@/redux/not-saved-changes/slice';
import { responseErrorHandling } from '@/utils';

export const useGroupItemEdit = ({ group }: IGroupItemEditProps) => {
  const dispatch = useDispatch();
  const { msisdn } = useKeycloak();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [updateGroup, { error }] = useMutation(Update_PHONE_GROUP);

  const initialValues = useMemo(
    () => ({
      groupName: group?.groupName ?? '',
      groupMembers: group?.groupMembers ?? [],
      subsIdent: group?.subsIdent ?? '',
      phoneGroupId: group?.phoneGroupId ?? 0,
    }),
    [group],
  );

  const closeEditOnSubmit = useCallback(() => {
    dispatch(clearFormGroupDirty());
    dispatch(selectGroupForEditing(null));
  }, []);

  const onSubmit = (params: PhoneGroupUpdateInput) => {
    updateGroup({
      variables: {
        input: params,
      },
      onCompleted: (data) => {
        responseErrorHandling(data?.phoneGroupUpdate);
        closeEditOnSubmit();
      },
      refetchQueries: [
        {
          query: GET_PHONE_GROUPS,
          variables: {
            msisdn,
          },
        },
      ],
      awaitRefetchQueries: true,
    }).catch((err) => {
      const errorCode = err?.graphQLErrors?.[0]?.extensions?.code;
      const isNotRbtError = errorCode === ErrorCodes.NO_RBT_AUTHORIZED;
      if (isNotRbtError) {
        dispatch(
          setFormGroupDirty({
            listType: NotSavedListType.Group,
            isFormGroupDirty: false,
          }),
        );
      }
    });
  };

  const groupSchema = useMemo(() => getGroupSchema(false), []);

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validationSchema: groupSchema,
  });

  const { dirty, errors } = formik;

  const errorMessage = useMemo(
    () => (typeof errors?.groupMembers === 'string' ? t(errors?.groupMembers) : null),
    [errors, language],
  );

  useGraphQlErrorHandler(error);

  useEffect(() => {
    dispatch(
      setFormGroupDirty({
        listType: NotSavedListType.Group,
        isFormGroupDirty: dirty,
      }),
    );
  }, [dirty]);

  return { t, formik, errorMessage, closeEditOnSubmit };
};
