import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ClientRoutes } from '@/constants';
import { useActiveSubscriber, useClientConfig } from '@/context';
import { useLocalization } from '@/hooks';
import { notRbtNotificationModalSelector } from '@/redux/not-rbt-notification-modal';
import { closeNotRbtNotificationModal } from '@/redux/not-rbt-notification-modal/slice';

export const useNotRbtNotificationModal = () => {
  const dispatch = useDispatch();
  const { navigate } = useLocalization();
  const { t } = useTranslation();
  const { isModalOpen: isOpen } = useSelector(notRbtNotificationModalSelector);
  const {
    config: { defaultCatalogCategory },
  } = useClientConfig();

  const submitText = t('componentPresentCard.acceptModal.accept');
  const cancelText = t('componentPresentCard.acceptModal.cancel');

  const description = t('errors.notRbtSubscriber');

  const { changeRbtAuthorizedStatus } = useActiveSubscriber();

  const onClose = () => {
    dispatch(closeNotRbtNotificationModal());
    changeRbtAuthorizedStatus(false);
    navigate(`${ClientRoutes.Catalog}/${defaultCatalogCategory}`);
  };

  return {
    isOpen,
    description,
    submitText,
    cancelText,
    onSubmit: onClose,
    onCancel: onClose,
    onClose,
    onlyConfirm: true,
  };
};
