import { gql } from '@apollo/client';

import type { InboxContentItem, Subscription } from '@/graphql-schema';
import type { ISubscriberResponse } from '@/types';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';

export const PERSONAL_CONTENT_UPLOAD = gql`
  mutation PersonalContentUpload($file: FileUpload, $input: PersonalContentUploadInput) {
    personalContentUpload(input: $input, upload: $file) {
      error {
        code
        message
      }
    }
  }
`;

export const GET_CONTENTS_WITH_FAVOURITE: TypedDocumentNode<ISubscriberResponse> =
  gql(/* GraphQL */ `
    query GetSubscriberContent(
      $msisdn: String!
      $filter: ContentFilterInput
      $offset: Int
      $pageSize: Int
    ) {
      subscriber(subsIdent: $msisdn) {
        contentItems(filter: $filter, offset: $offset, pageSize: $pageSize) {
          elements {
            artist
            title
            writer
            contentNo
            composer
            virtContentNo
            amountOnetime
            amountPeriodic
            onetimePeriod
            chargePeriod
            hasPicture
            favourite
            complexType
            priceModelNo
            providerId
          }
          numberOfElements
        }
      }
    }
  `);

export const GET_CONTENTS_FAVOURITES_FOR_SUBSCRIBER: TypedDocumentNode<ISubscriberResponse> =
  gql(/* GraphQL */ `
    query GetSubscriberContent($msisdn: String!) {
      subscriber(subsIdent: $msisdn) {
        favouriteContentItems {
          artist
          title
          writer
          contentNo
          composer
          virtContentNo
          amountOnetime
          amountPeriodic
          onetimePeriod
          chargePeriod
          hasPicture
          favourite
          complexType
          providerId
        }
      }
    }
  `);

export const GET_PERSONAL_CONTENT: TypedDocumentNode<{
  subscriber: {
    inboxContentItems: Array<InboxContentItem>;
    subscriptions: Array<Subscription>;
    coUnitNo: null;
  };
}> = gql(/* GraphQL */ `
  query subscriber($msisdn: String!) {
    subscriber(subsIdent: $msisdn) {
      coUnitNo
      inboxContentItems {
        contentNo
        title
        artist
        chargeState
        hasPicture
        contentType
        contentState
      }
      subscriptions {
        serviceNo
      }
    }
  }
`);

export const DELETE_PERSONAL_CONTENT: TypedDocumentNode<{}> = gql(/* GraphQL */ `
  mutation InboxContentRemove($input: InboxContentRemoveInput) {
    inboxContentRemove(input: $input) {
      error {
        code
        message
      }
    }
  }
`);

export const DELETE_SUBSCRIPTION: TypedDocumentNode<{}> = gql(/* GraphQL */ `
  mutation SubscriptionDelete($input: SubscriptionDeleteInput) {
    subscriptionDelete(input: $input) {
      error {
        code
        message
      }
    }
  }
`);
