import React from 'react';

import { useNotRbtNotificationModal } from './useNotRbtNotificationModal';

import { CustomConfirmModal } from '@/components';

const NotRbtNotificationModal = () => {
  const modalProps = useNotRbtNotificationModal();
  return <CustomConfirmModal {...modalProps} />;
};

export default NotRbtNotificationModal;
