import React, { memo } from 'react';

import { Box, IconButton, Typography } from '@mui/material';

import { styles } from './styles';

import { PauseIcon, TrashIcon, PlayRecordIcon } from '@/components';
import { theme } from '@/constants';
import { addOpacityToHexColor } from '@/utils';

interface AudioRecordedPreviewProps {
  isPlaying: boolean;
  isUpload?: boolean;
  isPlayBtnDisabled?: boolean;
  fileName?: string;
  deleteAudio: () => void;
  togglePlayAudio: () => void;
}

const AudioRecordedPreview: React.FC<AudioRecordedPreviewProps> = memo(
  ({
    isUpload = false,
    fileName,
    isPlaying,
    isPlayBtnDisabled = false,
    deleteAudio,
    togglePlayAudio,
  }) => {
    return (
      <Box sx={[styles.container, { mt: isUpload ? 0 : 2 }]}>
        <Box sx={styles.leftSide}>
          <IconButton disabled={isPlayBtnDisabled} onClick={togglePlayAudio}>
            {isPlaying ? (
              <PauseIcon sx={styles.togglePlayIcon} />
            ) : (
              <PlayRecordIcon
                sx={styles.togglePlayIcon}
                fill={
                  isPlayBtnDisabled
                    ? addOpacityToHexColor(theme.palette.grey['50'], 0.2)
                    : theme.palette.text.primary
                }
              />
            )}
          </IconButton>
          {!!fileName && (
            <Typography ml={1.5} variant="h3" sx={styles.fileName}>
              {fileName}
            </Typography>
          )}
        </Box>
        <IconButton onClick={deleteAudio}>
          <TrashIcon />
        </IconButton>
      </Box>
    );
  },
);

AudioRecordedPreview.displayName = 'AudioRecordedPreview';

export default AudioRecordedPreview;
