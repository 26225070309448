import React, { FC, lazy, Suspense } from 'react';

import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Routes, Route, Navigate } from 'react-router-dom';

import routes from './routes';

import { AuthGate, NotRbtNotificationModal } from '@/components';
import { ClientRoutes } from '@/constants';
import { useClientConfig } from '@/context';
import { useScrollOnPageChange } from '@/hooks';
import { MainLayout } from '@/layouts';

const PageNotFound = lazy(() => import('@/pages/PageNotFound'));
const TestPage = lazy(() => import('@/pages/TestPage'));

const AppRouter: FC = () => {
  const {
    config: { isTestMode, defaultCatalogCategory },
  } = useClientConfig();
  const { i18n } = useTranslation();
  useScrollOnPageChange();
  const language = i18n.language;
  const defaultLink = `/${language}${ClientRoutes.Catalog}/${defaultCatalogCategory}`;

  return (
    <Suspense fallback={<CircularProgress />}>
      <Routes>
        {defaultCatalogCategory && (
          <Route path={ClientRoutes.Main} element={<Navigate to={defaultLink} />} />
        )}
        {isTestMode && (
          <Route
            element={
              <MainLayout>
                <Suspense fallback={<CircularProgress />}>
                  <TestPage />
                </Suspense>
              </MainLayout>
            }
            path={`${ClientRoutes.Localization}${ClientRoutes.Test}`}
          />
        )}
        {routes.map(
          ({ path, protectedRoute, component: Component, layout: Layout, ...layoutProps }) => {
            const isProtected = !isTestMode && protectedRoute;

            return (
              <Route
                element={
                  <AuthGate protectedRoute={!isTestMode && protectedRoute} path={path}>
                    <Layout isProtected={isProtected} {...layoutProps}>
                      <Suspense fallback={<CircularProgress />}>
                        <Component />
                        <NotRbtNotificationModal />
                      </Suspense>
                    </Layout>
                  </AuthGate>
                }
                path={`${ClientRoutes.Localization}${path}`}
                key={path}
              />
            );
          },
        )}
        <Route
          path={`${ClientRoutes.Localization}/*`}
          element={
            <Suspense fallback={<CircularProgress />}>
              <PageNotFound />
            </Suspense>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
