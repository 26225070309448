import { buttonBaseClasses, checkboxClasses, svgIconClasses } from '@mui/material';

import { baseTheme } from '@/constants';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: {
      sm: 'row',
      xs: 'column',
    },
    justifyContent: 'space-between',
    mb: {
      xs: 2,
      md: 5,
    },
  },
  button: {
    width: 305,
    height: 24,
    backgroundColor: 'grey.100',
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.500',
      },
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    mt: {
      sm: 0,
      xs: 2,
    },
    gap: 1,
    px: 1,
    py: 0.5,
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.500',
        cursor: 'pointer',
        borderRadius: 1,
      },
    },
    [`& .${buttonBaseClasses.root}`]: {
      p: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      [`& .${checkboxClasses.root}`]: {
        padding: 0,
        [`& .${svgIconClasses.root}`]: {
          color: 'text.primary',
        },
      },
    },
  },
  modal: {
    main: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 600,
      borderRadius: 4,
      background: 'white',
      p: 2,
    },
    cross: {
      cursor: { md: 'pointer', xs: 'default' },
    },
    buttons: {
      display: 'flex',
      gap: 1.5,
    },
    buttonCancel: {
      whiteSpace: 'nowrap',
      minWidth: 'initial',
      px: 4,
      py: 1.5,
      [baseTheme.breakpoints.up('md')]: {
        '&:hover': {
          backgroundColor: 'grey.400',
          border: 'none',
        },
      },
    },
  },
};

export { styles };
