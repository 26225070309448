import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';

import { styles } from './styles';

import type { ButtonProps } from '@mui/material';

const CreateSmallButton = ({ children, ...props }: ButtonProps) => (
  <Button sx={props.disabled ? {} : styles.btn} variant="contained" {...props}>
    {children}
    <AddIcon sx={{ fontSize: 18 }} />
  </Button>
);

export default CreateSmallButton;
