import { useDispatch } from 'react-redux';

import { ContentStateEnum, InboxContentItem } from '@/graphql-schema';
import { useLocalization } from '@/hooks';
import { openDeletePersonalContentModal } from '@/redux/personal-content/slice';

export const usePersonalContentItem = (
  contentItem?: InboxContentItem | null,
  isReview?: boolean,
) => {
  const dispatch = useDispatch();
  const { t, transform } = useLocalization();

  const authName = contentItem?.authName ? transform(contentItem.authName) : null;

  const isProcessing =
    (contentItem?.contentState === ContentStateEnum.Submitting ||
      contentItem?.contentState === ContentStateEnum.InProgress) &&
    isReview;

  const onContentDelete = () => {
    dispatch(openDeletePersonalContentModal(contentItem));
  };

  return { t, isProcessing, authName, onContentDelete };
};
