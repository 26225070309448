import React from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { styles } from './styles';

import { ButtonCross } from '@/components';

interface ICustomDeleteModalProps {
  description: string;
  cancelText: string;
  submitText: string;
  isModalOpen: boolean;
  onCloseModal: () => void;
  onSubmit: () => void;
}

const CustomDeleteModal: React.FC<ICustomDeleteModalProps> = ({
  description,
  cancelText,
  submitText,
  isModalOpen,
  onCloseModal,
  onSubmit,
}) => {
  return (
    <Dialog sx={styles.dialog} open={isModalOpen} onClose={onCloseModal}>
      <DialogTitle sx={{ pb: 1 }}>
        <Box sx={styles.closeIcon}>
          <ButtonCross onClick={onCloseModal} />
        </Box>
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Typography variant="h3" textAlign="center">
          {description}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" sx={styles.cancel} onClick={onCloseModal}>
          <Typography variant="h4">{cancelText}</Typography>
        </Button>
        <Button fullWidth sx={styles.deleteBtn} variant="outlined" color="error" onClick={onSubmit}>
          <Typography variant="h4">{submitText}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDeleteModal;
