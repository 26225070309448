import { createSlice } from '@reduxjs/toolkit';

import type { IMyMusicState } from './types';

const initialState: IMyMusicState = {
  isDeleteModalOpen: false,
  selectedMyMusicForModal: null,
};

export const myMusicSlice = createSlice({
  name: 'myMusic',
  initialState,
  reducers: {
    openDeleteMyMusicModal: (state, { payload }) => {
      state.isDeleteModalOpen = true;
      state.selectedMyMusicForModal = payload;
    },
    closeDeleteMyMusicModal: (state) => {
      state.isDeleteModalOpen = false;
      state.selectedMyMusicForModal = null;
    },
  },
});

export const { openDeleteMyMusicModal, closeDeleteMyMusicModal } = myMusicSlice.actions;

export default myMusicSlice.reducer;
