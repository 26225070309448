import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import styles from '../styles';

import { mergeSx } from '@/utils';

function AppStoreIcon({
  viewBox = '0 0 120 30',
  sx = {},
  height = '30',
  width = '120',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox={viewBox}
      sx={mergeSx(styles.root, sx)}
      {...rest}
    >
      <path
        d="M17.7158 14.2058C17.7287 13.2063 17.9941 12.2264 18.4874 11.3571C18.9807 10.4879 19.6858 9.7575 20.5371 9.23402C19.9963 8.4615 19.2828 7.82574 18.4533 7.37724C17.6239 6.92875 16.7013 6.67987 15.7588 6.65038C13.7484 6.43932 11.7993 7.85358 10.7748 7.85358C9.73054 7.85358 8.15318 6.67133 6.45464 6.70629C5.35597 6.74179 4.28524 7.06132 3.34675 7.63376C2.40827 8.20619 1.63405 9.01201 1.09953 9.97268C-1.21592 13.9821 0.511196 19.8747 2.7292 23.1156C3.83891 24.7026 5.13584 26.4753 6.83286 26.4125C8.49349 26.3436 9.1137 25.3534 11.1182 25.3534C13.1041 25.3534 13.686 26.4125 15.4175 26.3725C17.1994 26.3436 18.3221 24.7785 19.3929 23.1764C20.1902 22.0456 20.8038 20.7959 21.2108 19.4734C20.1755 19.0355 19.2921 18.3024 18.6706 17.3657C18.0491 16.429 17.717 15.33 17.7158 14.2058Z"
        fill="#11223C"
      />
      <path
        d="M14.4461 4.51908C15.4178 3.35255 15.8965 1.85317 15.7806 0.339355C14.296 0.495284 12.9247 1.20482 11.9398 2.3266C11.4583 2.87462 11.0895 3.51216 10.8545 4.2028C10.6195 4.89343 10.5229 5.62361 10.5703 6.35158C11.3129 6.35923 12.0475 6.19828 12.7188 5.88086C13.3902 5.56344 13.9808 5.09783 14.4461 4.51908Z"
        fill="#11223C"
      />
      <path
        d="M38.7075 22.3944H33.0405L31.6796 26.4135H29.2793L34.647 11.5439H37.1408L42.5084 26.4135H40.0672L38.7075 22.3944ZM33.6274 20.5398H38.1194L35.905 14.0172H35.8431L33.6274 20.5398Z"
        fill="#11223C"
      />
      <path
        d="M54.1 20.9936C54.1 24.3625 52.2972 26.527 49.5765 26.527C48.8873 26.563 48.2019 26.4042 47.5988 26.0688C46.9956 25.7333 46.4991 25.2348 46.1661 24.6302H46.1146V29.9999H43.8896V15.5725H46.0433V17.3756H46.0842C46.4326 16.7739 46.9375 16.278 47.5452 15.9405C48.153 15.603 48.8408 15.4366 49.5356 15.459C52.2866 15.459 54.1 17.634 54.1 20.9936ZM51.8131 20.9936C51.8131 18.7987 50.679 17.3557 48.9487 17.3557C47.2487 17.3557 46.1053 18.8291 46.1053 20.9936C46.1053 23.1779 47.2487 24.6408 48.9487 24.6408C50.6791 24.6408 51.8131 23.2083 51.8131 20.9936Z"
        fill="#11223C"
      />
      <path
        d="M66.0307 20.9936C66.0307 24.3625 64.2278 26.5269 61.5072 26.5269C60.818 26.563 60.1326 26.4042 59.5294 26.0688C58.9263 25.7333 58.4298 25.2348 58.0967 24.6302H58.0452V29.9999H55.8203V15.5725H57.9739V17.3756H58.0148C58.3632 16.7739 58.8681 16.278 59.4758 15.9405C60.0836 15.603 60.7715 15.4366 61.4662 15.459C64.2173 15.459 66.0307 17.634 66.0307 20.9936ZM63.7438 20.9936C63.7438 18.7987 62.6097 17.3557 60.8793 17.3557C59.1794 17.3557 58.0359 18.8291 58.0359 20.9936C58.0359 23.1779 59.1794 24.6408 60.8793 24.6408C62.6097 24.6408 63.7438 23.2083 63.7438 20.9936H63.7438Z"
        fill="#11223C"
      />
      <path
        d="M73.9153 22.2708C74.0801 23.7454 75.5124 24.7135 77.4696 24.7135C79.3449 24.7135 80.6941 23.7453 80.6941 22.4158C80.6941 21.2616 79.8804 20.5705 77.9536 20.0969L76.0268 19.6327C73.2968 18.9732 72.0294 17.6963 72.0294 15.6242C72.0294 13.0586 74.2649 11.2964 77.4392 11.2964C80.5807 11.2964 82.7343 13.0586 82.8068 15.6242H80.5608C80.4264 14.1403 79.1999 13.2445 77.4076 13.2445C75.6152 13.2445 74.3888 14.1508 74.3888 15.4698C74.3888 16.5211 75.1721 17.1396 77.0884 17.6132L78.7264 18.0155C81.7767 18.7369 83.0441 19.9625 83.0441 22.1374C83.0441 24.9193 80.8285 26.6616 77.3047 26.6616C74.0076 26.6616 71.7815 24.9602 71.6377 22.2707L73.9153 22.2708Z"
        fill="#11223C"
      />
      <path
        d="M87.8466 13.0068V15.5724H89.9079V17.3346H87.8466V23.3112C87.8466 24.2396 88.2594 24.6723 89.1655 24.6723C89.4102 24.668 89.6545 24.6508 89.8974 24.6208V26.3725C89.49 26.4486 89.0759 26.4831 88.6616 26.4754C86.467 26.4754 85.6112 25.651 85.6112 23.5485V17.3346H84.0352V15.5724H85.6112V13.0068H87.8466Z"
        fill="#11223C"
      />
      <path
        d="M91.1016 20.9929C91.1016 17.5819 93.1102 15.4385 96.2424 15.4385C99.3851 15.4385 101.384 17.5819 101.384 20.9929C101.384 24.4133 99.3957 26.5473 96.2424 26.5473C93.0903 26.5473 91.1016 24.4132 91.1016 20.9929ZM99.1174 20.9929C99.1174 18.653 98.0453 17.272 96.2424 17.272C94.4395 17.272 93.3686 18.6636 93.3686 20.9929C93.3686 23.3421 94.4395 24.7126 96.2424 24.7126C98.0453 24.7126 99.1173 23.3421 99.1173 20.9929H99.1174Z"
        fill="#11223C"
      />
      <path
        d="M103.218 15.5724H105.34V17.4176H105.391C105.535 16.8413 105.872 16.332 106.347 15.9753C106.822 15.6186 107.405 15.4363 107.999 15.4589C108.255 15.458 108.511 15.4859 108.761 15.5419V17.6234C108.437 17.5245 108.099 17.479 107.761 17.4889C107.438 17.4758 107.116 17.5328 106.817 17.656C106.517 17.7792 106.249 17.9657 106.028 18.2026C105.808 18.4396 105.642 18.7214 105.541 19.0288C105.439 19.3361 105.406 19.6617 105.443 19.9831V26.4134H103.218L103.218 15.5724Z"
        fill="#11223C"
      />
      <path
        d="M119.019 23.2287C118.72 25.1967 116.803 26.5473 114.352 26.5473C111.198 26.5473 109.241 24.4343 109.241 21.0443C109.241 17.6439 111.209 15.4385 114.258 15.4385C117.257 15.4385 119.143 17.4989 119.143 20.7859V21.5483H111.487V21.6828C111.452 22.0818 111.502 22.4837 111.634 22.8619C111.766 23.2401 111.976 23.5859 112.252 23.8763C112.528 24.1668 112.862 24.3952 113.233 24.5464C113.604 24.6977 114.003 24.7683 114.403 24.7535C114.929 24.8028 115.457 24.681 115.908 24.4063C116.359 24.1315 116.709 23.7185 116.906 23.2287L119.019 23.2287ZM111.498 19.9931H116.917C116.937 19.6344 116.882 19.2754 116.757 18.9386C116.632 18.6019 116.438 18.2947 116.189 18.0364C115.939 17.778 115.639 17.574 115.306 17.4373C114.974 17.3005 114.617 17.2339 114.258 17.2416C113.896 17.2395 113.537 17.3091 113.201 17.4465C112.866 17.5839 112.561 17.7863 112.305 18.0421C112.048 18.2979 111.845 18.602 111.706 18.9369C111.568 19.2718 111.497 19.6307 111.498 19.9931V19.9931Z"
        fill="#11223C"
      />
      <path
        d="M33.3479 0.351857C33.8144 0.318379 34.2825 0.388859 34.7184 0.558213C35.1543 0.727567 35.5472 0.991574 35.8688 1.33119C36.1903 1.67081 36.4325 2.07757 36.5779 2.52214C36.7232 2.9667 36.7681 3.43799 36.7093 3.90201C36.7093 6.18458 35.4758 7.49665 33.3479 7.49665H30.7676V0.351857H33.3479ZM31.8771 6.48622H33.224C33.5573 6.50615 33.8909 6.45149 34.2004 6.32622C34.5099 6.20096 34.7876 6.00823 35.0133 5.76209C35.239 5.51594 35.4069 5.22256 35.5049 4.90331C35.6029 4.58405 35.6285 4.24696 35.5799 3.91657C35.625 3.58748 35.5968 3.25247 35.4973 2.93556C35.3978 2.61865 35.2294 2.32767 35.0043 2.08348C34.7791 1.83929 34.5027 1.64794 34.195 1.52312C33.8872 1.39831 33.5556 1.34313 33.224 1.36153H31.8771V6.48622Z"
        fill="#11223C"
      />
      <path
        d="M37.9652 4.79859C37.9313 4.44426 37.9718 4.08675 38.0842 3.74899C38.1965 3.41124 38.3782 3.1007 38.6177 2.83729C38.8571 2.57388 39.1489 2.36341 39.4744 2.21939C39.7999 2.07537 40.152 2.00098 40.5079 2.00098C40.8639 2.00098 41.2159 2.07537 41.5414 2.21939C41.8669 2.36341 42.1588 2.57388 42.3982 2.83729C42.6376 3.1007 42.8193 3.41124 42.9317 3.74899C43.044 4.08675 43.0846 4.44426 43.0507 4.79859C43.0852 5.1533 43.0452 5.5113 42.9331 5.8496C42.821 6.1879 42.6394 6.49901 42.3999 6.76294C42.1605 7.02687 41.8684 7.23777 41.5426 7.3821C41.2167 7.52643 40.8643 7.601 40.5079 7.601C40.1515 7.601 39.7991 7.52643 39.4732 7.3821C39.1474 7.23777 38.8554 7.02687 38.6159 6.76294C38.3764 6.49901 38.1948 6.1879 38.0827 5.8496C37.9707 5.5113 37.9306 5.1533 37.9652 4.79859ZM41.9561 4.79859C41.9561 3.62983 41.4311 2.94635 40.5097 2.94635C39.5847 2.94635 39.0644 3.62983 39.0644 4.79861C39.0644 5.97673 39.5847 6.65495 40.5097 6.65495C41.4311 6.65494 41.9562 5.97204 41.9562 4.79859H41.9561Z"
        fill="#11223C"
      />
      <path
        d="M49.8063 7.49735H48.7026L47.5883 3.52623H47.5042L46.3946 7.49735H45.3014L43.8154 2.10547H44.8946L45.8603 6.21979H45.9398L47.0482 2.10547H48.0689L49.1772 6.21979H49.2614L50.2225 2.10547H51.2864L49.8063 7.49735Z"
        fill="#11223C"
      />
      <path
        d="M52.5371 2.10548H53.5615V2.96203H53.641C53.7759 2.65439 54.0034 2.3965 54.2918 2.2243C54.5802 2.05211 54.9152 1.97419 55.25 2.0014C55.5124 1.98168 55.7758 2.02123 56.0208 2.11714C56.2658 2.21305 56.486 2.36285 56.6653 2.55545C56.8445 2.74805 56.9781 2.9785 57.0562 3.22974C57.1343 3.48099 57.1548 3.74657 57.1163 4.00683V7.4973H56.0522V4.27405C56.0522 3.40756 55.6757 2.97665 54.8887 2.97665C54.7106 2.96835 54.5327 2.99867 54.3674 3.06552C54.2021 3.13237 54.0532 3.23418 53.9309 3.36396C53.8086 3.49374 53.7159 3.64843 53.659 3.81742C53.602 3.98642 53.5823 4.16572 53.6012 4.34303V7.49736H52.5371L52.5371 2.10548Z"
        fill="#11223C"
      />
      <path d="M58.8105 0H59.8747V7.49671H58.8105V0Z" fill="#11223C" />
      <path
        d="M61.3538 4.79869C61.3199 4.44433 61.3605 4.0868 61.4729 3.74904C61.5853 3.41127 61.767 3.10072 62.0065 2.8373C62.2459 2.57389 62.5377 2.36342 62.8633 2.2194C63.1888 2.07537 63.5409 2.00098 63.8968 2.00098C64.2528 2.00098 64.6049 2.07537 64.9304 2.2194C65.256 2.36342 65.5478 2.57389 65.7872 2.8373C66.0267 3.10072 66.2084 3.41127 66.3208 3.74904C66.4332 4.0868 66.4738 4.44433 66.4399 4.79869C66.4744 5.15341 66.4343 5.51142 66.3222 5.84972C66.2101 6.18802 66.0285 6.49912 65.789 6.76304C65.5495 7.02696 65.2574 7.23786 64.9315 7.38218C64.6057 7.52651 64.2532 7.60106 63.8968 7.60106C63.5405 7.60106 63.188 7.52651 62.8621 7.38218C62.5363 7.23786 62.2442 7.02696 62.0047 6.76304C61.7652 6.49912 61.5836 6.18802 61.4715 5.84972C61.3594 5.51142 61.3193 5.15341 61.3538 4.79869ZM65.3448 4.79869C65.3448 3.62993 64.8197 2.94645 63.8983 2.94645C62.9733 2.94645 62.453 3.62993 62.453 4.79871C62.453 5.97683 62.9734 6.65505 63.8983 6.65505C64.8198 6.65504 65.3448 5.97214 65.3448 4.79869H65.3448Z"
        fill="#11223C"
      />
      <path
        d="M67.5576 5.97209C67.5576 5.00152 68.2803 4.44199 69.563 4.36247L71.0236 4.27828V3.81288C71.0236 3.2434 70.647 2.92184 69.9197 2.92184C69.3257 2.92184 68.9141 3.13992 68.796 3.52113H67.7658C67.8745 2.595 68.7457 2.00098 69.9688 2.00098C71.3206 2.00098 72.083 2.67393 72.083 3.81288V7.49693H71.0587V6.73919H70.9745C70.8036 7.01099 70.5636 7.23258 70.2791 7.38131C69.9945 7.53004 69.6756 7.60059 69.3549 7.58576C69.1286 7.60931 68.8998 7.58516 68.6833 7.51487C68.4669 7.44459 68.2676 7.32972 68.0982 7.17769C67.9289 7.02565 67.7933 6.83982 67.7002 6.63217C67.6071 6.42452 67.5585 6.19966 67.5576 5.97209ZM71.0236 5.51136V5.06058L69.7069 5.14477C68.9643 5.19446 68.6276 5.44705 68.6276 5.92238C68.6276 6.40766 69.0485 6.69006 69.6274 6.69006C69.797 6.70723 69.9683 6.6901 70.1312 6.6397C70.2941 6.5893 70.4451 6.50666 70.5754 6.39669C70.7056 6.28672 70.8124 6.15166 70.8894 5.99957C70.9664 5.84747 71.0121 5.68144 71.0236 5.51136Z"
        fill="#11223C"
      />
      <path
        d="M73.4814 4.79846C73.4814 3.09471 74.3573 2.0154 75.7196 2.0154C76.0566 1.99987 76.391 2.08058 76.6838 2.24809C76.9766 2.4156 77.2157 2.663 77.373 2.96135H77.4526V0H78.5167V7.49671H77.497V6.64484H77.4128C77.2433 6.94119 76.9959 7.1855 76.6974 7.35132C76.3989 7.51714 76.0608 7.59815 75.7196 7.58557C74.3479 7.58563 73.4814 6.5063 73.4814 4.79846ZM74.5807 4.79846C74.5807 5.94208 75.1198 6.63025 76.0213 6.63025C76.9182 6.63025 77.4725 5.93216 77.4725 4.80314C77.4725 3.67939 76.9124 2.97134 76.0213 2.97134C75.1256 2.97134 74.5806 3.66418 74.5806 4.79846H74.5807Z"
        fill="#11223C"
      />
      <path
        d="M82.9183 4.79859C82.8844 4.44426 82.9249 4.08675 83.0373 3.74899C83.1496 3.41124 83.3314 3.1007 83.5708 2.83729C83.8102 2.57388 84.102 2.36341 84.4275 2.21939C84.753 2.07537 85.1051 2.00098 85.461 2.00098C85.817 2.00098 86.169 2.07537 86.4945 2.21939C86.8201 2.36341 87.1119 2.57388 87.3513 2.83729C87.5907 3.1007 87.7724 3.41124 87.8848 3.74899C87.9971 4.08675 88.0377 4.44426 88.0038 4.79859C88.0383 5.1533 87.9983 5.5113 87.8862 5.8496C87.7741 6.1879 87.5925 6.49901 87.3531 6.76294C87.1136 7.02687 86.8215 7.23777 86.4957 7.3821C86.1698 7.52643 85.8174 7.601 85.461 7.601C85.1047 7.601 84.7522 7.52643 84.4264 7.3821C84.1005 7.23777 83.8085 7.02687 83.569 6.76294C83.3295 6.49901 83.1479 6.1879 83.0359 5.8496C82.9238 5.5113 82.8837 5.1533 82.9183 4.79859ZM86.9093 4.79859C86.9093 3.62983 86.3842 2.94635 85.4628 2.94635C84.5378 2.94635 84.0175 3.62983 84.0175 4.79861C84.0175 5.97673 84.5379 6.65495 85.4628 6.65495C86.3842 6.65494 86.9093 5.97204 86.9093 4.79859Z"
        fill="#11223C"
      />
      <path
        d="M89.4297 2.10548H90.454V2.96203H90.5336C90.6685 2.65439 90.896 2.3965 91.1844 2.2243C91.4728 2.05211 91.8078 1.97419 92.1426 2.0014C92.4049 1.98168 92.6684 2.02123 92.9134 2.11714C93.1584 2.21305 93.3786 2.36285 93.5579 2.55545C93.7371 2.74805 93.8707 2.9785 93.9488 3.22974C94.0268 3.48099 94.0474 3.74657 94.0089 4.00683V7.4973H92.9448V4.27405C92.9448 3.40756 92.5682 2.97665 91.7813 2.97665C91.6031 2.96835 91.4253 2.99867 91.26 3.06552C91.0947 3.13237 90.9458 3.23418 90.8235 3.36396C90.7012 3.49374 90.6084 3.64843 90.5515 3.81742C90.4946 3.98642 90.4749 4.16572 90.4938 4.34303V7.49736H89.4297V2.10548Z"
        fill="#11223C"
      />
      <path
        d="M100.02 0.762695V2.12967H101.188V3.02598H100.02V5.79851C100.02 6.36331 100.253 6.61063 100.782 6.61063C100.918 6.61019 101.053 6.60199 101.188 6.58607V7.47243C100.997 7.50662 100.803 7.52481 100.609 7.5268C99.4258 7.5268 98.9546 7.11051 98.9546 6.07096V3.02593H98.0986V2.12962H98.9546V0.762695H100.02Z"
        fill="#11223C"
      />
      <path
        d="M102.643 0H103.697V2.97133H103.782C103.923 2.66081 104.157 2.40146 104.451 2.22862C104.745 2.05578 105.085 1.97784 105.426 2.00544C105.687 1.99124 105.947 2.03482 106.19 2.13305C106.432 2.23129 106.649 2.38178 106.827 2.57377C107.004 2.76576 107.137 2.99453 107.216 3.24375C107.295 3.49297 107.317 3.75651 107.283 4.01556V7.49673H106.217V4.27807C106.217 3.41684 105.816 2.98067 105.064 2.98067C104.881 2.96567 104.697 2.99081 104.525 3.05434C104.353 3.11787 104.197 3.21824 104.068 3.34845C103.938 3.47865 103.839 3.63554 103.777 3.80813C103.714 3.98072 103.69 4.16485 103.707 4.34764V7.4967H102.643L102.643 0Z"
        fill="#11223C"
      />
      <path
        d="M113.486 6.04117C113.341 6.53396 113.028 6.96022 112.601 7.24538C112.174 7.53055 111.66 7.65646 111.149 7.60109C110.794 7.61046 110.441 7.54242 110.115 7.40169C109.789 7.26096 109.497 7.05091 109.26 6.78612C109.023 6.52132 108.846 6.20813 108.743 5.86828C108.639 5.52843 108.61 5.17005 108.659 4.81804C108.611 4.46494 108.641 4.10579 108.744 3.76492C108.848 3.42405 109.023 3.10941 109.259 2.8423C109.495 2.57518 109.785 2.36184 110.11 2.2167C110.436 2.07157 110.789 1.99803 111.145 2.00107C112.645 2.00107 113.55 3.02606 113.55 4.7192V5.09051H109.743V5.15014C109.726 5.34801 109.751 5.54718 109.816 5.73487C109.881 5.92256 109.984 6.09465 110.119 6.24008C110.254 6.38551 110.418 6.50109 110.601 6.57939C110.783 6.6577 110.98 6.69701 111.179 6.69481C111.433 6.72537 111.691 6.67953 111.92 6.56313C112.148 6.44674 112.337 6.26504 112.461 6.04114L113.486 6.04117ZM109.743 4.30352H112.466C112.48 4.12256 112.455 3.94079 112.394 3.7699C112.333 3.599 112.237 3.44276 112.112 3.31122C111.987 3.17967 111.836 3.07573 111.668 3.00607C111.501 2.93641 111.32 2.90257 111.139 2.90673C110.955 2.90442 110.772 2.93897 110.602 3.00836C110.431 3.07774 110.276 3.18055 110.146 3.31073C110.016 3.4409 109.913 3.59581 109.844 3.76632C109.775 3.93682 109.74 4.11948 109.743 4.30352H109.743Z"
        fill="#11223C"
      />
    </SvgIcon>
  );
}

export default AppStoreIcon;
