import React from 'react';

import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import { t } from 'i18next';
import { Element } from 'react-scroll';

import { styles } from './styles';
import { useCatalogPage } from './useCatalogPage';

import type { ContentItem as ContentItemType } from '@/graphql-schema';

import {
  ContentItem,
  EmptyContent,
  Slider,
  ContainerContentItems,
  DownloadMoreButton,
  CategoriesSwitcher,
  PageWrapper,
  HeaderNavigation,
  SidebarNavigation,
  HappyHourBanner,
} from '@/components';

const CatalogPage = () => {
  const { loading, catName, contentItems, isDownloadMoreVisible, isMobileScreenView, changePage } =
    useCatalogPage();

  return (
    <PageWrapper>
      <Box sx={{ ...styles.pageHeader }}>
        <Box sx={styles.bannerContainer}>
          <HappyHourBanner />
          <Slider />
        </Box>
        {isMobileScreenView && (
          <Box sx={styles.mobileCategories}>
            <HeaderNavigation />
            <SidebarNavigation />
            <Divider sx={{ my: 3 }} />
            <CategoriesSwitcher />
          </Box>
        )}
        {catName && (
          <Typography variant="h1" sx={styles.pageTitle}>
            {catName}
          </Typography>
        )}
      </Box>
      <Element name="catalog-container">
        <Box>
          <EmptyContent
            isLoading={loading}
            title={t('noTonesAreFoundInCategory') + ` ${catName}`}
            isEmpty={!contentItems?.length}
          >
            <ContainerContentItems>
              {contentItems?.map((contentItem, idx) => (
                <ContentItem
                  key={contentItem?.contentNo}
                  contentItem={contentItem as ContentItemType}
                  idx={idx}
                />
              ))}
            </ContainerContentItems>
          </EmptyContent>
        </Box>
      </Element>

      {!!contentItems?.length && (
        <>
          {isDownloadMoreVisible && <DownloadMoreButton changePage={changePage} />}
          {loading && (
            <Box sx={styles.progress}>
              <CircularProgress />
            </Box>
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default CatalogPage;
