import React from 'react';

import { SvgIcon } from '@mui/material';

import styles from '../styles';

import type { SvgIconProps } from '@mui/material';

import { mergeSx } from '@/utils';

const ViberIcon = ({
  viewBox = '0 0 19 18',
  sx = {},
  width = '19',
  height = '18',
  ...rest
}: SvgIconProps) => (
  <SvgIcon
    viewBox={viewBox}
    sx={mergeSx(styles.root, sx)}
    width={width}
    height={height}
    fill="none"
    {...rest}
  >
    <path
      d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0Z"
      fill="#0D4BA0"
    />
    <path
      d="M13.6586 4.23796C13.3429 3.95548 12.08 3.02497 9.23865 3.00835C9.23865 3.00835 5.89877 2.80895 4.27037 4.30442C3.35647 5.21832 3.04076 6.53102 3.00752 8.17604C2.97429 9.82105 2.92445 12.8951 5.89877 13.7425V15.022C5.89877 15.022 5.88215 15.5371 6.21448 15.6368C6.62989 15.7697 6.86252 15.3709 7.26131 14.9555C7.47732 14.7229 7.77641 14.3905 7.99243 14.1247C10.0196 14.2908 11.5816 13.9087 11.7643 13.8422C12.1797 13.7093 14.4894 13.4102 14.8716 10.3362C15.2704 7.14582 14.7054 5.13524 13.6586 4.23796ZM14.0075 10.0869C13.6918 12.6624 11.8142 12.8286 11.4652 12.9283C11.3157 12.9782 9.95316 13.3105 8.22506 13.2108C8.22506 13.2108 6.9456 14.7561 6.53019 15.1715C6.46373 15.238 6.39726 15.2546 6.34741 15.2546C6.28094 15.238 6.26433 15.1549 6.26433 15.0386C6.26433 14.8724 6.28095 12.9117 6.28095 12.9117C3.77188 12.2138 3.92142 9.58842 3.93804 8.20927C3.97127 6.83011 4.22052 5.71681 4.98487 4.95246C6.36403 3.70623 9.20542 3.88901 9.20542 3.88901C11.5982 3.90563 12.7447 4.62013 13.0106 4.86938C13.8746 5.63373 14.3233 7.44492 14.0075 10.0869Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.27709 7.10556C9.28187 7.01727 9.35731 6.94957 9.4456 6.95434C9.77983 6.97241 10.056 7.07344 10.2523 7.27736C10.4477 7.48034 10.5428 7.76342 10.5606 8.10281C10.5653 8.19111 10.4975 8.26645 10.4092 8.2711C10.3209 8.27575 10.2455 8.20794 10.2409 8.11964C10.2255 7.82758 10.1461 7.62877 10.0216 7.49941C9.89799 7.37099 9.70891 7.28924 9.42832 7.27407C9.34003 7.2693 9.27232 7.19385 9.27709 7.10556Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.06447 6.23637C9.07143 6.14823 9.14853 6.08241 9.23667 6.08937C9.89941 6.14169 10.4391 6.3707 10.8376 6.80547L10.8378 6.8057C11.2169 7.22086 11.4125 7.76064 11.3948 8.38052C11.3922 8.4689 11.3185 8.53851 11.2302 8.53598C11.1418 8.53345 11.0722 8.45976 11.0747 8.37137C11.0902 7.82813 10.9203 7.37092 10.6015 7.02171C10.2689 6.65894 9.81156 6.45595 9.21147 6.40858C9.12333 6.40162 9.05751 6.32452 9.06447 6.23637Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.74414 5.435C8.74414 5.34658 8.81582 5.2749 8.90424 5.2749C9.84092 5.2749 10.6294 5.58868 11.2591 6.20091C11.8934 6.8176 12.2216 7.65933 12.2216 8.70855C12.2216 8.79697 12.1499 8.86864 12.0615 8.86864C11.973 8.86864 11.9014 8.79697 11.9014 8.70855C11.9014 7.73049 11.598 6.97699 11.0359 6.43049C10.4692 5.87953 9.7622 5.5951 8.90424 5.5951C8.81582 5.5951 8.74414 5.52342 8.74414 5.435Z"
      fill="white"
    />
    <path
      d="M9.47263 10.2535C9.47263 10.2535 9.70525 10.2702 9.82157 10.1206L10.0542 9.82152C10.1705 9.67197 10.4364 9.57227 10.7189 9.72182C10.8684 9.8049 11.1343 9.97106 11.3004 10.104C11.4832 10.2369 11.8488 10.536 11.8488 10.536C12.0315 10.6856 12.0648 10.9016 11.9485 11.1342C11.8321 11.3502 11.666 11.5496 11.45 11.7324C11.2838 11.8819 11.1176 11.9484 10.9515 11.9816H10.885C10.8186 11.9816 10.7355 11.965 10.669 11.9484C10.4198 11.8819 10.0044 11.6992 9.28985 11.317C8.84121 11.0677 8.45903 10.8019 8.14332 10.5526C7.97716 10.4197 7.79438 10.2702 7.62821 10.0874L7.56175 10.0209C7.37897 9.83813 7.22942 9.67197 7.09649 9.50581C6.84724 9.1901 6.58138 8.80792 6.33213 8.35928C5.94996 7.66139 5.76718 7.24598 5.70072 6.98012C5.6841 6.91366 5.66748 6.84719 5.66748 6.76411V6.69764C5.6841 6.53148 5.76718 6.36532 5.91673 6.19915C6.09951 5.99976 6.2989 5.83359 6.51492 5.70066C6.74754 5.58435 6.96356 5.61758 7.1131 5.80036C7.1131 5.80036 7.4122 6.16592 7.54513 6.3487C7.66145 6.51487 7.82761 6.78073 7.9273 6.93028C8.07685 7.19614 7.97715 7.47862 7.84422 7.59493L7.54513 7.82756C7.39558 7.94387 7.4122 8.1765 7.4122 8.1765C7.4122 8.1765 7.82761 9.82152 9.47263 10.2535Z"
      fill="white"
    />
  </SvgIcon>
);

export default ViberIcon;
