import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import styles from '../styles';

import { theme } from '@/constants';
import { mergeSx } from '@/utils';

const MenuIcon = ({
  viewBox = '0 0 24 24',
  fill = theme.palette.primary.main,
  sx = {},
  ...rest
}: SvgIconProps) => (
  <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 8V6H21V8H3ZM3 13H21V11H3V13ZM3 18H21V16H3V18Z"
      fill={fill}
    />
  </SvgIcon>
);

export default MenuIcon;
