export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigDecimal: { input: any; output: any; }
  Byte: { input: any; output: any; }
  Date: { input: any; output: any; }
  FileUpload: { input: any; output: any; }
  Long: { input: any; output: any; }
  Short: { input: any; output: any; }
};

export enum BillingModeEnum {
  Default = 'DEFAULT',
  Postpaid = 'POSTPAID',
  Prepaid = 'PREPAID'
}

export type Charge = {
  __typename?: 'Charge';
  amount?: Maybe<Scalars['BigDecimal']['output']>;
  artist?: Maybe<Scalars['String']['output']>;
  billingCd?: Maybe<Scalars['String']['output']>;
  billingMode?: Maybe<BillingModeEnum>;
  channelCd?: Maybe<Scalars['String']['output']>;
  channelName?: Maybe<Scalars['String']['output']>;
  channelNo?: Maybe<Scalars['Int']['output']>;
  chargeDt?: Maybe<Scalars['Date']['output']>;
  chargeId?: Maybe<Scalars['Long']['output']>;
  chargeType?: Maybe<PathTypeEnum>;
  complexType?: Maybe<ComplexTypeEnum>;
  contentNo?: Maybe<Scalars['Long']['output']>;
  contentType?: Maybe<ContentTypeEnum>;
  createdDt?: Maybe<Scalars['Date']['output']>;
  endDt?: Maybe<Scalars['Date']['output']>;
  errCd?: Maybe<Scalars['String']['output']>;
  errMsg?: Maybe<Scalars['String']['output']>;
  hasPicture?: Maybe<Scalars['Boolean']['output']>;
  priceModelNo?: Maybe<Scalars['Int']['output']>;
  refIdent?: Maybe<Scalars['String']['output']>;
  serviceCd?: Maybe<Scalars['String']['output']>;
  serviceNo?: Maybe<Scalars['Short']['output']>;
  state?: Maybe<ChargeStateEnum>;
  subsIdent?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  virtContentNo?: Maybe<Scalars['Long']['output']>;
  virtServiceCd?: Maybe<Scalars['String']['output']>;
  virtServiceNo?: Maybe<Scalars['Short']['output']>;
};

export enum ChargeStateEnum {
  Charged = 'CHARGED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  ReservationCancelled = 'RESERVATION_CANCELLED',
  Reserved = 'RESERVED'
}

export enum CoModeEnum {
  Corporate = 'CORPORATE',
  CorporateAccount = 'CORPORATE_ACCOUNT',
  Personal = 'PERSONAL',
  PersonalCorporate = 'PERSONAL_CORPORATE'
}

export enum ComplexTypeEnum {
  Channel = 'CHANNEL',
  Disk = 'DISK',
  Package = 'PACKAGE',
  Single = 'SINGLE'
}

export type ContentCategory = {
  __typename?: 'ContentCategory';
  catName?: Maybe<Scalars['String']['output']>;
  contentCatId?: Maybe<Scalars['Long']['output']>;
  createdDt?: Maybe<Scalars['Date']['output']>;
  imageId?: Maybe<Scalars['Int']['output']>;
  orderNo?: Maybe<Scalars['Short']['output']>;
  parentCatId?: Maybe<Scalars['Long']['output']>;
};

export type ContentCategoryFilterInput = {
  parentCatId?: InputMaybe<Scalars['Long']['input']>;
};

export enum ContentChargeStateEnum {
  Activated = 'ACTIVATED',
  DeferredProlongation = 'DEFERRED_PROLONGATION',
  DeferredPurchase = 'DEFERRED_PURCHASE',
  WaitingProlongation = 'WAITING_PROLONGATION',
  WaitingPurchase = 'WAITING_PURCHASE'
}

export type ContentCopyInput = {
  contentNo?: InputMaybe<Scalars['Long']['input']>;
  sourceSubsIdent?: InputMaybe<Scalars['String']['input']>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
  virtServiceNo?: InputMaybe<Scalars['Short']['input']>;
};

export type ContentFilterInput = {
  artist?: InputMaybe<Scalars['String']['input']>;
  complexType?: InputMaybe<ComplexTypeEnum>;
  composer?: InputMaybe<Scalars['String']['input']>;
  contentCatId?: InputMaybe<Scalars['Long']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  virtContentNo?: InputMaybe<Scalars['Long']['input']>;
  writer?: InputMaybe<Scalars['String']['input']>;
};

export type ContentGiftInput = {
  recipientSubsIdent?: InputMaybe<Scalars['String']['input']>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
  virtContentNo?: InputMaybe<Scalars['Long']['input']>;
  virtServiceNo?: InputMaybe<Scalars['Short']['input']>;
};

export type ContentItem = {
  __typename?: 'ContentItem';
  amountMicro?: Maybe<Scalars['BigDecimal']['output']>;
  amountOnetime?: Maybe<Scalars['BigDecimal']['output']>;
  amountPeriodic?: Maybe<Scalars['BigDecimal']['output']>;
  artist?: Maybe<Scalars['String']['output']>;
  chargePeriod?: Maybe<Scalars['Short']['output']>;
  complexType?: Maybe<ComplexTypeEnum>;
  composer?: Maybe<Scalars['String']['output']>;
  contentEndDt?: Maybe<Scalars['Date']['output']>;
  contentNo?: Maybe<Scalars['Long']['output']>;
  contentStartDt?: Maybe<Scalars['Date']['output']>;
  contentState?: Maybe<ContentStateEnum>;
  contentSubItems?: Maybe<Array<Maybe<ContentItem>>>;
  contentType?: Maybe<ContentTypeEnum>;
  copyright?: Maybe<Scalars['String']['output']>;
  favourite?: Maybe<Scalars['Boolean']['output']>;
  hasPicture?: Maybe<Scalars['Boolean']['output']>;
  imageId?: Maybe<Scalars['Int']['output']>;
  microPeriod?: Maybe<Scalars['Short']['output']>;
  onetimePeriod?: Maybe<Scalars['Short']['output']>;
  priceEndDt?: Maybe<Scalars['Date']['output']>;
  priceModelNo?: Maybe<Scalars['Int']['output']>;
  priceModelState?: Maybe<EdStateEnum>;
  priceStartDt?: Maybe<Scalars['Date']['output']>;
  providerId?: Maybe<Scalars['Int']['output']>;
  saleState?: Maybe<SaleStateEnum>;
  title?: Maybe<Scalars['String']['output']>;
  virtContentNo?: Maybe<Scalars['Long']['output']>;
  virtEndDt?: Maybe<Scalars['Date']['output']>;
  virtStartDt?: Maybe<Scalars['Date']['output']>;
  writer?: Maybe<Scalars['String']['output']>;
};

export type ContentPurchaseInput = {
  pathType?: InputMaybe<PathTypeEnum>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
  virtContentNo?: InputMaybe<Scalars['Long']['input']>;
  virtServiceNo?: InputMaybe<Scalars['Short']['input']>;
};

export type ContentRenewableSetInput = {
  contentNo?: InputMaybe<Scalars['Long']['input']>;
  renewable?: InputMaybe<Scalars['Boolean']['input']>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
};

export enum ContentStateEnum {
  Activated = 'ACTIVATED',
  Expired = 'EXPIRED',
  InProgress = 'IN_PROGRESS',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED',
  Submitting = 'SUBMITTING',
  Suspended = 'SUSPENDED',
  Unused = 'UNUSED'
}

export enum ContentTypeEnum {
  Corporate = 'CORPORATE',
  Personal = 'PERSONAL',
  Public = 'PUBLIC',
  SystemFmb = 'SYSTEM_FMB'
}

export type CopyPermissionSetInput = {
  permit?: InputMaybe<PermitEnum>;
  personalContentNo?: InputMaybe<Scalars['Long']['input']>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
};

export enum EdStateEnum {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type FaultDetails = {
  __typename?: 'FaultDetails';
  code?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  values?: Maybe<Array<Maybe<TypedValue>>>;
};

export type FaultPayload = {
  __typename?: 'FaultPayload';
  error?: Maybe<FaultDetails>;
};

export type FavouriteContentCreateInput = {
  subsIdent?: InputMaybe<Scalars['String']['input']>;
  virtContentNo?: InputMaybe<Scalars['Long']['input']>;
};

export type FavouriteContentDeleteInput = {
  subsIdent?: InputMaybe<Scalars['String']['input']>;
  virtContentNo?: InputMaybe<Scalars['Long']['input']>;
};

export type GiftBoxContentItem = {
  __typename?: 'GiftBoxContentItem';
  amountMicro?: Maybe<Scalars['BigDecimal']['output']>;
  amountOnetime?: Maybe<Scalars['BigDecimal']['output']>;
  amountPeriodic?: Maybe<Scalars['BigDecimal']['output']>;
  artist?: Maybe<Scalars['String']['output']>;
  chargePeriod?: Maybe<Scalars['Short']['output']>;
  chargeState?: Maybe<GiftChargeStateEnum>;
  chargeStateDt?: Maybe<Scalars['Date']['output']>;
  complexType?: Maybe<ComplexTypeEnum>;
  composer?: Maybe<Scalars['String']['output']>;
  confirmNo?: Maybe<Scalars['Int']['output']>;
  contentEndDt?: Maybe<Scalars['Date']['output']>;
  contentNo?: Maybe<Scalars['Long']['output']>;
  contentStartDt?: Maybe<Scalars['Date']['output']>;
  contentState?: Maybe<ContentStateEnum>;
  contentSubItems?: Maybe<Array<Maybe<ContentItem>>>;
  contentType?: Maybe<ContentTypeEnum>;
  copyright?: Maybe<Scalars['String']['output']>;
  createdDt?: Maybe<Scalars['Date']['output']>;
  endDt?: Maybe<Scalars['Date']['output']>;
  extTransId?: Maybe<Scalars['String']['output']>;
  favourite?: Maybe<Scalars['Boolean']['output']>;
  hasPicture?: Maybe<Scalars['Boolean']['output']>;
  imageId?: Maybe<Scalars['Int']['output']>;
  microPeriod?: Maybe<Scalars['Short']['output']>;
  onetimePeriod?: Maybe<Scalars['Short']['output']>;
  pathType?: Maybe<PathTypeEnum>;
  priceEndDt?: Maybe<Scalars['Date']['output']>;
  priceModelNo?: Maybe<Scalars['Int']['output']>;
  priceModelState?: Maybe<EdStateEnum>;
  priceStartDt?: Maybe<Scalars['Date']['output']>;
  providerId?: Maybe<Scalars['Int']['output']>;
  refIdent?: Maybe<Scalars['String']['output']>;
  saleState?: Maybe<SaleStateEnum>;
  subsIdent?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  virtContentNo?: Maybe<Scalars['Long']['output']>;
  virtEndDt?: Maybe<Scalars['Date']['output']>;
  virtStartDt?: Maybe<Scalars['Date']['output']>;
  writer?: Maybe<Scalars['String']['output']>;
};

export enum GiftChargeStateEnum {
  Activated = 'ACTIVATED',
  Deferred = 'DEFERRED',
  Waiting = 'WAITING'
}

export type GiftContentAcceptInput = {
  confirmNo?: InputMaybe<Scalars['Int']['input']>;
  contentNo?: InputMaybe<Scalars['Long']['input']>;
  pathType?: InputMaybe<PathTypeEnum>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
};

export type GiftContentRejectInput = {
  confirmNo?: InputMaybe<Scalars['Int']['input']>;
  contentNo?: InputMaybe<Scalars['Long']['input']>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
};

export type GroupMember = {
  __typename?: 'GroupMember';
  phoneName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type GroupMemberInput = {
  phoneName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type HappyHours = {
  __typename?: 'HappyHours';
  amountOnetime?: Maybe<Scalars['BigDecimal']['output']>;
  createdDt?: Maybe<Scalars['Date']['output']>;
  endDt?: Maybe<Scalars['Date']['output']>;
  hhName?: Maybe<Scalars['String']['output']>;
  hhPriceId?: Maybe<Scalars['Int']['output']>;
  onetimeBillingCd?: Maybe<Scalars['String']['output']>;
  onetimePeriod?: Maybe<Scalars['BigDecimal']['output']>;
  providerId?: Maybe<Scalars['Int']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  startDt?: Maybe<Scalars['Date']['output']>;
};

export type InboxContentItem = {
  __typename?: 'InboxContentItem';
  amountMicro?: Maybe<Scalars['BigDecimal']['output']>;
  amountOnetime?: Maybe<Scalars['BigDecimal']['output']>;
  amountPeriodic?: Maybe<Scalars['BigDecimal']['output']>;
  artist?: Maybe<Scalars['String']['output']>;
  authCd?: Maybe<Scalars['Byte']['output']>;
  authDt?: Maybe<Scalars['Date']['output']>;
  authName?: Maybe<Scalars['String']['output']>;
  chargePeriod?: Maybe<Scalars['Short']['output']>;
  chargeState?: Maybe<ContentChargeStateEnum>;
  chargeStateDt?: Maybe<Scalars['Date']['output']>;
  complexType?: Maybe<ComplexTypeEnum>;
  composer?: Maybe<Scalars['String']['output']>;
  contentEndDt?: Maybe<Scalars['Date']['output']>;
  contentNo?: Maybe<Scalars['Long']['output']>;
  contentStartDt?: Maybe<Scalars['Date']['output']>;
  contentState?: Maybe<ContentStateEnum>;
  contentSubItems?: Maybe<Array<Maybe<ContentItem>>>;
  contentType?: Maybe<ContentTypeEnum>;
  copyright?: Maybe<Scalars['String']['output']>;
  endDt?: Maybe<Scalars['Date']['output']>;
  favourite?: Maybe<Scalars['Boolean']['output']>;
  graceDt?: Maybe<Scalars['Date']['output']>;
  hasPicture?: Maybe<Scalars['Boolean']['output']>;
  imageId?: Maybe<Scalars['Int']['output']>;
  microPeriod?: Maybe<Scalars['Short']['output']>;
  onetimePeriod?: Maybe<Scalars['Short']['output']>;
  pathType?: Maybe<PathTypeEnum>;
  permit?: Maybe<PermitEnum>;
  priceEndDt?: Maybe<Scalars['Date']['output']>;
  priceModelNo?: Maybe<Scalars['Int']['output']>;
  priceModelState?: Maybe<EdStateEnum>;
  priceStartDt?: Maybe<Scalars['Date']['output']>;
  providerId?: Maybe<Scalars['Int']['output']>;
  renewable?: Maybe<RenewableEnum>;
  saleState?: Maybe<SaleStateEnum>;
  startDt?: Maybe<Scalars['Date']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  virtContentNo?: Maybe<Scalars['Long']['output']>;
  virtEndDt?: Maybe<Scalars['Date']['output']>;
  virtStartDt?: Maybe<Scalars['Date']['output']>;
  writer?: Maybe<Scalars['String']['output']>;
};

export type InboxContentRemoveInput = {
  contentNo?: InputMaybe<Scalars['Long']['input']>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
};

export type LanguageUpdateInput = {
  languageCode?: InputMaybe<Scalars['String']['input']>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
};

/** Mutation root */
export type Mutation = {
  __typename?: 'Mutation';
  contentCopy?: Maybe<FaultPayload>;
  contentGift?: Maybe<FaultPayload>;
  contentPurchase?: Maybe<FaultPayload>;
  contentRenewableSet?: Maybe<FaultPayload>;
  copyPermissionSet?: Maybe<FaultPayload>;
  favouriteContentCreate?: Maybe<FaultPayload>;
  favouriteContentDelete?: Maybe<FaultPayload>;
  giftContentAccept?: Maybe<FaultPayload>;
  giftContentReject?: Maybe<FaultPayload>;
  inboxContentRemove?: Maybe<FaultPayload>;
  languageUpdate?: Maybe<FaultPayload>;
  personalContentUpload?: Maybe<FaultPayload>;
  phoneGroupCreate?: Maybe<PhoneGroupCreatePayload>;
  phoneGroupDelete?: Maybe<FaultPayload>;
  phoneGroupUpdate?: Maybe<FaultPayload>;
  playConditionCreate?: Maybe<PlayConditionCreatePayload>;
  playConditionDelete?: Maybe<FaultPayload>;
  playConditionUpdate?: Maybe<FaultPayload>;
  preservedContentDelete?: Maybe<FaultPayload>;
  preservedContentRestore?: Maybe<FaultPayload>;
  serviceActivationAccept?: Maybe<FaultPayload>;
  serviceActivationCreate?: Maybe<FaultPayload>;
  serviceActivationReject?: Maybe<FaultPayload>;
  subscriberPasswordChange?: Maybe<FaultPayload>;
  subscriptionDelete?: Maybe<FaultPayload>;
  timeZoneUpdate?: Maybe<FaultPayload>;
};


/** Mutation root */
export type MutationContentCopyArgs = {
  input?: InputMaybe<ContentCopyInput>;
};


/** Mutation root */
export type MutationContentGiftArgs = {
  input?: InputMaybe<ContentGiftInput>;
};


/** Mutation root */
export type MutationContentPurchaseArgs = {
  input?: InputMaybe<ContentPurchaseInput>;
};


/** Mutation root */
export type MutationContentRenewableSetArgs = {
  input?: InputMaybe<ContentRenewableSetInput>;
};


/** Mutation root */
export type MutationCopyPermissionSetArgs = {
  input?: InputMaybe<CopyPermissionSetInput>;
};


/** Mutation root */
export type MutationFavouriteContentCreateArgs = {
  input?: InputMaybe<FavouriteContentCreateInput>;
};


/** Mutation root */
export type MutationFavouriteContentDeleteArgs = {
  input?: InputMaybe<FavouriteContentDeleteInput>;
};


/** Mutation root */
export type MutationGiftContentAcceptArgs = {
  input?: InputMaybe<GiftContentAcceptInput>;
};


/** Mutation root */
export type MutationGiftContentRejectArgs = {
  input?: InputMaybe<GiftContentRejectInput>;
};


/** Mutation root */
export type MutationInboxContentRemoveArgs = {
  input?: InputMaybe<InboxContentRemoveInput>;
};


/** Mutation root */
export type MutationLanguageUpdateArgs = {
  input?: InputMaybe<LanguageUpdateInput>;
};


/** Mutation root */
export type MutationPersonalContentUploadArgs = {
  input?: InputMaybe<PersonalContentUploadInput>;
  upload?: InputMaybe<Scalars['FileUpload']['input']>;
};


/** Mutation root */
export type MutationPhoneGroupCreateArgs = {
  input?: InputMaybe<PhoneGroupCreateInput>;
};


/** Mutation root */
export type MutationPhoneGroupDeleteArgs = {
  input?: InputMaybe<PhoneGroupDeleteInput>;
};


/** Mutation root */
export type MutationPhoneGroupUpdateArgs = {
  input?: InputMaybe<PhoneGroupUpdateInput>;
};


/** Mutation root */
export type MutationPlayConditionCreateArgs = {
  input?: InputMaybe<PlayConditionCreateInput>;
};


/** Mutation root */
export type MutationPlayConditionDeleteArgs = {
  input?: InputMaybe<PlayConditionDeleteInput>;
};


/** Mutation root */
export type MutationPlayConditionUpdateArgs = {
  input?: InputMaybe<PlayConditionUpdateInput>;
};


/** Mutation root */
export type MutationPreservedContentDeleteArgs = {
  input?: InputMaybe<PreservedContentDeleteInput>;
};


/** Mutation root */
export type MutationPreservedContentRestoreArgs = {
  input?: InputMaybe<PreservedContentRestoreInput>;
};


/** Mutation root */
export type MutationServiceActivationAcceptArgs = {
  input?: InputMaybe<ServiceActivationAcceptInput>;
};


/** Mutation root */
export type MutationServiceActivationCreateArgs = {
  input?: InputMaybe<ServiceActivationCreateUploadInput>;
  upload?: InputMaybe<Scalars['FileUpload']['input']>;
};


/** Mutation root */
export type MutationServiceActivationRejectArgs = {
  input?: InputMaybe<ServiceActivationRejectInput>;
};


/** Mutation root */
export type MutationSubscriberPasswordChangeArgs = {
  input?: InputMaybe<SubscriberPasswordChangeInput>;
};


/** Mutation root */
export type MutationSubscriptionDeleteArgs = {
  input?: InputMaybe<SubscriptionDeleteInput>;
};


/** Mutation root */
export type MutationTimeZoneUpdateArgs = {
  input?: InputMaybe<TimeZoneUpdateInput>;
};

export type Pagination = {
  __typename?: 'Pagination';
  offset?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
};

export enum PathTypeEnum {
  Bonus = 'BONUS',
  Catch = 'CATCH',
  CopyPersonal = 'COPY_PERSONAL',
  CopyPublic = 'COPY_PUBLIC',
  CoUpload = 'CO_UPLOAD',
  Free = 'FREE',
  Gift = 'GIFT',
  Hangup = 'HANGUP',
  HappyHour = 'HAPPY_HOUR',
  Preserve = 'PRESERVE',
  Prolongation = 'PROLONGATION',
  ProlongationMicro = 'PROLONGATION_MICRO',
  Promo = 'PROMO',
  Purchase = 'PURCHASE',
  PurchaseMicro = 'PURCHASE_MICRO',
  Record = 'RECORD',
  StartPack = 'START_PACK',
  TryBuy = 'TRY_BUY',
  Upload = 'UPLOAD'
}

export enum PermitEnum {
  CopyDenied = 'COPY_DENIED',
  CopyPermitted = 'COPY_PERMITTED'
}

export type PersonalContentUploadInput = {
  contentTitle?: InputMaybe<Scalars['String']['input']>;
  contentType?: InputMaybe<ContentTypeEnum>;
  ext?: InputMaybe<Scalars['String']['input']>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
  virtServiceNo?: InputMaybe<Scalars['Short']['input']>;
};

export type PhoneGroup = {
  __typename?: 'PhoneGroup';
  groupMembers?: Maybe<Array<Maybe<GroupMember>>>;
  groupMembersCount?: Maybe<Scalars['Int']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  phoneGroupId?: Maybe<Scalars['Int']['output']>;
  subsIdent?: Maybe<Scalars['String']['output']>;
};

export type PhoneGroupCreateInput = {
  groupMembers?: InputMaybe<Array<InputMaybe<GroupMemberInput>>>;
  groupName?: InputMaybe<Scalars['String']['input']>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
};

export type PhoneGroupCreatePayload = {
  __typename?: 'PhoneGroupCreatePayload';
  error?: Maybe<FaultDetails>;
  phoneGroupId?: Maybe<Scalars['Int']['output']>;
};

export type PhoneGroupDeleteInput = {
  phoneGroupId?: InputMaybe<Scalars['Int']['input']>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
};

export type PhoneGroupUpdateInput = {
  groupMembers?: InputMaybe<Array<InputMaybe<GroupMemberInput>>>;
  groupName?: InputMaybe<Scalars['String']['input']>;
  phoneGroupId?: InputMaybe<Scalars['Int']['input']>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
};

export type PlayCondition = {
  __typename?: 'PlayCondition';
  coUnitNo?: Maybe<Scalars['Short']['output']>;
  contentItem?: Maybe<ContentItem>;
  createdDt?: Maybe<Scalars['Date']['output']>;
  pcParams?: Maybe<Scalars['String']['output']>;
  pcType?: Maybe<PlayConditionTypeEnum>;
  phoneGroupId?: Maybe<Scalars['Int']['output']>;
  playCondId?: Maybe<Scalars['Long']['output']>;
  serviceNo?: Maybe<Scalars['Short']['output']>;
  subscriptionId?: Maybe<Scalars['Long']['output']>;
  tmParams?: Maybe<Scalars['String']['output']>;
  tmType?: Maybe<TimeRuleTypeEnum>;
};

export type PlayConditionCreateInput = {
  coUnitNo?: InputMaybe<Scalars['Short']['input']>;
  contentNo?: InputMaybe<Scalars['Long']['input']>;
  pcParams?: InputMaybe<Scalars['String']['input']>;
  pcType?: InputMaybe<PlayConditionTypeEnum>;
  phoneGroupId?: InputMaybe<Scalars['Int']['input']>;
  serviceNo?: InputMaybe<Scalars['Short']['input']>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
  tmParams?: InputMaybe<Scalars['String']['input']>;
  tmType?: InputMaybe<TimeRuleTypeEnum>;
};

export type PlayConditionCreatePayload = {
  __typename?: 'PlayConditionCreatePayload';
  error?: Maybe<FaultDetails>;
  playCondId?: Maybe<Scalars['Long']['output']>;
};

export type PlayConditionDeleteInput = {
  playCondId?: InputMaybe<Scalars['Long']['input']>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
};

export enum PlayConditionTypeEnum {
  Default = 'DEFAULT',
  Group = 'GROUP',
  Incomplete = 'INCOMPLETE',
  Personal = 'PERSONAL',
  System = 'SYSTEM'
}

export type PlayConditionUpdateInput = {
  contentNo?: InputMaybe<Scalars['Long']['input']>;
  pcParams?: InputMaybe<Scalars['String']['input']>;
  pcType?: InputMaybe<PlayConditionTypeEnum>;
  phoneGroupId?: InputMaybe<Scalars['Int']['input']>;
  playCondId?: InputMaybe<Scalars['Long']['input']>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
  tmParams?: InputMaybe<Scalars['String']['input']>;
  tmType?: InputMaybe<TimeRuleTypeEnum>;
};

export type PreservedContentDeleteInput = {
  subsIdent?: InputMaybe<Scalars['String']['input']>;
};

export type PreservedContentItem = {
  __typename?: 'PreservedContentItem';
  amountMicro?: Maybe<Scalars['BigDecimal']['output']>;
  amountOnetime?: Maybe<Scalars['BigDecimal']['output']>;
  amountPeriodic?: Maybe<Scalars['BigDecimal']['output']>;
  artist?: Maybe<Scalars['String']['output']>;
  chargePeriod?: Maybe<Scalars['Short']['output']>;
  complexType?: Maybe<ComplexTypeEnum>;
  composer?: Maybe<Scalars['String']['output']>;
  contentEndDt?: Maybe<Scalars['Date']['output']>;
  contentNo?: Maybe<Scalars['Long']['output']>;
  contentStartDt?: Maybe<Scalars['Date']['output']>;
  contentState?: Maybe<ContentStateEnum>;
  contentSubItems?: Maybe<Array<Maybe<ContentItem>>>;
  contentType?: Maybe<ContentTypeEnum>;
  copyright?: Maybe<Scalars['String']['output']>;
  createdDt?: Maybe<Scalars['Date']['output']>;
  expirationDt?: Maybe<Scalars['Date']['output']>;
  favourite?: Maybe<Scalars['Boolean']['output']>;
  hasPicture?: Maybe<Scalars['Boolean']['output']>;
  imageId?: Maybe<Scalars['Int']['output']>;
  microPeriod?: Maybe<Scalars['Short']['output']>;
  onetimePeriod?: Maybe<Scalars['Short']['output']>;
  priceEndDt?: Maybe<Scalars['Date']['output']>;
  priceModelNo?: Maybe<Scalars['Int']['output']>;
  priceModelState?: Maybe<EdStateEnum>;
  priceStartDt?: Maybe<Scalars['Date']['output']>;
  providerId?: Maybe<Scalars['Int']['output']>;
  saleState?: Maybe<SaleStateEnum>;
  subsIdent?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  virtContentNo?: Maybe<Scalars['Long']['output']>;
  virtEndDt?: Maybe<Scalars['Date']['output']>;
  virtStartDt?: Maybe<Scalars['Date']['output']>;
  writer?: Maybe<Scalars['String']['output']>;
};

export type PreservedContentRestoreInput = {
  subsIdent?: InputMaybe<Scalars['String']['input']>;
};

/** Query root */
export type Query = {
  __typename?: 'Query';
  contentCategories?: Maybe<Array<Maybe<ContentCategory>>>;
  contentItem?: Maybe<ContentItem>;
  contentItems?: Maybe<SearchResult_ContentItem>;
  happyHours?: Maybe<Array<Maybe<HappyHours>>>;
  promoContentItems?: Maybe<Array<Maybe<ContentItem>>>;
  serviceActivations?: Maybe<Array<Maybe<ServiceActivation>>>;
  subscriber?: Maybe<Subscriber>;
  virtualServices?: Maybe<Array<Maybe<VirtualService>>>;
  webParameters?: Maybe<WebParameters>;
};


/** Query root */
export type QueryContentCategoriesArgs = {
  filter?: InputMaybe<ContentCategoryFilterInput>;
};


/** Query root */
export type QueryContentItemArgs = {
  virtContentNo: Scalars['Long']['input'];
};


/** Query root */
export type QueryContentItemsArgs = {
  filter?: InputMaybe<ContentFilterInput>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryPromoContentItemsArgs = {
  promoListCd: Scalars['String']['input'];
};


/** Query root */
export type QueryServiceActivationsArgs = {
  subsIdent: Scalars['String']['input'];
};


/** Query root */
export type QuerySubscriberArgs = {
  subsIdent: Scalars['String']['input'];
};

export enum RenewableEnum {
  No = 'NO',
  NoBySubs = 'NO_BY_SUBS',
  Yes = 'YES',
  YesBySubs = 'YES_BY_SUBS'
}

export enum SaleStateEnum {
  Allowed = 'ALLOWED',
  Permitted = 'PERMITTED'
}

export type SearchResult_ContentItem = {
  __typename?: 'SearchResult_ContentItem';
  elements?: Maybe<Array<Maybe<ContentItem>>>;
  numberOfElements?: Maybe<Scalars['Int']['output']>;
  pagination?: Maybe<Pagination>;
};

export enum ServiceActChargeStateEnum {
  Activated = 'ACTIVATED',
  Deferred = 'DEFERRED',
  Waiting = 'WAITING'
}

export enum ServiceActionEnum {
  Activate = 'ACTIVATE',
  Deactivate = 'DEACTIVATE'
}

export type ServiceActivation = {
  __typename?: 'ServiceActivation';
  action?: Maybe<ServiceActionEnum>;
  channelCd?: Maybe<Scalars['String']['output']>;
  channelName?: Maybe<Scalars['String']['output']>;
  channelNo?: Maybe<Scalars['Int']['output']>;
  contentItems?: Maybe<Array<Maybe<ServiceActivationContent>>>;
  createdDt?: Maybe<Scalars['Date']['output']>;
  errCd?: Maybe<Scalars['String']['output']>;
  errMsg?: Maybe<Scalars['String']['output']>;
  extTransId?: Maybe<Scalars['String']['output']>;
  priceModelNo?: Maybe<Scalars['Int']['output']>;
  serviceCd?: Maybe<Scalars['String']['output']>;
  serviceName?: Maybe<Scalars['String']['output']>;
  serviceNo?: Maybe<Scalars['Short']['output']>;
  state?: Maybe<SubscriptionActStateEnum>;
  stateDesc?: Maybe<Scalars['String']['output']>;
  subsActId?: Maybe<Scalars['Long']['output']>;
  subsIdent?: Maybe<Scalars['String']['output']>;
  updatedDt?: Maybe<Scalars['Date']['output']>;
  virtServiceNo?: Maybe<Scalars['Short']['output']>;
};

export type ServiceActivationAcceptInput = {
  confirmNo?: InputMaybe<Scalars['Int']['input']>;
  serviceNo?: InputMaybe<Scalars['Short']['input']>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceActivationContent = {
  __typename?: 'ServiceActivationContent';
  amountMicro?: Maybe<Scalars['BigDecimal']['output']>;
  amountOnetime?: Maybe<Scalars['BigDecimal']['output']>;
  amountPeriodic?: Maybe<Scalars['BigDecimal']['output']>;
  artist?: Maybe<Scalars['String']['output']>;
  boxEndDt?: Maybe<Scalars['Date']['output']>;
  channelCd?: Maybe<Scalars['String']['output']>;
  channelName?: Maybe<Scalars['String']['output']>;
  channelNo?: Maybe<Scalars['Int']['output']>;
  chargePeriod?: Maybe<Scalars['Short']['output']>;
  chargeState?: Maybe<ServiceActChargeStateEnum>;
  complexType?: Maybe<ComplexTypeEnum>;
  composer?: Maybe<Scalars['String']['output']>;
  confirmNo?: Maybe<Scalars['Int']['output']>;
  contentEndDt?: Maybe<Scalars['Date']['output']>;
  contentNo?: Maybe<Scalars['Long']['output']>;
  contentStartDt?: Maybe<Scalars['Date']['output']>;
  contentState?: Maybe<ContentStateEnum>;
  contentSubItems?: Maybe<Array<Maybe<ContentItem>>>;
  contentType?: Maybe<ContentTypeEnum>;
  copyright?: Maybe<Scalars['String']['output']>;
  createdDt?: Maybe<Scalars['Date']['output']>;
  favourite?: Maybe<Scalars['Boolean']['output']>;
  hasPicture?: Maybe<Scalars['Boolean']['output']>;
  imageId?: Maybe<Scalars['Int']['output']>;
  microPeriod?: Maybe<Scalars['Short']['output']>;
  onetimePeriod?: Maybe<Scalars['Short']['output']>;
  pathType?: Maybe<PathTypeEnum>;
  priceEndDt?: Maybe<Scalars['Date']['output']>;
  priceModelNo?: Maybe<Scalars['Int']['output']>;
  priceModelState?: Maybe<EdStateEnum>;
  priceStartDt?: Maybe<Scalars['Date']['output']>;
  providerId?: Maybe<Scalars['Int']['output']>;
  refIdent?: Maybe<Scalars['String']['output']>;
  saleState?: Maybe<SaleStateEnum>;
  subsActId?: Maybe<Scalars['Long']['output']>;
  subsIdent?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  virtContentNo?: Maybe<Scalars['Long']['output']>;
  virtEndDt?: Maybe<Scalars['Date']['output']>;
  virtStartDt?: Maybe<Scalars['Date']['output']>;
  writer?: Maybe<Scalars['String']['output']>;
};

export type ServiceActivationCreateUploadInput = {
  contentTitle?: InputMaybe<Scalars['String']['input']>;
  ext?: InputMaybe<Scalars['String']['input']>;
  pathType?: InputMaybe<PathTypeEnum>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
  virtContentNo?: InputMaybe<Scalars['Long']['input']>;
  virtServiceNo?: InputMaybe<Scalars['Short']['input']>;
};

export type ServiceActivationRejectInput = {
  serviceNo?: InputMaybe<Scalars['Short']['input']>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
};

export type Setup = {
  __typename?: 'Setup';
  artist?: Maybe<Scalars['String']['output']>;
  channelCd?: Maybe<Scalars['String']['output']>;
  channelName?: Maybe<Scalars['String']['output']>;
  channelNo?: Maybe<Scalars['Int']['output']>;
  contentNo?: Maybe<Scalars['Long']['output']>;
  contentType?: Maybe<ContentTypeEnum>;
  createdDt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hasPicture?: Maybe<Scalars['Boolean']['output']>;
  params?: Maybe<Scalars['String']['output']>;
  priceModelNo?: Maybe<Scalars['Int']['output']>;
  serviceCd?: Maybe<Scalars['String']['output']>;
  serviceNo?: Maybe<Scalars['Short']['output']>;
  setupId?: Maybe<Scalars['Long']['output']>;
  setupSubtype?: Maybe<Scalars['String']['output']>;
  setupType?: Maybe<Scalars['String']['output']>;
  subsIdent?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  virtContentNo?: Maybe<Scalars['Long']['output']>;
};

export type Subscriber = {
  __typename?: 'Subscriber';
  billingMode?: Maybe<BillingModeEnum>;
  channelCd?: Maybe<Scalars['String']['output']>;
  channelName?: Maybe<Scalars['String']['output']>;
  channelNo?: Maybe<Scalars['Int']['output']>;
  charges?: Maybe<Array<Maybe<Charge>>>;
  coMode?: Maybe<CoModeEnum>;
  coName?: Maybe<Scalars['String']['output']>;
  coSubsIdent?: Maybe<Scalars['String']['output']>;
  coUnitName?: Maybe<Scalars['String']['output']>;
  coUnitNo?: Maybe<Scalars['Short']['output']>;
  contentItem?: Maybe<ContentItem>;
  contentItems?: Maybe<SearchResult_ContentItem>;
  contractNo?: Maybe<Scalars['String']['output']>;
  copiableContentItems?: Maybe<Array<Maybe<ContentItem>>>;
  createdDt?: Maybe<Scalars['Date']['output']>;
  favouriteContentItems?: Maybe<Array<Maybe<ContentItem>>>;
  giftBoxContentItems?: Maybe<Array<Maybe<GiftBoxContentItem>>>;
  graceDt?: Maybe<Scalars['Date']['output']>;
  inboxContentItems?: Maybe<Array<Maybe<InboxContentItem>>>;
  langCd?: Maybe<Scalars['String']['output']>;
  locationCd?: Maybe<Scalars['String']['output']>;
  permit?: Maybe<PermitEnum>;
  phoneGroups?: Maybe<Array<Maybe<PhoneGroup>>>;
  playConditions?: Maybe<Array<Maybe<PlayCondition>>>;
  preservedContentItems?: Maybe<Array<Maybe<PreservedContentItem>>>;
  promo?: Maybe<Scalars['Boolean']['output']>;
  promoContentItems?: Maybe<Array<Maybe<ContentItem>>>;
  setups?: Maybe<Array<Maybe<Setup>>>;
  state?: Maybe<SubscriberStateEnum>;
  stateDt?: Maybe<Scalars['Date']['output']>;
  subsIdent?: Maybe<Scalars['String']['output']>;
  subsTz?: Maybe<Scalars['Int']['output']>;
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
  usedContentItems?: Maybe<Array<Maybe<UsedContentItem>>>;
};


export type SubscriberChargesArgs = {
  intervalInDays?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type SubscriberContentItemArgs = {
  virtContentNo: Scalars['Long']['input'];
};


export type SubscriberContentItemsArgs = {
  filter?: InputMaybe<ContentFilterInput>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type SubscriberCopiableContentItemsArgs = {
  sourceSubsIdent: Scalars['String']['input'];
};


export type SubscriberGiftBoxContentItemsArgs = {
  sentGifts?: InputMaybe<Scalars['Boolean']['input']>;
};


export type SubscriberPromoContentItemsArgs = {
  promoListCd: Scalars['String']['input'];
};


export type SubscriberSetupsArgs = {
  giftOnly?: InputMaybe<Scalars['Boolean']['input']>;
  intervalInDays?: InputMaybe<Scalars['Int']['input']>;
  langCd?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type SubscriberUsedContentItemsArgs = {
  intervalInDays?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type SubscriberPasswordChangeInput = {
  newPassword?: InputMaybe<Scalars['String']['input']>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
};

export enum SubscriberStateEnum {
  Activated = 'ACTIVATED',
  Suspended = 'SUSPENDED',
  Terminated = 'TERMINATED'
}

export type Subscription = {
  __typename?: 'Subscription';
  amountOnetime?: Maybe<Scalars['BigDecimal']['output']>;
  amountPeriodic?: Maybe<Scalars['BigDecimal']['output']>;
  channelCd?: Maybe<Scalars['String']['output']>;
  channelNo?: Maybe<Scalars['Int']['output']>;
  chargePeriod?: Maybe<Scalars['Short']['output']>;
  onetimePeriod?: Maybe<Scalars['Short']['output']>;
  priceModelNo?: Maybe<Scalars['Int']['output']>;
  renewable?: Maybe<RenewableEnum>;
  serviceCd?: Maybe<Scalars['String']['output']>;
  serviceName?: Maybe<Scalars['String']['output']>;
  serviceNo?: Maybe<Scalars['Short']['output']>;
  subscriptionId?: Maybe<Scalars['Long']['output']>;
  virtServiceNo?: Maybe<Scalars['Short']['output']>;
};

export enum SubscriptionActStateEnum {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
  Deferred = 'DEFERRED',
  Failed = 'FAILED',
  InProgressBss = 'IN_PROGRESS_BSS',
  Ready = 'READY',
  Rejected = 'REJECTED',
  WaitingConfirmation = 'WAITING_CONFIRMATION'
}

export type SubscriptionDeleteInput = {
  serviceNo?: InputMaybe<Scalars['Short']['input']>;
  subsIdent?: InputMaybe<Scalars['String']['input']>;
};

export enum TimeRuleTypeEnum {
  Always = 'ALWAYS',
  Daily = 'DAILY',
  Incomplete = 'INCOMPLETE',
  Monthly = 'MONTHLY',
  OneTime = 'ONE_TIME',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type TimeZoneUpdateInput = {
  subsIdent?: InputMaybe<Scalars['String']['input']>;
  subsTz?: InputMaybe<Scalars['Int']['input']>;
};

export type TypedValue = {
  __typename?: 'TypedValue';
  booleanValue?: Maybe<Scalars['Boolean']['output']>;
  dateValue?: Maybe<Scalars['Date']['output']>;
  enumValue?: Maybe<Scalars['String']['output']>;
  numberValue?: Maybe<Scalars['BigDecimal']['output']>;
  stringValue?: Maybe<Scalars['String']['output']>;
};

export type UsedContentItem = {
  __typename?: 'UsedContentItem';
  amountMicro?: Maybe<Scalars['BigDecimal']['output']>;
  amountOnetime?: Maybe<Scalars['BigDecimal']['output']>;
  amountPeriodic?: Maybe<Scalars['BigDecimal']['output']>;
  artist?: Maybe<Scalars['String']['output']>;
  channelCd?: Maybe<Scalars['String']['output']>;
  channelName?: Maybe<Scalars['String']['output']>;
  channelNo?: Maybe<Scalars['Int']['output']>;
  chargePeriod?: Maybe<Scalars['Short']['output']>;
  complexType?: Maybe<ComplexTypeEnum>;
  composer?: Maybe<Scalars['String']['output']>;
  contentEndDt?: Maybe<Scalars['Date']['output']>;
  contentNo?: Maybe<Scalars['Long']['output']>;
  contentStartDt?: Maybe<Scalars['Date']['output']>;
  contentState?: Maybe<ContentStateEnum>;
  contentSubItems?: Maybe<Array<Maybe<ContentItem>>>;
  contentType?: Maybe<ContentTypeEnum>;
  copyright?: Maybe<Scalars['String']['output']>;
  createdDt?: Maybe<Scalars['Date']['output']>;
  favourite?: Maybe<Scalars['Boolean']['output']>;
  hasPicture?: Maybe<Scalars['Boolean']['output']>;
  imageId?: Maybe<Scalars['Int']['output']>;
  microPeriod?: Maybe<Scalars['Short']['output']>;
  onetimePeriod?: Maybe<Scalars['Short']['output']>;
  pathType?: Maybe<PathTypeEnum>;
  priceEndDt?: Maybe<Scalars['Date']['output']>;
  priceModelNo?: Maybe<Scalars['Int']['output']>;
  priceModelState?: Maybe<EdStateEnum>;
  priceStartDt?: Maybe<Scalars['Date']['output']>;
  providerId?: Maybe<Scalars['Int']['output']>;
  saleState?: Maybe<SaleStateEnum>;
  title?: Maybe<Scalars['String']['output']>;
  virtContentNo?: Maybe<Scalars['Long']['output']>;
  virtEndDt?: Maybe<Scalars['Date']['output']>;
  virtStartDt?: Maybe<Scalars['Date']['output']>;
  writer?: Maybe<Scalars['String']['output']>;
};

export type VirtualService = {
  __typename?: 'VirtualService';
  amountOnetime?: Maybe<Scalars['BigDecimal']['output']>;
  amountPeriodic?: Maybe<Scalars['BigDecimal']['output']>;
  chargePeriod?: Maybe<Scalars['Short']['output']>;
  onetimePeriod?: Maybe<Scalars['Short']['output']>;
  priceModelNo?: Maybe<Scalars['Int']['output']>;
  serviceCd?: Maybe<Scalars['String']['output']>;
  serviceName?: Maybe<Scalars['String']['output']>;
  serviceNo?: Maybe<Scalars['Short']['output']>;
  virtServiceNo?: Maybe<Scalars['Short']['output']>;
};

export type WebParameters = {
  __typename?: 'WebParameters';
  excludedProviderIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  maxFavourites?: Maybe<Scalars['Int']['output']>;
  maxGiftBoxSize?: Maybe<Scalars['Int']['output']>;
  maxInBoxSize?: Maybe<Scalars['Int']['output']>;
  maxPhoneGroups?: Maybe<Scalars['Int']['output']>;
  maxPhoneNumbers?: Maybe<Scalars['Int']['output']>;
  maxPlayConditions?: Maybe<Scalars['Int']['output']>;
  mobileAppAndroidUrl?: Maybe<Scalars['String']['output']>;
  mobileAppIosUrl?: Maybe<Scalars['String']['output']>;
  textForSocialMedia?: Maybe<Scalars['String']['output']>;
  textForSocialMediaEn?: Maybe<Scalars['String']['output']>;
  textInFooter?: Maybe<Scalars['String']['output']>;
  textInFooterEn?: Maybe<Scalars['String']['output']>;
  virtNoBanner1?: Maybe<Scalars['String']['output']>;
  virtNoBanner2?: Maybe<Scalars['String']['output']>;
  virtNoBanner3?: Maybe<Scalars['String']['output']>;
  virtNoBanner4?: Maybe<Scalars['String']['output']>;
};
