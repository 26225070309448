import React, { FC } from 'react';

import { Box, Card, CircularProgress, Divider } from '@mui/material';

import { styles } from './styles';
import { useMainLayout } from './useMainLayout';

import type { ILayoutProps } from '@/types';

import {
  CatalogDrawer,
  Footer,
  Header,
  PurchaseModalWrapper,
  SidebarNavigation,
} from '@/components';

const ConfirmLogoutModal = React.lazy(() => import('@/components/modals/ConfirmLogoutModal'));
const MobileDrawer = React.lazy(() => import('@/components/widgets/MobileDrawer'));

const MainLayout: FC<ILayoutProps> = ({ children, isProtected = false }) => {
  const {
    isRbtAuthorized,
    isLoaded,
    isContentPath,
    isMobileScreenView,
    isTextPageOpen,
    isUserInitLoaded,
  } = useMainLayout();

  return (
    <Box sx={styles.mainContainer}>
      <Header />
      <Box
        sx={[styles.container, !isContentPath && isMobileScreenView ? styles.mobileContainer : {}]}
      >
        {!isMobileScreenView && (
          <>
            <Box sx={styles.sideBar}>
              <Card sx={styles.card}>
                <SidebarNavigation isOpenedByDefault />
                {!isTextPageOpen && (
                  <>
                    {isRbtAuthorized && <Divider sx={{ my: 3 }} />}
                    <CatalogDrawer />
                  </>
                )}
                {/*<Box sx={styles.bannerWrapper}>*/}
                {/*  <Banner />*/}
                {/*</Box>*/}
              </Card>
            </Box>
            <Divider orientation="vertical" flexItem sx={styles.divider} />
          </>
        )}
        {(isProtected && !isUserInitLoaded) || !isLoaded ? (
          <Box sx={styles.progressContainer}>
            <CircularProgress />
          </Box>
        ) : (
          <PurchaseModalWrapper>{children}</PurchaseModalWrapper>
        )}
      </Box>
      <ConfirmLogoutModal />
      {isMobileScreenView && <MobileDrawer />}
      <Footer />
    </Box>
  );
};

export default MainLayout;
