import React, { memo } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

import type { ITypeRulesComponentProps } from '@/components';

import { PhoneInput } from '@/components';

const PhonePlaybackRule: React.FC<ITypeRulesComponentProps> = memo(({ isPreview, ...props }) => {
  const { value, onChange } = props;
  if (isPreview || !onChange) {
    return (
      <Box sx={styles.preview}>
        <Typography variant="h3">{value}</Typography>
      </Box>
    );
  }

  return <PhoneInput size="small" {...props} />;
});

PhonePlaybackRule.displayName = 'PhonePlaybackRule';

export default PhonePlaybackRule;
