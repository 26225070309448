import { useMediaQuery } from '@mui/material';

import { ClientRoutes, theme } from '@/constants';
import { useLocalization } from '@/hooks/index';

const useScreenSize = () => {
  const { currentPath } = useLocalization();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletSmall = useMediaQuery(theme.breakpoints.between('sm', 'bg'));
  const isTabletBig = useMediaQuery(theme.breakpoints.between('bg', 'md'));

  const isTablet = isTabletSmall || isTabletBig;

  const isMobileScreenView = isTablet || isMobile;

  const isHelpPage =
    currentPath === ClientRoutes.FAQ || currentPath === ClientRoutes.TermsAndConditions;
  const isTermsOfUse = currentPath === ClientRoutes.TermsAndConditions;
  const isFAQ = currentPath === ClientRoutes.FAQ;

  const isTextPageOpen = isHelpPage || isTermsOfUse || isFAQ;

  return { isMobile, isTablet, isTabletSmall, isTextPageOpen, isMobileScreenView };
};

export default useScreenSize;
