import React from 'react';

import { Tab, Tabs } from '@mui/material';

import { styles } from './styles';

import type { ICustomTabs } from './types';

const CustomTabs = ({ items, handleChange, value }: ICustomTabs) => {
  return (
    <Tabs sx={styles.main} onChange={handleChange} value={value}>
      {items.map((item) => (
        <Tab key={item.id} {...item} />
      ))}
    </Tabs>
  );
};

export default CustomTabs;
