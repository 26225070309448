import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import { styles } from './styles';
import { useCustomContentCard } from './useCustomContentCard';

import type { ICustomContentCard } from './types';

import {
  ComplexCardLabel,
  NetworkButtons,
  PlayButton,
  PlayContentImage,
  ResponsiveCardComponent,
  ShareIcon,
} from '@/components';

const CustomContentCard = (props: ICustomContentCard) => {
  const { title, artist, contentNo, children } = props;
  const { complexType, isComplexContent, toggleButton, onToggle, onArtistClick } =
    useCustomContentCard(props);

  return (
    <ResponsiveCardComponent sx={styles.container}>
      <Box sx={styles.wrapper}>
        <Box display="flex" gap={2}>
          <PlayContentImage size={120} contentItem={props} isPreview={isComplexContent} />
          <Box sx={styles.item}>
            {isComplexContent && <ComplexCardLabel complexType={complexType} />}
            <Typography
              variant="h2"
              sx={{ ...styles.itemTitle, ...(isComplexContent ? styles.isOneLineRow : {}) }}
            >
              {title}
            </Typography>
            <Typography
              variant="h3"
              onClick={onArtistClick}
              sx={{ ...styles.itemArtist, ...(isComplexContent ? styles.isOneLineRow : {}) }}
            >
              {artist}
            </Typography>
          </Box>
        </Box>
        {!toggleButton ? (
          <Box sx={styles.containerButtons}>
            {!isComplexContent && (
              <Box sx={styles.playButton}>
                <PlayButton contentNo={`${contentNo}`} fullWidth />
              </Box>
            )}
            <Box sx={styles.buttonWrapper}>
              <Button
                sx={styles.networkButton}
                endIcon={<ShareIcon />}
                onClick={onToggle}
                fullWidth
              />
            </Box>
          </Box>
        ) : (
          <NetworkButtons onToggle={onToggle} contentItem={props} />
        )}
      </Box>
      {children}
    </ResponsiveCardComponent>
  );
};

export default CustomContentCard;
