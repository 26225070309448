import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';

const RedDot = styled('div')({
  width: '10px',
  height: '10px',
  backgroundColor: 'red',
  borderRadius: '50%',
  animation: '$blink 1s step-end infinite',
});

const BlinkingRedDot = () => {
  const [isBlinking, setIsBlinking] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsBlinking((prev) => !prev);
    }, 600);

    return () => clearInterval(intervalId);
  }, []);

  return <RedDot style={{ opacity: isBlinking ? 1 : 0 }} />;
};

export default BlinkingRedDot;
