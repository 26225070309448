import { useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getPathSlices } from './constants';
import { styles } from './styles';

import type { IContentItemProps } from './types';

import { ClientRoutes } from '@/constants';
import { useAudioPlayer, useClientConfig } from '@/context';
import { ComplexTypeEnum } from '@/graphql-schema';
import { useGetPageAccessStatus, useLocalization, usePurchaseParams } from '@/hooks';
import { happyHourSelector } from '@/redux/happy-hour/selectors';
import { setOffsetLoading } from '@/redux/offset';
import { setVirtContentNoBuy } from '@/redux/purchases/slice';
import { getHappyHourForContent, getVirtualNoFromContentNo } from '@/utils';

export const useContentItem = ({
  contentItem,
  isFullInfo,
  isOneCard,
  isComplexSubItem,
}: IContentItemProps) => {
  const dispatch = useDispatch();
  const { isHappyHour, happyHourData } = useSelector(happyHourSelector);
  const { navigate, currentPath } = useLocalization();
  const {
    config: { phoneNumberForSms },
  } = useClientConfig();

  const { isContentItemPage } = useGetPageAccessStatus();

  const { clearPurchaseParams } = usePurchaseParams();

  const { isPlaying, selectedAudio, stopPlaying, toggleAudioStatus } = useAudioPlayer();
  const isCurrentPlaying = selectedAudio === contentItem?.contentNo && isPlaying;

  const isFavouriteContent = contentItem?.favourite;

  const complexType = contentItem?.complexType;

  const isComplexContent = !!complexType && complexType !== ComplexTypeEnum.Single;

  const isPackageContent = complexType === ComplexTypeEnum.Package;

  const happyHourContent = useMemo(
    () => getHappyHourForContent({ happyHourData, contentItem }),
    [happyHourData, contentItem],
  );

  const amountOnetime = happyHourContent?.amountOnetime;
  const onetimePeriod =
    happyHourContent?.onetimePeriod === null
      ? contentItem?.onetimePeriod
      : happyHourContent?.onetimePeriod;

  const { searchValue, isSearchResultPage } = useMemo(
    () => getPathSlices(currentPath),
    [currentPath],
  );

  const contentNo = useMemo(
    () =>
      isComplexSubItem
        ? getVirtualNoFromContentNo(contentItem?.contentNo)
        : contentItem?.virtContentNo,
    [isComplexSubItem, contentItem],
  );

  const openModalForBuy = () => {
    clearPurchaseParams();
    stopPlaying();
    dispatch(setVirtContentNoBuy(contentItem?.virtContentNo));
  };

  const onContentItemClick = () => {
    navigate(`${ClientRoutes.Content}/${contentNo}`);
  };

  const onArtistClick = () => {
    dispatch(setOffsetLoading(true));
    navigate(`${ClientRoutes.Artist}/${contentItem?.artist}`);
  };

  const descriptionSx = isOneCard ? styles.oneCard : styles.severalCards;

  const textSx = useMemo(
    () => ({
      ...(!isContentItemPage || isComplexSubItem ? styles.areTwoRows : {}),
      ...(!isContentItemPage && isComplexContent ? styles.isOneLineRow : {}),
    }),
    [isContentItemPage, isComplexContent, isComplexSubItem],
  );

  return {
    textSx,
    isHappyHour,
    happyHourData,
    isContentItemPage,
    complexType,
    contentNo,
    amountOnetime,
    onetimePeriod,
    isPackageContent,
    isComplexContent,
    isFavouriteContent,
    isCurrentPlaying,
    phoneNumberForSms,
    contentItem,
    isFullInfo,
    searchValue,
    isSearchResultPage,
    toggleAudioStatus,
    openModalForBuy,
    onContentItemClick,
    onArtistClick,
    descriptionSx,
  };
};
