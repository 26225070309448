import { theme } from '@/constants';
import palette from '@/constants/theme/palette';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    mb: {
      md: 5,
    },
    py: 3,
    px: {
      xs: 2,
      md: 12.5,
    },
    backgroundColor: 'primary.main',
    borderRadius: {
      xs: '16px 16px 0 0',
      md: 4,
    },
    width: {
      md: 'calc(100% - 200px)',
      xs: 'calc(100% - 32px)',
    },
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'reverse',
      gap: 0,
    },
    width: {
      xs: '100%',
      md: 'calc(100% - 132px)',
    },
  },
  icon: {
    fontSize: '66px !important',
    '&:hover': {
      cursor: { md: 'pointer', xs: 'default' },
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    justifyContent: 'center',
    width: {
      xs: 'calc(100% - 156px)',
      md: 'calc(100% - 124px)',
    },
  },
  contentText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    wordWrap: 'break-word',
  },
  purchaseButton: {
    width: 300,
    justifyContent: 'space-between',
    mt: {
      xs: 2,
      md: 1.5,
    },
    px: 2,
    py: 1.5,
    backgroundColor: 'primary.blue',
    '&:hover': {
      backgroundColor: 'primary.blue',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  purchaseText: {
    textTransform: 'uppercase',
    fontWeight: 700,
    color: 'white',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      mt: 2,
    },
  },
  image: {
    width: 132,
    height: 132,
    borderRadius: 8,
    boxShadow: '0px 4px 24px 0px ' + palette.shadows.main,
  },
  switcher: {},
  imgContainer: {
    position: 'relative',
    '&:hover': {
      '.MuiBox-root': {
        opacity: '1 !important',
        pointerEvents: 'all !important',
      },
    },
  },
  playIcon: {
    height: 'fit-content',
  },
};

export { styles };
