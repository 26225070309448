import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import type { IErrorPurchaseModal } from './types';

import { getErrorMessages } from '@/constants';
import { errorModalSelectors } from '@/redux/error-modal';
import { closeErrorModal } from '@/redux/error-modal/slice';
import { PurchaseEnum } from '@/redux/purchases';
import { purchaseSelector } from '@/redux/purchases/selectors';

type ErrorMessages = {
  [key: string]: string;
};

export const useErrorPurchaseModal = ({ onClose, errorCode }: IErrorPurchaseModal) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isModalOpen: isOpen } = useSelector(errorModalSelectors.errorModalSelector);
  const { purchaseType } = useSelector(purchaseSelector);

  const errorMessages: ErrorMessages = getErrorMessages();

  const isGift = purchaseType === PurchaseEnum.GIFT;
  const isPurchase = purchaseType === PurchaseEnum.BUY;

  const description = useMemo(() => {
    if (errorMessages?.[errorCode]) return errorMessages?.[errorCode];
    if (isPurchase) return t('componentModalBuy.errorPurchaseOrder');
    if (isGift) return t('componentModalBuy.errorGiftOrder');
    return '';
  }, [purchaseType, errorCode]);

  const submitText = isGift ? t('componentModalBuy.tryAgain') : t('componentModalBuy.ok');

  const onSubmit = () => {
    isGift ? dispatch(closeErrorModal()) : onClose();
  };

  return {
    isOpen,
    description,
    submitText,
    cancelText: t('componentModalBuy.cancel'),
    onSubmit,
    onClose,
    onCancel: onClose,
    onlyConfirm: !isGift,
  };
};
