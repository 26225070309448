import { useEffect, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { getData } from './constants';

import { ClientRoutes } from '@/constants';
import { useKeycloak } from '@/context';
import { GET_HAPPY_HOUR_CONTENT } from '@/graphqL-queries/contents';
import { useGraphQlErrorHandler, useLocalization } from '@/hooks';
import { checkAreTwoDaysTheSame } from '@/utils';

export const useHappyHourBanner = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { currentPath } = useLocalization();
  const { pathname } = useLocation();
  const { isLoaded } = useKeycloak();

  const { data, refetch, error } = useQuery(GET_HAPPY_HOUR_CONTENT, {
    fetchPolicy: 'network-only',
    skip: !isLoaded,
  });

  const happyHours = data?.happyHours;

  const bannerData = useMemo(
    () => happyHours?.find(({ providerId }) => providerId === null) ?? happyHours?.[0],
    [happyHours],
  );

  const onetimePeriod = bannerData?.onetimePeriod;

  const amountOnetime = bannerData?.amountOnetime;

  const isOneDay = checkAreTwoDaysTheSame(bannerData?.startDt, bannerData?.endDt);

  const isPromotionsPage = currentPath === ClientRoutes.Promotions;

  const date = useMemo(() => getData(bannerData, isOneDay), [bannerData, language]);

  useGraphQlErrorHandler(error);

  useEffect(() => {
    return () => {
      refetch();
    };
  }, [pathname]);

  return {
    t,
    date,
    isOneDay,
    onetimePeriod,
    amountOnetime,
    isPromotionsPage,
  };
};
