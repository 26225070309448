import React from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

import { Localizations } from '@/constants';
import { useLocalization, useScreenSize } from '@/hooks';

const LocalizationSwitcher = () => {
  const { isEnSelected, isUkSelected, changeLangInUrl } = useLocalization();
  const { isMobileScreenView } = useScreenSize();
  return isMobileScreenView ? (
    <Box onClick={changeLangInUrl(isEnSelected ? Localizations.UK : Localizations.EN)}>
      <Typography variant="h5" color="text.primary">
        {isEnSelected ? 'Eng' : 'Укр'}
      </Typography>
    </Box>
  ) : (
    <Box sx={styles.container}>
      <Typography
        color={isUkSelected ? 'primary.main' : 'text.primary'}
        variant="h5"
        onClick={changeLangInUrl(Localizations.UK)}
        sx={[styles.language, !isUkSelected ? styles.notSelected : null]}
      >
        Укр
      </Typography>
      <Typography variant="h5" color="grey.main">
        /
      </Typography>
      <Typography
        color={isEnSelected ? 'primary.main' : 'text.primary'}
        variant="h5"
        onClick={changeLangInUrl(Localizations.EN)}
        sx={[styles.language, !isEnSelected ? styles.notSelected : null]}
      >
        Eng
      </Typography>
    </Box>
  );
};

export default LocalizationSwitcher;
