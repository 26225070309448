import React from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';
import { usePersonalContentList } from './usePersonalContentList';

import type { IPersonalContentListProps } from './types';

import { ContainerContentItems, EmptyContent, PersonalContentItem } from '@/components';

const PersonalContentList: React.FC<IPersonalContentListProps> = (props) => {
  const { isReview } = props;
  const { t, loading, personalContent } = usePersonalContentList(props);
  return (
    <Box sx={styles.container}>
      <Typography variant="h1" fontWeight={700} color="blue.100">
        {t(isReview ? 'reviewed' : 'rejected')}
      </Typography>
      <EmptyContent
        isLoading={loading}
        title={t(isReview ? 'youHaveNoTonesOnReview' : 'youHaveNoCanceledTones')}
        isEmpty={!personalContent?.length}
      >
        <ContainerContentItems sx={styles.content}>
          {personalContent?.map((contentItem) => (
            <PersonalContentItem {...{ isReview, contentItem }} key={contentItem?.contentNo} />
          ))}
        </ContainerContentItems>
      </EmptyContent>
    </Box>
  );
};

export default PersonalContentList;
