export const styles = {
  img: {
    width: 80,
    height: 80,
    borderRadius: 8,
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'grey.100',
    p: 2,
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'grey.100',
    mt: {
      md: 4,
      xs: 2,
    },
    p: 1.5,
    borderRadius: 2,
  },
  value: {
    width: {
      bg: 210,
      xs: 'auto',
    },
    px: {
      md: 0,
      xs: 2,
    },
    height: 35,
    backgroundColor: 'primary.main',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  defaultValue: {
    width: '100%',
    height: 35,
    background: '#FFC40C',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 1.25,
  },
  settings: {
    height: 'fit-content',
  },
  rowTitle: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  rowTitleDefault: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowItem: {
    mt: 3,
  },
};
