import { useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { ClientRoutes } from '@/constants';
import { useLocalization } from '@/hooks/index';
import { selectGroupForEditing } from '@/redux/groups/slice';
import { notSavedChangesSelectors, NotSavedListType } from '@/redux/not-saved-changes';
import { openNotSavedModal } from '@/redux/not-saved-changes/slice';
import { selectPlayback } from '@/redux/plaback-rules/slice';

const useEditFormDirty = () => {
  const dispatch = useDispatch();
  const {
    isFormGroupDirty,
    isFormPlaybackDirty,
    isRecordContentDirty,
    isUploadContentDirty,
    isAcceptedClose,
  } = useSelector(notSavedChangesSelectors.notSavedChangesSelector);
  const { currentPath } = useLocalization();

  const isFormDirty =
    (isFormGroupDirty || isFormPlaybackDirty || isRecordContentDirty || isUploadContentDirty) &&
    !isAcceptedClose;
  const isPlayRulesPage = useMemo(() => currentPath === ClientRoutes.PlayRules, [currentPath]);

  const openNotSavedChangesModal = () => {
    if (isFormDirty) {
      dispatch(openNotSavedModal(NotSavedListType.Both));
      return isFormDirty;
    }
  };

  const closeEditMode = () => {
    dispatch(selectPlayback(null));
    dispatch(selectGroupForEditing(null));
  };

  const checkPlayRulesPageAndCloseEditMode = () => {
    if (isPlayRulesPage) {
      closeEditMode();
    }
  };

  return {
    closeEditMode,
    openNotSavedChangesModal,
    checkPlayRulesPageAndCloseEditMode,
    isFormDirty,
    isPlayRulesPage,
  };
};
export default useEditFormDirty;
