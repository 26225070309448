import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import type { IClientConfigType } from '@/types';

import { apiUrl } from '@/constants';

export const clientConfigApi = createApi({
  reducerPath: 'clientConfigApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiUrl}/client-config`,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getClientConfig: builder.query<IClientConfigType, {}>({
      query: () => ({
        url: '/',
      }),
    }),
  }),
});

export const { useGetClientConfigQuery } = clientConfigApi;
