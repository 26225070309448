import React, { memo } from 'react';

import { Box, MenuItem, Radio, Typography } from '@mui/material';

import { styles } from './styles';
import { usePlaybackRuleForm } from './usePlaybackRuleForm';

import type { IPlaybackRuleFormProps } from './types';

import { SelectInput } from '@/components';
import { PlayConditionTypeEnum, TimeRuleTypeEnum } from '@/graphql-schema';

const PlaybackRuleForm: React.FC<IPlaybackRuleFormProps> = memo((props) => {
  const { values, rule, errors, touched } = props;

  const { pcType, tmType, tmParams } = values;
  const {
    t,
    pcValue,
    pcTypeValues,
    tmTypeValues,
    pcParamsError,
    PcTypeComponent,
    TmTypeComponent,
    setFormikParamsValue,
    setFormikSelectInputValue,
  } = usePlaybackRuleForm(props);

  return (
    <>
      <Box sx={styles.row}>
        <Typography variant="h3">{t('rule')}</Typography>
        <SelectInput
          name="pcType"
          value={pcType}
          sx={styles.select}
          formControlSx={styles.formControl}
          menuPropsSx={styles.menuPropsSx}
          onChange={setFormikSelectInputValue('pcType')}
          renderValue={() => (
            <Typography variant="h3">
              {pcTypeValues[pcType ?? PlayConditionTypeEnum.Default]}
            </Typography>
          )}
        >
          {Object.entries(pcTypeValues).map(([key, item]) => {
            if (!item) return null;
            const isSelected = pcType === key;

            return (
              <MenuItem value={key} key={key} sx={styles.menuItem}>
                <Typography variant="h3">{item}</Typography>
                <Radio size="small" color="secondary" checked={isSelected} />
              </MenuItem>
            );
          })}
        </SelectInput>
      </Box>
      {pcType !== PlayConditionTypeEnum.Default && (
        <Box mb={3}>
          <PcTypeComponent
            error={pcParamsError}
            value={pcValue}
            rule={rule}
            onChange={setFormikParamsValue('pcParams')}
          />
        </Box>
      )}
      <Box sx={styles.row}>
        <Typography variant="h3">{t('timeCondition')}</Typography>
        <SelectInput
          name="tmType"
          value={tmType}
          sx={styles.select}
          formControlSx={styles.formControl}
          menuPropsSx={styles.menuPropsSx}
          onChange={setFormikSelectInputValue('tmType')}
          renderValue={() => (
            <Typography variant="h3">{tmTypeValues[tmType ?? TimeRuleTypeEnum.Always]}</Typography>
          )}
        >
          {Object.entries(tmTypeValues).map(([key, item]) => {
            if (!item) return null;
            const isSelected = tmType === key;

            return (
              <MenuItem value={key} key={key} sx={styles.menuItem}>
                <Typography variant="h3">{item}</Typography>
                <Radio size="small" color="secondary" checked={isSelected} />
              </MenuItem>
            );
          })}
        </SelectInput>
      </Box>
      {tmType !== TimeRuleTypeEnum.Always && (
        <TmTypeComponent
          error={touched?.tmParams && errors?.tmParams}
          value={tmParams}
          rule={rule}
          onChange={setFormikParamsValue('tmParams')}
        />
      )}
    </>
  );
});

PlaybackRuleForm.displayName = 'PlaybackRuleForm';

export default PlaybackRuleForm;
