import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { apiUrl } from '@/constants';

export const audioApi = createApi({
  reducerPath: 'audioApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiUrl}/audio`,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getAudio: builder.mutation<Blob, { contentNo: string }>({
      query: ({ contentNo }) => ({
        url: `/${contentNo}`,
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const { useGetAudioMutation } = audioApi;
