import { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useClientConfig, useKeycloak } from '@/context';
import { GET_CATEGORIES } from '@/graphqL-queries/categories';
import { useGraphQlErrorHandler } from '@/hooks';
import { setCurrentCategory } from '@/redux/catalog';

export const useCategoriesSwitcher = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { categoryId: contentCatId } = useParams();
  const { isLoaded } = useKeycloak();

  const {
    config: { parentCategoryId, parentPromoId },
  } = useClientConfig();

  const { data: contentCategoriesResponse, error: errorContentCategories } = useQuery(
    GET_CATEGORIES,
    {
      variables: {
        filter: {
          parentCatId: parentCategoryId,
        },
      },
      skip: !parentCategoryId || !isLoaded,
    },
  );

  const { data: promoCategoriesResponse, error: errorPromoCategories } = useQuery(GET_CATEGORIES, {
    variables: {
      filter: {
        parentCatId: parentPromoId,
      },
    },
    skip: !parentPromoId || !isLoaded,
  });

  const promoCategories = promoCategoriesResponse?.contentCategories;

  const contentCategories = contentCategoriesResponse?.contentCategories;

  useGraphQlErrorHandler(errorContentCategories);
  useGraphQlErrorHandler(errorPromoCategories);

  useEffect(() => {
    if (promoCategories?.length && contentCategories?.length) {
      const currentCategory = [...promoCategories, ...contentCategories].find(
        (cat) => cat.contentCatId === contentCatId,
      );
      dispatch(setCurrentCategory(currentCategory));
    }
  }, [promoCategories, contentCategories, contentCatId]);

  return {
    t,
    contentCategories,
    promoCategories,
  };
};
