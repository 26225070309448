import { tabClasses, tabsClasses } from '@mui/material';

const styles = {
  main: {
    [`&.${tabsClasses.root}`]: {
      minHeight: 24,
      [`& .${tabClasses.root}`]: {
        minHeight: 24,
        pt: 0.25,
        px: 1.5,
        pb: 0.75,
        fontSize: 14,
        lineHeight: '16px',
        fontWeight: 400,
        color: 'text.secondary',
        textTransform: 'none',
        '&:not(:last-of-type)': {
          mr: 1,
        },
      },
      [`& .${tabClasses.selected}`]: {
        color: 'text.primary',
      },
    },
    [`& .${tabsClasses.indicator}`]: {
      backgroundColor: 'text.primary',
      height: '1px',
    },
  },
};

export { styles };
