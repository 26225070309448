import { gql } from '@apollo/client';

import type { ICategoriesResponse } from '@/types';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';

export const GET_CATEGORIES: TypedDocumentNode<ICategoriesResponse> = gql`
  query GetContentCategories($filter: ContentCategoryFilterInput) {
    contentCategories(filter: $filter) {
      catName
      contentCatId
      createdDt
      imageId
      orderNo
      parentCatId
    }
  }
`;
