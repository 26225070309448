export const styles = {
  container: {
    flex: 1,
    width: '100%',
    boxSizing: 'border-box',
  },
  mobileNavigation: {
    backgroundColor: 'white',
    px: 2,
    py: 3,
    borderRadius: 4,
    mb: 5,
  },
} as const;
