import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import styles from '../styles';

import { mergeSx } from '@/utils';

function PlayCircleIcon({
  viewBox = '0 0 66 66',
  width = 66,
  height = 66,
  fill = 'white',
  sx = {},
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox={viewBox}
      sx={mergeSx(styles.root, sx)}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33 5.5C17.82 5.5 5.5 17.82 5.5 33C5.5 48.18 17.82 60.5 33 60.5C48.18 60.5 60.5 48.18 60.5 33C60.5 17.82 48.18 5.5 33 5.5ZM33 55C20.8725 55 11 45.1275 11 33C11 20.8725 20.8725 11 33 11C45.1275 11 55 20.8725 55 33C55 45.1275 45.1275 55 33 55ZM44 33L27.5 45.375V20.625L44 33Z"
        fill={fill}
      />
    </SvgIcon>
  );
}

export default PlayCircleIcon;
