import { baseTheme, theme } from '@/constants';
import { addOpacityToHexColor } from '@/utils';

export const styles = {
  btn: {
    minWidth: 170,
    display: 'flex',
    backgroundColor: addOpacityToHexColor(theme.palette.secondary.main, 0.15),
    color: 'secondary.main',
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'secondary.main',
        color: 'white',
      },
    },
    [baseTheme.breakpoints.down('md')]: {
      '&:hover': {
        backgroundColor: addOpacityToHexColor(theme.palette.secondary.main, 0.15),
        color: 'secondary.main',
      },
    },
    py: 1,
    px: 1.5,
  },
} as const;
