import React, { memo } from 'react';

import { Box, Button, FormControl, FormHelperText, Typography } from '@mui/material';

import { styles } from './styles';
import { useWeeklyPlaybackRule } from './useWeeklyPlaybackRule';

import type { ITypeRulesComponentProps } from '@/components';

import { DailyPlaybackRule } from '@/components';

const WeeklyPlaybackRule: React.FC<ITypeRulesComponentProps> = memo((props) => {
  const { error, isPreview } = props;
  const { t, daysOfTheWeek, selectedTime, selectedDays, onChangeDayStatus, onTimeChange } =
    useWeeklyPlaybackRule(props);

  return (
    <>
      <FormControl fullWidth sx={{ mb: 3 }}>
        <Box sx={styles.container}>
          {daysOfTheWeek.map((day, idx) => {
            const isSelected = selectedDays.includes(idx + 1);
            return (
              <Button
                key={day}
                sx={[styles.day, isSelected ? styles.selectedDay : styles.notSelectedDay]}
                disabled={isPreview}
                variant={isSelected ? 'contained' : 'outlined'}
                onClick={onChangeDayStatus(idx, isSelected)}
              >
                <Typography variant="h2">{day}</Typography>
              </Button>
            );
          })}
        </Box>
        {!selectedDays.length && error && (
          <FormHelperText error={!!error}>{t(error as string)}</FormHelperText>
        )}
      </FormControl>
      <DailyPlaybackRule
        error={error}
        allowInitialize
        value={selectedTime}
        isPreview={isPreview}
        onChange={onTimeChange}
      />
    </>
  );
});

WeeklyPlaybackRule.displayName = 'WeeklyPlayback';

export default WeeklyPlaybackRule;
