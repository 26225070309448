export const styles = {
  container: {
    width: '100%',
  },
  conditions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  header: {
    display: 'flex',
    flexDirection: {
      sm: 'row',
      xs: 'column',
    },
    justifyContent: 'space-between',
    alignItems: {
      sm: 'center',
      xs: 'flex-start',
    },
    mb: {
      xs: 0,
      md: 3,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    justifyContent: {
      xs: 'space-between',
      sm: 'flex-start',
    },
    width: {
      xs: '100%',
      sm: 'auto',
    },
  },
  createGroupBtn: {
    display: 'flex',
    justifyContent: 'space-around',
    px: {
      md: 6.25,
      xs: 3,
    },
    py: 1.5,
    height: 48,
    mt: {
      md: 0,
      xs: 2,
    },
  },
  createNewRule: {
    mr: 3,
  },
  createDisableMessage: {
    display: 'flex',
    justifyContent: 'center',
    mt: {
      xs: 2,
      md: 5,
    },
    mb: 4,
  },
  createDisableMessageTxt: {
    color: 'text.secondary',
    textAlign: 'center',
  },
} as const;
