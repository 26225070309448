import { buttonClasses } from '@mui/material';

export const styles = {
  buttons: {
    display: 'flex',
    gap: 12,
  },
  button: {
    boxShadow: 'none',
    borderRadius: 8,
    p: 1,
    minWidth: {
      xs: 47,
      lg: 67,
    },
    width: '100%',
    height: 32,
    [`& .${buttonClasses.endIcon}`]: {
      marginLeft: 0,
      marginRight: 0,
    },
    '&:hover': {
      backgroundColor: {
        xs: 'transparent',
        md: 'grey.400',
      },
      borderRadius: 8,
    },
  },
  playButton: {
    flex: 1,
    maxWidth: 120,
  },
  buttonContainer: {
    flex: 1,
  },
  favouriteBtn: {
    backgroundColor: 'blue.400',
    '&:hover': {
      backgroundColor: 'blue.400',
    },
  },
} as const;
