import { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useActiveSubscriber, useClientConfig, useKeycloak } from '@/context';
import { GET_PLAYBACKS } from '@/graphqL-queries/playback';
import { GET_PERSONAL_CONTENT } from '@/graphqL-queries/subscriber';
import { PlayCondition } from '@/graphql-schema';
import { filterActiveContentItems } from '@/helpers/filterActiveContentItems';
import { useGraphQlErrorHandler } from '@/hooks';
import { NotSavedListType, notSavedChangesSelectors } from '@/redux/not-saved-changes';
import {
  clearFormPlaybackDirty,
  clearNotOpenStatus,
  openNotSavedModal,
} from '@/redux/not-saved-changes/slice';
import { openCreatePlaybackModal, selectPlayback } from '@/redux/plaback-rules/slice';
import { sortPlayRulesWithOutdatedTimes } from '@/utils';

export const usePlaybackList = () => {
  const dispatch = useDispatch();
  const {
    config: { maxPlayConditions },
  } = useClientConfig();
  const { t } = useTranslation();
  const { isRbtAuthorized } = useActiveSubscriber();
  const { msisdn, isLoaded } = useKeycloak();
  const [isActiveContentReceived, setIsActiveContentReceived] = useState(false);
  const { isFormPlaybackDirty, listType, isAcceptedClose } = useSelector(
    notSavedChangesSelectors.notSavedChangesSelector,
  );
  const [isHintOpen, setIsHintOpen] = useState(false);

  const {
    data,
    loading,
    error: errorPlaybacks,
  } = useQuery(GET_PLAYBACKS, {
    variables: {
      msisdn,
    },
    skip: !msisdn || !isRbtAuthorized || !isLoaded,
  });

  const {
    data: personalContent,
    called,
    error: errorPersonal,
  } = useQuery(GET_PERSONAL_CONTENT, {
    variables: {
      msisdn,
    },
    skip: !msisdn || !isRbtAuthorized || !isLoaded,
  });

  const playConditions = data?.subscriber?.playConditions;

  const playConditionsSorted = useMemo(() => {
    return playConditions?.length && Array.isArray(playConditions)
      ? [...(playConditions as PlayCondition[])].sort(sortPlayRulesWithOutdatedTimes)
      : [];
  }, [playConditions]);

  const isCreateDisabled = called && (playConditions?.length ?? 0) >= Number(maxPlayConditions);

  const contentItems = useMemo(() => {
    if (!personalContent?.subscriber) return [];
    const result = personalContent?.subscriber?.inboxContentItems.filter(filterActiveContentItems);
    if (called) {
      setIsActiveContentReceived(true);
    }
    return result;
  }, [personalContent, called]);

  useGraphQlErrorHandler(errorPlaybacks);
  useGraphQlErrorHandler(errorPersonal);

  useEffect(() => {
    const isPlayback = listType === NotSavedListType.Playback;

    if (isAcceptedClose && isPlayback) {
      dispatch(selectPlayback(null));
      dispatch(clearFormPlaybackDirty());
      dispatch(clearNotOpenStatus());
    }
  }, [isFormPlaybackDirty, isAcceptedClose]);

  const onClickCreatePlaybackModal = () => {
    if (isFormPlaybackDirty) {
      const isGroupOrBoth =
        listType === NotSavedListType.Group || listType === NotSavedListType.Both;
      dispatch(
        openNotSavedModal(isGroupOrBoth ? NotSavedListType.Both : NotSavedListType.Playback),
      );
      return;
    }
    dispatch(openCreatePlaybackModal());
  };

  const onToggleHint = useCallback(() => setIsHintOpen(!isHintOpen), [isHintOpen]);

  const onCloseHint = useCallback(() => setIsHintOpen(false), []);

  return {
    t,
    msisdn,
    loading,
    isHintOpen,
    contentItems,
    playConditions,
    isCreateDisabled,
    playConditionsSorted,
    isActiveContentReceived,
    onCloseHint,
    onToggleHint,
    onClickCreatePlaybackModal,
  };
};
