import { dialogClasses, paperClasses } from '@mui/material';

export const styles = {
  dialog: {
    [` .${dialogClasses.container}`]: {
      [` .${paperClasses.root}`]: {
        width: '100%',
        maxWidth: 400,
        mx: 1.5,
      },
    },
  },
  closeIcon: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  description: {
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
} as const;
