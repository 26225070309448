import { baseTheme } from '@/constants';
import palette from '@/constants/theme/palette';

export const styles = {
  logout: {
    fontSize: 18,
    ml: {
      xs: 0,
      md: 0,
    },
  },
  login: {
    ml: 1.5,
    display: 'flex',
    alignItems: 'center',
    lineHeight: 'initial',
    color: 'text.primary',
  },
  phoneAuthorized: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'grey.200',
    borderRadius: {
      sm: 0.5,
      xs: 1.5,
    },
    width: 'auto',
    height: 36,
    px: 1.5,
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: palette.grey['500'],
      },
    },
  },
  loginContainer: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    py: 1,
    px: 1.5,
    borderRadius: 0.5,
    backgroundColor: 'grey.200',
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: palette.grey['400'],
      },
    },
  },
  headerText: {
    mr: 3,
    lineHeight: 'initial',
    color: 'text.primary',
  },
  icon: {
    mr: 1.5,
  },
} as const;
