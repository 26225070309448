import React from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';
import { useAudioRecordInProcess } from './useAudioRecordInProcess';

import type { IAudioRecordInProcessProps } from './types';

import { RecordInProcessIcon, BlinkingRedDot } from '@/components';

const AudioRecordInProcess: React.FC<IAudioRecordInProcessProps> = (props) => {
  const { time, maxDuration } = useAudioRecordInProcess(props);
  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant="h3" color="text.secondary">
          {time} / {maxDuration}
        </Typography>
        <BlinkingRedDot />
      </Box>
      <Box sx={styles.iconContainer}>
        <RecordInProcessIcon sx={styles.recordInProcessIcon} />
      </Box>
    </Box>
  );
};

export default AudioRecordInProcess;
