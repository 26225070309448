import React from 'react';

import { SvgIcon } from '@mui/material';

import styles from '../styles';

import type { SvgIconProps } from '@mui/material';

import { mergeSx } from '@/utils';
const CrossIcon = ({
  viewBox = '0 0 24 24',
  sx = {},
  width = 24,
  height = 24,
  fill = '#11223C',
  ...rest
}: SvgIconProps) => (
  <SvgIcon
    viewBox={viewBox}
    sx={mergeSx(styles.root, sx)}
    width={width}
    height={height}
    fill="none"
    {...rest}
  >
    <g clipPath="url(#clip0_263_2425)">
      <path
        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_263_2425">
        <rect width={width} height={width} fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default CrossIcon;
