import React from 'react';

import { useClientConfigProvider } from './useClientConfigProvider';

import { ClientConfigContext } from '@/context';

interface IConfigProviderProps {
  children: React.ReactNode;
}

const ConfigProvider: React.FC<IConfigProviderProps> = ({ children }) => {
  const value = useClientConfigProvider();
  return <ClientConfigContext.Provider value={value}>{children}</ClientConfigContext.Provider>;
};

export default ConfigProvider;
