import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import styles from '../styles';

import { mergeSx } from '@/utils';

function GiftIcon({ viewBox = '0 0 25 24', sx = {}, ...rest }: SvgIconProps) {
  return (
    <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.42263 8C7.16007 7.5707 7.025 7.04228 7.025 6.4C7.025 5.13367 7.97728 4 9.425 4C10.8746 4 12.0436 4.9709 12.625 6.49106C13.2064 4.9709 14.3754 4 15.825 4C17.2727 4 18.225 5.13367 18.225 6.4C18.225 7.04228 18.0899 7.5707 17.8274 8H19.025C19.9087 8 20.625 8.71634 20.625 9.6V11.2C20.625 12.0837 19.9087 12.8 19.025 12.8V18.4C19.025 19.2837 18.3087 20 17.425 20H7.825C6.94134 20 6.225 19.2837 6.225 18.4V12.8C5.34134 12.8 4.625 12.0837 4.625 11.2V9.6C4.625 8.71634 5.34134 8 6.225 8H7.42263ZM11.8243 9.60018H6.22426V11.2002H11.8243V9.60018ZM19.0237 11.2002H13.4237V9.60018H19.0237V11.2002ZM11.8254 18.3996V12.7996H7.82537V18.3996H11.8254ZM17.4237 18.3996H13.4237V12.7996H17.4237V18.3996ZM9.425 5.60018C8.92872 5.60018 8.625 5.96175 8.625 6.40018C8.625 7.40277 9.4386 7.87948 11.3685 7.97977C11.154 6.48946 10.3547 5.60018 9.425 5.60018ZM13.8254 7.97977C14.0399 6.48946 14.8392 5.60018 15.7689 5.60018C16.2652 5.60018 16.5689 5.96175 16.5689 6.40018C16.5689 7.40277 15.7553 7.87948 13.8254 7.97977Z"
        fill="#0D4BA0"
      />
    </SvgIcon>
  );
}

export default GiftIcon;
