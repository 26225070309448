import React, { memo } from 'react';

import { Typography, ButtonGroup, Button } from '@mui/material';

import { styles } from './styles';
import { useSliderSwitcher } from './useSliderSwitcher';

import type { ISliderSwitcher } from './types';

const SliderSwitcher = memo((props: ISliderSwitcher) => {
  const { items = [] } = props;
  const { getButtonLabel, onSliderClick, getMobileBtnStyle } = useSliderSwitcher(props);

  return (
    <ButtonGroup disableRipple disableFocusRipple sx={styles.buttonGroups}>
      {items.map(({ label, value, active }, idx) => (
        <Button
          disableRipple
          disableFocusRipple
          key={value}
          onClick={onSliderClick(value, label)}
          sx={[
            active ? styles.active : styles.noActive,
            getMobileBtnStyle(idx),
            !label ? styles.disabled : null,
          ]}
          title={label}
        >
          <Typography variant="h3" sx={styles.title}>
            {getButtonLabel(label)}
          </Typography>
        </Button>
      ))}
    </ButtonGroup>
  );
});

SliderSwitcher.displayName = 'SliderSwitcher';

export default SliderSwitcher;
