import React from 'react';

import { Box, Typography, List } from '@mui/material';

import { styles } from './styles';
import { useCategoriesSwitcher } from './useCategoriesSwitcher';

import { CategoryItem } from '@/components';

const CategoriesSwitcher = () => {
  const { t, contentCategories, promoCategories } = useCategoriesSwitcher();
  return (
    <>
      <Box>
        <Typography variant="h2" color="text.primary">
          {t('promo')}
        </Typography>
        <List sx={styles.list}>
          {promoCategories?.map((category) => (
            <CategoryItem {...category} key={category.contentCatId} />
          ))}
        </List>
      </Box>
      <Box mt={2}>
        <Typography variant="h2" color="text.primary">
          {t('genres')}
        </Typography>
        <Box sx={styles.list}>
          {contentCategories?.map((category) => (
            <CategoryItem {...category} key={category.contentCatId} />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default CategoriesSwitcher;
