import { useAudioPlayer } from '@/context';

export const usePlayButton = (contentNo: string) => {
  const { isPlaying, selectedAudio, toggleAudioStatus } = useAudioPlayer();
  const isCurrentPlaying = selectedAudio === contentNo && isPlaying;
  return {
    isCurrentPlaying,
    toggleAudioStatus,
  };
};
