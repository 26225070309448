import React from 'react';

import { SvgIcon } from '@mui/material';

import styles from '../styles';

import type { SvgIconProps } from '@mui/material';

import { theme } from '@/constants';
import { mergeSx } from '@/utils';

const FileIcon = ({
  viewBox = '0 0 25 24',
  fill = theme.palette.text.primary,
  sx = {},
  ...rest
}: SvgIconProps) => {
  return (
    <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 2H14.5L20.5 8V20C20.5 21.1 19.6 22 18.5 22H6.49C5.39 22 4.5 21.1 4.5 20L4.51 4C4.51 2.9 5.4 2 6.5 2ZM6.5 4V20H18.5V9H13.5V4H6.5Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default FileIcon;
