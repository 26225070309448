import { createSlice } from '@reduxjs/toolkit';

import type { IRecordContentState } from './types';

const initialState: IRecordContentState = {
  isDeleteModalOpen: false,
  selectedContent: null,
};

export const personalContentSlice = createSlice({
  name: 'personalContent',
  initialState,
  reducers: {
    openDeletePersonalContentModal: (state, { payload }) => {
      state.selectedContent = payload;
      state.isDeleteModalOpen = true;
    },
    closeDeletePersonalContentModal: (state) => {
      state.isDeleteModalOpen = false;
    },
  },
});

export const { openDeletePersonalContentModal, closeDeletePersonalContentModal } =
  personalContentSlice.actions;

export default personalContentSlice.reducer;
