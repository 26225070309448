import React from 'react';

import { Box, Button } from '@mui/material';

import { styles } from './styles';
import { useNetworkButtons } from './useNetworkButtons';

import type { INetworkButtonsProps } from './types';

import { ArrowLeftIcon, FacebookIcon, TelegramIcon, ViberIcon } from '@/components';
import { ExternalUrls } from '@/constants';

const NetworkButtons = (props: INetworkButtonsProps) => {
  const { onToggle } = props;
  const { goToSocialNetwork } = useNetworkButtons(props);
  return (
    <Box sx={styles.container}>
      <Button sx={styles.buttonBack} endIcon={<ArrowLeftIcon />} onClick={onToggle} />
      <Button
        sx={styles.button}
        endIcon={<FacebookIcon />}
        onClick={goToSocialNetwork(ExternalUrls.Facebook)}
      />
      <Button
        sx={styles.button}
        endIcon={<ViberIcon />}
        onClick={goToSocialNetwork(ExternalUrls.Viber)}
      />
      <Button
        sx={styles.button}
        endIcon={<TelegramIcon />}
        onClick={goToSocialNetwork(ExternalUrls.Telegram)}
      />
    </Box>
  );
};

export default NetworkButtons;
