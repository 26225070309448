import React from 'react';

import { Typography } from '@mui/material';
import { t } from 'i18next';

import { styles } from './styles';

import { CategoriesSwitcher } from '@/components';

const CatalogDrawer = () => (
  <>
    <Typography variant="h1" fontWeight="bold" sx={styles.title}>
      {t('catalog')}
    </Typography>
    <CategoriesSwitcher />
  </>
);

export default CatalogDrawer;
