import { gql } from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';

import type { Subscriber } from '@/graphql-schema';

export const CREATE_PLAYBACK = gql`
  mutation PlayConditionCreate($input: PlayConditionCreateInput) {
    playConditionCreate(input: $input) {
      error {
        code
        message
      }
    }
  }
`;

export const UPDATE_PLAYBACK = gql`
  mutation PlayConditionUpdate($input: PlayConditionUpdateInput) {
    playConditionUpdate(input: $input) {
      error {
        code
        message
      }
    }
  }
`;

export const GET_PLAYBACKS: TypedDocumentNode<{ subscriber: Subscriber }> = gql`
  query subscriberContent($msisdn: String!) {
    subscriber(subsIdent: $msisdn) {
      playConditions {
        playCondId
        pcType
        pcParams
        tmParams
        phoneGroupId
        tmType
        contentItem {
          contentNo
          title
          artist
          hasPicture
          contentType
        }
      }
    }
  }
`;

export const DELETE_PLAYBACK = gql`
  mutation PlayConditionDelete($input: PlayConditionDeleteInput) {
    playConditionDelete(input: $input) {
      error {
        code
        message
      }
    }
  }
`;
