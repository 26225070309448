import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import styles from '../styles';

import { mergeSx } from '@/utils';

function ShareIcon({ viewBox = '0 0 25 24', sx = {}, ...rest }: SvgIconProps) {
  return (
    <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4752 15.9277C15.8797 15.5502 16.4086 15.3092 16.9997 15.3092C18.2519 15.3092 19.2708 16.3614 19.2708 17.6546C19.2708 18.9478 18.2519 20 16.9997 20C15.7475 20 14.7286 18.9478 14.7286 17.6546C14.7286 17.4779 14.7519 17.3012 14.7908 17.1325L9.25301 13.7912C8.83301 14.1928 8.28079 14.4418 7.66634 14.4418C6.37523 14.4418 5.33301 13.3655 5.33301 12.0321C5.33301 10.6988 6.37523 9.62249 7.66634 9.62249C8.28079 9.62249 8.83301 9.87149 9.25301 10.2731L14.7363 6.97189C14.6975 6.78715 14.6663 6.60241 14.6663 6.40964C14.6663 5.07631 15.7086 4 16.9997 4C18.2908 4 19.333 5.07631 19.333 6.40964C19.333 7.74297 18.2908 8.81928 16.9997 8.81928C16.3852 8.81928 15.833 8.57028 15.413 8.16867L9.92967 11.4699C9.96856 11.6546 9.99967 11.8394 9.99967 12.0321C9.99967 12.2249 9.96856 12.4096 9.92967 12.5944L15.4752 15.9277ZM17.7766 6.40966C17.7766 5.96789 17.4266 5.60645 16.9989 5.60645C16.5711 5.60645 16.2211 5.96789 16.2211 6.40966C16.2211 6.85143 16.5711 7.21287 16.9989 7.21287C17.4266 7.21287 17.7766 6.85143 17.7766 6.40966ZM7.66541 12.8354C7.23764 12.8354 6.88764 12.474 6.88764 12.0322C6.88764 11.5904 7.23764 11.229 7.66541 11.229C8.09319 11.229 8.44319 11.5904 8.44319 12.0322C8.44319 12.474 8.09319 12.8354 7.66541 12.8354ZM16.2211 17.6706C16.2211 18.1124 16.5711 18.4739 16.9989 18.4739C17.4266 18.4739 17.7766 18.1124 17.7766 17.6706C17.7766 17.2289 17.4266 16.8674 16.9989 16.8674C16.5711 16.8674 16.2211 17.2289 16.2211 17.6706Z"
        fill="#0D4BA0"
      />
    </SvgIcon>
  );
}

export default ShareIcon;
