import React from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';
import { useHeaderNavigation } from './useHeaderNavigation';

import { ClientRoutes } from '@/constants';

interface IHeaderNavigationProps {
  isDrawer?: boolean;
}

const HeaderNavigation: React.FC<IHeaderNavigationProps> = ({ isDrawer = false }) => {
  const { currentPath, navigationList, goToPage } = useHeaderNavigation();
  return (
    <Box sx={[styles.container, isDrawer ? { mb: 0 } : styles.drawerWithSpacing]}>
      {navigationList.map(({ title, link, subPages }) => {
        const isSelected =
          currentPath.includes(link) ||
          (subPages && subPages.includes(currentPath as ClientRoutes));
        return (
          <Box
            sx={{ ...styles.item, ...(isSelected ? styles.selected : styles.noSelected) }}
            key={title}
            onClick={goToPage(link)}
          >
            <Typography variant="h2" sx={styles.title}>
              {title}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default HeaderNavigation;
