import { baseTheme } from '@/constants';
import palette from '@/constants/theme/palette';

export const styles = {
  imgPreview: {
    borderRadius: 8,
  },
  buttons: {
    display: 'flex',
    gap: 12,
  },
  playButtonIcon: {
    fill: 'white',
    width: 10,
    height: 12,
  },
  button: {
    '.MuiButton-endIcon': {
      marginLeft: 0,
      marginRight: 0,
    },
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.400',
        borderRadius: 8,
      },
    },
    '&:focus': {
      backgroundColor: 'primary.soft.blue.150',
      borderRadius: 8,
    },
  },
  buttonIcon: {
    borderRadius: 2,
    boxShadow: '0 4px 24px 0 ' + palette.shadows.main,
    minWidth: 57,
    px: 0,
    py: 1,
  },
  network: {
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.500',
      },
    },
    backgroundColor: 'grey.400',
  },
  back: {
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.400',
      },
    },
    backgroundColor: 'none',
  },
  imgContainerPlay: {
    position: 'relative',
    '&:hover': {
      '.MuiBox-root': {
        opacity: '1 !important',
        pointerEvents: 'all !important',
      },
    },
  },
  overlay: {
    borderRadius: 2,
    backgroundColor: palette.grey.overlay,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transition: 'opacity 250ms linear',
    top: 0,
    cursor: { md: 'pointer', xs: 'default' },
    pointerEvents: 'none',
  },
  playIcon: {
    width: 21,
    height: 24,
    fill: 'white',
  },
  pauseIcon: {
    fontSize: 32,
    color: 'white',
  },
} as const;
