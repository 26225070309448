import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { ClientRoutes, getHeaderNavigationList } from '@/constants';
import { useActiveSubscriber, useClientConfig } from '@/context';
import { useEditFormDirty, useLocalization, useMobileDrawerHook, useScreenSize } from '@/hooks';

export const useHeaderNavigation = () => {
  const { isMobileScreenView } = useScreenSize();
  const {
    config: { isTestMode, defaultCatalogCategory },
  } = useClientConfig();
  const { onMobileDrawerClose } = useMobileDrawerHook();
  const { isRbtAuthorized } = useActiveSubscriber();
  const { navigate, currentPath } = useLocalization();
  const { openNotSavedChangesModal, checkPlayRulesPageAndCloseEditMode } = useEditFormDirty();
  const {
    i18n: { language },
  } = useTranslation();

  const navigationList = useMemo(() => {
    const list = getHeaderNavigationList();
    if (!isRbtAuthorized && !isTestMode) {
      return list.filter(({ isRbtProtected }) => !isRbtProtected);
    }
    return list;
  }, [language, isRbtAuthorized, isTestMode]);

  const goToPage = (link: string) => () => {
    if (!openNotSavedChangesModal()) {
      checkPlayRulesPageAndCloseEditMode();
      if (link === ClientRoutes.Catalog) {
        navigate(`${link}/${defaultCatalogCategory}`);
      } else navigate(link);
    }
    if (isMobileScreenView) {
      onMobileDrawerClose();
    }
  };

  return { navigationList, currentPath, goToPage };
};
