import { pathToHappyHourBg } from '@/constants';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    py: 3,
    px: {
      xs: 2,
      md: 12.5,
    },
    backgroundColor: 'primary.main',
    flexGrow: 1,
    borderRadius: 4,
    backgroundImage: `url(${pathToHappyHourBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionX: {
      xs: '65%',
    },
  },
  bannerDescription: {
    maxWidth: { xs: 200, md: 500, lg: 600 },
    color: 'secondary.main',
  },
};

export { styles };
