export const dailyTimeRegexp = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]~([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

export const weeklyTimeRegexp = /^(?:[1-7]\s\d{2}:\d{2}~[1-7]\s\d{2}:\d{2}(?:,(?!$)|$))+$/;

export const monthlyTimeRegexp =
  /^(\d{1,2}\s+\d{2}:\d{2}~\d{1,2}\s+\d{2}:\d{2}(?:,\s*\d{1,2}\s+\d{2}:\d{2}~\d{1,2}\s+\d{2}:\d{2})*)$/;

export const yearlyTimeRegexp =
  /^(\d{2}-\d{2}\s\d{2}:\d{2}~\d{2}-\d{2}\s\d{2}:\d{2})(,\d{2}-\d{2}\s\d{2}:\d{2}~\d{2}-\d{2}\s\d{2}:\d{2})*$/;

export const oneTimeRegexp =
  /^(\d{2}-\d{2}-\d{2}\s\d{2}:\d{2}~\d{2}-\d{2}-\d{2}\s\d{2}:\d{2})(,\d{2}-\d{2}-\d{2}\s\d{2}:\d{2}~\d{2}-\d{2}-\d{2}\s\d{2}:\d{2})*$/;

export const phoneRegex = /^380\d{9}$/;

export const highlightedTextRegExp = '(?!([^<]+)?<)';

// eslint-disable-next-line no-useless-escape
export const escapeSpecialChars = /[\[\]\\^$.|?*+()]/g;

export const removeSpacesRegExp = /\s+/g;

export const contentItemPageRegExp = /\/content\/(\d+)/;

export const meloringTitleRegExp = /^(\/+)?$/;
