import type { ChangeEvent, FocusEvent } from 'react';
import { useEffect, useMemo, useRef } from 'react';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { getDailySchema } from './schema';

import type { ITypeRulesComponentProps } from '@/components';

import { TimeRuleTypeEnum } from '@/graphql-schema';
import { hasObjectAnyValues, convertNumbersToTimeString } from '@/utils';

export const useDailyPlaybackRule = ({
  value,
  onChange,
  rule,
  allowInitialize,
  isPreview,
}: ITypeRulesComponentProps) => {
  const firstRender = useRef(true);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const dailySchema = useMemo(() => getDailySchema(), [language]);
  const { values, errors, setFieldValue, setFieldTouched } = useFormik({
    initialValues: {
      startDate: '00:00',
      endDate: '23:59',
    },
    validationSchema: dailySchema,
    onSubmit: () => {},
  });

  const isDaily = rule === TimeRuleTypeEnum.Daily;
  const isSavedRule = isDaily || allowInitialize;

  useEffect(() => {
    const isError = hasObjectAnyValues(errors);
    if (onChange && !isError) {
      const { startDate, endDate } = values;
      onChange(`${startDate}~${endDate}`);
    }
  }, [values, errors]);

  useEffect(() => {
    if (value && isSavedRule && firstRender.current) {
      const [start, end] = value.split('~');
      setFieldValue('startDate', start);
      setFieldValue('endDate', end);
      firstRender.current = false;
    }
  }, [value, isSavedRule, isPreview]);

  const handleInputBlur = (e: FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setFieldTouched(name, true);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFieldValue(name, convertNumbersToTimeString(value));
  };

  const startDateError = errors.startDate;
  const endDateError = errors.endDate;

  return {
    t,
    values,
    startDateError,
    endDateError,
    handleInputChange,
    handleInputBlur,
  };
};
