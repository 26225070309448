import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import type { PlayCondition } from '@/graphql-schema';

import { notSavedChangesSelectors, NotSavedListType } from '@/redux/not-saved-changes';
import { openNotSavedModal } from '@/redux/not-saved-changes/slice';
import { selectedPlaybackForEditingSelector } from '@/redux/plaback-rules/selectors';
import { openDeletePlaybackModal, selectPlayback } from '@/redux/plaback-rules/slice';

export const usePlaybackItem = (condition: PlayCondition) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isFormPlaybackDirty, listType } = useSelector(
    notSavedChangesSelectors.notSavedChangesSelector,
  );
  const selectedPlayback = useSelector(selectedPlaybackForEditingSelector);

  const isSelectedItem = condition.playCondId === selectedPlayback?.playCondId;

  const toggleIsEditing = useCallback(() => {
    if (isFormPlaybackDirty) {
      const isGroupOrBoth =
        listType === NotSavedListType.Group || listType === NotSavedListType.Both;
      dispatch(
        openNotSavedModal(isGroupOrBoth ? NotSavedListType.Both : NotSavedListType.Playback),
      );
      return;
    }
    dispatch(selectPlayback(isSelectedItem ? null : condition));
  }, [condition, isSelectedItem, isFormPlaybackDirty]);

  const openDeleteModal = useCallback(() => {
    dispatch(openDeletePlaybackModal(condition));
  }, [condition]);

  return {
    t,
    isSelectedItem,
    toggleIsEditing,
    openDeleteModal,
  };
};
