import React from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';
import { useToggleDisablePermission } from './useToggleDisablePermission';

import { CustomCheckbox } from '@/components';

const ConfirmDisableServiceModal = React.lazy(
  () => import('@/components/modals/ConfirmDisableServiceModal'),
);
const DisableServiceModal = React.lazy(() => import('@/components/modals/DisableServiceModal'));
const PermissionServiceModal = React.lazy(
  () => import('@/components/modals/PermissionServiceModal'),
);

const ToggleDisablePermission = () => {
  const { t, isChecked, onOpenPermissionModalClick, onOpenDisabledModal } =
    useToggleDisablePermission();
  return (
    <Box sx={styles.container}>
      <Box sx={styles.button} onClick={onOpenDisabledModal}>
        <Typography variant="h6" color="text.primary">
          {t('disableService')}
        </Typography>
      </Box>
      <DisableServiceModal />
      <ConfirmDisableServiceModal />
      <Box sx={styles.wrapper} onClick={onOpenPermissionModalClick}>
        <CustomCheckbox checked={isChecked} />
        <Typography variant="h4" color="text.primary">
          {t('contentAvailableToSubscribers')}
        </Typography>
      </Box>
      <PermissionServiceModal />
    </Box>
  );
};

export default ToggleDisablePermission;
