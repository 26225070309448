import { gql } from '@apollo/client';

export const POST_CONTENT_PURCHASE = gql`
  mutation PostContentPurchase($input: ContentPurchaseInput) {
    contentPurchase(input: $input) {
      error {
        code
        message
      }
    }
  }
`;

export const POST_CONTENT_GIFT = gql`
  mutation PostContentGift($input: ContentGiftInput) {
    contentGift(input: $input) {
      error {
        code
        message
      }
    }
  }
`;

export const POST_GIFT_CONTENT_ACCEPT = gql`
  mutation PostGiftContentAccept($input: GiftContentAcceptInput) {
    giftContentAccept(input: $input) {
      error {
        code
        message
      }
    }
  }
`;

export const POST_GIFT_CONTENT_REJECT = gql`
  mutation PostGiftContentReject($input: GiftContentRejectInput) {
    giftContentReject(input: $input) {
      error {
        code
        message
      }
    }
  }
`;
