import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { ClientRoutes } from '@/constants';
import { useActiveSubscriber, useKeycloak } from '@/context';
import { useScreenSize } from '@/hooks';

export const useMainLayout = () => {
  const { pathname } = useLocation();

  const { isLoaded, msisdn } = useKeycloak();
  const { isMobileScreenView, isTextPageOpen } = useScreenSize();
  const { isRbtAuthorized } = useActiveSubscriber();

  const { isUserInitLoaded } = useActiveSubscriber();

  const isContentPath = useMemo(() => pathname.includes(ClientRoutes.Content), [pathname]);

  return {
    msisdn,
    isLoaded,
    isContentPath,
    isRbtAuthorized,
    isMobileScreenView,
    isTextPageOpen,
    isUserInitLoaded,
  };
};
