import { dialogClasses, paperClasses } from '@mui/material';

export const styles = {
  dialog: {
    [` .${dialogClasses.container}`]: {
      [` .${paperClasses.root}`]: {
        width: '100%',
        maxWidth: 568,
        mx: 1.5,
      },
    },
  },
  closeIconBtn: {
    p: 0,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    mt: 2,
    p: 1,
    borderRadius: 2,
  },
  closeIcon: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  contentNo: {
    height: 92,
    backgroundColor: 'background.default',
    borderRadius: 2,
  },
  contentNoFormControl: {
    width: '100%',
  },
  dialogContent: {
    p: 2,
    mt: 2,
  },
  submitBtn: {
    flexGrow: 1,
    height: 40,
  },
  cancel: {
    width: 100,
    height: 40,
    mr: 0.5,
  },
  actions: {
    pt: 3,
  },
};
