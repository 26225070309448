import React from 'react';

import { useSuccessPurchaseModal } from './useSuccessPurchaseModal';

import type { ISuccessPurchaseModal } from './types';

import { CustomConfirmModal } from '@/components';

const SuccessPurchaseModal = (props: ISuccessPurchaseModal) => {
  const modalProps = useSuccessPurchaseModal(props);

  return <CustomConfirmModal {...modalProps} />;
};

export default SuccessPurchaseModal;
