import React from 'react';

import { useDeclineGiftModal } from './useDeclineGiftModal';

import { CustomConfirmModal } from '@/components';

const DeclineGiftModal = () => {
  const modalProps = useDeclineGiftModal();
  return <CustomConfirmModal {...modalProps} />;
};

export default DeclineGiftModal;
