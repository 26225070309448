import { createSlice } from '@reduxjs/toolkit';

import type { IAcceptGiftModalState } from './types';

const initialState: IAcceptGiftModalState = {
  isModalOpen: false,
  contentItem: null,
};

export const acceptGiftModalSlice = createSlice({
  name: 'acceptGiftModal',
  initialState,
  reducers: {
    openAcceptGiftModal: (state, { payload }) => {
      state.contentItem = payload;
      state.isModalOpen = true;
    },
    closeAcceptGiftModal: (state) => {
      state.isModalOpen = false;
      state.contentItem = null;
    },
  },
});

export const { openAcceptGiftModal, closeAcceptGiftModal } = acceptGiftModalSlice.actions;

export default acceptGiftModalSlice.reducer;
