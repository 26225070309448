import { styles } from './styles';

import type { ISliderSwitcher } from './types';

import { useScreenSize } from '@/hooks';
import { shortenString } from '@/utils';

export const useSliderSwitcher = ({ onChange = () => {} }: ISliderSwitcher) => {
  const { isMobile } = useScreenSize();

  const onSliderClick = (value: number, label: string) => () => label && onChange(value);

  const getMobileBtnStyle = (idx: number) => {
    return [styles.first, styles.second, styles.third, styles.forth][idx];
  };

  const getButtonLabel = (label: string) => shortenString(label, isMobile ? 15 : 30);

  return {
    isMobile,
    onSliderClick,
    getMobileBtnStyle,
    getButtonLabel,
  };
};
