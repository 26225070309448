import { useCallback, useEffect, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import type { IPlaybackItemEditProps } from './types';
import type { IUpdatePlaybackType } from '@/types';

import { ErrorCodes, playbackRuleSchema } from '@/constants';
import { useKeycloak } from '@/context';
import { GET_PLAYBACKS, UPDATE_PLAYBACK } from '@/graphqL-queries/playback';
import { PlayConditionTypeEnum, TimeRuleTypeEnum } from '@/graphql-schema';
import { useGraphQlErrorHandler } from '@/hooks';
import { NotSavedListType } from '@/redux/not-saved-changes';
import { clearFormPlaybackDirty, setFormPlaybackDirty } from '@/redux/not-saved-changes/slice';
import { selectPlayback } from '@/redux/plaback-rules/slice';
import { hasObjectAnyValues, responseErrorHandling } from '@/utils';

export const usePlaybackItemEdit = ({ condition }: IPlaybackItemEditProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { msisdn } = useKeycloak();
  const [updatePlayback, { error }] = useMutation(UPDATE_PLAYBACK);

  const initialValues = useMemo(
    () => ({
      pcType: condition?.pcType ?? PlayConditionTypeEnum.Default,
      pcParams: condition?.pcParams ?? null,
      tmType: condition?.tmType ?? TimeRuleTypeEnum.Always,
      tmParams: condition?.tmParams ?? null,
      contentNo: condition?.contentItem?.contentNo ?? '',
      phoneGroupId:
        condition?.pcType === PlayConditionTypeEnum.Group ? `${condition?.phoneGroupId}` : null,
      playCondId: condition?.playCondId ?? '',
      subsIdent: msisdn,
    }),
    [condition],
  );

  const rule = initialValues.tmType;

  const closeEditOnSubmit = useCallback(() => {
    dispatch(clearFormPlaybackDirty());
    dispatch(selectPlayback(null));
  }, []);

  const onSubmit = (params: IUpdatePlaybackType) => {
    const phoneGroupId =
      params.pcType === PlayConditionTypeEnum.Group ? parseInt(params?.phoneGroupId ?? '') : null;

    updatePlayback({
      variables: {
        input: {
          ...params,
          phoneGroupId,
        },
      },
      onCompleted: (data) => {
        responseErrorHandling(data?.playConditionUpdate);
      },
      refetchQueries: [
        {
          query: GET_PLAYBACKS,
          variables: {
            msisdn,
          },
        },
      ],
      awaitRefetchQueries: true,
    })
      .then(() => {
        closeEditOnSubmit();
      })
      .catch((err) => {
        const errorCode = err?.graphQLErrors?.[0]?.extensions?.code;
        const isNotRbtError = errorCode === ErrorCodes.NO_RBT_AUTHORIZED;
        if (isNotRbtError) {
          dispatch(
            setFormPlaybackDirty({
              listType: NotSavedListType.Playback,
              isFormPlaybackDirty: false,
            }),
          );
        }
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validationSchema: playbackRuleSchema,
  });

  const { setFieldValue, errors, dirty } = formik;

  useGraphQlErrorHandler(error);

  useEffect(() => {
    dispatch(
      setFormPlaybackDirty({
        listType: NotSavedListType.Playback,
        isFormPlaybackDirty: dirty,
      }),
    );
  }, [dirty]);

  const isError = hasObjectAnyValues(errors);

  const onFieldValueChange = useCallback((name: string, value: string | number) => {
    setFieldValue(name, value);
  }, []);

  return {
    t,
    rule,
    isError,
    formik,
    onFieldValueChange,
  };
};
