const styles = {
  main: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(auto-fill, minmax(calc(100%), 1fr))',
      sm: 'repeat(auto-fill, minmax(calc(50% - 10px), 1fr))',
      bg: 'repeat(auto-fill, minmax(calc(50% - 10px), 1fr))',
      md: 'repeat(auto-fill, minmax(calc(50% - 10px), 1fr))',
      xl: 'repeat(auto-fill, minmax(calc(33.33% - 10px), 1fr))',
    },
    gap: '10px',
    width: '100%',
  },
};

export { styles };
