import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import apolloClient from '@/apollo';
import {
  KeycloakProvider,
  ClientConfigProvider,
  ActiveUserProvider,
  ToastNotifications,
  AudioPlayerProvider,
} from '@/components';
import { theme } from '@/constants';
import i18n from '@/i18n';
import store from '@/redux';
import AppRouter from '@/router';
import './styles.css';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement as Element);
root.render(
  <I18nextProvider i18n={i18n}>
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <ClientConfigProvider>
              <KeycloakProvider>
                <ActiveUserProvider>
                  <AudioPlayerProvider>
                    <AppRouter />
                  </AudioPlayerProvider>
                  <ToastNotifications />
                </ActiveUserProvider>
              </KeycloakProvider>
            </ClientConfigProvider>
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </I18nextProvider>,
);
