import React from 'react';

import { SvgIcon } from '@mui/material';

import type { SvgIconProps } from '@mui/material';

import styles from '@/components/shared/icons/styles';
import { theme } from '@/constants';
import { mergeSx } from '@/utils';

export const MyMusicIcon = ({
  viewBox = '0 0 24 24',
  fill = theme.palette.primary.main,
  sx = {},
  ...rest
}: SvgIconProps) => (
  <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 5H2.5V7H14.5V5ZM21.5 5H16.5V13.18C16.19 13.07 15.85 13 15.5 13C13.84 13 12.5 14.34 12.5 16C12.5 17.66 13.84 19 15.5 19C17.16 19 18.5 17.66 18.5 16V7H21.5V5ZM2.5 9H14.5V11H2.5V9ZM10.5 13H2.5V15H10.5V13ZM15.5 15C14.95 15 14.5 15.45 14.5 16C14.5 16.55 14.95 17 15.5 17C16.05 17 16.5 16.55 16.5 16C16.5 15.45 16.05 15 15.5 15Z"
      fill={fill}
    />
  </SvgIcon>
);
