import { useMemo } from 'react';

import type { ContentItem } from '@/graphql-schema';

import { pathToDefaultImg, pathToPersonalImg } from '@/constants';
import { useClientConfig } from '@/context';
import { ContentTypeEnum } from '@/graphql-schema';
import { getUrlForImg, transformIdToPath } from '@/utils';

export const useGetContentImage = (contentItem: ContentItem) => {
  const {
    config: { contentDiyDefault, contentPicDefault },
  } = useClientConfig();

  const isPersonalContent = contentItem?.contentType === ContentTypeEnum.Personal;

  const defaultDiyImage = contentDiyDefault
    ? transformIdToPath(contentDiyDefault)
    : pathToPersonalImg;

  const defaultGlobalImage = contentPicDefault
    ? transformIdToPath(contentPicDefault)
    : pathToDefaultImg;

  const defaultImage = isPersonalContent ? defaultDiyImage : defaultGlobalImage;

  const imgUrl = useMemo(
    () => getUrlForImg(!!contentItem?.hasPicture, contentItem?.contentNo, defaultImage),
    [contentItem?.contentNo],
  );

  return imgUrl ?? '';
};
