import { createSlice } from '@reduxjs/toolkit';

import type { IMobileDrawerState } from './types';

const initialState: IMobileDrawerState = {
  isMobileDrawerOpen: false,
};
export const mobileDrawerSlice = createSlice({
  name: 'mobileDrawer',
  initialState,
  reducers: {
    openMobileDrawer: (state) => {
      state.isMobileDrawerOpen = true;
    },
    closeMobileDrawer: (state) => {
      state.isMobileDrawerOpen = false;
    },
  },
});

export const { openMobileDrawer, closeMobileDrawer } = mobileDrawerSlice.actions;

export default mobileDrawerSlice.reducer;
