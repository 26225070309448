import { useEffect, useState } from 'react';

import Keycloak from 'keycloak-js';

import { updateAuthorizationToken } from '@/apollo';
import { useClientConfig } from '@/context';

export const useKeycloakProvider = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [keycloak, setKeycloak] = useState<Keycloak | null>(null);

  const {
    config: { keycloak: keycloakConfig, isTestMode, testMsisdn },
  } = useClientConfig();

  const keycloakToken = keycloak?.tokenParsed?.msisdn;
  const msisdn = isTestMode
    ? testMsisdn
    : isLoaded
    ? keycloakToken
      ? `${keycloakToken}`
      : null
    : null;

  useEffect(() => {
    const { api, realm, clientId } = keycloakConfig;
    const initKeycloak = async () => {
      if (!api || !realm || !clientId) return;

      const keycloakService = new Keycloak({
        url: api,
        realm,
        clientId,
      });

      try {
        await keycloakService.init({
          onLoad: 'check-sso',
          checkLoginIframe: false,
        });

        const updateAndSaveToken = async () => {
          try {
            await keycloakService.updateToken(0);
            const { token } = keycloakService;
            if (token) {
              updateAuthorizationToken(token);
            }
          } catch (error) {
            console.error('Failed to update and save token: ', error);
          }
        };

        keycloakService.onTokenExpired = () => {
          updateAndSaveToken();
        };

        const tokenRefreshInterval = setInterval(() => {
          updateAndSaveToken();
        }, 10 * 60 * 1000);

        const { authenticated, token } = keycloakService;

        if (authenticated) {
          if (keycloakService?.isTokenExpired()) {
            updateAndSaveToken();
          } else {
            updateAuthorizationToken(token ?? '');
          }
        }
        setKeycloak(keycloakService);
        setIsLoaded(true);
        return () => {
          clearInterval(tokenRefreshInterval); // Прибираємо інтервал при завершенні
        };
      } catch (error) {
        console.error('Failed to initialize Keycloak: ', error);
      }
    };
    if (isTestMode) return;
    initKeycloak();
  }, [keycloakConfig]);

  return { isLoaded, msisdn, keycloak };
};
