import type { ChangeEvent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { allowedAudioFormats, getFileNameWithoutExtension } from './constants';

import { meloringTitleRegExp } from '@/constants';
import { useAudioPlayer } from '@/context';
import { PathTypeEnum } from '@/graphql-schema';
import { useFileUploadLimit, useUploadedPlayAudio, useUploadPersonalContent } from '@/hooks';
import { notSavedChangesSelector } from '@/redux/not-saved-changes/selectors';
import { clearUploadContentDirty, setIsUploadContentDirty } from '@/redux/not-saved-changes/slice';
import { changeAudioSelectedStatus } from '@/redux/record-my-meloring/slice';
import { truncateString } from '@/utils';

export const useUploadAudioCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fileInput = useRef<HTMLInputElement>(null);
  const {
    isPlaying,
    stopPlaying: stopPlayingUpload,
    selectedFile,
    selectAudioFile,
    deleteAudio: deleteRecordedAudio,
    togglePlayAudio,
  } = useUploadedPlayAudio();
  const { stopPlaying } = useAudioPlayer();
  const { isUploadContentDirty } = useSelector(notSavedChangesSelector);

  const fileName = selectedFile?.name;

  const { loading, isDiyAudioSelected, uploadPersonalContent } = useUploadPersonalContent();

  const [title, setTitle] = useState('');

  const { isSizeLarger, fileSizeLimit } = useFileUploadLimit(selectedFile);

  const isEmptyTextError = useMemo(
    () => !!selectedFile && (!title.trim().length || meloringTitleRegExp.test(title.trim())),
    [selectedFile, title],
  );

  useEffect(() => {
    if (selectedFile) {
      dispatch(setIsUploadContentDirty(true));
    }
  }, [selectedFile]);

  const isFormatError = selectedFile && !allowedAudioFormats.includes(selectedFile?.type);

  const isBtnDisabled =
    (isDiyAudioSelected && !selectedFile) ||
    isFormatError ||
    isSizeLarger ||
    (!!selectedFile && !title.length) ||
    loading ||
    isEmptyTextError;

  const isPlayBtnDisabled = isSizeLarger || !!isFormatError;

  useEffect(() => {
    const isTitleEmpty = !title.trim().length;
    if (selectedFile?.name && isTitleEmpty) {
      const fileName = getFileNameWithoutExtension(selectedFile.name);
      setTitle(truncateString(fileName, 200));
    }
    if (!selectedFile) setTitle('');
  }, [selectedFile]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      dispatch(changeAudioSelectedStatus(true));
      selectAudioFile(file);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      if (isUploadContentDirty) dispatch(clearUploadContentDirty());
      dispatch(clearUploadContentDirty());
      uploadPersonalContent(selectedFile, title.trim(), PathTypeEnum.Upload).then(() => {
        stopPlaying();
        stopPlayingUpload();
        setTitle('');
        deleteRecordedAudio();
        dispatch(clearUploadContentDirty());
      });
    } else {
      fileInput.current?.click();
    }
  };

  const deleteAudio = () => {
    if (isDiyAudioSelected) dispatch(changeAudioSelectedStatus(false));
    dispatch(clearUploadContentDirty());
    deleteRecordedAudio();
  };

  const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  return {
    t,
    title,
    loading,
    fileName,
    fileInput,
    isPlaying,
    selectedFile,
    fileSizeLimit,
    isBtnDisabled,
    isSizeLarger,
    isFormatError,
    isEmptyTextError,
    isPlayBtnDisabled,
    deleteAudio,
    handleUpload,
    togglePlayAudio,
    handleFileChange,
    onTitleChange,
  };
};
