import { iconButtonClasses, radioClasses, svgIconClasses } from '@mui/material';

import { baseTheme, theme } from '@/constants';

export const styles = {
  inputControl: {
    p: 1.25,
    backgroundColor: 'grey.200',
    gap: 1.25,
    border: `1px solid transparent`,
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        border: `1px solid ${theme.palette.grey['500']}`,
      },
    },
    width: '100%',
  },
  input: {
    p: 0,
    color: 'grey.dark',
    fontSize: 16,
  },
  searchIcon: {
    color: 'grey.dark',
  },
  menuItem: {
    justifyContent: 'start',
    padding: 1.25,
    backgroundColor: 'grey.200',
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.400',
      },
    },
    mark: {
      backgroundColor: 'primary.yellow.60',
      color: 'text.primary',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
    ml: 1,
    width: 'calc(100% - 48px)',
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: 'text.secondary',
  },
  songTitle: {
    color: 'text.primary',
  },
  arrow: {
    pointerEvents: 'none',
    p: 0,
    ml: 'auto',
    [`.${svgIconClasses.root}`]: {
      fontSize: 18,
    },
  },
  buttons: {
    display: 'flex',
    gap: 1,
    width: 20,
    height: 20,
  },
  inputButton: {
    height: 20,
    width: 20,
    p: 0,
    svg: {
      fontSize: 18,
      color: 'blue.100',
    },
    pointerEvents: 'all',
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.400',
      },
    },
  },
  selectControl: {
    minWidth: {
      xs: '100%',
      md: 238,
      lg: 300,
      xl: 413,
      cursor: { md: 'pointer', xs: 'default' },
    },
    [`.${svgIconClasses.root}`]: {
      display: 'none',
    },
  },
  paper: {
    maxHeight: {
      xs: 140,
      md: 240,
    },
    boxShadow: `0px 6px 16px 0px ${theme.palette.grey[900]}`,
    borderRadius: '0 0 22px 22px',
    minHeight: 40,
    backgroundColor: 'grey.200',
    borderTop: `1px solid ${theme.palette.grey['700']}`,
  },
  selectItem: {
    justifyContent: 'space-between',
    p: 1.25,
    backgroundColor: 'grey.200',
    [`& .${radioClasses.root}`]: {
      padding: 0,
      color: 'text.primary',
      '& svg': {
        height: 18,
        width: 18,
      },
      '&.Mui-checked': {
        color: 'blue.50',
      },
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'grey.200',
    },
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.400',
      },
    },
  },
  nothingFoundText: {
    color: 'grey.dark',
    mx: 'auto',
    width: 'fit-content',
    mt: 1.25,
    textAlign: 'center',
  },
  selectPaper: {
    borderRadius: 2,
    mt: 0.15,
    boxShadow: `0px 6px 16px 0px ${theme.palette.grey[900]}`,
    backgroundColor: 'grey.200',
  },

  dropdown: {
    position: 'absolute',
    maxHeight: 240,
    overflow: 'hidden',
    boxShadow: `0px 6px 16px 0px ${theme.palette.grey[900]}`,
    borderRadius: '0 0 22px 22px',
    minHeight: 60,
    backgroundColor: 'grey.200',
    borderTop: `1px solid ${theme.palette.grey['700']}`,
    zIndex: 1099,
    overflowY: 'auto',
  },
  errorBox: {
    py: 1.5,
    px: 1.25,
    mt: 1.5,
    color: 'error.main',
    borderTop: `1px solid ${theme.palette.grey['700']}`,
    textAlign: 'center',
  },
  searchButton: {
    backgroundColor: 'grey.200',
    borderRadius: 1.5,
    height: 46,
    width: 46,
    [`& .${svgIconClasses.root}`]: {
      color: 'text.primary',
    },
    [`&.${iconButtonClasses.disabled}`]: {
      backgroundColor: 'grey.100',
    },
  },
  returnButton: {
    transform: 'rotate(180deg)',
  },
  container: {
    display: 'flex',
    gap: 1,
  },
  searchFieldContainer: {
    position: 'relative',
    width: '100%',
  },
} as const;
