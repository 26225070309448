import React from 'react';

import { Box, type BoxProps } from '@mui/material';

import { styles } from './styles';

import { mergeSx } from '@/utils';

const ContainerContent = (props: BoxProps) => {
  return <Box sx={mergeSx(styles.container, props.sx)} {...props} />;
};

export default ContainerContent;
