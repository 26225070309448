export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    mt: 1,
    height: '100%',
  },
  member: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'grey.400',
    borderRadius: 12,
    px: 2,
    py: 1.5,
    // minWidth: 144,
    mr: 1,
    mb: 1,
    overflow: 'hidden',
  },
  members: {
    display: 'flex',
    flexWrap: 'wrap',
    transition: 'max-height 0.5s ease-out',
    overflow: 'hidden',
  },
  hideBtn: {
    py: 1,
    backgroundColor: 'grey.100',
    '&:hover': {
      backgroundColor: { xs: 'grey.100', md: 'grey.400' },
    },
  },
  phoneNumber: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
} as const;
