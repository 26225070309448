import React from 'react';

import { Box, Button } from '@mui/material';

import { styles } from './styles';
import { IButtonsGroupProps } from './types';
import { useContentButtonsGroup } from './useContentButtonsGroup';

import {
  FavoriteFilledIcon,
  FavoriteIcon,
  GiftIcon,
  NetworkButtons,
  PlayButton,
  ShareIcon,
  TrashIcon,
} from '@/components';
import { theme } from '@/constants';

const ContentButtonsGroup: React.FC<IButtonsGroupProps> = (props) => {
  const { contentItem, isComplexContent, isComplexSubItem } = props;
  const {
    toggleButton,
    onToggle,
    isFavouriteContent,
    isFavouriteButtonVisible,
    isGiftButtonVisible,
    isPersonalContent,
    isMyMusicPage,
    onFavouriteClick,
    openModalForGift,
    onContentRemove,
  } = useContentButtonsGroup(props);

  return toggleButton ? (
    <Box style={styles.buttons}>
      {!isComplexContent && (
        <Box style={styles.playButton}>
          <PlayButton contentNo={contentItem.contentNo} />
        </Box>
      )}
      {!isComplexSubItem && isGiftButtonVisible && (
        <Box style={styles.buttonContainer}>
          <Button
            sx={styles.button}
            disableFocusRipple
            disableRipple
            onClick={openModalForGift}
            endIcon={<GiftIcon />}
          />
        </Box>
      )}
      {!isComplexSubItem && isFavouriteButtonVisible && (
        <Box style={styles.buttonContainer}>
          <Button
            sx={{
              ...styles.button,
              ...(isFavouriteContent ? styles.favouriteBtn : {}),
            }}
            disableFocusRipple
            disableRipple
            endIcon={isFavouriteContent ? <FavoriteFilledIcon /> : <FavoriteIcon />}
            onClick={onFavouriteClick}
          />
        </Box>
      )}
      {!isPersonalContent && (
        <Box style={styles.buttonContainer}>
          <Button
            sx={styles.button}
            disableFocusRipple
            disableRipple
            endIcon={<ShareIcon />}
            onClick={onToggle}
          />
        </Box>
      )}
      {isMyMusicPage && (
        <Box style={styles.buttonContainer}>
          <Button
            sx={styles.button}
            disableFocusRipple
            disableRipple
            onClick={onContentRemove}
            endIcon={<TrashIcon fill={theme.palette.text.secondary} />}
          />
        </Box>
      )}
    </Box>
  ) : (
    <NetworkButtons
      isComplexSubItem={isComplexSubItem}
      onToggle={onToggle}
      contentItem={contentItem}
    />
  );
};

export default ContentButtonsGroup;
