import { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';

import { useKeycloak } from '@/context';
import { GET_HAPPY_HOUR_CONTENT } from '@/graphqL-queries/contents';
import { setHappyHourData, clearHappyHourData } from '@/redux/happy-hour';

const useHappyHourData = () => {
  const dispatch = useDispatch();
  const { isLoaded } = useKeycloak();

  const { data } = useQuery(GET_HAPPY_HOUR_CONTENT, {
    fetchPolicy: 'network-only',
    skip: !isLoaded,
  });

  const bannerData = data?.happyHours;

  useEffect(() => {
    if (bannerData) {
      dispatch(setHappyHourData(bannerData));
    } else {
      dispatch(clearHappyHourData());
    }
  }, [bannerData]);
};

export default useHappyHourData;
