const styles = {
  item: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  wrapperButtons: {
    height: '100%',
    display: 'flex',
    alignItems: 'end',
  },
  containerButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 2,
    mt: 1,
    width: '100%',
  },
  buttonAccept: {
    p: 1.5,
    height: 40,
  },
};

export { styles };
