import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { toast } from 'react-toastify';

import type { IClientConfigType } from '@/types';

import { apiUrl, ErrorCodes, StorageNames } from '@/constants';
import { getSessionStorageObject } from '@/helpers';
import i18n from '@/i18n';
import { shortenString } from '@/utils';

const uri = `${apiUrl}/rbt-graphql`;

const uploadLink = createUploadLink({ uri });

const createAuthLink = (token: string | null) => {
  const config = getSessionStorageObject<IClientConfigType>(StorageNames.ClientConfig);

  const authorization = config?.isTestMode
    ? config.testMsisdn ?? ''
    : token
    ? `Bearer ${token}`
    : '';

  return setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization,
    },
  }));
};

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors?.length) {
    graphQLErrors.map(({ message, extensions }) => {
      if (
        extensions.code !== ErrorCodes.NO_RBT_AUTHORIZED &&
        extensions.code !== ErrorCodes.CONTENT_NOT_FOUND
      ) {
        const errorMessage = extensions?.code ? message : i18n.t('invalidRequest');
        toast.error(shortenString(errorMessage, 35));
      }
    });
  }
});

const createClientLink = (token: string | null) =>
  //TODO: remove any issue
  ApolloLink.from([errorLink, createAuthLink(token), uploadLink as any]);

const client = new ApolloClient({
  link: createClientLink(null),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

export const updateAuthorizationToken = (token: string | null = null) => {
  client.setLink(createClientLink(token));
};

export default client;
