import i18n from '@/i18n';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getMonthName = (num: number) => {
  if (num >= 1 && num <= 12) {
    const month = months[num - 1];
    return i18n.t(`months.${month}`);
  } else {
    return 'Invalid month number';
  }
};
