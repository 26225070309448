export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
  },
  member: {
    width: '100%',
    display: 'flex',
    alignItems: {
      bg: 'flex-start',
      xs: 'flex-end',
    },
    justifyContent: 'space-between',
    mt: 3,
  },
  formWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: {
      bg: 'row',
      xs: 'column',
    },
  },
  formControl: {
    width: {
      bg: '48%',
      xs: '100%',
    },
    mb: {
      bg: 0,
      xs: 1,
    },
  },
  trashButton: {
    mt: {
      bg: 3,
      xs: 0,
    },
    height: {
      bg: 'auto',
      xs: '100%',
    },
    ml: {
      bg: 1,
      xs: 0,
    },
  },
  trashButtonWithError: {
    alignSelf: 'center',
  },
  divider: {
    my: 4,
    width: '100%',
  },
  iconWrapper: {
    height: 120,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'grey.200',
    ml: {
      bg: 0,
      xs: 1,
    },
    mb: {
      bg: 0,
      xs: 1,
    },
    mt: {
      bg: 0,
      xs: 2.5,
    },
    borderRadius: 1,
    px: 0.8,
  },
  helperText: {
    width: '100%',
  },
} as const;
