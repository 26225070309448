const styles = {
  root: {
    fill: 'none',
    fontSize: {
      sm: 24,
      xs: 20,
    },
    color: 'black',
  },
} as const;
export default styles;
