import { listClasses, menuClasses } from '@mui/material';

import { baseTheme } from '@/constants';

export const styles = {
  selected: {
    '&& .Mui-selected': {
      backgroundColor: 'grey.400',
      [baseTheme.breakpoints.up('md')]: {
        '&:hover': {
          backgroundColor: 'grey.500',
        },
      },
      [baseTheme.breakpoints.down('md')]: {
        '&:hover': {
          backgroundColor: 'grey.400',
        },
      },
    },
    [`&.${menuClasses.root}`]: {
      marginTop: 0.125,
    },
  },
  paperProps: {
    boxShadow: 'none',
    borderRadius: 8,
    [`&.${listClasses.root}`]: {
      margin: 1,
    },
    [`&.${menuClasses.root}`]: {
      margin: 1,
    },
  },
  input: {
    display: 'flex',
    alignItems: 'center',
  },
} as const;
