import { createSlice } from '@reduxjs/toolkit';

import { IDisableServiceState } from './types';

const initialState: IDisableServiceState = {
  isModalOpen: false,
  isModalNotificationOpen: false,
};

export const disableServiceSlice = createSlice({
  name: 'disableService',
  initialState,
  reducers: {
    openDisableServiceModal: (state) => {
      state.isModalOpen = true;
    },
    closeDisableServiceModal: (state) => {
      state.isModalOpen = false;
    },
    openDisableServiceNotificationModal: (state) => {
      state.isModalNotificationOpen = true;
    },
    closeDisableServiceNotificationModal: (state) => {
      state.isModalNotificationOpen = false;
    },
  },
});

export const {
  openDisableServiceModal,
  closeDisableServiceModal,
  openDisableServiceNotificationModal,
  closeDisableServiceNotificationModal,
} = disableServiceSlice.actions;

export default disableServiceSlice.reducer;
