import { menuClasses, outlinedInputClasses } from '@mui/material';

import { baseTheme } from '@/constants';

export const styles = {
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    my: 1,
    p: 1,
    borderRadius: 2,
  },
  formControl: {
    [` .${outlinedInputClasses.root}`]: {
      border: 'none',
    },
    width: '50%',
  },
  select: {
    width: '100%',
    borderRadius: 2,
    height: 35,
  },
  content: {
    backgroundColor: 'grey.100',
  },
  closeIcon: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  menuItem: {
    height: 35,
    px: 0,
    pl: 1.5,
    backgroundColor: 'grey.400',
    '&.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
      backgroundColor: 'grey.400',
    },
    [baseTheme.breakpoints.down('md')]: {
      '&:hover': {
        backgroundColor: 'grey.400',
      },
    },
  },
  menuPropsSx: {
    [`.${menuClasses.list}`]: {
      overflow: 'hidden',
    },
  },
};
