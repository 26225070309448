import { gql } from '@apollo/client';

import type {
  IContentItemResponse,
  IContentResponse,
  IHappyHourResponse,
  IPromotionContentResponse,
  ISubscriberResponse,
} from '@/types';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';

export const GET_CONTENTS: TypedDocumentNode<IContentResponse> = gql(/* GraphQL */ `
  query GetContent($filter: ContentFilterInput, $offset: Int, $pageSize: Int) {
    contentItems(filter: $filter, offset: $offset, pageSize: $pageSize) {
      elements {
        artist
        title
        writer
        contentNo
        composer
        virtContentNo
        amountOnetime
        amountPeriodic
        onetimePeriod
        chargePeriod
        hasPicture
        complexType
        providerId
      }
      numberOfElements
    }
  }
`);

export const GET_PROMO_CONTENT: TypedDocumentNode<IPromotionContentResponse> = gql`
  query PromoContentItems($promoListCd: String!) {
    promoContentItems(promoListCd: $promoListCd) {
      artist
      title
      writer
      contentNo
      composer
      virtContentNo
      amountOnetime
      amountPeriodic
      onetimePeriod
      chargePeriod
      hasPicture
      complexType
      providerId
    }
  }
`;

export const GET_SUBSCRIBER_PROMO_CONTENT: TypedDocumentNode<ISubscriberResponse> =
  gql(/* GraphQL */ `
    query subscriberContent($msisdn: String!, $promoListCd: String!) {
      subscriber(subsIdent: $msisdn) {
        promoContentItems(promoListCd: $promoListCd) {
          amountPeriodic
          chargePeriod
          contentNo
          contentType
          virtContentNo
          complexType
          title
          favourite
          artist
          hasPicture
          amountOnetime
          onetimePeriod
          providerId
        }
      }
    }
  `);

export const GET_SUBSCRIBER_CONTENT: TypedDocumentNode<ISubscriberResponse> = gql(/* GraphQL */ `
  query subscriber($msisdn: String!) {
    subscriber(subsIdent: $msisdn) {
      permit
      inboxContentItems {
        contentNo
        virtContentNo
        artist
        composer
        complexType
        endDt
        contentEndDt
        priceModelNo
        title
        amountPeriodic
        chargePeriod
        startDt
        contentState
        amountOnetime
        onetimePeriod
        chargeState
        hasPicture
        contentType
        graceDt
        authName
        amountOnetime
        providerId
      }
      giftBoxContentItems {
        amountOnetime
        amountPeriodic
        artist
        chargePeriod
        complexType
        composer
        contentEndDt
        contentNo
        contentState
        createdDt
        endDt
        hasPicture
        priceModelNo
        refIdent
        title
        virtContentNo
        chargeState
        hasPicture
      }
    }
  }
`);

export const GET_SUBSCRIBER: TypedDocumentNode<ISubscriberResponse> = gql(/* GraphQL */ `
  query subscriber($msisdn: String!) {
    subscriber(subsIdent: $msisdn) {
      subscriptions {
        serviceNo
      }
    }
  }
`);

export const GET_COPIABLE_CONTENTS: TypedDocumentNode<ISubscriberResponse> = gql(/* GraphQL */ `
  query Subscriber($msisdn: String!, $sourceSubsIdent: String!) {
    subscriber(subsIdent: $msisdn) {
      subsIdent
      copiableContentItems(sourceSubsIdent: $sourceSubsIdent) {
        artist
        title
        writer
        favourite
        contentNo
        composer
        virtContentNo
        amountOnetime
        amountPeriodic
        onetimePeriod
        chargePeriod
        hasPicture
        complexType
        providerId
      }
    }
  }
`);

export const GET_SUBSCRIBER_CONTENT_ITEM = gql(/* GraphQL */ `
  query subscriberContent($msisdn: String!, $virtContentNo: Long!) {
    subscriber(subsIdent: $msisdn) {
      contentItem(virtContentNo: $virtContentNo) {
        artist
        title
        writer
        contentNo
        composer
        virtContentNo
        amountPeriodic
        onetimePeriod
        chargePeriod
        hasPicture
        favourite
        complexType
        amountOnetime
        providerId
        contentSubItems {
          title
          artist
          contentNo
          hasPicture
          virtContentNo
          amountPeriodic
          chargePeriod
        }
      }
    }
  }
`);

export const GET_CONTENT_ITEM: TypedDocumentNode<IContentItemResponse> = gql(/* GraphQL */ `
  query GetContentItem($virtContentNo: Long!) {
    contentItem(virtContentNo: $virtContentNo) {
      artist
      title
      writer
      contentNo
      composer
      virtContentNo
      amountOnetime
      amountPeriodic
      onetimePeriod
      chargePeriod
      hasPicture
      complexType
      providerId
      contentSubItems {
        title
        artist
        contentNo
        hasPicture
        virtContentNo
        amountPeriodic
        chargePeriod
      }
    }
  }
`);

export const SUBSCRIBER_CONTENT_REMOVE: TypedDocumentNode<{}> = gql(/* GraphQL */ `
  mutation InboxContentRemove($input: InboxContentRemoveInput) {
    inboxContentRemove(input: $input) {
      error {
        code
        message
      }
    }
  }
`);

export const GET_SENT_PRESENTS: TypedDocumentNode<ISubscriberResponse> = gql(/* GraphQL */ `
  query getSentPresent($msisdn: String!) {
    subscriber(subsIdent: $msisdn) {
      giftBoxContentItems(sentGifts: true) {
        artist
        chargeState
        contentNo
        createdDt
        endDt
        hasPicture
        subsIdent
        title
        virtContentNo
        complexType
      }
    }
  }
`);

export const GET_GIFT_BOX_CONTENTS: TypedDocumentNode<ISubscriberResponse> = gql(/* GraphQL */ `
  query getGiftBoxContent($msisdn: String!) {
    subscriber(subsIdent: $msisdn) {
      giftBoxContentItems {
        amountPeriodic
        artist
        chargePeriod
        complexType
        composer
        contentEndDt
        contentNo
        contentState
        createdDt
        endDt
        hasPicture
        priceModelNo
        refIdent
        title
        virtContentNo
        chargeState
        hasPicture
        amountOnetime
        onetimePeriod
      }
    }
  }
`);

export const CREATE_FAVOURITE_CONTENT = gql(/* GraphQL */ `
  mutation FavouriteContentCreate($input: FavouriteContentCreateInput) {
    favouriteContentCreate(input: $input) {
      error {
        code
        message
      }
    }
  }
`);

export const DELETE_FAVOURITE_CONTENT: TypedDocumentNode<ISubscriberResponse> = gql(/* GraphQL */ `
  mutation FavouriteContentDelete($input: FavouriteContentDeleteInput) {
    favouriteContentDelete(input: $input) {
      error {
        code
        message
      }
    }
  }
`);

export const GET_HAPPY_HOUR_CONTENT: TypedDocumentNode<IHappyHourResponse> = gql(/* GraphQL */ `
  query HappyHours {
    happyHours {
      amountOnetime
      createdDt
      endDt
      hhName
      hhPriceId
      onetimePeriod
      providerId
      providerName
      startDt
    }
  }
`);
