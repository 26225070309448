import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import styles from '../styles';

import { theme } from '@/constants';
import { mergeSx } from '@/utils';

function DownloadIcon({
  viewBox = '0 0 17 16',
  fill = theme.palette.text.primary,
  sx = {},
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
      <path
        d="M11.7633 4.23366C10.7966 3.26699 9.46992 2.66699 7.99659 2.66699C5.04992 2.66699 2.66992 5.05366 2.66992 8.00033C2.66992 10.947 5.04992 13.3337 7.99659 13.3337C10.4833 13.3337 12.5566 11.6337 13.1499 9.33366H11.7633C11.2166 10.887 9.73659 12.0003 7.99659 12.0003C5.78992 12.0003 3.99659 10.207 3.99659 8.00033C3.99659 5.79366 5.78992 4.00033 7.99659 4.00033C9.10326 4.00033 10.0899 4.46033 10.8099 5.18699L8.66326 7.33366H13.3299V2.66699L11.7633 4.23366Z"
        fill={fill}
      />
    </SvgIcon>
  );
}

export default DownloadIcon;
