import { paperClasses } from '@mui/material';

import { theme } from '@/constants';
import { addOpacityToHexColor } from '@/utils';

export const styles = {
  mainContainer: {
    backgroundColor: 'background.default',
    pt: {
      xs: 14.5,
      md: 8.6,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    mt: 4,
    display: 'flex',
    pb: 3,
    width: '100%',
    px: 2,
    boxSizing: 'border-box',
  },
  card: {
    mr: 2,
    px: 2,
    py: 3,
    gap: 8,
    borderRadius: 2,
    width: 303,
    maxHeight: 'calc(100vh - 101px - 24px)',
    overflowY: 'auto',
    [`.${paperClasses.root}`]: {
      height: 'fit-container',
    },
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    mx: 'auto',
  },
  divider: {
    height: 'revert',
    mr: 2,
    backgroundColor: addOpacityToHexColor(theme.palette.grey['700'], 0.2),
  },
  bannerWrapper: {
    width: '100%',
  },
  sideBar: {
    position: 'sticky',
    top: 101,
    height: 'min-content',
  },
  mobileContainer: {
    minHeight: {
      xs: 'calc(100vh - 360px)',
      md: 'calc(100vh - 80px)',
    },
  },
};
