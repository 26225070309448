import React from 'react';

import { IconButton } from '@mui/material';

import { styles } from './styles';

import type { ButtonCrossProps } from './types';

import { CrossIcon } from '@/components';
import { mergeSx } from '@/utils';

const ButtonCross = ({ sx, onClick }: ButtonCrossProps) => {
  return (
    <IconButton sx={mergeSx(styles.main, sx)} onClick={onClick}>
      <CrossIcon />
    </IconButton>
  );
};

export default ButtonCross;
