import { buttonClasses, typographyClasses } from '@mui/material';

import { baseTheme } from '@/constants';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  containerButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 1.5,
  },
  playButton: {
    flex: 1,
    maxWidth: 90,
  },
  buttonWrapper: {
    flex: 1,
  },
  networkButton: {
    height: 32,
    p: 0.5,
    borderRadius: 8,
    [`& .${buttonClasses.endIcon}`]: {
      margin: 0,
      '&>:nth-of-type(1)': {
        fontSize: 24,
      },
    },
    [baseTheme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: 'grey.400',
      },
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'calc(100% - 106px)',
    [`.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      wordWrap: 'break-word',
    },
  },
  itemTitle: {
    color: 'text.primary',
    fontWeight: 700,
    overflow: 'hidden',
    [`&.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      wordBreak: 'break-word',
    },
  },
  itemArtist: {
    cursor: 'pointer',
    mt: 1,
    overflow: 'hidden',
    color: 'text.secondary',
    [`&.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      wordBreak: 'break-word',
    },
  },
  isOneLineRow: {
    [`&.${typographyClasses.root}`]: {
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical',
      wordBreak: 'break-word',
    },
  },
};

export { styles };
