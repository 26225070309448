import React from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';
import { useSentGiftCard } from './useSentGiftCard';

import { CustomContentCard, InfoCard, InfoCardWithTime } from '@/components';
import { GiftBoxContentItem, GiftChargeStateEnum } from '@/graphql-schema';

const SentGiftCard = (props: GiftBoxContentItem) => {
  const { chargeState, subsIdent } = props;
  const { t, until, formatEndDt, formatEndTime, formatCreatedDt, formatCreatedTime } =
    useSentGiftCard(props);
  return (
    <CustomContentCard {...props}>
      <Box sx={styles.item}>
        <InfoCard title={t('componentSentPresentCard.giftFor')} description={`${subsIdent}`} />
        <InfoCardWithTime
          title={`${t('componentSentPresentCard.sentAt')}`}
          date={formatCreatedDt}
          time={formatCreatedTime}
        />
        <InfoCardWithTime title={until} date={formatEndDt} time={formatEndTime} />
      </Box>
      <Box sx={styles.labelContainer}>
        {chargeState !== GiftChargeStateEnum.Activated && (
          <Box sx={styles.label}>
            <Typography variant="h4" color="primary.blue">
              {t('componentSentPresentCard.notBought')}
            </Typography>
          </Box>
        )}
      </Box>
    </CustomContentCard>
  );
};

export default SentGiftCard;
