import React from 'react';

import { Card } from '@mui/material';

import { styles } from './styles';

import type { SxProps } from '@mui/material';

import { mergeSx } from '@/utils';

interface ResponsiveCardComponentProps {
  sx?: SxProps;
  children: React.ReactNode;
}
const ResponsiveCardComponent: React.FC<ResponsiveCardComponentProps> = ({ sx = {}, children }) => {
  return <Card sx={mergeSx(styles.card, sx)}>{children}</Card>;
};

export default ResponsiveCardComponent;
