import { createSlice } from '@reduxjs/toolkit';

import type { INotSavedChangesState } from './types';

const initialState: INotSavedChangesState = {
  isModalOpen: false,
  isAcceptedClose: false,
  listType: null,
  isFormGroupDirty: false,
  isFormPlaybackDirty: false,
  isRecordContentDirty: false,
  isUploadContentDirty: false,
};

export const notSavedChangesSlice = createSlice({
  name: 'notSavedChanges',
  initialState,
  reducers: {
    openNotSavedModal: (state, { payload }) => {
      state.listType = payload;
      state.isModalOpen = true;
    },
    closeNotSavedModal: (state) => {
      state.isModalOpen = false;
    },
    acceptCloseWithoutSaving: (state) => {
      state.isAcceptedClose = true;
      state.isModalOpen = false;
    },
    clearNotOpenStatus: (state) => {
      state.isModalOpen = false;
      state.isAcceptedClose = false;
    },
    setFormGroupDirty: (state, { payload: { listType, isFormGroupDirty } }) => {
      state.isFormGroupDirty = isFormGroupDirty;
      state.listType = listType;
    },
    clearFormGroupDirty: (state) => {
      state.isFormGroupDirty = false;
      state.listType = null;
    },
    setFormPlaybackDirty: (state, { payload: { listType, isFormPlaybackDirty } }) => {
      state.isFormPlaybackDirty = isFormPlaybackDirty;
      state.listType = listType;
    },
    clearFormPlaybackDirty: (state) => {
      state.isFormPlaybackDirty = false;
      state.listType = null;
    },
    setIsRecordContentDirty: (state, { payload }) => {
      state.isRecordContentDirty = payload;
      state.isAcceptedClose = false;
    },
    clearRecordContentDirty: (state) => {
      state.isRecordContentDirty = false;
      state.isAcceptedClose = false;
    },
    setIsUploadContentDirty: (state, { payload }) => {
      state.isUploadContentDirty = payload;
      state.isAcceptedClose = false;
    },
    clearUploadContentDirty: (state) => {
      state.isUploadContentDirty = false;
      state.isAcceptedClose = false;
    },
  },
});

export const {
  openNotSavedModal,
  closeNotSavedModal,
  acceptCloseWithoutSaving,
  clearNotOpenStatus,
  setFormGroupDirty,
  clearFormGroupDirty,
  clearFormPlaybackDirty,
  setFormPlaybackDirty,
  setIsRecordContentDirty,
  clearRecordContentDirty,
  setIsUploadContentDirty,
  clearUploadContentDirty,
} = notSavedChangesSlice.actions;

export default notSavedChangesSlice.reducer;
