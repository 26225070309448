import { gql } from '@apollo/client';

import type { PhoneGroup } from '@/graphql-schema';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';

export const Update_PHONE_GROUP = gql`
  mutation PhoneGroupUpdate($input: PhoneGroupUpdateInput) {
    phoneGroupUpdate(input: $input) {
      error {
        code
        message
      }
    }
  }
`;

export const CREATE_PHONE_GROUP = gql`
  mutation PhoneGroupCreate($input: PhoneGroupCreateInput) {
    phoneGroupCreate(input: $input) {
      error {
        code
        message
      }
    }
  }
`;

export const GET_PHONE_GROUPS: TypedDocumentNode<{
  subscriber: {
    phoneGroups: Array<PhoneGroup>;
  };
}> = gql(/* GraphQL */ `
  query subscriber($msisdn: String!) {
    subscriber(subsIdent: $msisdn) {
      phoneGroups {
        groupName
        phoneGroupId
        subsIdent
        groupMembers {
          phoneName
          phoneNumber
        }
      }
    }
  }
`);

export const DELETE_GROUP = gql`
  mutation PlayGroupDelete($input: PhoneGroupDeleteInput) {
    phoneGroupDelete(input: $input) {
      error {
        code
        message
      }
    }
  }
`;
