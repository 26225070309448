import { ISearchState, TSearchAction } from './types';

import { theme } from '@/constants';

export enum SearchActionsEnum {
  UPDATE_SEARCH = 'updateSearch',
  CLEAR_SEARCH = 'clearSearch',
  UPDATE_ANCHOR = 'updateAnchor',
  CLEAR_ANCHOR = 'clearAnchor',
  CLEAR_INPUT = 'clearInput',
  UPDATE_FOCUS = 'updateFocus',
}

export enum InputTypeEnum {
  INPUT = 'input',
  SELECT = 'select',
}
export enum FilterTypeEnum {
  TITLE = 'title',
  VIRTUAL_CONTENT_NO = 'virtContentNo',
  ARTIST = 'artist',
}

export const initialArg = {
  anchorEl: null,
  searchValue: '',
};

export const searchLimit = 32;

export const reducer = (state: ISearchState, action: TSearchAction) => {
  switch (action.type) {
    case SearchActionsEnum.UPDATE_SEARCH: {
      return { ...state, searchValue: action.value };
    }
    case SearchActionsEnum.CLEAR_SEARCH: {
      return {
        ...state,
        searchValue: '',
      };
    }
    case SearchActionsEnum.UPDATE_ANCHOR: {
      return {
        ...state,
        anchorEl: action.value,
      };
    }
    case SearchActionsEnum.CLEAR_ANCHOR: {
      return {
        ...state,
        anchorEl: null,
      };
    }
    case SearchActionsEnum.CLEAR_INPUT: {
      return {
        ...state,
        anchorEl: null,
      };
    }
  }
};

export const getNumberValueSx = (length: number) => {
  const gradientColor = theme.palette.text.secondary;
  return {
    letterSpacing: '0.5ch',
    background: `repeating-linear-gradient(90deg, ${gradientColor} 0, ${gradientColor} 1ch,
             transparent 0, transparent 1.5ch) 0 100%/${length * 1.5}ch 1px no-repeat`,
  };
};
