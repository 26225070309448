export const styles = {
  container: {
    flex: 1,
    width: '100%',
    boxSizing: 'border-box',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    rowGap: 3,
    columnGap: 2,
    flexWrap: 'wrap',
    width: '100%',
  },
  pageHeader: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  progress: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    mt: 2,
  },
  mobileCategories: {
    backgroundColor: 'white',
    px: 2,
    py: 3,
    borderRadius: '0 0 16px 16px',
    mb: 5,
  },
  bannerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  pageTitle: {
    mb: {
      xs: 2,
      md: 5,
    },
    fontWeight: 700,
    color: 'blue.100',
  },
} as const;
